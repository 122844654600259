import { loc } from '../../../localizations/localizationHandler';


export const MODES = {
    ADMIN: {
        index: 0,
        message: `Looks like you already know what you're doing!`,
        icon: 'developer_mode'
    },
    PICKUP_SELECT: {
        index: 1,
        message: 'selectHowToManage',
        icon: 'ballot',
        isLoc: true
    },
    PICKUP: {
        index: 2,
        message: 'proceedToNextPickup',
        icon: 'directions_car',
        isLoc: true
    },
    SELECT: {
        index: 3,
        message: 'yourNextPickup',
        icon: 'ballot',
        isLoc: true
    },
    DROPOFF: {
        index: 4,
        message: 'proceedToYourNextDropZone',
        icon: 'directions_car',
        isLoc: true
    },
    START_ROUTE: {
        index: 5,
        message: 'proceedToYourStartingLocation',
        icon: 'directions_car',
        isLoc: true
    },
    LUNCH: {
        index: 6,
        message: 'timeRemainingForLunch',
        icon: 'directions_car',
        isLoc: true
    }
};

export const DRIVER_REASONS_AVAILABLE = [
    { short: 'No bags found / Not refundable', description: 'we had troubles finding your bags', userFault: true },
    { short: 'Could not find / access location', description: 'we had troubles accessing your bags', userFault: true },
    {
        short: 'Bad GPS - Location too far away',
        description: 'we had troubles getting to your location',
        userFault: true
    },
    { short: 'Hazardous', description: 'we were unable to pickup your bags', userFault: true }
];

export const ADMIN_REASONS_AVAILABLE = DRIVER_REASONS_AVAILABLE.concat([
    { short: 'Reschedule', description: 'we have rescheduled your pickup', userFault: false }
]);

export const MODIFIERS = ['confirmed', 'unconfirmed', 'bypassZoneChecks', 'highPriority', 'alwaysBypassZoneChecks'];
