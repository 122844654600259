import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { _collector } from 'std';
import TripsList from './TripsList/';
import moment from 'moment-timezone';

// import DatePickerTz from 'components/DateTimePickersTz/DatePickerTz';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

import { blue } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';
import {
    Tooltip,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Typography,
    Paper
} from '@material-ui/core';
import Badge from 'components/Badge/Badge';
import Icon from '@mdi/react';
import {
    mdiCrosshairsQuestion,
    mdiMapMarker,
    mdiMapMarkerOff,
    mdiMapMarkerQuestion,
    mdiChevronDown,
    mdiChevronRight,
    mdiChevronLeft,
    mdiPlusCircle,
    mdiChevronUp,
    mdiMapMarkerAlert
} from '@mdi/js';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

function DatePickerPanel(props) {
    const {
        theme,
        filterDate,
        timezones,
        timezone,
        trips,
        tripIdsUnchecked,
        pickups,
        pickupsHidden,
        collectors,
        collector,
        selectedNums,
        commoditiesAvailable,
        handlePinClick,
        handleAssignPickupsToTrips,
        onUnservicedFilterDate,
        onPrevFilterDate,
        onNextFilterDate,
        onFilterDateChange,
        onTripDialog,
        onTripToCenter,
        onCheckTrip,
        onChangeTimezone,
        selectedCollector,
        setSelectedCollector,
        recalculatingTrips,
        showAllPickups,
        setShowAllPickups,
        sendETANotifications,
        setSendETANotifications,
        isSystemAdmin,
        onForceRecalculation,
        sendingEtas,
        onSendEtas,
        hidden,
        drivers
    } = props;

    const [collapsed, setCollapsed] = useState(false);
    const [pickupNums, setPickupNums] = useState({ total: 0, notConfirmed: 0, tripless: 0 });
    const [loading, setLoading] = useState(false);
    const [recenterOnChange, setRecenterOnChange] = useState(false);
    const [driversOnVacation, setDriversOnVacation] = useState([]);

    const iconBtnStyles = {
        position: 'absolute',
        bottom: 0,
        padding: 1,
        marginBottom: 12
    };

    const pickupInfoStyles = {
        display: 'flex',
        alignItems: 'center'
    };

    const handleClick = async () => {
        if (!loading) {
            setLoading(true);
            handleAssignPickupsToTrips(setLoading);
        }
    };

    useEffect(() => {
        if (selectedCollector) {
            const tripToCenter = _.find(trips, t => t.collector._id === selectedCollector);
            // if (tripToCenter) {
            //     onTripToCenter(tripToCenter);
            // } else { //^^@rarmclea: Not working properly for some reason?
            if (recenterOnChange) {
                const col = _.find(collectors, c => c._id === selectedCollector);
                if (!_.isNil(col)) {
                    const collectorToCenter = {
                        collector: col,
                        startLocation: {
                            ...col.location
                        },
                        pickups: pickups.filter(p => _.get(p, 'collector._id') === selectedCollector)
                    }; //Object made to mimic a trip to center on object
                    onTripToCenter(collectorToCenter);
                }
            }
        }
    }, [selectedCollector]);

    useEffect(() => {
        let total = 0,
            notConfirmed = 0,
            tripless = 0;

        pickups.forEach(p => {
            if (p.collector && p.collector._id === selectedCollector) {
                total++;
                if (_.isNil(p.trip)) {
                    tripless++;
                }
                if (!p.confirmed) {
                    notConfirmed++;
                }
            }
        });

        setPickupNums({ total, notConfirmed, tripless });
    }, [selectedCollector, filterDate, pickups]);

    useEffect(() => {
        setDriversOnVacation(
            _.filter(
                drivers,
                d =>
                    !d.banned &&
                    !d.disabled &&
                    d.collector._id === selectedCollector &&
                    _.get(d, 'driverVacationDays', [])
                        .map(date => moment(date).toISOString())
                        .includes(moment(filterDate).toISOString())
            )
        );
    }, [selectedCollector, filterDate]);

    return (
        !hidden && (
            <div
                style={{
                    position: 'absolute',
                    left: theme.spacing.unit * 2,
                    top: theme.spacing.unit * 2,
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    maxWidth: 480
                }}
            >
                <Paper style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                    <div style={{ display: 'flex', marginRight: -theme.spacing.unit * 0.5 }}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 100px',
                                alignItems: 'end',
                                flex: '1'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginLeft: -theme.spacing.unit,
                                    position: 'relative',
                                    padding: '0 30px'
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        setRecenterOnChange(false);
                                        onUnservicedFilterDate();
                                    }}
                                    id="pickups-drawer-unserviced-button"
                                    style={{ ...iconBtnStyles, left: 0 }}
                                    size="small"
                                    disabled={recalculatingTrips}
                                >
                                    <Icon path={mdiMapMarkerAlert} size={1} color={theme.palette.text.secondary} />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setRecenterOnChange(false);
                                        onPrevFilterDate();
                                    }}
                                    id="pickups-drawer-leftarrow-button"
                                    style={{ ...iconBtnStyles, left: 20 }}
                                    size="small"
                                    disabled={recalculatingTrips}
                                >
                                    <Icon path={mdiChevronLeft} size={1} color={theme.palette.text.secondary} />
                                </IconButton>
                                <FormControl style={{ left: 15, flexGrow: 1 }} margin="normal">
                                    <DatePicker
                                        autoOk
                                        timezone={timezone}
                                        value={filterDate}
                                        onChange={e => {
                                            setRecenterOnChange(false);
                                            onFilterDateChange(e);
                                        }}
                                    />
                                </FormControl>
                                <IconButton
                                    onClick={() => {
                                        setRecenterOnChange(false);
                                        onNextFilterDate();
                                    }}
                                    id="pickups-drawer-rightarrow-button"
                                    style={{ ...iconBtnStyles, right: -10 }}
                                    size="small"
                                    disabled={recalculatingTrips}
                                >
                                    <Icon path={mdiChevronRight} size={1} color={theme.palette.text.secondary} />
                                </IconButton>
                            </div>
                            <FormControl
                                fullWidth
                                data-cy="pickups-admin-assign-trip-collector"
                                style={{ marginTop: theme.spacing.unit / 2, maxWidth: 100 }}
                                margin="normal"
                                disabled={!isSystemAdmin && collectors.length <= 1}
                            >
                                <InputLabel>Transporter</InputLabel>
                                <Select
                                    value={selectedCollector || ''}
                                    onChange={e => {
                                        setRecenterOnChange(true);
                                        setSelectedCollector(e);
                                    }}
                                    disabled={_.isEmpty(collectors) || collectors.length === 1}
                                    renderValue={collector_id => {
                                        if (collector_id === 'all') return 'All';
                                        const col = _(collectors)
                                            .filter(c => c._id === collector_id)
                                            .value();
                                        return _.get(col, '[0].code', null);
                                    }}
                                >
                                    {collectors.map(c => {
                                        let total = 0,
                                            notConfirmed = 0,
                                            tripless = 0;

                                        pickups.forEach(p => {
                                            if (_.isNil(p.trip) && p.collector && p.collector._id === c._id) {
                                                total++;
                                                tripless++;
                                                if (!p.confirmed) {
                                                    notConfirmed++;
                                                }
                                            } else if (!_.isNil(p.trip)) {
                                                let trip = _.find(trips, t => t._id === p.trip);
                                                if (
                                                    !_.isNil(trip) &&
                                                    _.get(
                                                        trip,
                                                        'transporterCollector._id',
                                                        _.get(trip, 'transporter.collector._id', '').toString()
                                                    ) === c._id
                                                ) {
                                                    total++;
                                                    if (!p.confirmed) {
                                                        notConfirmed++;
                                                    }
                                                }
                                            }
                                        });
                                        return (
                                            <MenuItem
                                                key={_collector.getCode(c)}
                                                value={c._id}
                                                data-cy={`collector-${_collector.getCode(c)}`}
                                            >
                                                {c.name} - {_collector.getCode(c)}({total}/{notConfirmed}/{tripless})
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div>
                            <IconButton
                                color="default"
                                data-cy="date-picker-panel-create-trip-button"
                                onClick={() => onTripDialog(true)}
                            >
                                <Icon path={mdiPlusCircle} size={1} color={theme.palette.text.secondary} />
                            </IconButton>
                        </div>
                    </div>
                    <div
                        style={{
                            marginLeft: theme.spacing.unit * 2,
                            marginRight: 10,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', gap: theme.spacing.unit }}>
                            <Tooltip title="Total Pickups">
                                <div style={pickupInfoStyles}>
                                    <Icon path={mdiMapMarker} size={0.85} color={theme.palette.text.secondary} />
                                    <Typography color="textSecondary" data-cy="total-pickups-count">
                                        {pickupNums.total}
                                    </Typography>
                                </div>
                            </Tooltip>

                            <Tooltip title="Unconfirmed Pickups">
                                <div style={pickupInfoStyles}>
                                    <Icon
                                        path={mdiMapMarkerQuestion}
                                        size={0.85}
                                        color={theme.palette.text.secondary}
                                    />
                                    <Typography color="textSecondary">{pickupNums.notConfirmed}</Typography>
                                </div>
                            </Tooltip>

                            <Tooltip title="Hidden Pickups">
                                <div style={pickupInfoStyles}>
                                    <Icon path={mdiMapMarkerOff} size={0.85} color={theme.palette.text.secondary} />
                                    <Typography color="textSecondary">{pickupsHidden}</Typography>
                                </div>
                            </Tooltip>

                            <Tooltip title="Unrouted Pickups">
                                <div style={pickupInfoStyles}>
                                    <Icon
                                        path={mdiCrosshairsQuestion}
                                        size={0.85}
                                        color={theme.palette.text.secondary}
                                    />
                                    <Typography color="textSecondary" data-cy="unrouted-pickups-count">
                                        {pickupNums.tripless}
                                    </Typography>
                                </div>
                            </Tooltip>
                        </div>
                        {/* {isSystemAdmin && (
                            <FormControlLabel
                                style={{
                                    height: 15,
                                    opacity: '0.54'
                                }}
                                control={
                                    <Checkbox
                                        name="sendETANotifications"
                                        style={{ paddingLeft: theme.spacing.unit }}
                                        checked={sendETANotifications}
                                        onChange={() => setSendETANotifications(!sendETANotifications)}
                                    />
                                }
                                label={
                                    <Typography style={{ fontSize: '77%', textAlign: 'right' }}>
                                        Send ETA Updates
                                    </Typography>
                                }
                                labelPlacement="start"
                            />
                        )} */}
                        {isSystemAdmin && (
                            <FormControlLabel
                                style={{
                                    height: 15,
                                    opacity: '0.54'
                                }}
                                control={
                                    <Checkbox
                                        name="showAllPickups"
                                        checked={showAllPickups}
                                        onChange={() => setShowAllPickups(!showAllPickups)}
                                        style={{ paddingLeft: theme.spacing.unit }}
                                    />
                                }
                                label={
                                    <Typography style={{ fontSize: '77%', textAlign: 'right' }}>
                                        Show all pickups
                                    </Typography>
                                }
                                labelPlacement="start"
                            />
                        )}
                    </div>
                    {driversOnVacation.length > 0 && (
                        <div
                            style={{
                                marginLeft: theme.spacing.unit * 3,
                                marginRight: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            data-cy="driver-vaction-days-wrapper"
                        >
                            <Typography color="textSecondary" style={{ fontSize: '80%' }}>
                                Vacation: {driversOnVacation.map(d => getCustomerFirstNameAndLastInitial(d)).join(', ')}
                            </Typography>
                        </div>
                    )}
                    <div
                        style={{
                            marginLeft: theme.spacing.unit * 3,
                            marginRight: 10,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                        data-cy="auto-assign-trips-button-wrapper"
                    >
                        <div>
                            {selectedNums.pickups > 1 && (
                                <Badge style={{ backgroundColor: blue[400] }}>
                                    <Typography style={{ marginRight: theme.spacing.unit, color: '#fff' }} inline>
                                        Pickups: {selectedNums.pickups}
                                    </Typography>
                                    <Typography style={{ color: '#fff' }} inline>
                                        Bags: {selectedNums.bags}
                                    </Typography>
                                </Badge>
                            )}
                        </div>
                        {pickupNums.tripless > 0 && (
                            <div
                                style={{
                                    marginLeft: theme.spacing.unit * 2
                                }}
                            >
                                <Button onClick={handleClick} color="primary" data-cy="auto-assign-trips-button">
                                    {loading ? 'Assigning trips...' : 'Auto Assign Trips'}
                                </Button>
                            </div>
                        )}
                    </div>
                    {collapsed && (
                        <IconButton
                            style={{
                                display: 'block',
                                width: 24,
                                height: 24,
                                padding: 0,
                                margin: '0 auto',
                                marginTop: theme.spacing.unit
                            }}
                            onClick={() => setCollapsed(!collapsed)}
                        >
                            <Icon path={mdiChevronDown} size={1} color={theme.palette.text.secondary} />
                        </IconButton>
                    )}
                    {!collapsed && (
                        <>
                            <div
                                style={{
                                    marginLeft: -theme.spacing.unit,
                                    marginRight: -theme.spacing.unit,
                                    marginTop: theme.spacing.unit,
                                    textAlign: 'center',
                                    maxHeight: 'calc(90vh - 300px)',
                                    overflow: 'auto'
                                }}
                            >
                                <TripsList
                                    trips={trips}
                                    tripIdsUnchecked={tripIdsUnchecked}
                                    pickups={pickups}
                                    commoditiesAvailable={commoditiesAvailable}
                                    onTripDialog={onTripDialog}
                                    onTripToCenter={onTripToCenter}
                                    onCheckTrip={onCheckTrip}
                                    handlePinClick={handlePinClick}
                                    selectedCollector={selectedCollector}
                                    recalculatingTrips={recalculatingTrips}
                                    onForceRecalculation={onForceRecalculation}
                                    sendingEtas={sendingEtas}
                                    onSendEtas={onSendEtas}
                                />
                            </div>
                            <IconButton
                                style={{ display: 'block', width: 24, height: 24, padding: 0, margin: '0 auto' }}
                                onClick={() => setCollapsed(!collapsed)}
                            >
                                <Icon path={mdiChevronUp} size={1} color={theme.palette.text.secondary} />
                            </IconButton>
                        </>
                    )}
                    <div />
                </Paper>
            </div>
        )
    );
}

export default withTheme()(DatePickerPanel);
