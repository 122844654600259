import { useContext } from 'react';
import _ from 'lodash';
import { _time } from 'std';
import moment from 'moment-timezone';

// TODO: not nest this hook in here
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';

import HttpContext from 'utils/contexts/HttpContext';

function useFilterDate(props) {
    const http = useContext(HttpContext);
    const { unselectEverything, timezones } = props;
    // save timezone in url query params

    const { date: filterDate, timezone, handleChangeDate, handleChangeTimezone } = useDatePicker({
        saveStateInURL: true,
        timezones
    });
    const unservicedDate = moment('20180102')
        .tz(timezone)
        .startOf('year');

    const handleFilterDateChange = date => {
        handleChangeDate(date);
        unselectEverything();
    };
    const onUnservicedFilterDate = () => {
        handleChangeDate(unservicedDate);
        unselectEverything();
    };
    const handlePrevFilterDate = async () => {
        const res = await http.getJSON(`/pickups/prevDayWithPickups?date=${filterDate}&timezone=${timezone}`);
        if (res.ok) {
            const { nextDate } = res.data;
            if (!moment(nextDate).isSame(filterDate, 'day')) {
                handleChangeDate(moment(nextDate).tz(timezone));
            } else {
                handleChangeDate(
                    moment(filterDate)
                        .tz(timezone)
                        .subtract(1, 'day')
                );
            }
            unselectEverything();
        }
    };

    const handleNextFilterDate = async () => {
        const res = await http.getJSON(`/pickups/nextDayWithPickups?date=${filterDate}&timezone=${timezone}`);
        if (res.ok) {
            const { nextDate } = res.data;

            if (!moment(nextDate).isSame(filterDate, 'day')) {
                handleChangeDate(moment(nextDate).tz(timezone));
            } else {
                handleChangeDate(
                    moment(filterDate)
                        .tz(timezone)
                        .add(1, 'day')
                );
            }

            unselectEverything();
        }
    };

    return {
        filterDate,
        timezone,
        onUnservicedFilterDate,
        handleFilterDateChange,
        handlePrevFilterDate,
        handleNextFilterDate,
        handleChangeTimezone
    };
}

export default useFilterDate;
