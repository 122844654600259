import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { wait, isProductionEnv, isEXPRegion } from 'utils/misc';

import {
    FinancialWidget,
    StatsWidget,
    HouseholdWidget,
    CombinedPickupsWidget,
    FeaturedCharityWidget,
    BottleDriveWidget
} from 'components/CustomerWidgets';
import CustomerInfoWidget from 'components/CustomerWidgets/CustomerInfo';

import Swipeable from 'react-swipeable';

import * as colors from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { withTheme } from '@material-ui/core/styles';
import { getClosestRegion } from 'utils/latlngFunctions';

import { _user } from 'std';
import { Fab, withWidth } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { Icon as MDIcon } from '@mdi/react';
import { mdiNumeric } from '@mdi/js';
import { isWidthUp } from '@material-ui/core/withWidth';
import VerifyEmailWidget from 'components/CustomerWidgets/VerifyEmailWidget';
import DynamicGift from 'icons/DynamicGift';

let UPPER_SWIPE_LIMIT;
let LOWER_SWIPE_LIMIT;
let STICKING_OUT = 200; // in px
const HYSTERESIS = 0.125;

const CONTAINER_RATES = require('containers/Legal/Rates/rates-' +
    process.env.REACT_APP_REGION_EXT.toLowerCase() +
    '.json');

class Dashboard extends Component {
    static contextType = LocalizationContext;
    swipeablePanel;
    combinedPickupsWidget;
    backdrop;
    topValueInit = 0;
    state = {
        fabOpen: false
    };

    handleFabClick = () => {
        this.setState(prevState => ({
            fabOpen: !prevState.fabOpen
        }));
    };

    initialize = () => {
        if (_.isNil(this.swipeablePanel)) {
            return;
        }

        this.swipeablePanel.style.top = `calc(100% - ${STICKING_OUT}px - env(safe-area-inset-top))`;
        this.swipeablePanel.style.height = STICKING_OUT;
        this.combinedPickupsWidget.style.opacity = 1;
        this.backdrop.style.opacity = 0;

        this.topValueInit = this.swipeablePanel.offsetTop;
        // console.log('this.topValueInit', this.topValueInit); // TODO: fix this broken shit
        LOWER_SWIPE_LIMIT = this.topValueInit;
        UPPER_SWIPE_LIMIT = -this.swipeablePanel.scrollHeight + LOWER_SWIPE_LIMIT + STICKING_OUT;
    };

    setZohoHelpWidgetMargin = raised => {
        const launcherbox = document.getElementById('zohohc-asap-web-launcherbox');
        if (launcherbox) {
            if (raised) {
                launcherbox.style.marginBottom = '105px';
            } else {
                launcherbox.style.marginBottom = '';
            }
        }
    };

    componentDidMount() {
        if (isEXPRegion() && (this.props.scanQREnabled || this.props.showQREnabled)) {
            STICKING_OUT = 295;
            this.setState({ fabOpen: false });
        }
        window.addEventListener('resize', this.handleResize);
        this.setZohoHelpWidgetMargin(!isWidthUp('sm', this.props.width));
    }

    componentWillUnmount() {
        this.setZohoHelpWidgetMargin(false);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = e => {
        this.initialize();
        setTimeout(() => this.setZohoHelpWidgetMargin(!isWidthUp('sm', this.props.width)), 250);
    };

    handleSwiping = (e, deltaX, deltaY, absX, absY, velocity) => {
        if (_.get(e, 'target.className', '') === 'no-parent-scroll') {
            return;
        }

        if (_.isNil(UPPER_SWIPE_LIMIT)) {
            console.log('UPPER_SWIPE_LIMIT is nil');
            this.initialize();
            this.swipeablePanel.addEventListener('wheel', this.handleWheel);
        }

        if (this.topValueInit - deltaY <= UPPER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = UPPER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${UPPER_SWIPE_LIMIT}px)`;
            this.combinedPickupsWidget.style.opacity = 0;
        } else if (this.topValueInit - deltaY >= LOWER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
            this.combinedPickupsWidget.style.opacity = 1;
            this.backdrop.style.opacity = 0;
        } else {
            let topValueNew = this.topValueInit - deltaY;
            this.swipeablePanel.style.top = topValueNew.toString() + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${topValueNew.toString()}px)`;

            let darkenRatio = 1 - topValueNew / LOWER_SWIPE_LIMIT;
            this.combinedPickupsWidget.style.opacity = topValueNew / LOWER_SWIPE_LIMIT;
            this.backdrop.style.opacity = darkenRatio;
        }
    };

    handleSwipedUp = async (e, deltaY) => {
        if (_.get(e, 'target.className', '') === 'no-parent-scroll') {
            return;
        }
        let scrollRatio = 1 - this.swipeablePanel.offsetTop / LOWER_SWIPE_LIMIT;

        if (scrollRatio > 0 && scrollRatio < 1) {
            if (scrollRatio >= HYSTERESIS) {
                this.animateScroll();

                this.swipeablePanel.style.top = 56 + 'px';
                this.swipeablePanel.style.height = `calc(100% - 56px - env(safe-area-inset-top))`;
                this.combinedPickupsWidget.style.opacity = 0;
                this.backdrop.style.opacity = 1;
            } else {
                this.animateScroll();

                this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
                this.combinedPickupsWidget.style.opacity = 1;
                this.backdrop.style.opacity = 0;
            }
        }

        await wait(200);
        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    handleSwipedDown = async (e, deltaY) => {
        if (_.get(e, 'target.className', '') === 'no-parent-scroll') {
            return;
        }
        let scrollRatio = 1 - this.swipeablePanel.offsetTop / LOWER_SWIPE_LIMIT;

        if (scrollRatio > 0 && scrollRatio < 1) {
            if (scrollRatio >= 1 - HYSTERESIS) {
                this.animateScroll();

                this.swipeablePanel.style.top = 56 + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${56}px)`;
                this.combinedPickupsWidget.style.opacity = 0;
                this.backdrop.style.opacity = 1;
            } else {
                this.animateScroll();

                this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
                this.combinedPickupsWidget.style.opacity = 1;
                this.backdrop.style.opacity = 0;
            }
        }

        await wait(200);
        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    handleWheel = async e => {
        if (_.get(e, 'target.className', '') === 'no-parent-scroll') {
            return;
        }
        if (this.topValueInit - e.deltaY <= UPPER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = UPPER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${UPPER_SWIPE_LIMIT}px)`;
            this.combinedPickupsWidget.style.opacity = 0;
        } else if (this.topValueInit - e.deltaY >= LOWER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
            this.combinedPickupsWidget.style.opacity = 1;
            this.backdrop.style.opacity = 0;
        } else {
            let topValueNew = this.topValueInit - e.deltaY;
            this.swipeablePanel.style.top = topValueNew.toString() + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${topValueNew.toString()}px)`;

            let darkenRatio = 1 - topValueNew / LOWER_SWIPE_LIMIT;
            this.combinedPickupsWidget.style.opacity = topValueNew / LOWER_SWIPE_LIMIT;
            this.backdrop.style.opacity = darkenRatio;
        }

        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    animateScroll = async () => {
        this.swipeablePanel.classList.add('gradual-transition-scroll');
        this.combinedPickupsWidget.classList.add('gradual-transition-scroll');
        this.backdrop.classList.add('gradual-transition-scroll');

        await wait(150 + 50);

        this.swipeablePanel.classList.remove('gradual-transition-scroll');
        this.combinedPickupsWidget.classList.remove('gradual-transition-scroll');
        this.backdrop.classList.remove('gradual-transition-scroll');
    };

    filterCharitiesByLocation = featuredCharities => {
        const { customer, regions } = this.props;

        if (_.isNil(regions)) return featuredCharities;

        //find closest city to cutomer location
        const customerCity = getClosestRegion(customer.location, regions);

        const featuredCharitiesSameCity = _.filter(featuredCharities, charity => {
            //if no city locations set show featured charity anyway
            const regions = _.get(charity, 'regionsFeaturedIn', []);
            if (_.isEmpty(regions)) {
                return true;
            } else {
                return _.find(regions, city => city._id === _.get(customerCity, '_id', '')) !== undefined;
            }
        });

        return featuredCharitiesSameCity;
    };

    render() {
        const {
            theme,
            history,
            http,
            customer,
            bulks,
            pickups,
            charities,
            rates = [],
            allRates,
            redemptions,
            tips,
            financialStats,
            environmentalStats,
            onCharityShareDialogOpen,
            shareDialogEnabled,
            reloadCustomer,
            displayQRCode,
            // allowRequestLabels,
            onQRDialog,
            onPickupConfirmationDialog,
            charityPreferred,
            commodityProjections,
            auth,
            enableReferrals,
            handleCustomerReferralDialog,
            giftIcon,
            printIcon,
            childAccountBulks,
            childAccountTips,
            childAccountRedemptions,
            charityEnabled,
            scanQREnabled,
            showQREnabled,
            emailVerificationRequired,
            adminPermissions,
            operator,
            onResendVerificationEmail,
            onVerifyEmail
        } = this.props;
        const isAdmin =
            _user.isSystemAdmin(operator) || _user.isCollectorAdmin(operator) || _user.isInternalRole(operator);

        let descriptionBySkuAndRates = {};
        allRates.forEach(ratesObject => {
            _.get(ratesObject, 'rates', []).forEach(rate => {
                if (!rate.description || !rate.label) return;
                if (_.isNil(descriptionBySkuAndRates[ratesObject._id])) {
                    descriptionBySkuAndRates[ratesObject._id] = {};
                }
                descriptionBySkuAndRates[ratesObject._id][rate.sku] = `${rate.description} ${rate.label}`;
            });
        });
        let data = {};
        _.filter(bulks, bulk => !bulk.adjustment).forEach(bulk => {
            const commodityForBulkType = _.find(commodityProjections, { skuType: bulk.skuType });
            if (_.isNil(commodityForBulkType)) {
                return;
            }
            if (commodityForBulkType.includeContainersInStats) {
                bulk.counts.forEach(count => {
                    count.items.forEach(item => {
                        const sku = item.sku;
                        if (
                            !sku ||
                            !descriptionBySkuAndRates[bulk.rates] ||
                            !descriptionBySkuAndRates[bulk.rates][sku]
                        ) {
                            data[item.materialType] = _.get(data, item.materialType, 0) + item.quantity;
                        } else {
                            const description = descriptionBySkuAndRates[bulk.rates][sku];
                            data[description] = _.get(data, description, 0) + item.quantity;
                        }
                    });
                });

                return;
            }
            // commodities use this multiplier to make it more visible on the chart
            const commodityProjectionMultiplier = _.get(commodityForBulkType, 'projectionMultiplier', 1);
            let bulkType = _.startCase(bulk.skuType);
            data[bulkType] =
                _.get(data, bulkType, 0) + _.get(bulk, 'commodityAmount', 0) * commodityProjectionMultiplier;
        });

        // Do not show Opening Balance in chart
        _.unset(data, 'Opening Balance');

        let chartData = _.map(data, (value, prop) => {
            return { name: prop, value: value };
        });

        let featuredCharities = _.filter(charities, charity => charity.isFeatured && charity.approved);

        if (!_.isNil(customer.location) && customer.location.lat && customer.location.lng) {
            featuredCharities = this.filterCharitiesByLocation(featuredCharities);
        }
        const widgets = (elevated, showFab) => (
            <React.Fragment>
                <div
                    style={{
                        width: '100%',
                        marginBottom: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        {/* {((!isEXPRegion() && displayQRCode) || (isEXPRegion() && allowRequestLabels)) && showFab && ( */}
                        {(scanQREnabled || showQREnabled) && !isEXPRegion() && showFab && (
                            <Fab
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    marginLeft: theme.spacing.unit * 2
                                }}
                                onClick={onQRDialog(true)}
                                data-cy="customer-site-qr-scan-button"
                            >
                                {/* <img height="50px" width="50px" src={printIcon} alt="QR" /> */}
                                <Icon style={{ color: theme.palette.primary[500] }}>qr_code_scanner</Icon>
                            </Fab>
                        )}
                        {(scanQREnabled || showQREnabled) && isEXPRegion() && showFab ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Fab
                                        style={{
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            marginLeft: theme.spacing.unit * 2.8,
                                            marginBottom: theme.spacing.unit,
                                            width: 40,
                                            height: 40,
                                            transform: this.state.fabOpen ? 'translateY(0px)' : 'translateY(106px)', // Moves the QR icon up when fabOpen is true
                                            transition: 'transform 0.5s'
                                        }}
                                        onClick={onQRDialog(true)}
                                        data-cy="customer-site-qr-scan-button"
                                    >
                                        <Icon style={{ color: theme.palette.primary[500] }}>qr_code_scanner</Icon>
                                    </Fab>
                                    <Typography
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit,
                                            opacity: this.state.fabOpen ? 1 : 0,
                                            transition: 'opacity 0.5s',
                                            color: '#0033A0',
                                            fontWeight: 'bold',
                                            fontSize: '12px'
                                        }}
                                    >
                                        Scan QR Code
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Fab
                                        style={{
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            marginLeft: theme.spacing.unit * 2.8,
                                            marginBottom: theme.spacing.unit,
                                            width: 40,
                                            height: 40,
                                            transform: this.state.fabOpen ? 'translateY(0px)' : 'translateY(56px)', // Moves the door icon up when fabOpen is true
                                            transition: 'transform 0.5s' // transition for the transform property
                                        }}
                                        data-cy="dashboard-door-fab"
                                        onClick={() => this.props.handleAccessDoor()}
                                    >
                                        <MDIcon path={mdiNumeric} size={1.2} color={'#0033A0'} />
                                    </Fab>
                                    <Typography
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit,
                                            opacity: this.state.fabOpen ? 1 : 0,
                                            transition: 'opacity 0.5s',
                                            color: '#0033A0',
                                            fontWeight: 'bold',
                                            fontSize: '12px'
                                        }}
                                    >
                                        Open Access Door
                                    </Typography>
                                </div>
                                <Fab
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        marginLeft: theme.spacing.unit * 2
                                    }}
                                    data-cy="dashboard-action-fab"
                                    onClick={this.handleFabClick}
                                >
                                    <Icon
                                        style={{
                                            color: '#0033A0',
                                            transform: this.state.fabOpen ? 'rotate(45deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.5s',
                                            fontSize: '2.5rem'
                                        }}
                                    >
                                        add
                                    </Icon>
                                </Fab>
                            </div>
                        ) : isEXPRegion() && showFab ? (
                            <Fab
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    marginLeft: theme.spacing.unit * 2
                                }}
                                data-cy="dashboard-door-fab"
                                onClick={() => this.props.handleAccessDoor()}
                            >
                                <MDIcon path={mdiNumeric} size={1.5} color={'#0033A0'} />
                            </Fab>
                        ) : null}
                    </div>

                    <div>
                        {enableReferrals && showFab && (
                            <Fab
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    marginRight: theme.spacing.unit * 2
                                }}
                                onClick={handleCustomerReferralDialog}
                                data-cy="customer-site-referral-dialog-button"
                            >
                                <DynamicGift theme={theme} />
                            </Fab>
                        )}
                    </div>
                </div>

                <FinancialWidget
                    http={http}
                    customer={customer}
                    charityEnabled={charityEnabled}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    elevated={elevated}
                    balance={financialStats.balance}
                    amountPending={financialStats.amountPending}
                    amountDonated={financialStats.amountDonated}
                    lastRedemption={_.last(redemptions)}
                    onRedemptionDialog={this.props.onRedemptionDialog}
                    style={{ marginTop: 0 }} // TODO: get rid of this hackity-haCK
                    disabled={
                        customer.suspended || (isAdmin && !_.get(adminPermissions, 'canRedeemCustomerBalance', false))
                    }
                    adminPermissions={adminPermissions}
                />
                {(this.props.adminView || !isProductionEnv) && (
                    <CustomerInfoWidget
                        customer={customer}
                        reloadCustomer={reloadCustomer}
                        http={http}
                        auth={auth}
                        redemptions={redemptions}
                    />
                )}
                {_user.isBottleDriveOwner(customer) && (
                    <BottleDriveWidget
                        elevated={elevated}
                        bottleDrives={_user.getBottleDrives(customer)}
                        charities={charities}
                    />
                )}

                {!_.get(customer, 'verification.email.verified', false) && emailVerificationRequired && (
                    <VerifyEmailWidget
                        customer={customer}
                        theme={theme}
                        isAdmin={isAdmin}
                        onResendVerificationEmail={onResendVerificationEmail}
                        onVerifyEmail={onVerifyEmail}
                    />
                )}
                {charityEnabled && (
                    <FeaturedCharityWidget
                        elevated={elevated}
                        http={http}
                        charities={featuredCharities}
                        onCharityInfoDialog={this.props.onCharityInfoDialog}
                        onDonate={this.props.onDonate}
                        customer={customer}
                        onCharityShareDialogOpen={onCharityShareDialogOpen}
                        shareDialogEnabled={shareDialogEnabled}
                        width={this.props.width}
                        environmentalStats={environmentalStats}
                        subject={this.props.subject}
                        message={this.props.message}
                        onNativeShare={this.props.onNativeShare}
                    />
                )}

                <StatsWidget
                    elevated={elevated}
                    bulks={bulks}
                    bottleDrives={_user.getBottleDrives(customer)}
                    customer={customer}
                    financialStats={financialStats}
                    environmentalStats={environmentalStats}
                    childAccountBulks={childAccountBulks}
                    childAccountTips={childAccountTips}
                    childAccountRedemptions={childAccountRedemptions}
                    tips={tips}
                    redemptions={redemptions}
                    charityEnabled={charityEnabled}
                />

                <HouseholdWidget
                    elevated={elevated}
                    data={chartData}
                    style={{ width: 'calc(100% - 32px)', margin: 16 }}
                />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Hidden smUp>
                    <div
                        ref={elem => (this.backdrop = elem)}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: theme.palette.grey[900],
                            opacity: 0,
                            pointerEvents: 'none'
                        }}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            marginTop: 56
                        }}
                    >
                        <CombinedPickupsWidget
                            assignRef={elem => (this.combinedPickupsWidget = elem)}
                            elevated
                            history={history}
                            customer={customer}
                            bulks={bulks}
                            pickups={pickups}
                            charities={charities}
                            onPickupDialog={this.props.onPickupDialog}
                            http={http}
                            onReloadCustomer={reloadCustomer}
                            onPickupConfirmationDialog={onPickupConfirmationDialog}
                            charityPreferred={charityPreferred}
                            charityEnabled={charityEnabled}
                            onSnackbar={this.props.onSnackbar}
                            regions={this.props.regions}
                        />
                    </div>

                    <div
                        ref={elem => (this.swipeablePanel = elem)}
                        style={{
                            position: 'absolute',
                            top: `calc(100% - ${STICKING_OUT}px)`,
                            height: `calc(${STICKING_OUT}px)`,
                            overflow: 'hidden',
                            width: '100%',
                            backgroundColor: 'transparent',
                            zIndex: 1000,
                            touchAction: 'none'
                        }}
                    >
                        <Swipeable
                            trackMouse
                            onSwiping={this.handleSwiping}
                            onSwipedUp={this.handleSwipedUp}
                            onSwipedDown={this.handleSwipedDown}
                            style={{ paddingTop: theme.spacing.unit * 2 }}
                        >
                            {widgets(true, true)}
                        </Swipeable>
                    </div>
                </Hidden>

                <Hidden xsDown>
                    <div style={{ marginTop: theme.spacing.unit * 2 }}>{widgets(false, false)}</div>
                </Hidden>
            </React.Fragment>
        );
    }
}

export default withWidth()(withTheme()(Dashboard));
