import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';

import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';
import PhotoAndBagsInput from 'components/InputComponents/PhotoAndBagsInput';

import * as colors from '@material-ui/core/colors';

import FormControl from '@material-ui/core/FormControl';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    TextField,
    Typography,
    Collapse,
    DialogContentText,
    FormHelperText
} from '@material-ui/core/';
import EligibleExamplesList from 'components/Lists/EligibleExamplesList';

import { formatAsCurrency } from 'utils/misc';

import { uppercaseFirstLetter, isMXDRegion, getPlural } from 'utils/misc';

import * as allIcons from '@mdi/js';

import { withTheme } from '@material-ui/core/styles';

import { _commodity, _user } from 'std';

import { MAX_NUM_OF_RECYCLING_BAGS, MIN_NUM_OF_RECYCLING_BAGS, ROLES } from 'constants.js';

import OperatorContext from 'utils/contexts/OperatorContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function PayloadInput(props) {
    const {
        payload,
        onPayload,
        subPayloads,
        onSubPayloads,
        formErrors,
        payloadAccepted,
        payloadRequired,
        hideHelpButtons,
        theme,
        driverView,
        adminView,
        showPhotoInput = false,
        imagePreviews,
        imageIcons,
        onPhotoButton,
        lowVolumeContainerThreshold,
        minPickupFeeEnabled,
        auth
    } = props;

    const { lang } = useContext(LocalizationContext);
    const operator = useContext(OperatorContext);

    const [eligibleDialogOpen, setEligibleDialogOpen] = useState(false);
    const [selectedCommodity, setSelectedCommodity] = useState(null);

    const [lockedPayloads, setLockedPayloads] = useState([]);
    const [expandedCommodities, setExpandedCommodities] = useState([]); // Show subcommodities for commodity

    const handleEligibleExampleDialog = commodity => {
        setEligibleDialogOpen(true);
        setSelectedCommodity(commodity);
    };

    const isAdmin = ['System Administrator', 'Collector Administrator', ...ROLES].includes(
        _.get(operator, 'accountType', _.get(auth, 'accountType', ''))
    );
    const isDriver = _user.isDriver(operator);
    const payloadAcceptedSorted = _.cloneDeep(payloadAccepted);
    sortPayload(payloadAcceptedSorted, lang);

    const toggleSubCommodityView = commodity => {
        const updatedCommodities = _.cloneDeep(expandedCommodities);
        if (updatedCommodities.includes(commodity)) {
            setExpandedCommodities(_.filter(updatedCommodities, c => c != commodity));
        } else {
            updatedCommodities.push(commodity);
            setExpandedCommodities(updatedCommodities);
        }
    };

    useEffect(() => {
        // if (!_.isEmpty(operator) && _.get(operator, 'accountType') !== 'Customer') {
        let updatedExpandedCommodities = [];

        for (let subPayload of subPayloads) {
            if (!subPayload.amount || !subPayload.skuType) continue;
            if (updatedExpandedCommodities.includes(subPayload.skuType)) continue;

            updatedExpandedCommodities.push(subPayload.skuType);
        }

        setExpandedCommodities(updatedExpandedCommodities);
        // return;
        // }

        // const newLockedPayloads = new Set();
        // subPayloads.forEach(subPayload => {
        //     const isSubscriptionPayload = _.get(subPayload, 'subCommodity.fee');
        //     if (isSubscriptionPayload) {
        //         newLockedPayloads.add(subPayload.skuType);
        //     }
        // });

        // setLockedPayloads(Array.from(newLockedPayloads));
    }, []);
    return (
        <>
            {// ((_.isEmpty(operator) || _.get(operator, 'accountType') === 'Customer') && !adminView
            //     ? payloadAcceptedSorted.filter(c => !c.hideFromCustomer)
            //     : payloadAcceptedSorted
            // )
            payloadAcceptedSorted.map(commodity => {
                const skuType = _commodity.getSkuType(commodity);
                const preAppliedFee = _.get(commodity, 'preAppliedFee.amount');
                const preAppliedFeeText = preAppliedFee
                    ? `A service charge of ${formatAsCurrency(preAppliedFee, lang)} will be applied.`
                    : '';
                let componentValue = _.get(payload, skuType, 0);

                let componentHelperText = _.get(formErrors, `${skuType}.fail`, false)
                    ? _.get(formErrors, `${skuType}.reason`, '')
                    : _commodity.getPayloadHelperText(commodity, lang) + '. ' + preAppliedFeeText;
                const minVolumeFeeText =
                    _.get(commodity, 'includeContainersInStats', _.get(commodity, 'skuType', '') === 'beverage') &&
                    componentValue === 1 &&
                    minPickupFeeEnabled
                        ? loc('lowVolumeFeeWarning', lang, { lowVolumeContainerThreshold })
                        : '';
                let componentError = _.get(formErrors, `${skuType}.fail`, false);
                if (!componentError) {
                    componentError = componentValue < 0;
                    if (componentError) {
                        componentHelperText = 'Invalid number of bags';
                    }
                }

                let commodityUnits = _.get(commodity, `units.${lang}`, '');

                let disableField = false;
                if (lockedPayloads.includes(skuType)) {
                    disableField = true;
                }

                const commodityIcon = allIcons[_.get(commodity, 'iconName')];
                const commodityColor = _.get(commodity, 'color', colors.blue[300]);

                const subCommodities = _.get(commodity, 'subCommodities', []);
                // let visibleSubCommodities = subCommodities;

                // if (_.isEmpty(operator) || _.get(operator, 'accountType') === 'Customer') {
                //     visibleSubCommodities = _.filter(visibleSubCommodities, c => {
                //         if (!c.fee) return true;
                //         // Don't show fee sub commodity to customer if it's not applied
                //         const subPayloadsForFee = _.find(subPayloads, subPayload => {
                //             return c.fee === _.get(subPayload, 'subCommodity.fee');
                //         });
                //         return !_.isEmpty(subPayloadsForFee);
                //     });
                // }

                const hasSubCommodity = !_.isEmpty(subCommodities);
                const showSubCommodities =
                    hasSubCommodity && (expandedCommodities.includes(skuType) || commodity.hidePrimary);

                let subCommodityDisclaimer;
                if (driverView) {
                    subCommodityDisclaimer = _.get(commodity, `subCommodityDriverDisclaimer.${lang}`, '');
                } else {
                    subCommodityDisclaimer = _.get(commodity, `subCommodityCustomerDisclaimer.${lang}`, '');
                }

                const subPayloadsForCommodity = _.filter(subPayloads, { skuType });
                const subPayloadAmount = subPayloadsForCommodity.reduce(
                    (total, subPayload) => (total += _.get(subPayload, 'amount', 0)),
                    0
                );

                // const subPayloadsCommodityEquivalent = _commodity.getSubPayloadsCommodityEquivalent(
                //     skuType,
                //     subPayloads
                // );

                // const subPayloadsForCommodity = _.filter(subPayloads, { skuType });

                const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                    [{ subPayloads: subPayloadsForCommodity }],
                    payloadAcceptedSorted
                );
                const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);
                const groupedPayloadStringParts = Object.keys(groupedPayloadTotals).map(
                    unit => ` ${groupedPayloadTotals[unit]} ${groupedPayloadTotals[unit] > 1 ? getPlural(unit) : unit}`
                );

                const groupedPayloadString = groupedPayloadStringParts.join(',');

                if (
                    commodity.hideFromCustomer &&
                    !isDriver &&
                    !(isAdmin && (componentValue > 0 || subPayloadAmount > 0))
                ) {
                    return <></>;
                }
                return (
                    <>
                        <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 3 }} key={commodity._id}>
                            {commodity.hidePrimary && componentValue > 0 && (isAdmin || isDriver) && (
                                <FormHelperText
                                    style={{
                                        paddingRight: 12,
                                        paddingLeft: 12,
                                        marginBottom: theme.spacing.unit * 2,
                                        color: colors.red[500]
                                    }}
                                >
                                    This primary commodity is hidden and should not be accessible to customers
                                </FormHelperText>
                            )}
                            {!commodity.hidePrimary &&
                                commodity.hideFromCustomer &&
                                ((componentValue > 0 && isAdmin) || isDriver) && (
                                    <FormHelperText
                                        style={{
                                            paddingRight: 12,
                                            paddingLeft: 12,
                                            marginBottom: theme.spacing.unit * 2,
                                            color: colors.red[500]
                                        }}
                                    >
                                        This commodity is hidden and should not be accessible to customers
                                    </FormHelperText>
                                )}
                            {!commodity.hidePrimary ||
                            (_.get(auth, 'accountType') !== 'Customer' && componentValue > 0) ? (
                                showPhotoInput ? (
                                    <>
                                        <PhotoAndBagsInput
                                            required={_commodity.isRequired(commodity, payloadRequired)}
                                            disableEntireInput={disableField}
                                            startAdornmentIcon={commodityIcon}
                                            startAdornmentIconColor={commodityColor}
                                            maxNumber={MAX_NUM_OF_RECYCLING_BAGS}
                                            minNumber={MIN_NUM_OF_RECYCLING_BAGS}
                                            componentValue={componentValue}
                                            componentLabel={_commodity.getPayloadInputName(commodity, lang)}
                                            componentName={skuType}
                                            componentHelperText={componentHelperText}
                                            componentError={componentError}
                                            onBags={onPayload}
                                            hasSubCommodity={hasSubCommodity}
                                            toggleSubCommodityView={toggleSubCommodityView}
                                            commodityUnits={commodityUnits}
                                            subPayloadAmount={subPayloadAmount}
                                            // subPayloadAmount={subPayloadsCommodityEquivalent}
                                            groupedPayloadString={groupedPayloadString}
                                            subCommoditiesVisible={showSubCommodities}
                                            driverView={driverView}
                                            infoAdornment={
                                                !hideHelpButtons &&
                                                !_.isEmpty(_.get(commodity, 'eligibleExamples', [])) && (
                                                    <IconButton
                                                        onClick={() => handleEligibleExampleDialog(commodity)}
                                                        data-cy={`payload-input-${_commodity.getSkuType(
                                                            commodity
                                                        )}-help`}
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                )
                                            }
                                            imagePreviews={imagePreviews}
                                            commodity={commodity}
                                            imageRequired={_.get(commodity, 'imageRequired', false)}
                                            onPhotoButton={onPhotoButton}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <NumberOfBagsInput
                                            required={_commodity.isRequired(commodity, payloadRequired)}
                                            disableEntireInput={disableField}
                                            startAdornmentIcon={commodityIcon}
                                            startAdornmentIconColor={commodityColor}
                                            maxNumber={MAX_NUM_OF_RECYCLING_BAGS}
                                            minNumber={MIN_NUM_OF_RECYCLING_BAGS}
                                            componentValue={componentValue}
                                            componentLabel={_commodity.getPayloadInputName(commodity, lang)}
                                            componentName={skuType}
                                            componentHelperText={componentHelperText}
                                            componentError={componentError}
                                            onBags={onPayload}
                                            hasSubCommodity={hasSubCommodity}
                                            toggleSubCommodityView={toggleSubCommodityView}
                                            commodityUnits={commodityUnits}
                                            subPayloadAmount={subPayloadAmount}
                                            // subPayloadAmount={subPayloadsCommodityEquivalent}
                                            groupedPayloadString={groupedPayloadString}
                                            subCommoditiesVisible={showSubCommodities}
                                            driverView={driverView}
                                            infoAdornment={
                                                !hideHelpButtons &&
                                                !_.isEmpty(_.get(commodity, 'eligibleExamples', [])) && (
                                                    <IconButton
                                                        onClick={() => handleEligibleExampleDialog(commodity)}
                                                        data-cy={`payload-input-${_commodity.getSkuType(
                                                            commodity
                                                        )}-help`}
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                )
                                            }
                                            extraAdornments={
                                                <InputAdornment
                                                    position="end"
                                                    style={{
                                                        height: '100%',
                                                        whiteSpace: 'nowrap',
                                                        marginRight:
                                                            hideHelpButtons ||
                                                            _.isEmpty(_.get(commodity, 'eligibleExamples', []))
                                                                ? theme.spacing.unit
                                                                : 0
                                                    }}
                                                >
                                                    {uppercaseFirstLetter(commodityUnits)}s
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText
                                            style={{
                                                paddingRight: 12,
                                                paddingLeft: 12
                                            }}
                                        >
                                            {minVolumeFeeText}
                                        </FormHelperText>
                                    </>
                                )
                            ) : (
                                <>
                                    <DialogContentText>
                                        {_commodity.getPayloadInputName(commodity, lang)}
                                    </DialogContentText>
                                </>
                            )}

                            <Collapse in={showSubCommodities || commodity.hidePrimary}>
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: commodity.hidePrimary ? 0 : theme.spacing.unit * 2
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                color: colors.yellow[700],
                                                margin: theme.spacing.unit
                                            }}
                                        >
                                            priority_high
                                        </Icon>

                                        <Typography variant="body2">{subCommodityDisclaimer}</Typography>
                                    </div>
                                    {subCommodities.map((subCommodity, idx) => {
                                        const subCommodityId = subCommodity._id;
                                        const multiplier = subCommodity.multiplier;
                                        const name = subCommodity.name[lang || 'en'];
                                        const units = subCommodity.units[lang || 'en'];
                                        const key = `${_.kebabCase(name)}`;

                                        const icon = allIcons[convertToImportName(subCommodity.iconName)];
                                        const color = subCommodity.color;

                                        const subPayload = _.find(subPayloads, subPayload => {
                                            const id = _.get(subPayload, 'subCommodity._id');
                                            return id === subCommodityId;
                                        });

                                        return (
                                            <>
                                                {commodity.hideFromCustomer &&
                                                    _.get(subPayload, 'amount', 0) > 0 &&
                                                    isAdmin && (
                                                        <FormHelperText
                                                            style={{
                                                                paddingRight: 12,
                                                                paddingLeft: 12,
                                                                marginBottom: theme.spacing.unit * 2,
                                                                color: colors.red[500]
                                                            }}
                                                        >
                                                            This commodity is hidden and should not be accessible to
                                                            customers
                                                        </FormHelperText>
                                                    )}
                                                <FormControl
                                                    fullWidth
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        marginTop: theme.spacing.unit * 1
                                                    }}
                                                >
                                                    {showPhotoInput ? (
                                                        <>
                                                            <PhotoAndBagsInput
                                                                required={false}
                                                                disableEntireInput={disableField}
                                                                startAdornmentIcon={icon}
                                                                startAdornmentIconColor={color}
                                                                maxNumber={Math.ceil(
                                                                    MAX_NUM_OF_RECYCLING_BAGS / multiplier
                                                                )}
                                                                minNumber={0}
                                                                componentValue={_.get(subPayload, 'amount', 0)}
                                                                componentLabel={
                                                                    commodity.hidePrimary
                                                                        ? _.get(subCommodity, `name.${lang}`, '')
                                                                        : ''
                                                                }
                                                                componentName={subCommodityId}
                                                                componentError={componentError}
                                                                componentHelperText={
                                                                    commodity.hidePrimary && componentError
                                                                        ? componentHelperText
                                                                        : ''
                                                                }
                                                                onBags={onSubPayloads}
                                                                hasSubCommodity={false}
                                                                toggleSubCommodityView={() => {}}
                                                                commodityUnits={units}
                                                                subPayloadAmount={0}
                                                                // subPayloadAmount={subPayloadsCommodityEquivalent}
                                                                groupedPayloadString={''}
                                                                subCommoditiesVisible={false}
                                                                driverView={driverView}
                                                                infoAdornment={<></>}
                                                                imagePreviews={imagePreviews}
                                                                commodity={subCommodity}
                                                                isSubCommodity={true}
                                                                imageRequired={_.get(commodity, 'imageRequired', false)}
                                                                onPhotoButton={onPhotoButton}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumberOfBagsInput
                                                                minimal={!commodity.hidePrimary}
                                                                required={false}
                                                                disableEntireInput={disableField}
                                                                startAdornmentIcon={icon}
                                                                startAdornmentIconColor={color}
                                                                maxNumber={Math.ceil(
                                                                    MAX_NUM_OF_RECYCLING_BAGS / multiplier
                                                                )}
                                                                minNumber={0}
                                                                componentValue={_.get(subPayload, 'amount', 0)}
                                                                componentLabel={
                                                                    commodity.hidePrimary
                                                                        ? _.get(subCommodity, `name.${lang}`, '')
                                                                        : ''
                                                                }
                                                                componentName={subCommodityId}
                                                                componentError={componentError}
                                                                componentHelperText={
                                                                    commodity.hidePrimary && componentError
                                                                        ? componentHelperText
                                                                        : ''
                                                                }
                                                                onBags={onSubPayloads}
                                                                extraAdornments={
                                                                    <InputAdornment
                                                                        data-cy={`subcommodity-${skuType}-${idx}`}
                                                                        position="end"
                                                                        style={{
                                                                            height: '100%',
                                                                            whiteSpace: 'nowrap',
                                                                            marginRight: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        {commodity.hidePrimary
                                                                            ? uppercaseFirstLetter(
                                                                                  _.get(
                                                                                      subCommodity,
                                                                                      `units.${lang}`,
                                                                                      name
                                                                                  )
                                                                              )
                                                                            : name}
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </FormControl>
                                            </>
                                        );
                                    })}
                                </div>
                            </Collapse>
                        </FormControl>
                    </>
                );
            })}
            <Dialog open={eligibleDialogOpen} onClose={() => setEligibleDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    {isMXDRegion() && loc('allowedTypes', lang)}
                    {!isMXDRegion() && _commodity.getPayloadInputName(selectedCommodity, lang)}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <EligibleExamplesList
                            eligibleExamples={_.get(selectedCommodity, 'eligibleExamples', [])}
                            editDisabled
                            headerText={
                                minPickupFeeEnabled &&
                                _.get(
                                    selectedCommodity,
                                    'includeContainersInStats',
                                    _.get(selectedCommodity, 'skuType', '') === 'beverage'
                                )
                                    ? loc('lowVolumeFeeWarning', lang, { lowVolumeContainerThreshold })
                                    : ''
                            }
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEligibleDialogOpen(false)}>{loc('close', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(PayloadInput);

function sortPayload(payload, lang) {
    // put beverage first then sort the rest alphabetically
    payload.sort((a, b) => {
        if (_commodity.getSkuType(a) === 'beverage') {
            return -1;
        } else if (_commodity.getSkuType(b) === 'beverage') {
            return 1;
        }
        return _commodity.getPayloadInputName(a, lang).toLowerCase() >
            _commodity.getPayloadInputName(b, lang).toLowerCase()
            ? 1
            : -1;
    });
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
