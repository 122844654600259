import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user, _time } from 'std';

import { PICKUP_TIME_OF_DAY_PREFERENCES, COMMERCIAL } from 'constants.js';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import TimePicker from 'material-ui-pickers/TimePicker';


import {
    DialogContentText,
    OutlinedInput,
    LinearProgress,
    Fade,
    InputAdornment,
    Icon,
    DialogActions,
    DialogContent,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    TextField,
    Button,
    Typography,
    Switch,
    colors,
    Grid,
    FormHelperText,
    Tooltip
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import OperatorContext from 'utils/contexts/OperatorContext';
import HttpContext from 'utils/contexts/HttpContext';

import ReceiversSelectDropdown from 'components/DropDownSelects/Receivers';
import { withTheme } from '@material-ui/core/styles';

import { getReceiverName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { isCONRegion } from '../../../../utils/misc';

const SUNDAY = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;

const weekdayName = [];
weekdayName[MONDAY] = 'Monday';
weekdayName[TUESDAY] = 'Tuesday';
weekdayName[WEDNESDAY] = 'Wednesday';
weekdayName[THURSDAY] = 'Thursday';
weekdayName[FRIDAY] = 'Friday';
weekdayName[SATURDAY] = 'Saturday';
weekdayName[SUNDAY] = 'Sunday';

const AdminOptions = props => {
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    
    const { lang } = useContext(LocalizationContext);

    const {
        theme,
        unserviceable,
        inProgress,
        pickupForm,
        onBack,
        onNext,
        onChange,
        onTimeOverride,
        onDateChange,
        onReceiverChange,
        onTimePreferenceChange,
        onOverrideAlwaysConfirmChange,
        onOverrideProcessorChange,
        onOverrideTransporterChange,
        onEfficiencyModifierChange,
        onGenericError,
        customer,
        isSystemAdmin,
        showTimeOverride,
        showBypassZoneOptions,
        onBypassChange,
        availableBookings,
        availableTimeSlots,
        showTimeSlots,
        handleAvailableTimeSlots,
        toggleBottleDrive,
        togglePlaceAtStartOfTrip,
        driversAvailable,
        collectorsAvailable
    } = props;

    const {
        date,
        availableDates,
        recurring,
        frequency,
        pickupType,
        trip,
        zone,
        receiver,
        receivers,
        customerNextPickupDate,
        alwaysBypassZoneChecks,
        location,
        collector,
        transporterCollector,
        driver,
        isBottleDrive,
        timePreference,
        efficiencyModifier,
        overrideProcessor,
        overrideTransporter,
        promos,
        alwaysConfirmedOverride
    } = pickupForm;

    const [loading, setLoading] = useState(false);
    const [showTripSelect, setShowTripSelector] = useState(false);
    const [tripsAvailable, setTripsAvailable] = useState([]);

    //displayed to admin
    const allowedDaysOfTheWeekCopy = useMemo(() => {
        let temp = _.cloneDeep(customer.allowedDaysOfTheWeek) || [];
        if (!_.isNil(temp)) {
            temp.sort((a, b) => {
                const ordering = {
                    Sunday: 0,
                    Monday: 1,
                    Tuesday: 2,
                    Wednesday: 3,
                    Thursday: 4,
                    Friday: 5,
                    Saturday: 6
                };
                return ordering[a] - ordering[b];
            });
        } else {
            temp = [];
        }
    }, [customer]);

    const getTripsOnDate = async () => {
        const timezone = _.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
        const dateInteger = _time.getDateInteger(timezone, date);

        const res = await http.getJSON(`/trips/tripsOnDate?dateInteger=${dateInteger}&timezone=${timezone}`);

        if (res.ok) {
            const sortedTrips = _.sortBy(res.data, t => t.collector.code);
            setTripsAvailable(sortedTrips);
        }
    };

    useEffect(() => {
        const isToday = moment(date)
            .tz(_.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE))
            .isSame(new Date(), 'day');
        setShowTripSelector(isToday);
        if (isToday && _.isEmpty(tripsAvailable)) {
            getTripsOnDate();
        }

        // Update time slot options when date changes
        if (showTimeSlots) {
            const timezone = _.get(zone, 'timezone', process.env.TIMEZONE);

            const objForSelectedDate = _.find(availableBookings, availableDate => {
                const dateToCheck = moment(availableDate.day).tz(timezone);
                const selectedDateAsMoment = moment(date).tz(timezone);

                return dateToCheck.isSame(selectedDateAsMoment, 'day');
            });

            const timeSlotsForSelectedDate = _.get(objForSelectedDate, 'availableTimes', []);

            // Sets default time to first available timeslot
            if (!_.isEmpty(timeSlotsForSelectedDate)) {
                onDateChange(momentForLocation(timeSlotsForSelectedDate[0], location));
            }

            handleAvailableTimeSlots(timeSlotsForSelectedDate);
        }
    }, [moment(date).format('LL')]);

    const bypassZoneDateString = locDate(
        date,
        'MMMM Do',
        lang,
        _.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)
    );

    const driversSelectable = useMemo(
        () =>
            _(driversAvailable)
                .filter(
                    driver =>
                        (!driver.banned && driver.collector._id === transporterCollector) ||
                        driver._id.toString() === driver
                )
                .sortBy(driver => driver.name.first.toLowerCase())
                .value(),
        [driversAvailable, transporterCollector]
    );

    return (
        <Fragment>
            <DialogContent>
                {(_user.isSystemAdmin(operator) || _user.isCollectorAdmin(operator)) && (
                    <>
                        <CustomFormTitle titleText="Driver" iconName="local_shipping" />
                        <FormControl
                            fullWidth
                            data-cy="pickupdialog-receiver-select"
                            style={{ marginBottom: theme.spacing.unit * 2 }}
                        >
                            <ReceiversSelectDropdown
                                id="receiver-dropdown"
                                value={receiver}
                                receivers={receivers}
                                cypress_id="trip-list-receiver-dropdown"
                                onChange={onReceiverChange}
                                showAll={false}
                            />
                        </FormControl>
                    </>
                )}

                <CustomFormTitle
                    style={{ marginTop: 5, marginBottom: 0 }}
                    titleText={'Override pickup settings'}
                    iconName="admin_panel_settings"
                />
                {/* If manual time override is true, pickup will be set to skipped by default */}

                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            flexDirection: 'column',
                            display: 'flex'
                        }}
                    >
                        {customer.alwaysConfirmedOverride ? (
                            <div style={{ margin: '15px 0px' }}>
                                <Typography variant="caption" style={{ color: theme.palette.text.secondary }}>
                                    User preference - Always confirmed - enabled
                                </Typography>
                            </div>
                        ) : (
                            <>
                                <div
                                    style={{
                                        margin: '10px 0px',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    <Typography variant="caption">
                                        <b>Set Always Confirmed <br/> Override:</b>
                                    </Typography>
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Will override 'Always confirmed' to be true. (Bypass the need for customers to confirm their pickups via email/SMS etc.)"
                                            style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                        >
                                            <Icon>info</Icon>
                                        </Tooltip>
                                    </InputAdornment>
                                    <Switch
                                        checked={alwaysConfirmedOverride}
                                        onChange={e => onOverrideAlwaysConfirmChange(!alwaysConfirmedOverride)}
                                        color="primary"
                                        disabled={pickupType === COMMERCIAL}
                                    />
                                </div>
                                {pickupType === COMMERCIAL && (
                                    <Typography variant="caption" style={{ color: theme.palette.text.secondary }}>
                                        Commercial pickups are always confirmed - no need to override.
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid container>
                    {!showTimeSlots && (
                        <Grid
                            item
                            xs={3}
                            style={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Typography variant="caption">
                                <b>Set Time:</b>
                                <Switch checked={showTimeOverride} onChange={onTimeOverride} color="primary" />
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        {showTimeOverride ? (
                            !showTimeSlots && (
                                <FormControl fullWidth margin="normal" style={{ paddingRight: 3 }}>
                                    <TimePicker
                                        fullWidth
                                        label="Set specific Time"
                                        variant="outlined"
                                        value={date}
                                        format="h:mm A"
                                        onChange={time => onDateChange(momentForLocation(time, location))}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>schedule</Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            )
                        ) : (
                            <FormControl fullWidth margin="normal" style={{ paddingRight: 3 }}>
                                <InputLabel variant="outlined">{loc('timePreference', lang)}</InputLabel>
                                <Select
                                    value={timePreference}
                                    onChange={e => onTimePreferenceChange(e.target.value)}
                                    input={
                                        <OutlinedInput
                                            labelWidth={loc('timePreference', lang).length * theme.spacing.unit}
                                            name="timePreference"
                                            id="time-preference-select"
                                        />
                                    }
                                >
                                    {PICKUP_TIME_OF_DAY_PREFERENCES.map(timeOfDay => {
                                        // selector uses iso strings for values
                                        // onChange converts the iso strings to moment objects
                                        return (
                                            <MenuItem value={timeOfDay.value} key={timeOfDay.value}>
                                                {loc(timeOfDay.text, lang)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    {/* <Grid
                        item
                        xs="2"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: theme.spacing.unit
                        }}
                    >
                        <FormControl
                            style={{
                                marginLeft: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                        >
                            <FormControlLabel
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '80%',
                                            marginLeft: theme.spacing.unit / 2,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    >
                                        First Pickup
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        name="placeAtStartOfTrip"
                                        checked={placeAtStartOfTrip}
                                        value="placeAtStartOfTrip"
                                        onChange={togglePlaceAtStartOfTrip}
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid> */}
                    <Grid
                        item
                        xs="3"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: theme.spacing.unit
                        }}
                    >
                        <FormControl
                            style={{
                                marginLeft: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                        >
                            <FormControlLabel
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '80%',
                                            marginLeft: theme.spacing.unit / 2,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    >
                                        Bottle Drive
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        name="isBottleDrive"
                                        checked={isBottleDrive}
                                        value="isBottleDrive"
                                        onChange={toggleBottleDrive}
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth margin="normal" style={{ paddingRight: 3 }}>
                            <TextField
                                label="Difficulty Modifier"
                                type="number"
                                data-cy="pickup-dialog-efficiency-modifier-input"
                                variant="outlined"
                                value={efficiencyModifier}
                                onChange={e => onEfficiencyModifierChange(e.target.value)}
                                error={efficiencyModifier < 1}
                                helperText={efficiencyModifier < 1 ? 'Must be 1 or higher' : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title="Multiplies the number of bags to give the driver more time to complete the pickup"
                                                style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                            >
                                                <Icon>info</Icon>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {!isBottleDrive && (
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    overflow: 'hidden'
                                }}
                            >
                                <FormControl fullWidth margin="normal" style={{ marginRight: theme.spacing.unit / 2 }}>
                                    <InputLabel variant="outlined">Override Transporter</InputLabel>
                                    <Select
                                        value={overrideTransporter}
                                        onChange={e => onOverrideTransporterChange(e.target.value)}
                                        input={
                                            <OutlinedInput
                                                labelWidth={'Override Transporter'.length * theme.spacing.unit}
                                                name="timePreference"
                                                id="time-preference-select"
                                            />
                                        }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon>local_shipping</Icon>
                                            </InputAdornment>
                                        }
                                        disabled={!_.isNil(customer.overrideTransporter)}
                                    >
                                        <MenuItem value={'Any'} key={'Any'}>
                                            Any
                                        </MenuItem>
                                        {collectorsAvailable.map(collector => {
                                            return (
                                                <MenuItem value={collector._id} key={collector._id}>
                                                    {collector.name} [{collector.code}]
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {isSystemAdmin && !_.isNil(customer.overrideTransporter) && (
                                        <FormHelperText style={{ margin: theme.spacing.unit }}>
                                            Pickups for customer are collected by{' '}
                                            {_.get(
                                                _.find(
                                                    collectorsAvailable,
                                                    c => c._id.toString() === customer.overrideTransporter.toString()
                                                ),
                                                'code',
                                                'this collector'
                                            )}{' '}
                                            (can be updated in User Preferences)
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    overflow: 'hidden'
                                }}
                            >
                                <FormControl fullWidth margin="normal" style={{ marginLeft: theme.spacing.unit / 2 }}>
                                    <InputLabel variant="outlined">Override Processor</InputLabel>
                                    <Select
                                        value={overrideProcessor}
                                        onChange={e => onOverrideProcessorChange(e.target.value)}
                                        input={
                                            <OutlinedInput
                                                labelWidth={'Override Processor'.length * theme.spacing.unit}
                                                name="timePreference"
                                                id="time-preference-select"
                                            />
                                        }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon>business</Icon>
                                            </InputAdornment>
                                        }
                                        disabled={!_.isNil(customer.overrideProcessor)}
                                    >
                                        <MenuItem value={'Any'} key={'Any'}>
                                            Any
                                        </MenuItem>
                                        {collectorsAvailable.map(collector => {
                                            return (
                                                <MenuItem value={collector._id} key={collector._id}>
                                                    {collector.name} [{collector.code}]
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {isSystemAdmin && !_.isNil(customer.overrideProcessor) && (
                                        <FormHelperText style={{ margin: theme.spacing.unit }}>
                                            Pickups for customer are routed to{' '}
                                            {_.get(
                                                _.find(
                                                    collectorsAvailable,
                                                    c => c._id.toString() === customer.overrideProcessor.toString()
                                                ),
                                                'code',
                                                'this collector'
                                            )}{' '}
                                            (can be updated in User Preferences)
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                )}
                {showTimeSlots && (
                    <>
                        {!_.isEmpty(availableTimeSlots) ? (
                            <FormControl fullWidth margin="normal">
                                <InputLabel variant="outlined" htmlFor="time-select">
                                    Time
                                </InputLabel>
                                <Select
                                    value={date.toISOString()}
                                    onChange={e => onDateChange(momentForLocation(e.target.value, location))}
                                    input={<OutlinedInput labelWidth={36} name="time" id="time-select" />}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Icon>schedule</Icon>
                                        </InputAdornment>
                                    }
                                >
                                    {availableTimeSlots.map(time => {
                                        // selector uses iso strings for values
                                        // onChange converts the iso strings to moment objects
                                        return (
                                            <MenuItem value={time} key={time}>
                                                {momentForLocation(time, location).format('h:mm A')}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        ) : (
                            <Typography
                                variant="caption"
                                style={{ color: colors.red[500], margin: theme.spacing.unit / 2 }}
                            >
                                <b>{loc('pickupDialog66', lang)}</b>
                            </Typography>
                        )}
                    </>
                )}
                {customer.adminView && (
                    <>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    <b>Allowed Days:</b>{' '}
                                    {!_.isEmpty(allowedDaysOfTheWeekCopy) ? allowedDaysOfTheWeekCopy.join(', ') : 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    <b>Next pickup date:</b>{' '}
                                    {!_.isNil(customerNextPickupDate) &&
                                        _time
                                            .convertISODateToMomentObject(
                                                _.get(zone, 'timezone', process.env.REACT_APP_REGION_TIMEZONE),
                                                customerNextPickupDate
                                            )
                                            .format('MMM DD, YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                        {_user.isCollectorCustomer(customer) &&
                            _.get(zone, 'collector', '').toString() !== customer.collector.toString() && (
                                <Typography variant="caption">
                                    <b>NOTE:</b> This zone belongs to a different collector
                                </Typography>
                            )}
                    </>
                )}
                {isBottleDrive && (
                    <>
                        <FormControl
                            fullWidth
                            data-cy="create-edit-trip-dialog-collector-select"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel htmlFor="transporterCollector" variant="outlined" shrink>
                                Transporter
                            </InputLabel>
                            <Select
                                value={transporterCollector}
                                onChange={onChange}
                                inputProps={{ id: 'transporterCollector', name: 'transporterCollector' }}
                                input={
                                    <OutlinedInput
                                        name="transporterCollector"
                                        id="transporterCollector"
                                        labelWidth={'Transporter'.length * theme.spacing.unit}
                                        notched
                                    />
                                }
                            >
                                {collectorsAvailable.map(collector => {
                                    return (
                                        <MenuItem
                                            key={collector._id}
                                            value={collector._id}
                                            data-cy={`create-edit-trip-transporterCollector-${collector.name
                                                .replace(/\s*\W*\s+/g, '-')
                                                .toLowerCase()}-li`}
                                        >
                                            {collector.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {_.isEmpty(transporterCollector) && (
                            <Typography
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    color: theme.palette.error.main,
                                    marginLeft: 5
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit / 2,
                                        lineHeight: 1.25,
                                        color: theme.palette.error.main
                                    }}
                                >
                                    warning
                                </Icon>{' '}
                                No transporter selected!
                            </Typography>
                        )}
                        <FormControl
                            fullWidth
                            data-cy="create-edit-trip-dialog-collector-select"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel htmlFor="collector" variant="outlined" shrink>
                                Processor
                            </InputLabel>
                            <Select
                                value={collector}
                                onChange={e => {
                                    console.log('collectorSelected', e);
                                    onChange(e);
                                }}
                                inputProps={{ id: 'collector', name: 'collector' }}
                                input={
                                    <OutlinedInput
                                        name="collector"
                                        id="collector"
                                        labelWidth={'Processor'.length * theme.spacing.unit}
                                        notched
                                    />
                                }
                            >
                                {collectorsAvailable.map(collector => {
                                    return (
                                        <MenuItem
                                            key={collector._id}
                                            value={collector._id}
                                            data-cy={`create-edit-trip-${collector.name
                                                .replace(/\s*\W*\s+/g, '-')
                                                .toLowerCase()}-li`}
                                        >
                                            {collector.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {_.isEmpty(collector) && (
                            <Typography
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    color: theme.palette.error.main,
                                    marginLeft: 5
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit / 2,
                                        lineHeight: 1.25,
                                        color: theme.palette.error.main
                                    }}
                                >
                                    warning
                                </Icon>{' '}
                                No processor selected!
                            </Typography>
                        )}
                        <FormControl
                            fullWidth
                            data-cy="create-edit-trip-dialog-driver-select"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel htmlFor="driver" variant="outlined" shrink>
                                Driver
                            </InputLabel>
                            <Select
                                disabled={_.isEmpty(collector)}
                                value={driver}
                                onChange={e => {
                                    console.log('collectorSelected', e);
                                    onChange(e);
                                }}
                                inputProps={{ id: 'driver', name: 'driver' }}
                                input={
                                    <OutlinedInput
                                        name="driver"
                                        id="driver"
                                        labelWidth={'Driver'.length * theme.spacing.unit}
                                        notched
                                    />
                                }
                            >
                                {driversSelectable.map(driver => {
                                    return (
                                        <MenuItem
                                            key={driver._id}
                                            value={driver._id}
                                            data-cy={`${getReceiverName(driver)
                                                .replace(/\s/g, '-')
                                                .toLowerCase()}-create-edit-trip-driver-li`}
                                        >
                                            {getReceiverName(driver)} ➡{' '}
                                            <span style={{ color: theme.palette.primary.main }}>
                                                {_.get(
                                                    _.find(collectorsAvailable, c => c._id.toString() === collector),
                                                    'code',
                                                    'N/A'
                                                )}
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {_.isEmpty(driver) && (
                            <Typography
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    color: theme.palette.error.main,
                                    marginLeft: 5,
                                    marginBottom: 5
                                }}
                            >
                                <Icon
                                    style={{
                                        marginRight: theme.spacing.unit / 2,
                                        lineHeight: 1.25,
                                        color: theme.palette.error.main
                                    }}
                                >
                                    warning
                                </Icon>{' '}
                                No driver selected!
                            </Typography>
                        )}
                    </>
                )}
                {showTripSelect && !isBottleDrive && (
                    <FormControl fullWidth margin="normal" helperText="Trip that the pickup will be assigned to">
                        <InputLabel htmlFor="trip-assign" variant="outlined" shrink>
                            Trip
                        </InputLabel>
                        <Select
                            data-cy="pickupdialog-trip-select"
                            value={trip}
                            onChange={onChange}
                            input={
                                <OutlinedInput
                                    name="trip"
                                    id="trip-assign"
                                    labelWidth={'Trip'.length * theme.spacing.unit}
                                    notched
                                />
                            }
                        >
                            {tripsAvailable &&
                                tripsAvailable.map(({ _id, startTime, collector, transporter }) => {
                                    return (
                                        <MenuItem
                                            key={_id}
                                            value={_id}
                                            data-cy={`pickupdialog-trip-select-${_.kebabCase(
                                                getReceiverName(transporter)
                                            )}`}
                                        >
                                            <span>
                                                <span style={{ color: theme.palette.text.secondary }}>
                                                    {locDate(
                                                        startTime,
                                                        'ddd, MMM Do h:mm A',
                                                        lang,
                                                        _.get(
                                                            location,
                                                            'timezone',
                                                            process.env.REACT_APP_REGION_TIMEZONE
                                                        )
                                                    )}
                                                </span>{' '}
                                                {getReceiverName(transporter)} ➡{' '}
                                                <span style={{ color: theme.palette.primary.main }}>
                                                    {collector.code}
                                                </span>
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack} data-cy="pickup-dialog-date-back">
                    {loc('back', lang)}
                </Button>
                <Button
                    color="primary"
                    disabled={
                        loading ||
                        (showTripSelect && !trip && !isBottleDrive) ||
                        (showTimeSlots && availableTimeSlots.length < 1) ||
                        (isBottleDrive && (_.isEmpty(collector) || _.isEmpty(driver))) ||
                        efficiencyModifier < 1
                    }
                    onClick={onNext}
                    data-cy="admin-pickupdialog-next-button"
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Fragment>
    );
};

export default withTheme()(AdminOptions);

function momentForLocation(date, location) {
    return moment(date).tz(_.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE));
}
