import React, { useState, useContext, useMemo, useRef } from 'react';
import _ from 'lodash';

import {
    Typography,
    withTheme,
    FormGroup,
    FormControlLabel,
    FormControl,
    Switch,
    Collapse,
    TextField,
    MenuItem,
    Icon,
    InputAdornment,
    CircularProgress,
    IconButton,
    Divider,
    InputLabel,
    Select,
    FormHelperText,
    colors
} from '@material-ui/core';

import withWidth from '@material-ui/core/withWidth';
import { getDeviceInformation, getStateAbbreviationFromFullName, isAUSRegion, isEXPRegion } from 'utils/misc.js';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import * as terms from 'localizations/terms';

import AlternativeRegistrations from 'components/AlternativeRegistrations/AlternativeRegistrations';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';
import PasswordInput from 'components/InputComponents/PasswordInput';
import { useEffect } from 'react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import useIsMounted from 'utils/hooks/useIsMounted';
import QuestionnaireForm from 'components/QuestionnaireForm';

import GMapsAutocomplete from 'components/GMapsAutocomplete';
import GoogleContext from 'utils/contexts/GoogleContext';
import HelpDialog from 'components/HelpDialog/HelpDialog';
import { ACCOUNT_TYPE_SELECTIONS, REGISTRATION_TABS } from '../../../constants';
import CommodityRequestDialog from 'components/CommodityRequestDialog';
import FakeLink from 'components/FakeLink/FakeLink';

function AccountInformationForm(props) {
    const formRef = useRef(null);
    const {
        promoInputShown,
        tabIndex,
        hasAnAccount,
        redirectedFromApp,
        showAlternativeLogins,
        formControlStyle,
        textFieldStyle,
        form,
        formErrors,
        promos,
        questionnaire,
        questionnaireFormIncomplete,
        questionnaireReponses,
        touchedInputs,
        overrideTouched,
        services,
        servicesLoading,
        biometricsAvailable,
        biometricsOptIn,
        handleResponseSelected,
        handleCustomReponseChanged,
        handleOAuth,
        handleCustomAuth,
        handleFormChange,
        handleChangeLocationType,
        handleCommodityRequest,
        handleApplyPromo,
        handleRemovePromo,
        handleCharityPreferred,
        handleShowPromoInput,
        toggleGPSOverride,
        handleCoordinateChange,
        handleAddressChange,
        handleSuggestionSelected,
        handleToggleBiometricsOptIn,
        theme,
        width,
        registrationConfig,
        http,
        commodities
    } = props;
    const { lang } = useContext(LocalizationContext);
    const { google } = useContext(GoogleContext);
    const isMounted = useIsMounted();
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);

    const [informationDialogOpen, setInformationDialogOpen] = useState(false);
    const [informationDialogText, setInformationDialogText] = useState(false);
    const [commodityRequestDialogOpen, setCommodityRequestDialogOpen] = useState(false);
    const [showUnitNum, setShowUnitNum] = useState(isEXPRegion() ? true : false);
    const [showTradingName, setShowTradingName] = useState(isEXPRegion() ? true : false);
    const [showPOBox, setShowPOBox] = useState(isEXPRegion() ? true : false);

    const commoditiesAvailableForRequest = useMemo(() => {
        return !_.isNil(
            _.find(commodities, c => {
                return (
                    (c.enableCustomerOrders &&
                        _.get(c, 'locationType', []).includes(form.locationType) &&
                        (!isAUSRegion() ||
                            _.isNil(_.get(form, 'location.province')) ||
                            _.get(c, 'state', []).includes(
                                getStateAbbreviationFromFullName(_.get(form, 'location.province'))
                            ))) ||
                    !_.isNil(
                        _.find(
                            _.get(c, 'subCommodities', []),
                            sc =>
                                sc.enableCustomerOrders &&
                                _.get(sc, 'locationType', []).includes(form.locationType) &&
                                (!isAUSRegion() ||
                                    _.isNil(_.get(form, 'location.province')) ||
                                    _.get(sc, 'state', []).includes(
                                        getStateAbbreviationFromFullName(_.get(form, 'location.province'))
                                    ))
                        )
                    )
                );
            })
        );
    }, [commodities, form.location]);

    const accountTypeSelection =
        tabIndex === REGISTRATION_TABS.BUSINESS
            ? ACCOUNT_TYPE_SELECTIONS.BUSINESS
            : tabIndex === REGISTRATION_TABS.PERSONAL
            ? ACCOUNT_TYPE_SELECTIONS.PERSONAL
            : ACCOUNT_TYPE_SELECTIONS.GROUP;

    // const isSmallScreen = isWidthDown('xs', width);

    useEffect(() => {
        const asyncFunc = async () => {
            let deviceInfo = await getDeviceInformation();
            if (isMounted()) {
                setIsInAppBrowser(deviceInfo.isInAppBrowser);
            }
        };
        asyncFunc();
    }, [isMounted]);

    const accountText = [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex)
        ? loc('organizationName', lang, { organizationName: terms.ORGANIZATION_NAME }).toLowerCase()
        : loc('companybottledrive', lang);

    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(services, service => service.isMobilePickupService && !service.disabled),
                'subServices',
                []
            ).filter(
                service =>
                    (_.isNil(_.get(service, 'associatedAccountTypes')) ||
                        _.get(service, 'associatedAccountTypes', []).includes(accountTypeSelection)) &&
                    !service.disabled &&
                    !(service.charityLocked && [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex))
            ),
        [services, tabIndex]
    );

    const selectedSubService = useMemo(() => _.first(mobilePickupSubServices), [mobilePickupSubServices]);

    useEffect(() => {
        if (!_.isNil(selectedSubService)) {
            handleChangeLocationType(_.get(selectedSubService, 'pickupType'), true);
        }
    }, [mobilePickupSubServices, selectedSubService]);

    const toggleHelpDialog = text => () => {
        if (text === informationDialogText && informationDialogOpen) {
            setInformationDialogOpen(false);
        } else {
            setInformationDialogOpen(true);
            setInformationDialogText(text);
        }
    };

    const handleCommodityRequestDialog = state => () => {
        setCommodityRequestDialogOpen(state);
    };

    const onSubmitCommodityRequest = commodities => {
        handleCommodityRequest(commodities);
        setCommodityRequestDialogOpen(false);
    };

    const handleCancelCommodityRequest = async () => {
        handleCommodityRequest([]);
        setCommodityRequestDialogOpen(false);
    };

    const [formWidth, setFormWidth] = useState(width);

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        const width = formRef.current.offsetWidth;
        setFormWidth(width);
    }, [width]);
    return (
        <React.Fragment>
            {/* TODO: provide link to download the app */}

            {[REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY, REGISTRATION_TABS.BOTTLE_DRIVE].includes(
                tabIndex
            ) && (
                <>
                    <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                        {hasAnAccount ? loc('registrationOrg1', lang) : loc('registrationOrg2', lang)}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                        {hasAnAccount
                            ? loc('registrationOrg3', lang, { accountText })
                            : loc('registrationOrg4', lang, { accountText })}
                    </Typography>
                </>
            )}

            <Collapse in={!hasAnAccount}>
                {[
                    REGISTRATION_TABS.PERSONAL,
                    REGISTRATION_TABS.BUSINESS,
                    REGISTRATION_TABS.BOTTLE_DRIVE,
                    REGISTRATION_TABS.GROUP,
                    REGISTRATION_TABS.CHARITY
                ].includes(tabIndex) &&
                    showAlternativeLogins &&
                    !redirectedFromApp &&
                    !isInAppBrowser && (
                        <>
                            <AlternativeRegistrations
                                style={{ marginBottom: theme.spacing.unit * 2 }}
                                registerFacebook={() => {}}
                                registerGoogle={() =>
                                    handleOAuth(
                                        'google',
                                        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex),
                                        tabIndex === 3
                                    )
                                }
                                registerApple={() =>
                                    handleOAuth(
                                        'apple',
                                        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex),
                                        tabIndex === 3
                                    )
                                }
                                registerExpress={() =>
                                    handleCustomAuth(
                                        'exp',
                                        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex),
                                        tabIndex === 3
                                    )
                                }
                                signUp
                            />
                            <div style={{ display: 'flex', marginTop: theme.spacing.unit * 2 }}>
                                <Divider style={{ flex: 2, marginTop: theme.spacing.unit }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        paddingRight: theme.spacing.unit,
                                        paddingLeft: theme.spacing.unit
                                    }}
                                >
                                    <Typography variant="body1" color="textSecondary" style={{ flex: 1 }}>
                                        {loc('or', lang)}
                                    </Typography>
                                </div>
                                <Divider style={{ flex: 2, marginTop: theme.spacing.unit }} />
                            </div>
                        </>
                    )}

                <div
                    ref={formRef}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        marginTop: theme.spacing.unit
                    }}
                >
                    {servicesLoading && <CircularProgress size={32} />}
                    {!_.isNil(mobilePickupSubServices) &&
                        !_.isEmpty(mobilePickupSubServices) &&
                        mobilePickupSubServices.length > 1 && (
                            <TextField
                                onChange={e => handleChangeLocationType(e.target.value, true)}
                                value={form.locationType}
                                fullWidth
                                select
                                data-cy="registration-location-type-select"
                            >
                                {_(mobilePickupSubServices)
                                    .map((service, i) => {
                                        const title = _.get(service, `text.${lang}.title`, '');
                                        return (
                                            <MenuItem
                                                value={_.get(service, 'pickupType')}
                                                key={_.get(service, 'pickupType')}
                                                data-cy={`registration-location-type-input-${_.get(
                                                    service,
                                                    'pickupType'
                                                )}`}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            verticalAlign: 'middle'
                                                        }}
                                                    >
                                                        <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                                            <MDIcon
                                                                path={_.get(allIcons, _.get(service, 'mdiIcon'))}
                                                                size={1}
                                                            />
                                                        </td>
                                                        <td>{title}</td>
                                                    </tr>
                                                </div>
                                            </MenuItem>
                                        );
                                    })
                                    .value()}
                            </TextField>
                        )}
                </div>
                {form.locationType === 'Commercial' && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy={`${tabIndex}-registration-registered-business-name-input`}
                            name="registeredBusinessName"
                            label={loc('accountSettings29', lang)}
                            value={form.registeredBusinessName}
                            helperText={formErrors['registeredBusinessName'].reason}
                            error={formErrors['registeredBusinessName'].fail}
                            InputProps={{
                                style: textFieldStyle,
                                endAdornment: !_.isNil(
                                    _.get(registrationConfig, `tooltips.registeredBusinessName.${lang}`)
                                ) ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleHelpDialog(
                                                _.get(registrationConfig, `tooltips.registeredBusinessName.${lang}`, '')
                                            )}
                                            data-cy="registration-registered-business-name-help"
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    undefined
                                )
                            }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{
                                'data-cy': `${tabIndex}-registration-registered-business-name-input-helper-txt`
                            }}
                        />
                    </FormControl>
                )}
                {form.locationType === 'Commercial' && (
                    <>
                        {showTradingName ? (
                            <FormControl fullWidth style={formControlStyle}>
                                <TextField
                                    data-cy={`${tabIndex}-registration-business-name-input`}
                                    name="businessName"
                                    label={
                                        isAUSRegion()
                                            ? `${loc('accountSettings28a', lang)} (${loc('ifDifferent', lang)})`
                                            : `${loc('accountSettings28', lang)} (${loc('ifDifferent', lang)})`
                                    }
                                    value={form.businessName}
                                    helperText={formErrors['businessName'].reason}
                                    error={formErrors['businessName'].fail}
                                    InputProps={{
                                        style: textFieldStyle,
                                        endAdornment: !_.isNil(
                                            _.get(registrationConfig, `tooltips.doingBusinessAs.${lang}`)
                                        ) ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleHelpDialog(
                                                        _.get(
                                                            registrationConfig,
                                                            `tooltips.doingBusinessAs.${lang}`,
                                                            ''
                                                        )
                                                    )}
                                                    data-cy="registration-business-name-help"
                                                >
                                                    <Icon>help</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            undefined
                                        )
                                    }}
                                    onChange={handleFormChange}
                                    FormHelperTextProps={{
                                        'data-cy': `${tabIndex}-registration-business-name-input-helper-txt`
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <div>
                                <Typography
                                    data-cy={`${tabIndex}-registration-business-name-cover`}
                                    color="primary"
                                    style={{
                                        textDecoration: 'underline',
                                        marginTop: 30,
                                        fontSize: 15,
                                        cursor: 'pointer',
                                        display: 'inline-block'
                                    }}
                                    onClick={() => setShowTradingName(true)}
                                >
                                    {loc('labels23', lang)}
                                </Typography>
                            </div>
                        )}
                    </>
                )}
                {form.locationType === 'Commercial' && _.get(registrationConfig, 'showBusinessNumber', true) && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy={`${tabIndex}-registration-business-number-input`}
                            name="businessNumber"
                            label={isAUSRegion() ? loc('charityNumName', lang) : loc('accountSettings30', lang)}
                            value={form.businessNumber}
                            helperText={formErrors['businessNumber'].reason}
                            error={formErrors['businessNumber'].fail}
                            InputProps={{
                                style: textFieldStyle
                            }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{
                                'data-cy': `${tabIndex}-registration-business-number-input-helper-txt`
                            }}
                        />
                    </FormControl>
                )}
                <FormControl fullWidth style={{ ...formControlStyle, maxWidth: formWidth }}>
                    <GMapsAutocomplete
                        data-cy="registration-address-input"
                        google={google}
                        location={form.location}
                        label={form.locationType === 'Commercial' ? loc('companyAddress', lang) : loc('address', lang)}
                        placeholder={loc('placeholder1', lang)}
                        selectedValue={form.location.description}
                        variant=""
                        types={['address']}
                        style={{ ...textFieldStyle }}
                        error={formErrors.location['description']}
                        onChange={handleAddressChange}
                        onSuggestionSelected={handleSuggestionSelected}
                        suggestionsOccupyVerticalSpace={true}
                        endAdornment={
                            !_.isNil(_.get(registrationConfig, `tooltips.address.${lang}`)) ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleHelpDialog(
                                            _.get(registrationConfig, `tooltips.address.${lang}`, '')
                                        )}
                                        data-cy="registration-address-help"
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                undefined
                            )
                        }
                    />
                </FormControl>
                {showUnitNum ? (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy={`${tabIndex}-registration-unit-number-input`}
                            name="unitNumber"
                            label={loc('labels9', lang)}
                            value={form.unitNumber}
                            helperText={formErrors['unitNumber'].reason}
                            error={formErrors['unitNumber'].fail}
                            InputProps={{
                                style: textFieldStyle
                            }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-unit-number-input-helper-txt` }}
                        />
                    </FormControl>
                ) : (
                    <div>
                        <Typography
                            data-cy={`${tabIndex}-registration-unit-number-cover`}
                            color="primary"
                            style={{
                                textDecoration: 'underline',
                                marginTop: 30,
                                fontSize: 15,
                                cursor: 'pointer',
                                display: 'inline-block'
                            }}
                            onClick={() => setShowUnitNum(true)}
                        >
                            {loc('labels24', lang)}
                        </Typography>
                    </div>
                )}

                {_.get(registrationConfig, 'showPOBoxInput', false) && (
                    <>
                        {showPOBox ? (
                            <FormControl fullWidth style={formControlStyle}>
                                <TextField
                                    data-cy={`${tabIndex}-registration-p-o-box-input`}
                                    name="POBox"
                                    label={loc('labels21', lang)}
                                    value={form.POBox}
                                    helperText={formErrors['POBox'].reason}
                                    error={formErrors['POBox'].fail}
                                    InputProps={{
                                        style: textFieldStyle
                                    }}
                                    onChange={handleFormChange}
                                    FormHelperTextProps={{
                                        'data-cy': `${tabIndex}-registration-p-o-box-input-helper-txt`
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <div>
                                <Typography
                                    data-cy={`${tabIndex}-registration-p-o-box-cover`}
                                    color="primary"
                                    style={{
                                        textDecoration: 'underline',
                                        marginTop: 30,
                                        fontSize: 15,
                                        cursor: 'pointer',
                                        display: 'inline-block'
                                    }}
                                    onClick={() => setShowPOBox(true)}
                                >
                                    {loc('labels25', lang)}
                                </Typography>
                            </div>
                        )}
                    </>
                )}

                <FormControl
                    fullWidth
                    style={{
                        // flexDirection: 'row',
                        // padding: `0 ${theme.spacing.unit * 0.5}px`,
                        ...formControlStyle
                    }}
                >
                    <TextField
                        data-cy={`${tabIndex}-registration-nameFirst-input`}
                        name="nameFirst"
                        label={
                            form.locationType === 'Commercial' ? loc('contactFirstName', lang) : loc('firstName', lang)
                        }
                        value={form['nameFirst']}
                        helperText={formErrors['nameFirst'].reason}
                        error={formErrors['nameFirst'].fail}
                        style={{
                            // marginLeft: -theme.spacing.unit * 0.5,
                            // marginRight: theme.spacing.unit * 0.5,
                            width: '100%'
                        }}
                        InputProps={{
                            style: textFieldStyle,
                            endAdornment: !_.isNil(_.get(registrationConfig, `tooltips.name.${lang}`)) ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleHelpDialog(
                                            _.get(registrationConfig, `tooltips.name.${lang}`, '')
                                        )}
                                        data-cy="registration-name-help"
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                undefined
                            )
                        }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-nameFirst-input-helper-txt` }}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    style={{
                        // flexDirection: 'row',
                        // padding: `0 ${theme.spacing.unit * 0.5}px`,
                        ...formControlStyle
                    }}
                >
                    <TextField
                        data-cy={`${tabIndex}-registration-nameLast-input`}
                        name="nameLast"
                        label={
                            form.locationType === 'Commercial' ? loc('contactLastName', lang) : loc('lastName', lang)
                        }
                        value={form['nameLast']}
                        helperText={formErrors['nameLast'].reason}
                        error={formErrors['nameLast'].fail}
                        style={{
                            // marginLeft: theme.spacing.unit * 0.5,
                            // marginRight: -theme.spacing.unit * 0.5,
                            width: '100%'
                        }}
                        InputProps={{ style: textFieldStyle }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-nameLast-input-helper-txt` }}
                    />
                </FormControl>
                {_.get(registrationConfig, 'showSchemeID', false) && (
                    <FormControl
                        fullWidth
                        style={{
                            ...formControlStyle
                        }}
                    >
                        <TextField
                            data-cy={`${tabIndex}-registration-schemeID-input`}
                            name="schemeID"
                            label={'Scheme ID'}
                            value={form['schemeID']}
                            helperText={formErrors['schemeID'].reason}
                            error={formErrors['schemeID'].fail}
                            style={{
                                width: '100%'
                            }}
                            InputProps={{ style: textFieldStyle }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-schemeID-input-helper-txt` }}
                        />
                    </FormControl>
                )}
                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy={`${tabIndex}-registration-email-input`}
                        name="email"
                        label={form.locationType === 'Commercial' ? loc('contactEmail', lang) : loc('email', lang)}
                        value={form.email}
                        helperText={formErrors['email'].reason}
                        error={formErrors['email'].fail}
                        InputProps={{
                            style: textFieldStyle,
                            endAdornment: !_.isNil(_.get(registrationConfig, `tooltips.email.${lang}`)) ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleHelpDialog(
                                            _.get(registrationConfig, `tooltips.email.${lang}`, '')
                                        )}
                                        data-cy="registration-email-help"
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                undefined
                            )
                        }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{
                            'data-cy': `${tabIndex}-registration-email-input-helper-txt`,
                            style: { color: formErrors['email'].warning ? colors.amber[700] : undefined }
                        }}
                    />
                </FormControl>

                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy={`${tabIndex}-registration-phone-input`}
                        type="tel"
                        name="phone"
                        label={loc('phoneNumber', lang)}
                        value={form.phone}
                        helperText={formErrors['phone'].reason}
                        error={formErrors['phone'].fail}
                        InputProps={{
                            inputComponent: TextMaskPhoneNumber,
                            style: textFieldStyle,
                            endAdornment: !_.isNil(_.get(registrationConfig, `tooltips.phone.${lang}`)) ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleHelpDialog(
                                            _.get(registrationConfig, `tooltips.phone.${lang}`, '')
                                        )}
                                        data-cy="registration-phone-help"
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                undefined
                            )
                            // startAdornment: (
                            //     <div style={{ marginRight: theme.spacing.unit / 2 }}>{process.env.REACT_APP_COUNTRY_CALLING_CODE}</div>
                            // )
                        }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-phone-input-helper-txt` }}
                    />{' '}
                </FormControl>

                {form.locationType === 'Commercial' && _.get(registrationConfig, 'showBusinessPhone', true) && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy={`${tabIndex}-registration-businessPhone-input`}
                            type="tel"
                            name="businessPhone"
                            label={loc('businessPhoneNumber', lang)}
                            value={form.buisnessPhoneNumber}
                            helperText={formErrors['businessPhone'].reason}
                            error={formErrors['businessPhone'].fail}
                            InputProps={{
                                inputComponent: TextMaskPhoneNumber,
                                style: textFieldStyle
                                // startAdornment: (
                                //     <div style={{ marginRight: theme.spacing.unit / 2 }}>{process.env.REACT_APP_COUNTRY_CALLING_CODE}</div>
                                // )
                            }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{
                                'data-cy': `${tabIndex}-registration-businessPhone-input-helper-txt`
                            }}
                        />
                    </FormControl>
                )}

                <FormControl fullWidth style={formControlStyle}>
                    <PasswordInput
                        data-cy={`${tabIndex}-registration-password-input`}
                        showValidationRules
                        type="password"
                        name="password"
                        label={loc('password', lang)}
                        value={form.password}
                        helperText={formErrors['password'].reason}
                        error={formErrors['password'].fail}
                        InputProps={{ style: textFieldStyle }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{ 'data-cy': `${tabIndex}-registration-password-input-helper-txt` }}
                    />
                </FormControl>
                {_.get(registrationConfig, 'showRepeatPassword', false) && (
                    <FormControl fullWidth style={formControlStyle}>
                        <PasswordInput
                            data-cy={`${tabIndex}-registration-repeat-password-input`}
                            showValidationRules
                            type="password"
                            name="repeatPassword"
                            label={loc('repeatPassword', lang)}
                            value={form.repeatPassword}
                            helperText={formErrors['repeatPassword'].reason}
                            error={formErrors['repeatPassword'].fail}
                            InputProps={{ style: textFieldStyle }}
                            onChange={handleFormChange}
                            FormHelperTextProps={{
                                'data-cy': `${tabIndex}-registration-repeat-password-input-helper-txt`
                            }}
                            repeat={true}
                            matchPassword={form.password}
                        />
                    </FormControl>
                )}
                {biometricsAvailable && (
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                onChange={handleToggleBiometricsOptIn}
                                value={biometricsOptIn}
                                checked={biometricsOptIn}
                            />
                        }
                        label={loc('biometrics1', lang)}
                    />
                )}
                {_.get(registrationConfig, 'showSecurityQuestions', false) &&
                    !_.isEmpty(_.get(registrationConfig, `securityQuestions.${lang}`, [])) && (
                        <FormControl
                            data-cy={`${tabIndex}-registration-security-question-input`}
                            fullWidth
                            style={{ ...formControlStyle, maxWidth: formWidth }}
                            error={formErrors['securityQuestion'].fail}
                        >
                            <InputLabel htmlFor="question">{loc('securityQuestion', lang)}</InputLabel>
                            <Select
                                value={form.securityQuestion}
                                onChange={handleFormChange}
                                inputProps={{
                                    name: 'securityQuestion',
                                    id: 'security-question'
                                }}
                            >
                                {_.get(registrationConfig, `securityQuestions.${lang}`, []).map((question, index) => {
                                    return (
                                        <MenuItem
                                            data-cy={index + '-redemption-dialog-question'}
                                            key={question}
                                            value={question}
                                            style={{ whiteSpace: 'normal' }}
                                        >
                                            {question}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {formErrors['securityQuestion'].fail && (
                                <FormHelperText>{formErrors['securityQuestion'].reason}</FormHelperText>
                            )}
                        </FormControl>
                    )}

                {_.get(registrationConfig, 'showSecurityQuestions', false) &&
                    !_.isEmpty(_.get(registrationConfig, `securityQuestions.${lang}`, [])) && (
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                data-cy={`${tabIndex}-registration-security-answer-input`}
                                type="text"
                                name="securityAnswer"
                                label={loc('answer', lang)}
                                value={form.securityAnswer}
                                helperText={formErrors['securityAnswer'].reason}
                                error={formErrors['securityAnswer'].fail}
                                InputProps={{
                                    style: textFieldStyle
                                }}
                                onChange={handleFormChange}
                                FormHelperTextProps={{
                                    'data-cy': `${tabIndex}-registration-security-answer-input-helper-txt`
                                }}
                            />
                        </FormControl>
                    )}

                <QuestionnaireForm
                    questionnaire={questionnaire}
                    responses={questionnaireReponses}
                    InputProps={{ style: textFieldStyle }}
                    touchedInputs={touchedInputs}
                    overrideTouched={overrideTouched}
                    onResponseSelected={handleResponseSelected}
                    onCustomResponseChange={handleCustomReponseChanged}
                    variant=""
                />

                <Collapse in={commoditiesAvailableForRequest}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        align="right"
                        style={{
                            fontSize: '0.875rem'
                        }}
                    >
                        <FakeLink
                            theme={theme}
                            text={loc('commodityRequestDialog1', lang)}
                            onClickEvent={handleCommodityRequestDialog(true)}
                            color={theme.palette.primary.main}
                            data-cy="registration-request-commodity"
                        />
                        {!_.isEmpty(form.requestedCommodities) && (
                            <Typography variant="caption" style={{ color: colors.green[500] }}>
                                <Icon style={{ fontSize: '12px' }}>check</Icon> {loc('commodityRequestDialog6', lang)}
                            </Typography>
                        )}
                    </Typography>
                </Collapse>
            </Collapse>

            <Collapse in={hasAnAccount}>
                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy={`${tabIndex}-registration-existingEmail-input`}
                        name="existingEmail"
                        label={loc('email', lang)}
                        disabled={redirectedFromApp}
                        value={form.existingEmail}
                        helperText={formErrors['existingEmail'].reason}
                        error={formErrors['existingEmail'].fail}
                        InputProps={{ style: textFieldStyle }}
                        onChange={handleFormChange}
                    />
                </FormControl>
            </Collapse>
            <HelpDialog
                open={informationDialogOpen}
                body={<Typography variant="body1">{informationDialogText}</Typography>}
                onClose={toggleHelpDialog(informationDialogText)}
            />

            <CommodityRequestDialog
                open={commodityRequestDialogOpen}
                onClose={handleCommodityRequestDialog(false)}
                theme={theme}
                loading={false}
                onSubmit={onSubmitCommodityRequest}
                location={form.location}
                locationType={form.locationType}
                http={http}
                onCancel={handleCancelCommodityRequest}
            />
        </React.Fragment>
    );
}

export default withWidth()(withTheme()(AccountInformationForm));
