import React, { useMemo, useRef, useContext } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    withMobileDialog,
    TextField,
    InputAdornment,
    Typography,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    FormHelperText,
    Grow,
    Tooltip,
    CircularProgress,
    Icon,
    Badge,
    Grid,
    colors,
    Collapse
} from '@material-ui/core';

import { getTextInput, getSwitch, getSelect } from '../helperFunctions';
import CurrencyInput from '../Inputs/CurrencyInput';
import LocalizedTextInput from '../Inputs/LocalizedTextInput';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import EligibleExamplesList from 'components/Lists/EligibleExamplesList';
import SubCommoditiesList from 'components/Lists/SubCommoditiesList';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import { AUS_STATES, COMMERCIAL, COMMODITY_COMPLETION_STRATEGIES, CONDO, RESIDENTIAL } from 'constants.js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useState } from 'react';
import { isWidthDown } from '@material-ui/core/withWidth';
import { isAUSRegion } from 'utils/misc';
import SingleCommodityList from 'components/Lists/SingleCommodityList';

const COMMODITY_TABS = ['Configuration', 'Commodities', 'Examples'];

function CommodityForm(props) {
    const {
        commodity, //NOTE: will be null/undefined if creating a new one
        allFees,
        allRates,
        open,
        onClose,
        onSubmit,
        fullScreen,
        theme,
        loadingRates,
        services,
        width
    } = props;

    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(services, service => service.isMobilePickupService && !service.disabled),
                'subServices',
                []
            ).filter(service => !service.disabled),
        [services]
    );

    const [selectedTab, setSelectedTab] = useState(_.first(COMMODITY_TABS));
    // const [visibility, setVisibility] = useState(
    //     _.get(commodity, 'hideFromCustomer', false) && _.get(commodity, 'hidePrimary', false)
    //         ? 'Hidden'
    //         : !_.get(commodity, 'hideFromCustomer', false) && _.get(commodity, 'hidePrimary', false)
    //         ? 'Only Sub Commodities'
    //         : 'Everything'
    // );
    const { lang } = useContext(LocalizationContext);
    const firstRate = _.first(allRates); //all rates should contain all skus for this to work

    const handleSubmit = values => {
        const toSubmit = _.cloneDeep(values);
        if (toSubmit.subscriptionFee === 'none') {
            toSubmit.subscriptionFee = null;
        }
        toSubmit.iconName = convertToImportName(values.iconName);
        onSubmit(toSubmit);
    };

    const editing = !_.isNil(commodity);

    const isSmallScreen = isWidthDown('xs', width);

    const allIconNames = useMemo(
        () => Object.keys(allIcons).map(importName => convertImportToIconName(importName)),
        []
    );

    //fixes slowness when dragging color selector, prevents react from rerendering to much,
    //needs to be in a ref so the function isn't recreated every render
    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue('color', e.target.value);
            }
        }, 100)
    );

    const handleFeeChange = e => {
        const fee = e.target.value;
        if (fee === 'none') {
            formik.setFieldValue(e.target.name, null);
            return;
        }

        formik.setFieldValue(e.target.name, fee);
        if (e.target.name === 'preAppliedFee') {
            formik.setFieldValue('preAppliedFeeSingle', fee);
            formik.setFieldValue('preAppliedFeeMultiple', fee);
        }
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(commodity, '_id', undefined),
            disabled: _.get(commodity, 'disabled', false),
            skuType: _.get(commodity, 'skuType', ''),
            units: _.get(commodity, 'units', ''),
            defaultSku: _.get(commodity, 'defaultSku'),
            completionStrategy: _.get(commodity, 'completionStrategy', 'Auto'),
            sizeRatio: _.get(commodity, 'sizeRatio', 1),
            projectionMultiplier: _.get(commodity, 'projectionMultiplier', 1),
            defaultPerUnitAmount: _.get(commodity, 'defaultPerUnitAmount', 1),
            payloadInputName: _.get(commodity, 'payloadInputName', ''),
            payloadHelperText: _.get(commodity, 'payloadHelperText', ''),
            eligibleExamples: _.get(commodity, 'eligibleExamples', []),
            subCommodities: _.get(commodity, 'subCommodities', []),
            color: _.get(commodity, 'color', '#000000'),
            iconName: convertImportToIconName(_.get(commodity, 'iconName', '')),
            preAppliedFee: _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee')),
            preAppliedFeeSingle: _.get(
                commodity,
                'preAppliedFeeSingle._id',
                _.get(
                    commodity,
                    'preAppliedFeeSingle',
                    _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee'))
                )
            ),
            preAppliedFeeMultiple: _.get(
                commodity,
                'preAppliedFeeMultiple._id',
                _.get(
                    commodity,
                    'preAppliedFeeMultiple',
                    _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee'))
                )
            ),
            preAppliedFeeApply: _.get(commodity, 'preAppliedFeeApply', 'Once'),
            unserviced: _.get(commodity, 'unserviced', false),
            imageRequired: _.get(commodity, 'imageRequired', true),
            subCommodityCustomerDisclaimer: _.get(commodity, 'subCommodityCustomerDisclaimer', ''),
            subCommodityDriverDisclaimer: _.get(commodity, 'subCommodityDriverDisclaimer', ''),
            showOnMap: _.get(commodity, 'showOnMap', false),
            hideFromCustomer: _.get(commodity, 'hideFromCustomer', false),
            hidePrimary: _.get(commodity, 'hidePrimary', false),
            // subCommodityCounterDisclaimer: _.get(commodity, 'subCommodityCounterDisclaimer', '')
            includeContainersInStats: _.get(commodity, 'includeContainersInStats', false),
            showAsDonatedInHistory: _.get(commodity, 'showAsDonatedInHistory', false),
            enableCustomerOrders: _.get(commodity, 'enableCustomerOrders', false),
            trackInventory: _.get(commodity, 'trackInventory', false),
            locationType: _.get(commodity, 'locationType', [RESIDENTIAL]),
            state: _.get(commodity, 'state', []),
            subscriptionFee: _.get(commodity, 'subscriptionFee', 'none'),
            subscriptionFeeRecurrence: _.get(commodity, 'subscriptionFeeRecurrence', 0)
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            skuType: Yup.string().required('You must enter a commodity name.'),
            units: Yup.string().required('You must enter a unit of measure.'),
            defaultSku: !editing
                ? Yup.number().when('completionStrategy', {
                      is: 'Auto',
                      then: Yup.number().required('You must enter a unique number')
                  })
                : Yup.string(),
            defaultPerUnitAmount: Yup.number()
                .required('You must enter a value.')
                .min(1, 'Must be 1 or more'),
            completionStrategy: Yup.string().required('You must select a value'),
            payloadInputName: Yup.string().required('You must enter a value.'),
            payloadHelperText: Yup.string().required('You must enter a value.'),
            color: Yup.string().required('You must enter a color'),
            iconName: Yup.string()
                .transform((value, originalValue) => convertToImportName(originalValue))
                .oneOf(allIconNames.map(iconName => convertToImportName(iconName)), 'Invalid icon name')
                .required('You must enter an icon name'),
            unserviced: Yup.boolean(),
            imageRequired: Yup.boolean(),
            showAsDonatedInHistory: Yup.boolean(),
            enableCustomerOrders: Yup.boolean(),
            trackInventory: Yup.boolean(),
            subscriptionFee: Yup.string()
                .nullable()
                .when('enableCustomerOrders', {
                    is: true,
                    then: Yup.string()
                        .nullable()
                        .required('You must select a subscription fee')
                })
        }),
        onSubmit: handleSubmit
    });
    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik);
    };

    return (
        <Dialog open={open} fullScreen={fullScreen}>
            <span>
                <ToggleButtonGroup
                    value={selectedTab}
                    exclusive
                    onChange={(e, selectedTab) => !_.isEmpty(selectedTab) && setSelectedTab(selectedTab)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit, display: 'flex' }}
                >
                    {COMMODITY_TABS.map(tab => (
                        <ToggleButton
                            key={tab}
                            value={tab}
                            style={{
                                flexGrow: 1,
                                height: 50
                            }}
                            data-cy={`commodity-form-${tab}-tab`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color:
                                        tab === 'Commodities' && errorOnCommoditiesTab(formik.errors, formik.touched)
                                            ? colors.red[500]
                                            : 'auto'
                                }}
                            >
                                {tab}
                                {tab === 'Commodities' && !_.isEmpty(formik.values.subCommodities) && (
                                    <Badge
                                        style={{ marginLeft: theme.spacing.unit * 2 }}
                                        badgeContent={formik.values.subCommodities.length}
                                        color="primary"
                                    />
                                )}
                            </div>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </span>

            <DialogContent
                style={{
                    height: isSmallScreen ? 'auto' : '700px',
                    width: isSmallScreen ? '100%' : '600px'
                }}
            >
                {selectedTab === 'Configuration' && (
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs="12">
                            <div style={{ display: 'flex' }}>
                                {editing && getSwitch(theme, 'disabled', 'Disabled', formik, {}, { fontSize: '95%' })}
                            </div>
                        </Grid>
                        <Grid item xs="12" sm="12">
                            <TextField
                                {...formik.getFieldProps('skuType')}
                                error={formik.touched.skuType && formik.errors.skuType ? true : null}
                                label="Internal Code"
                                margin="normal"
                                variant="outlined"
                                helperText={
                                    formik.touched.skuType && formik.errors.skuType ? formik.errors.skuType : null
                                }
                                fullWidth
                                disabled={editing}
                            />
                        </Grid>

                        {/* 
                        ============================
                        moved to SingleCommodityList
                        ============================
                        */}
                        {/* 
                        <Grid item xs="6" sm="5">
                            <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                                {getTextInput(theme, 'iconName', 'Icon', formik, 'text', {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <MDIcon
                                                path={_.get(allIcons, convertToImportName(formik.values.iconName))}
                                                size={1.5}
                                                color={formik.values.color}
                                            />
                                        </InputAdornment>
                                    )
                                })}
                                <div style={{ display: 'flex', marginTop: theme.spacing.unit }}>
                                    <ExternalLink
                                        text="Click here to see all icons"
                                        url="https://materialdesignicons.com/"
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs="6" sm="2">
                            <TextField
                                type="color"
                                onChange={handleChangeColorWrapper}
                                value={formik.values.color}
                                onBlur={formik.handleBlur}
                                name="color"
                                error={formik.touched.color && formik.errors.color ? true : null}
                                label="Color"
                                margin="normal"
                                variant="outlined"
                                helperText={formik.touched.color && formik.errors.color ? formik.errors.color : null}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs="6">
                            <LocalizedTextInput
                                label="Display Name"
                                value={_.get(formik, 'values.payloadInputName')}
                                onChange={value => formik.setFieldValue('payloadInputName', value)}
                                touched={_.get(formik, 'touched.payloadInputName')}
                                error={_.get(formik, 'errors.payloadInputName')}
                                infoText="ex. General Electronics, Clothing Donation"
                            />
                        </Grid> */}
                        {/* <Grid item xs="12" sm="6">
                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                <InputLabel variant="outlined">Visibility</InputLabel>
                                <Select
                                    fullWidth
                                    input={<OutlinedInput labelWidth={60} data-cy={`visibility-input`} />}
                                    onChange={e => {
                                        setVisibility(e.target.value);
                                        switch (e.target.value) {
                                            case 'Everything':
                                                formik.setFieldValue('hideFromCustomer', false);
                                                formik.setFieldValue('hidePrimary', false);
                                                break;
                                            case 'Only Sub Commodities':
                                                formik.setFieldValue('hideFromCustomer', false);
                                                formik.setFieldValue('hidePrimary', true);
                                                break;
                                            case 'Hidden':
                                                formik.setFieldValue('hideFromCustomer', true);
                                                formik.setFieldValue('hidePrimary', true);
                                                break;
                                            default:
                                                formik.setFieldValue('hideFromCustomer', false);
                                                formik.setFieldValue('hidePrimary', false);
                                        }
                                    }}
                                    margin="normal"
                                    value={visibility}
                                >
                                    {['Everything', 'Only Sub Commodities', 'Hidden'].map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs="6">
                            <LocalizedTextInput
                                label="Unit Of Measurement"
                                value={_.get(formik, 'values.units')}
                                onChange={value => formik.setFieldValue('units', value)}
                                style={{ marginTop: theme.spacing.unit }}
                                touched={_.get(formik, 'touched.units')}
                                error={_.get(formik, 'errors.units')}
                                infoText="ex. bag, item, phone"
                            />
                        </Grid> 
                        <Grid item xs="12">
                            <LocalizedTextInput
                                label="Customer Input Helper Text"
                                value={_.get(formik, 'values.payloadHelperText')}
                                onChange={value => formik.setFieldValue('payloadHelperText', value)}
                                style={{ marginTop: theme.spacing.unit }}
                                touched={_.get(formik, 'touched.payloadHelperText')}
                                error={_.get(formik, 'errors.payloadHelperText')}
                                infoText="ex. Number of garbage bags"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={
                                _.isNil(formik.values.preAppliedFee)
                                    ? '12'
                                    : formik.values.preAppliedFeeApply === 'Per Item'
                                    ? '4'
                                    : '6'
                            }
                        >
                            {formik.values.preAppliedFeeApply === 'Once' &&
                                getSelect(
                                    theme,
                                    'preAppliedFee',
                                    'Additional Service Fee',
                                    [{ _id: 'none', description: 'None' }, ...allFees],
                                    formik,
                                    handleFeeChange,
                                    '_id',
                                    'description'
                                )}
                            {formik.values.preAppliedFeeApply === 'Per Item' &&
                                getSelect(
                                    theme,
                                    'preAppliedFeeSingle',
                                    'Fee (Single Unit)',
                                    [{ _id: 'none', description: 'None' }, ...allFees],
                                    formik,
                                    handleFeeChange,
                                    '_id',
                                    'description'
                                )}
                        </Grid>
                        {formik.values.preAppliedFeeApply === 'Per Item' && (
                            <Grid item xs={4}>
                                {formik.values.preAppliedFeeApply === 'Per Item' &&
                                    getSelect(
                                        theme,
                                        'preAppliedFeeMultiple',
                                        'Fee (Multiple Units)',
                                        [{ _id: 'none', description: 'None' }, ...allFees],
                                        formik,
                                        handleFeeChange,
                                        '_id',
                                        'description'
                                    )}
                            </Grid>
                        )}
                        {!_.isNil(formik.values.preAppliedFee) && (
                            <Grid item xs={formik.values.preAppliedFeeApply === 'Per Item' ? '4' : '6'}>
                                {getSelect(
                                    theme,
                                    'preAppliedFeeApply',
                                    'Apply',
                                    ['Once', 'Per Item'],
                                    formik,
                                    null,
                                    null,
                                    null,
                                    false,
                                    _.isNil(formik.values.preAppliedFee)
                                )}
                            </Grid>
                        )}
                        <Grid item xs="6">
                            {getSwitch(
                                theme,
                                'trackInventory',
                                'Track Inventory',
                                formik,
                                {},
                                { fontSize: '95%' },
                                undefined,
                                'Will include the commodity in inventory management.'
                            )}
                        </Grid>
                        <Grid item xs="6">
                            {getSwitch(
                                theme,
                                'enableCustomerOrders',
                                'Enable Customer Orders',
                                formik,
                                {},
                                { fontSize: '95%' },
                                undefined,
                                'Allows customers to request these items with an approval workflow. These items will be tracked in asset manager.'
                            )}
                        </Grid>
                        {formik.values.enableCustomerOrders && (
                            <>
                                <Grid item xs={isAUSRegion() ? 6 : 12}>
                                    {!_.isNil(mobilePickupSubServices) &&
                                        !_.isEmpty(mobilePickupSubServices) &&
                                        mobilePickupSubServices.length > 1 && (
                                            <>
                                                <FormControl fullWidth>
                                                    <InputLabel variant="outlined">Customer Type</InputLabel>
                                                    <Select
                                                        multiple
                                                        fullWidth
                                                        input={
                                                            <OutlinedInput
                                                                labelWidth={110}
                                                                data-cy={`location-type-input`}
                                                            />
                                                        }
                                                        onChange={e =>
                                                            formik.setFieldValue('locationType', e.target.value)
                                                        }
                                                        value={formik.values.locationType}
                                                        renderValue={values => (
                                                            <div style={{ display: 'flex', overflow: 'hidden' }}>
                                                                {values.map((v, index) => {
                                                                    const service = _.find(
                                                                        mobilePickupSubServices,
                                                                        s => s.pickupType === v
                                                                    );
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                verticalAlign: 'center',
                                                                                marginRight: theme.spacing.unit
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                style={{
                                                                                    color: 'inherit',
                                                                                    marginRight: theme.spacing.unit
                                                                                }}
                                                                            >
                                                                                {_.get(service, 'icon')}
                                                                            </Icon>
                                                                            <Typography variant="body1">
                                                                                {service.text.en.title}
                                                                                {index < values.length - 1 && ', '}
                                                                            </Typography>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                        data-cy="commodity-request-type-select"
                                                    >
                                                        {_(mobilePickupSubServices)
                                                            .map((service, i) => {
                                                                const title = _.get(service, `text.en.title`, '');
                                                                return (
                                                                    <MenuItem
                                                                        value={_.get(service, 'pickupType')}
                                                                        key={_.get(service, 'pickupType')}
                                                                    >
                                                                        <Icon
                                                                            style={{
                                                                                color: 'inherit',
                                                                                marginRight: theme.spacing.unit
                                                                            }}
                                                                        >
                                                                            {_.get(service, 'icon')}
                                                                        </Icon>
                                                                        {title}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                            .value()}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                </Grid>
                                {isAUSRegion() && (
                                    <Grid item xs={6}>
                                        {getSelect(theme, 'state', 'State', AUS_STATES, formik, null, null, null, true)}
                                    </Grid>
                                )}
                                <Grid item xs={6}>
                                    {getSelect(
                                        theme,
                                        'subscriptionFee',
                                        'Subscription Fee',
                                        [{ _id: 'none', description: 'None' }, ...allFees],
                                        formik,
                                        undefined,
                                        '_id',
                                        'description'
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                        <InputLabel variant="outlined">Interval</InputLabel>
                                        <Select
                                            fullWidth
                                            input={<OutlinedInput labelWidth={50} data-cy={`visibility-input`} />}
                                            onChange={e =>
                                                formik.setFieldValue('subscriptionFeeRecurrence', e.target.value)
                                            }
                                            value={formik.values.subscriptionFeeRecurrence}
                                        >
                                            <MenuItem value={0} data-cy="select-interval-0">
                                                One Time Fee
                                            </MenuItem>
                                            {[7, 14, 21, 28].map((interval, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={interval}
                                                        data-cy={`select-interval-${interval}`}
                                                    >
                                                        Every {interval} days
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )} */}
                        <Grid item xs="6">
                            {getTextInput(
                                theme,
                                'sizeRatio',
                                'Bag Size Percent',
                                formik,
                                'number',
                                {},
                                {},
                                '0.1 means 1 unit/item is 10% the size of a large garbage bag'
                            )}
                        </Grid>
                        {/* <Grid item xs="4">
                            {getTextInput(
                                theme,
                                'projectionMultiplier',
                                'Equivalent Containers',
                                formik,
                                'number',
                                {},
                                {},
                                '20 means 1 unit/item is worth 20 beverage containers'
                            )}
                        </Grid> */}
                        <Grid item xs="6">
                            {getTextInput(
                                theme,
                                'defaultPerUnitAmount',
                                'Default Amount',
                                formik,
                                'number',
                                {},
                                {},
                                'Counts to use for unprocessed bags when calculating synthetic containers'
                            )}
                        </Grid>
                        <Grid item xs={12} sm={_.get(formik.values, 'completionStrategy', '') === 'Auto' ? 6 : 12}>
                            <TextField
                                {...formik.getFieldProps('completionStrategy')}
                                select
                                error={
                                    _.get(formik.touched, 'completionStrategy', false) &&
                                    _.get(formik.errors, 'completionStrategy', false)
                                        ? true
                                        : null
                                }
                                label="Completion Strategy"
                                variant="outlined"
                                helperText={
                                    _.get(formik.touched, 'completionStrategy', false) &&
                                    _.get(formik.errors, 'completionStrategy', false) &&
                                    _.get(formik.errors, 'completionStrategy', false)
                                }
                                fullWidth
                            >
                                {COMMODITY_COMPLETION_STRATEGIES.map(completionStrategy => (
                                    <MenuItem key={completionStrategy} value={completionStrategy}>
                                        {completionStrategy}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {_.get(formik.values, 'completionStrategy', '') === 'Auto' &&
                            (!editing ? (
                                <Grid xs={12} sm={6}>
                                    {getTextInput(
                                        theme,
                                        'defaultSku',
                                        'Default Sku (ex. 20010)',
                                        formik,
                                        'number',
                                        {},
                                        {},
                                        'A new rate will be generated with this sku',
                                        false,
                                        undefined,
                                        { marginTop: theme.spacing.unit / 2 }
                                    )}
                                </Grid>
                            ) : (
                                <Grid xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'defaultSku', false) &&
                                            _.get(formik.errors, 'defaultSku', false)
                                                ? true
                                                : null
                                        }
                                        style={{ marginTop: theme.spacing.unit / 2 }}
                                    >
                                        <InputLabel variant="outlined">Default Sku (ex. 20010)</InputLabel>

                                        <Select
                                            {...formik.getFieldProps('defaultSku')}
                                            fullWidth
                                            input={<OutlinedInput labelWidth={150} />}
                                        >
                                            {!_.isNil(firstRate) &&
                                                _.get(firstRate, 'rates', []).map(rate => {
                                                    if (rate.skuType !== _.get(commodity, 'skuType', '')) {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <MenuItem
                                                                value={_.get(rate, 'sku')}
                                                                key={_.get(rate, 'sku')}
                                                            >
                                                                {/*<ListItemText
                        primary={`${rate.description} ${rate.label && `(${rate.label})`}`}
                        secondary={rate.sku}
                    />*/}
                                                                <Typography inline>
                                                                    {rate.description} {rate.label && `(${rate.label})`}{' '}
                                                                    -
                                                                </Typography>{' '}
                                                                <Typography color="textSecondary" inline>
                                                                    {rate.sku}
                                                                </Typography>
                                                            </MenuItem>
                                                        );
                                                    }
                                                })}
                                        </Select>

                                        {_.get(formik.touched, 'defaultSku', false) &&
                                        _.get(formik.errors, 'defaultSku', false) ? (
                                            <FormHelperText>{_.get(formik.errors, 'defaultSku', '')}</FormHelperText>
                                        ) : (
                                            <FormHelperText>
                                                Sku that will be used to generate counts for Auto, Split Units, and
                                                Accept Units completion methods
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    {loadingRates && (
                                        <CircularProgress
                                            style={{
                                                marginTop: theme.spacing.unit * 2,
                                                marginLeft: theme.spacing.unit
                                            }}
                                        />
                                    )}
                                </Grid>
                            ))}
                        <Grid item xs="12">
                            <div style={{}}>
                                {getSwitch(
                                    theme,
                                    'imageRequired',
                                    'Driver image required',
                                    formik,
                                    {},
                                    { fontSize: '95%' },
                                    undefined,
                                    'Forces drivers to take a photo of this commodity on pickup.'
                                )}
                                {/* {getSwitch(
                                    theme,
                                    'showOnMap',
                                    'Show icon on map',
                                    formik,
                                    {},
                                    { fontSize: '95%' },
                                    undefined,
                                    'Will display the commodity icon above pin on the routing admin view.'
                                )} */}
                                {getSwitch(
                                    theme,
                                    'showAsDonatedInHistory',
                                    'Always display as donated',
                                    formik,
                                    {},
                                    { fontSize: '95%' },
                                    undefined,
                                    'Will not show the customer to total amount on receipt / details. Will just show as "donated"'
                                )}
                                {!_.get(formik, 'values.disabled') &&
                                    getSwitch(
                                        theme,
                                        'unserviced',
                                        'Available out of zone',
                                        formik,
                                        {},
                                        { fontSize: '95%' },
                                        undefined,
                                        'Allow customers to select this even when out of service range.'
                                    )}
                                {getSwitch(
                                    theme,
                                    'includeContainersInStats',
                                    'Include with beverage',
                                    formik,
                                    {},
                                    { fontSize: '95%' },
                                    undefined,
                                    'Include these numbers with beverage containers system wide (eg: container totals) & automatically carry over payload when creating recurring pickups'
                                )}
                                {!_.get(formik, 'values.hide') &&
                                    getSwitch(
                                        theme,
                                        'hideFromCustomer',
                                        'Driver View Only',
                                        formik,
                                        {},
                                        { fontSize: '95%' },
                                        undefined,
                                        'Only allow the driver to see these commodities when completing a pickup.'
                                    )}
                            </div>
                        </Grid>
                    </Grid>
                )}
                {selectedTab === 'Commodities' && (
                    <div>
                        {!errorOnCommoditiesTab(formik.errors, {}, false) &&
                            getSwitch(
                                theme,
                                'hidePrimary',
                                'Disable Primary Commodity',
                                formik,
                                {},
                                { fontSize: '95%' }
                            )}
                        <Collapse in={!_.get(formik.values, 'hidePrimary', false)}>
                            <div style={{ marginTop: theme.spacing.unit * 2 }}>
                                <CustomWrapper title={'Primary Commodity'} innerDivStyle={{ fontSize: '12px' }}>
                                    <div style={{ marginTop: theme.spacing.unit }}>
                                        <SingleCommodityList
                                            allFees={allFees}
                                            allIcons={allIcons}
                                            allIconNames={allIconNames}
                                            commodity={formik.values}
                                            commodityFormik={formik}
                                            mobilePickupSubServices={mobilePickupSubServices}
                                            defaultFormToOpen={errorOnCommoditiesTab(formik.errors, formik.touched)}
                                        />
                                    </div>
                                </CustomWrapper>
                            </div>
                        </Collapse>
                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                            <CustomWrapper title={'Sub-Commodities'} innerDivStyle={{ fontSize: '12px' }}>
                                <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                                    <SubCommoditiesList
                                        allFees={allFees}
                                        allIcons={allIcons}
                                        allIconNames={allIconNames}
                                        commodity={formik.values}
                                        commodityFormik={formik}
                                        subCommodities={formik.values.subCommodities}
                                        onChange={values => formik.setFieldValue('subCommodities', values)}
                                        mobilePickupSubServices={mobilePickupSubServices}
                                    />
                                </div>
                            </CustomWrapper>
                        </div>
                        {!_.isEmpty(formik.values.subCommodities) && (
                            <LocalizedTextInput
                                label="Customer Sub-Commodity Disclaimer"
                                value={_.get(formik, 'values.subCommodityCustomerDisclaimer')}
                                onChange={value => formik.setFieldValue('subCommodityCustomerDisclaimer', value)}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                            />
                        )}

                        {!_.isEmpty(formik.values.subCommodities) && (
                            <LocalizedTextInput
                                label="Driver Sub-Commodity Disclaimer"
                                value={_.get(formik, 'values.subCommodityDriverDisclaimer')}
                                onChange={value => formik.setFieldValue('subCommodityDriverDisclaimer', value)}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                            />
                        )}
                    </div>
                )}
                {selectedTab === 'Examples' && (
                    <CustomWrapper title={'Eligible Examples'} innerDivStyle={{ fontSize: '12px' }}>
                        <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                            <EligibleExamplesList
                                eligibleExamples={formik.values.eligibleExamples}
                                onChange={values => formik.setFieldValue('eligibleExamples', values)}
                            />
                        </div>
                    </CustomWrapper>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="commodity-form-submit">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertToImportName(iconName) {
    if (!iconName.startsWith('mdi')) {
        return _.camelCase(`mdi-${iconName}`);
    } else {
        return _.camelCase(iconName);
    }
}

function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

function errorOnCommoditiesTab(errors, touched, checkTouched = true) {
    return (
        checkTouched &&
        !_.isEmpty(
            _.pick(touched, [
                'payloadInputName',
                'payloadHelperText',
                'units',
                'projectionMultiplier',
                'color',
                'iconName',
                'fee',
                'feeSingle',
                'feeMultiple',
                'feeApply',
                'subscriptionFee'
            ])
        ) &&
        !_.isEmpty(
            _.pick(errors, [
                'payloadInputName',
                'payloadHelperText',
                'units',
                'projectionMultiplier',
                'color',
                'iconName',
                'fee',
                'feeSingle',
                'feeMultiple',
                'feeApply',
                'subscriptionFee'
            ])
        )
    );
}

export default withMobileDialog()(withTheme()(CommodityForm));
