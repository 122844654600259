import React, { useMemo } from 'react';

import _ from 'lodash';

import { uppercaseFirstLetter } from 'utils/misc';

import { Icon as MDIcon } from '@mdi/react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { colors } from '@material-ui/core';

import { mdiCheckboxBlankCircle } from '@mdi/js';

function BulkSkuTypeSelectionDropdownMulti({
    commodityColors,
    fullWidth,
    helperText,
    typeFilter,
    skus,
    handleChange,
    labelCustomWidth,
    showAll = true,
    style = { marginRight: 8 }
}) {
    const types = useMemo(
        () =>
            _.keys(skus).map(sku => {
                return (
                    <MenuItem value={sku} key={sku} data-cy={`bulk-type-select-dropdown-${sku}`}>
                        <MDIcon
                            path={mdiCheckboxBlankCircle}
                            color={_.get(commodityColors, sku, colors.grey[500])}
                            size={0.4}
                        />
                        <span style={{ marginLeft: 2 }}>{uppercaseFirstLetter(sku)}</span>
                    </MenuItem>
                );
            }),
        [skus, commodityColors]
    );
    return (
        <FormControl fullWidth={fullWidth} style={style}>
            <InputLabel variant="outlined" htmlFor="commodity-filter">
                {helperText}
            </InputLabel>
            <Select
                multiple
                data-cy="bulk-sku-type-selection-dropdown"
                value={typeFilter}
                onChange={handleChange}
                inputProps={{
                    name: 'commodity-filter',
                    id: 'commodity-filter'
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name="age" id="outlined-age-simple" />}
                style={{
                    minWidth: 135
                }}
                // renderValue={val => {
                //     if (!val) {
                //         return 'None';
                //     }
                //     return _.get(
                //         allOTCGroups.find(g => g._id === val),
                //         'name',
                //         ''
                //     );
                // }}
                renderValue={val => `${val.length} ${val.length !== 1 ? 'Commodities' : 'Commodity'}`}
            >
                {types}
            </Select>
        </FormControl>
    );
}

export default BulkSkuTypeSelectionDropdownMulti;
