import React, { useState, useEffect } from 'react';
import { TIMEZONES_BY_ENV } from 'constants.js';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Select, MenuItem, InputLabel, FormControl, Button } from '@material-ui/core';

import CRONLogList from 'components/Lists/CRONLog';
import useSystemSocket from 'utils/hooks/useSystemSocket';
import SystemTasksTable from 'components/SystemTasksTable/SystemTasksTable';

function Debug({ theme }) {
    const {
        infoDialogOpen,
        infoDialogTask,
        handleViewMoreInfo,
        handleCloseMoreInfo,
        handleTriggerSystemTask,
        handleToggleSystemTask,
        systemTasks,
        systemTaskLogs
    } = useSystemSocket();

    const [selectedTimeZone, setSelectedTimeZone] = useState(TIMEZONES_BY_ENV[process.env.REACT_APP_REGION_EXT]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [taskFilterInitialzed, setTaskFilterInitialized] = useState(false); // prevent task filters from being set more than once

    useEffect(() => {
        if (!_.isEmpty(_.flatMap(systemTasks)) && !taskFilterInitialzed) {
            setSelectedTasks(_.flatMap(systemTasks).map(task => task.name));
            setTaskFilterInitialized(true);
        }
    }, [systemTasks]);

    return (
        <Grid container spacing={16} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={12} sm={4} style={{ height: '100%' }}>
                <Paper style={{ padding: theme.spacing.unit * 2 }}>
                    <Typography variant="h5" style={{ marginBottom: theme.spacing.unit }}>
                        System Task Logs
                    </Typography>

                    <div style={{ display: 'flex', marginBottom: theme.spacing.unit }}>
                        <TimeZoneFilter selectedTimeZone={selectedTimeZone} setSelectedTimeZone={setSelectedTimeZone} />
                        <TaskFilter
                            selectedTasks={selectedTasks}
                            setSelectedTasks={setSelectedTasks}
                            systemTasks={systemTasks}
                        />
                    </div>

                    <div style={{ height: 400 }}>
                        <CRONLogList
                            logs={systemTaskLogs}
                            selectedTimeZone={selectedTimeZone}
                            selectedTasks={selectedTasks}
                        />{' '}
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={8}>
                <SystemTasksTable
                    infoDialogOpen={infoDialogOpen}
                    infoDialogTask={infoDialogTask}
                    systemTasks={systemTasks}
                    onToggleSystemTask={handleToggleSystemTask}
                    onClose={handleCloseMoreInfo}
                    onViewMoreInfo={handleViewMoreInfo}
                    onTriggerSystemTask={handleTriggerSystemTask}
                />
            </Grid>
        </Grid>
    );
}

const TimeZoneFilter = ({ selectedTimeZone, setSelectedTimeZone }) => {
    const handleTimeZoneChange = timezone => {
        setSelectedTimeZone(timezone);
    };

    return (
        <FormControl
            style={{
                width: '50%',
                marginRight: '4px'
            }}
        >
            <InputLabel id="cron-log-list-select-timezone">Timezone</InputLabel>
            <Select
                id={'cron-log-list-select-timezone'}
                value={selectedTimeZone}
                multiple
                onChange={e => handleTimeZoneChange(e.target.value)}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            handleTimeZoneChange(TIMEZONES_BY_ENV[process.env.REACT_APP_REGION_EXT]);
                        }}
                    >
                        All
                    </Button>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            handleTimeZoneChange([]);
                        }}
                    >
                        None
                    </Button>
                </div>
                {TIMEZONES_BY_ENV[process.env.REACT_APP_REGION_EXT].map((timezone, index) => (
                    <MenuItem key={index} value={timezone}>
                        {timezone}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const TaskFilter = ({ selectedTasks, setSelectedTasks, systemTasks }) => {
    const handleTaskChange = task => {
        setSelectedTasks(task);
    };

    return (
        <FormControl
            style={{
                width: '50%',
                marginLeft: '4px'
            }}
        >
            <InputLabel id="cron-log-list-select-task">Task</InputLabel>
            <Select
                id={'cron-log-list-select-task'}
                value={selectedTasks}
                multiple
                onChange={e => handleTaskChange(e.target.value)}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            handleTaskChange(_.flatMap(systemTasks).map(task => task.name));
                        }}
                    >
                        All
                    </Button>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            handleTaskChange([]);
                        }}
                    >
                        None
                    </Button>
                </div>
                {_.flatMap(systemTasks).map((task, index) => (
                    <MenuItem key={index} value={task.name}>
                        {task.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default withTheme()(Debug);
