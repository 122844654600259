import { ROLES } from 'constants.js';
import _ from 'lodash';

function useFetchProbe({ dispatch, handleSnackbar, handleLang }) {
    const fetchProbe = () => {
        const Break = {};

        const docURL = document.URL;
        const resetURL = process.env.REACT_APP_ORIGIN_URL + '/reset/';
        if (docURL.startsWith(resetURL)) {
            // prevent auto login on password reset page
            dispatch({ type: 'SET_AUTH_FALSE' });
            return;
        }

        fetch(process.env.REACT_APP_API_URL + '/probe', { credentials: 'include' })
            .then(res => {
                console.log('GET /probe', res);
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    throw new Break(); // TODO: test how exactly this works when server is OFF
                } else if (res.status === 502) {
                    throw new Break();
                } else {
                    throw new Error('Unrecoverable error.');
                }
            })
            .then(data => {
                console.log('GET /probe (data)', data);
                let homeURL;
                switch (data.accountType) {
                    case 'Customer':
                        homeURL = '/customers/' + data._id;
                        if (!_.isNil(data.lastPath)) {
                            homeURL += data.lastPath;
                        } else if (!_.isEmpty(data.charities)) {
                            homeURL += '/' + _.first(data.charities) + '/charity';
                        }
                        break;
                    case 'Collector Employee':
                        homeURL = `/operators/${data._id}/driver`;
                        break;
                    case 'Collector Administrator':
                        homeURL = `/operators/${data._id}/collector`;
                        break;
                    case 'System Administrator':
                        homeURL = '/operators/' + data._id;
                        break;
                    default:
                        if (ROLES.includes(data.accountType)) {
                            homeURL = '/operators/' + data._id;
                        } else {
                            throw new Error('Unexpected account type.');
                        }
                }
                if (data.lang) {
                    handleLang(data.lang);
                }
                dispatch({
                    type: 'SET_AUTH_TRUE',
                    accountType: data.accountType,
                    multipleAccountAccessList: data.multipleAccountAccessList,
                    name: !_.isNil(data.name) ? data.name.first + ' ' + data.name.last : undefined, // NB: don't use getCustomerName here
                    _id: data._id,
                    collector_id: _.get(data, 'collector._id', undefined),
                    home: homeURL,
                    accountPermissions: data.accountPermissions
                });
            })
            .catch(Break => {
                dispatch({ type: 'SET_AUTH_FALSE' }); // QUESTION: check if this is correct logic
            })
            .catch(err => {
                handleSnackbar('Network failure.', 'error');
                console.error('NETWORK FAILURE: GET /probe', err);
            });
    };

    return { fetchProbe };
}

export default useFetchProbe;
