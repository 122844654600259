import React, { useContext, useEffect } from 'react';
import _ from 'lodash';

import { _time, _user } from 'std';
import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import useStopFilters from './hooks/useStopFilters';

import {
    FormControl,
    MenuItem,
    Select,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Icon,
    Grid,
    IconButton,
    Button,
    TextField,
    OutlinedInput,
    InputLabel,
    Menu,
    Dialog,
    Checkbox,
    ListItemText,
    Tooltip
} from '@material-ui/core';
import MDIcon from '@mdi/react';
import { mdiAccountOff } from '@mdi/js';

import { useState } from 'react';
import moment from 'moment-timezone';
import { formatAsCurrency } from 'utils/misc';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import useQueryString from 'utils/hooks/useQueryString';

const headers = [
    { name: 'Actions', key: null },
    { name: 'Collector^', key: 'collectorName' },
    { name: 'Driver^', key: 'driverName.first' },
    { name: 'Completion Date^', key: 'completionDate' },
    { name: 'Frequency^', key: 'frequency' },
    { name: 'Address', key: 'location.description' },
    { name: 'Est. Stop Duration (min)^', key: 'stopDuration' },
    { name: 'Act. Stop Duration (min)^', key: 'actualStopDuration' },
    { name: 'Stop Pay Amount^', key: 'stopPayAmount' },
    { name: 'Comment^', key: 'comment' },
    { name: 'Name^', key: 'stopName' },
    { name: 'Date^', key: 'date' }
];

function Stops(props) {
    const { theme } = props;
    const { lang } = useContext(LocalizationContext);
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [OTCGroupIdQuery, setOTCGroupIdQuery] = useQueryString('OTCGroupFilter', '');
    const [OTCLocationIdQuery, setOTCLocationIdQuery] = useQueryString('OTCLocationFilter', '');

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        allOTCGroups,
        OTCGroupSelected,
        setOTCGroupSelected,
        allOTCLocations,
        OTCLocationSelected,
        setOTCLocationSelected,
        handleAllFilterCollectors
    } = useStopFilters({ OTCGroupIdQuery, OTCLocationIdQuery });

    const {
        loading,
        getData,
        page,
        search,
        rowsPerPage,
        // response,
        filteredData,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/pickups/completedOOS?collectors=${collectorsSelected}&receivers=${driversSelected}&OTCGroup=${OTCGroupSelected}&OTCLocation=${OTCLocationSelected}`,
        key: 'completedStops',
        headers: headers.map(h => h.name)
    });

    const handleOpenImage = images => {
        setImages(images);
        setOpen(true);
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const renderRow = (stop, filteredHeaders) => {
        const { _id } = stop;
        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ minWidth: 150 }}>
                        <Tooltip title="View Image" disableFocusListener disableTouchListener>
                            <span>
                                <IconButton
                                    disabled={_.isEmpty(_.get(stop, 'images', []))}
                                    onClick={() => handleOpenImage(_.get(stop, 'images', []))}
                                >
                                    <Icon>image</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Collector^') && <TableCell>{_.get(stop, 'collectorName', '')}</TableCell>}
                {filteredHeaders.includes('Driver^') && (
                    <TableCell>
                        {_.get(stop, 'driverName.first', '')} {_.get(stop, 'driverName.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Completion Date^') && (
                    <TableCell>{moment(_.get(stop, 'completionDate', '')).format('YYYY-MM-DD')}</TableCell>
                )}
                {filteredHeaders.includes('Frequency^') && <TableCell>{_.get(stop, 'frequency', 0)}</TableCell>}
                {filteredHeaders.includes('Address') && (
                    <TableCell>{_.get(stop, 'location.description', '')}</TableCell>
                )}
                {filteredHeaders.includes('Est. Stop Duration (min)^') && (
                    <TableCell>{_.get(stop, 'stopDuration', 0) / 60}</TableCell>
                )}
                {filteredHeaders.includes('Act. Stop Duration (min)^') && (
                    <TableCell>{_.round(_.get(stop, 'actualStopDuration', 0) / 60, 2)}</TableCell>
                )}
                {filteredHeaders.includes('Stop Pay Amount^') && (
                    <TableCell>{formatAsCurrency(_.get(stop, 'stopPayAmount', 0))}</TableCell>
                )}
                {filteredHeaders.includes('Comment^') && <TableCell>{_.get(stop, 'comment', '')}</TableCell>}
                {filteredHeaders.includes('Name^') && <TableCell>{_.get(stop, 'stopName', '')}</TableCell>}
                {filteredHeaders.includes('Date^') && (
                    <TableCell>{moment(_.get(stop, 'date', '')).format('YYYY-MM-DD')}</TableCell>
                )}
            </TableRow>
        );
    };

    const handleSubmit = e => {
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                    inputStyle={{ maxWidth: 110 }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl>
                                                <Select
                                                    value={dateWindow}
                                                    disabled={loading}
                                                    onChange={handleWindowSelect}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <MenuItem value="today">Daily</MenuItem>
                                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                    <MenuItem value="allTime">All Time</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setCollectorsSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={collectorsSelected}
                                                    renderValue={s => `${s.length} Collectors`}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allCollectors.map(({ _id, name }) => (
                                                        <MenuItem disabled={loading} key={_id} value={_id}>
                                                            <Checkbox checked={collectorsSelected.includes(_id)} />
                                                            <ListItemText>{name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    disabled={loading}
                                                    multiple
                                                    renderValue={val =>
                                                        `${val.length} Driver${val.length !== 1 ? 's' : ''}`
                                                    }
                                                    value={driversSelected}
                                                    onChange={e => setDriversSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected(
                                                                    _(allDrivers)
                                                                        .filter(driver =>
                                                                            collectorsSelected.includes(
                                                                                driver.collector._id
                                                                            )
                                                                        )
                                                                        .map(driver => driver._id)
                                                                        .value()
                                                                );
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected([]);
                                                            }}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {_.orderBy(
                                                        allDrivers,
                                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                                        ['desc', 'asc']
                                                    ).map(driver => {
                                                        const { _id, collector, banned } = driver;
                                                        if (!collectorsSelected.includes(collector._id)) return null;
                                                        return (
                                                            <MenuItem
                                                                key={_id}
                                                                value={_id}
                                                                style={{
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {_user.getNameFull(driver).toLowerCase()}
                                                                {banned && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: theme.spacing.unit,
                                                                            display: 'grid',
                                                                            placeItems: 'center'
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={mdiAccountOff}
                                                                            size={0.85}
                                                                            color={theme.palette.text.secondary}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    disabled={loading}
                                                    displayEmpty
                                                    renderValue={val => {
                                                        if (!val) {
                                                            return 'No Group';
                                                        }
                                                        return _.get(
                                                            allOTCGroups.find(g => g._id === val),
                                                            'name',
                                                            ''
                                                        );
                                                    }}
                                                    value={OTCGroupSelected}
                                                    onChange={e => {
                                                        setOTCGroupSelected(e.target.value);
                                                        const selectedLocationGroupId = _.get(
                                                            allOTCLocations.find(l => l._id === OTCLocationSelected),
                                                            'group._id',
                                                            ''
                                                        );
                                                        if (selectedLocationGroupId !== e.target.value) {
                                                            setOTCLocationSelected('');
                                                        }
                                                    }}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setOTCGroupSelected('');
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                    {allOTCGroups.map(({ _id, name }) => (
                                                        <MenuItem disabled={loading} key={_id} value={_id}>
                                                            <ListItemText>{name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    disabled={loading}
                                                    displayEmpty
                                                    renderValue={val => {
                                                        if (!val) {
                                                            return 'No Location';
                                                        }
                                                        return _.get(
                                                            allOTCLocations.find(l => l._id === val),
                                                            'name',
                                                            ''
                                                        );
                                                    }}
                                                    value={OTCLocationSelected}
                                                    onChange={e => setOTCLocationSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setOTCLocationSelected('');
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                    {allOTCLocations
                                                        .filter(l => {
                                                            if (
                                                                _.isNil(OTCGroupSelected) ||
                                                                _.isEmpty(OTCGroupSelected)
                                                            )
                                                                return true;
                                                            return OTCGroupSelected === l.group._id;
                                                        })
                                                        .map(({ _id, name }) => (
                                                            <MenuItem disabled={loading} key={_id} value={_id}>
                                                                <ListItemText>{name}</ListItemText>
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headers.map(({ name, locKey }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Out of System Stops"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(stop => {
                        return renderRow(stop, filteredHeaders);
                    })}
            </DashBoardTable>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <ImageViewer
                    displayImages={images}
                    disableDelete={true}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
        </>
    );
}

export default withTheme()(Stops);
