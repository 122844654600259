import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import HttpContext from 'utils/contexts/HttpContext';

function useStopFilters({ OTCGroupIdQuery, OTCLocationIdQuery }) {
    const http = useContext(HttpContext);

    const [allCollectors, setAllCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);

    const [allDrivers, setAllDrivers] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);

    const [allOTCGroups, setAllOTCGroups] = useState([]);
    const [OTCGroupSelected, setOTCGroupSelected] = useState(OTCGroupIdQuery || '');

    const [allOTCLocations, setAllOTCLocations] = useState([]);
    const [OTCLocationSelected, setOTCLocationSelected] = useState(OTCLocationIdQuery || '');

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(drivers.map(driver => driver._id));
            }
            const res2 = await http.getJSON('/collectors');
            if (res2.ok) {
                let collectors = _.get(res2, 'data.collectors', []);
                setAllCollectors(collectors);
                setCollectorsSelected(collectors.map(collector => collector._id));
            }
            const res3 = await http.getJSON('/getAllExternalGroups');
            if (res3.ok) {
                let allGroups = _.get(res3, 'data.collectionData', []);
                setAllOTCGroups(allGroups);
            }
            const res4 = await http.getJSON('/getAllExternalLocations');
            if (res4.ok) {
                let allLocations = _.get(res4, 'data.collectionData', []);
                setAllOTCLocations(allLocations);
            }
        })();
    }, []);

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(allCollectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    return {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        allOTCGroups,
        OTCGroupSelected,
        setOTCGroupSelected,
        allOTCLocations,
        OTCLocationSelected,
        setOTCLocationSelected,
        handleAllFilterCollectors
    };
}

export default useStopFilters;
