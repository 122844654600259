import { colors } from '@material-ui/core';

import {
    mdiTruckCheck,
    mdiRunFast,
    mdiBallotRecount,
    mdiFlagOutline,
    mdiFlag,
    mdiFlagCheckered,
    mdiCurrencyUsd,
    mdiCheckAll,
    mdiRun,
    mdiTagArrowDown,
    mdiQrcodeScan,
    mdiStore,
    mdiPrinter
} from '@mdi/js';

export let MSG_SOCIAL_CUSTOMER_DEFAULT = 'MSG_SOCIAL_CUSTOMER_DEFAULT';
export let MSG_SOCIAL_ORGANIZATION_DEFAULT = 'MSG_SOCIAL_ORGANIZATION_DEFAULT';
export let MSG_SOCIAL_CUSTOMER_RECYCLED = 'MSG_SOCIAL_CUSTOMER_RECYCLED';
export let MSG_SOCIAL_CUSTOMER_DONATED = 'MSG_SOCIAL_CUSTOMER_DONATED';

export let AUDIT_INFRACTION_USER_STATES = ['Under Review', 'Suspension'];
export let AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW = 'Under Review';
export let AUDIT_INFRACTION_USER_STATE_SUSPENSION = 'Suspension';

export const MAX_NUM_OF_RECYCLING_BAGS = 99;
export const MIN_NUM_OF_RECYCLING_BAGS = 0;

export const TIMEZONES = [
    'America/Edmonton',
    'America/Toronto',
    'America/Montreal',
    'America/Blanc-Sablon',
    'America/Vancouver',
    'America/Metlakatla',
    'America/Creston',
    'Australia/Brisbane',
    'Australia/Sydney',
    'Australia/Perth',
    'Australia/Melbourne'
];

// pickupTypes
export const RESIDENTIAL = 'Residential';
export const COMMERCIAL = 'Commercial';
export const CONDO = 'Condo';
export const BOTTLE_DRIVE = 'Bottle Drive';
export const EXPRESS = 'Express';
export const DROPANDGO = 'Drop&Go';
export const LABELS = 'Request Labels';
export const EXTERNAL_LINK = 'External Link';
export const WALK_IN = 'Walk-In';
export const DEPOT_SEARCH = 'Search';
export const INSTRUCTIONS = 'Instructions';
export const QR_SCAN = 'QR Scan';
export const ACCESS_DOOR = 'Access Door';

// bulkTypes
export const DROPNGO = 'inhouse';
export const ADJUSTMENT = 'adjustment';
export const PICKUP = 'pickup';
export const WALKIN = 'walk-in';

// export const PAYLOAD_ISSUE_NON_CONFORMANT = 'Non-conformant';
// export const PAYLOAD_ISSUE_HAZARDOUS = 'Hazardous';
// export const PAYLOAD_ISSUE_GARBAGE = 'Garbage';
// export const PAYLOAD_ISSUE_OTHER = 'Other';

export const COMMODITY_COLORS = {
    beverage: colors.blue[400],
    clothing: colors.green[400],
    electronics: colors.amber[500],
    credit: colors.orange[500],
    penalty: colors.red[500],
    promotion: colors.deepOrange[500]
};

export const ZONES_COLORS = [
    colors.blue, // Monday
    colors.teal, // Tuesday
    colors.lime, // Wednesday
    colors.orange, // Thursday
    colors.pink, // Friday,
    colors.deepPurple, // Saturday
    colors.brown // Sunday
];

export const BULK_STATUS_ICONS = {
    uncounted: mdiFlagOutline,
    counted: mdiFlag,
    submitted: mdiFlagCheckered,
    redeemed: mdiCurrencyUsd,
    invoiced: mdiCheckAll
};

export const BULK_STATUS_COLORS = {
    overdue: colors.red[500],
    uncounted: colors.yellow[200],
    counted: colors.indigo[200],
    submitted: colors.green[400],
    redeemed: colors.grey[200],
    invoiced: colors.cyan[200]
};

export const LABEL_ICONS = {
    printed: mdiPrinter,
    scanned: mdiTagArrowDown
};

export const BULK_TYPE_ICONS = {
    pickup: mdiTruckCheck,
    adjustment: mdiBallotRecount,
    inhouse: mdiTagArrowDown,
    'walk-in': process.env.REACT_APP_REGION_EXT === 'CON' ? mdiQrcodeScan : mdiStore
};

export const BULK_TYPE_COLORS = {
    pickup: colors.blue[400],
    inhouse: colors.green[400],
    adjustment: colors.amber[500]
};

export const LOGGED_COLLECTIONS = [
    'batches',
    'bulks',
    'charities',
    'collectors',
    'configs',
    'configuration',
    'defaultcharitytasks',
    'entries',
    'locations',
    'pickups',
    'promos',
    'rates',
    'ratingPlatforms',
    'redemptions',
    'referrals',
    'regions',
    'trips',
    'users',
    'zones'
];

export const maxFileSize = 5 * 1024 * 1024; //5MiB
export const maxFileSizeMB = 5;
export const maxCharityImageWidthAndHeight = 400;
export const maxImageWidthAndHeight = 1200;

export const DEFAULT_DRIVER_WAGE = 1800;
export const DEFAULT_DRIVER_COMMISSION = 2;
export const DEFAULT_TRUCK_KPL = 3.75;
export const DEFAULT_GAS_PRICE_STD = 60;
export const DEFAULT_GAS_PRICE_AUS = 148;

export const CONTACT_EMAIL_OPTIONS = ['Redemptions', 'Service', 'Orders'];

export const INVALID_REDEMPTION_EMAILS = ['@privaterelay.appleid.com'];

export const TAX_TYPES = ['none', 'excluded', 'included'];

export const USER_PERMISSIONS = ['Driver', 'Clerk'];
export const DRIVER_PAYMENT_MODELS = ['Wage', 'Commission'];

export const TIME_ZONES_STD = ['America/Edmonton', 'America/Toronto'];
export const TIME_ZONES_EXP = ['America/Vancouver', 'America/Metlakatla', 'America/Creston'];
export const TIME_ZONES_MXD = ['America/Montreal', 'America/Toronto'];
export const TIME_ZONES_CON = ['America/Montreal', 'America/Toronto'];
export const TIME_ZONES_AUS = ['Australia/Brisbane', 'Australia/Sydney', 'Australia/Perth', 'Australia/Melbourne'];
export const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const TIMEZONES_BY_ENV = {
    STD: TIME_ZONES_STD,
    EXP: TIME_ZONES_EXP,
    MXD: TIME_ZONES_MXD,
    CON: TIME_ZONES_CON,
    AUS: TIME_ZONES_AUS
};

export const CLUSTERING_ALGORITHMS = ['CAPACITY_FIRST', 'EVEN_SPLIT', 'MIN_DRIVERS_EVEN_SPLIT']; //TODO: add ROUTE_FIRST

export const COMMODITY_COMPLETION_STRATEGIES = ['Manual', 'Auto', 'Split Units', 'Accept Units'];

export const AVAILABLE_FREQUENCIES = {
    7: [7 * 1, 7 * 2, 7 * 4, 7 * 10, 7 * 12],
    14: [14 * 1, 14 * 2, 14 * 4, 14 * 6],
    21: [21 * 1, 21 * 2, 21 * 3, 21 * 4],
    28: [28 * 1, 28 * 2, 28 * 3, 28 * 4],
    35: [35 * 1, 35 * 2, 35 * 3],
    42: [42 * 1, 42 * 2, 42 * 3],
    49: [49 * 1, 49 * 2, 49 * 3],
    56: [56 * 1, 56 * 2, 56 * 3],
    63: [63 * 1, 63 * 2, 63 * 3],
    70: [70 * 1, 70 * 2, 70 * 3],
    77: [77 * 1, 77 * 2, 77 * 3],
    84: [84 * 1, 84 * 2, 84 * 3]
};

export const IGNORE_PAYLOAD_SIZE_SKUS = ['beverage'];
export const DEFAULT_GST_RATE_STD = 0.05;

export const TIPPING_MODEL_INDIVIDUAL = 'Individual';
export const TIPPING_MODEL_POOL = 'Pool';

export const AVAILABLE_LANGS = {
    STD: ['en'],
    AUS: ['en'],
    EXP: ['en'],
    MXD: ['fr', 'en'],
    CON: ['fr', 'en']
};

export const ACCOUNT_TYPE_SELECTIONS = {
    PERSONAL: 'PERSONAL',
    BUSINESS: 'BUSINESS',
    GROUP: 'GROUP'
};

export const COMMISSION_TYPES = ['Counts', 'Commodity Amount'];

export const INFRACTION_TYPES = {
    AUDIT_TYPE_BAGCOUNT: 'AUDIT_TYPE_BAGCOUNT',
    AUDIT_TYPE_DRIVERCOMPLAINT: 'AUDIT_TYPE_DRIVERCOMPLAINT',
    AUDIT_TYPE_COUNTERCOMPLAINT: 'AUDIT_TYPE_COUNTERCOMPLAINT',
    AUDIT_TYPE_AVGCONTAINERCOUNT: 'AUDIT_TYPE_AVGCONTAINERCOUNT',
    AUDIT_TYPE_AMOUNTDIFFERENCE: 'AUDIT_TYPE_AMOUNTDIFFERENCE',
    AUDIT_TYPE_REDEMPTIONAMOUNT: 'AUDIT_TYPE_REDEMPTIONAMOUNT',
    AUDIT_TYPE_REDEMPTIONEMAIL: 'AUDIT_TYPE_REDEMPTIONEMAIL',
    AUDIT_TYPE_SAMEIPADDRESS: 'AUDIT_TYPE_SAMEIPADDRESS',
    AUDIT_TYPE_HAZARDOUS: 'AUDIT_TYPE_HAZARDOUS',
    AUDIT_TYPE_GARBAGE: 'AUDIT_TYPE_GARBAGE'
};
export const PICKUP_TIME_OF_DAY_PREFERENCES = [
    { text: 'morning', value: 'Morning' },
    { text: 'afternoon', value: 'Afternoon' },
    { text: 'none', value: 'None' }
];

export const FEE_APPLICATION_OPTIONS = [
    'Apply to balance', //Normal (deduct from running balance / deposits)
    'Invoice' //Don't deduct from balance, create separate invoice item in history and keep out of balance.
];

export const INVOICE_FREQUENCY_OPTIONS = ['On Pickup', '15th of Month', '30th of Month'];

export const DESTINATION_ROUTING_OPTIONS = ['Zone', 'Driver'];

export const REPORT_TYPES = {
    CUSTOMER: 'customer',
    DRIVER: 'driver'
};

export const AUS_STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const AUS_STATES_FULL_NAME = [
    'Australian Capital Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia'
];

export const S3_FALLBACK_IMAGE = 'https://local-std-pickup-photos.s3-us-west-2.amazonaws.com/fallback_image.jpg';

export const SHARE_AND_FEEDBACK_TYPES = [
    { key: 'shareAndFeedback-shareScreen', value: 'Share Screen' },
    { key: 'shareAndFeedback-referralScreen', value: 'Referral Screen' },
    { key: 'shareAndFeedback-ratingScreen', value: 'Rating Screen' }
];

export const DROP_OFF_TYPES = [
    { value: 'App Only', icon: 'mobile_friendly', localizationName: 'appOnly' },
    { value: 'Kiosk', icon: 'store', localizationName: 'kiosk' },
    { value: 'Kiosk & Scanner', icon: 'add_business', localizationName: 'kioskAndScanner' },
    { value: 'Access Door', icon: 'fiber_pin', localizationName: 'accessDoor' },
    { value: 'Reverse Vending Machine', icon: 'qr_code_scanner', localizationName: 'reverseVendingMachine' }
];

export const NO_TECHNOLOGY = { value: 'No Technology', icon: 'label_off', localizationName: 'noTechnology' };

export const ROLES = [
    'Customer Service',
    'Customer Service Administrator',
    'Finance Administrator',
    'Marketing',
    'Manager',
    'IT Administrator'
    // 'New Role'
];

export const NONCONFORMANTISSUE = 'NONCONFORMANTISSUE';
export const LOWVOLUMEISSUE = 'LOWVOLUMEISSUE';
export const NON_CONFORMANT_COMPLAINT_CODE = '4A';
export const LOW_VOLUME_COMPLAINT_CODE = '4B';

export const REGISTRATION_TABS = {
    PERSONAL: 0,
    CHARITY: 1,
    DEPOT: 2, //deprecated
    BOTTLE_DRIVE: 3,
    BUSINESS: 4,
    GROUP: 5
};

export const EXPRESS_APP_BALANCE_SKUTYPE = 'Opening Balance';

export const DB_LOG_TYPES = {
    PICKUP: 'Pickup',
    ORDER: 'Order',
    USER: 'User',
    REDEMPTION: 'Redemption'
};

export const PAYLOAD_ISSUE_HAZARDOUS_CODE = '1';
export const PAYLOAD_ISSUE_GARBAGE_CODE = '2';
