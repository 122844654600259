import React, { useContext } from 'react';

import _ from 'lodash';

import { Icon, Paper, Typography, CircularProgress } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import { loc } from '../../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function StatsContainer(props) {
    const { theme, stats, trips, selectedTrip, loadingTrips, onCenterMapOnPickups } = props;
    const { lang } = useContext(LocalizationContext);

    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

    if (loadingTrips) {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: theme.spacing.unit * 2,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                }}
            >
                <Paper
                    square
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit * 1.5,
                        width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                        maxWidth: 480
                    }}
                >
                    <CircularProgress />
                </Paper>
            </div>
        );
    }

    return (
        <div
            style={{
                position: 'absolute',
                left: 0,
                top: theme.spacing.unit * 2,
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <Paper
                square
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing.unit * 1.5,
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    maxWidth: 480
                }}
            >
                {trips.map((trip, i) => {
                    let pickupsTotal = stats.pickupsTotal;
                    let pickupsCompleted = stats.pickupsCompleted;
                    let bagsTotal = stats.bagsTotal;
                    let bagsCompleted = stats.bagsCompleted;
                    let pickupsSkipped = stats.pickupsSkipped;
                    let activeTrip = true;

                    if (trip._id !== selectedTrip._id) {
                        pickupsTotal = _.get(trip, 'stats.pickupsTotal', 0);
                        pickupsCompleted = _.get(trip, 'stats.pickupsCompleted', 0);
                        bagsTotal = _.get(trip, 'stats.bagsTotal', 0);
                        bagsCompleted = _.get(trip, 'stats.bagsCompleted', 0);
                        pickupsSkipped = _.get(trip, 'stats.pickupsSkipped', 0);
                        activeTrip = false;
                    }

                    return (
                        <div style={{ display: 'grid', gridTemplateColumns: '4fr 7fr 7fr' }}>
                            {!isMobile && (
                                <Typography
                                    variant="body2"
                                    style={{ marginLeft: '15%', display: 'flex', alignItems: 'center' }}
                                >
                                    {_.capitalize(loc('trip', lang))} {i + 1}{' '}
                                    {activeTrip && <Icon style={{ marginLeft: '2px', fontSize: '14px' }}>check</Icon>} :
                                </Typography>
                            )}
                            {isMobile && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            borderRadius: '3px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: '10px',
                                            backgroundColor: activeTrip ? 'limegreen' : 'black',
                                            color: 'white',
                                            fontWeight: 900,
                                            padding: '2px 4px',
                                            width: 'fit-content',
                                            marginLeft: '10%'
                                        }}
                                    >
                                        {loc('trip', lang).toUpperCase()} {i + 1}
                                    </span>
                                    {activeTrip && (
                                        <span>
                                            <Icon style={{ marginLeft: '2px', fontSize: '14px', color: 'limegreen' }}>
                                                check
                                            </Icon>
                                        </span>
                                    )}
                                </div>
                            )}
                            <Typography variant="body2" style={{ marginLeft: '10%' }}>
                                {loc('pickups', lang)}: {pickupsTotal - pickupsCompleted} {loc('left', lang)}{' '}
                                {pickupsSkipped > 0 && (
                                    <>
                                        (
                                        <span
                                            style={{
                                                color: theme.palette.linkColor,
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}
                                            onClick={onCenterMapOnPickups}
                                        >
                                            {pickupsSkipped} {loc('skip', lang)}
                                        </span>
                                        )
                                    </>
                                )}
                            </Typography>{' '}
                            <Typography variant="body2" style={{ marginLeft: '10%' }}>
                                {_.capitalize(loc('bags', lang))}: {bagsTotal - bagsCompleted} {loc('left', lang)}
                            </Typography>
                        </div>
                    );
                })}
            </Paper>
        </div>
    );
}

export default withTheme()(StatsContainer);

function getNumberAsWord(num) {
    if (num === 1) return 'one';
    if (num === 2) return 'two';
    if (num === 3) return 'three';
    if (num === 4) return 'four';
    if (num === 5) return 'five';
    if (num === 6) return 'six';

    return 'six'; // supports up to six distinct trips for now
}
