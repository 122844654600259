import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { _user } from 'std';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import DebugContext from 'components/CustomDebugDialog/DebugContext';
import CurrentTime from 'components/Misc/CurrentTime';
import UserNoteDialog from 'components/Dialogs/Users/UserNoteDialog';

import { Paper, Typography, Divider, Button, IconButton, Icon, Collapse, Tooltip, colors } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

import {
    formatAsCurrency,
    formatAsPhoneNumber,
    getCustomerFirstName,
    getUserAccountStatusAndModifiers,
    getUserName,
    isEXPRegion
} from 'utils/misc';
import { useEffect } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function CustomerInfoWidget(props) {
    const { theme, elevated, customer, reloadCustomer, http, auth, redemptions } = props;

    const { lang } = useContext(LocalizationContext);
    const onDebug = useContext(DebugContext);
    const warnAction = useContext(ConfirmDialogContext);

    const loginType = _.get(customer, 'oAuth.provider', 'Manual');
    const relativeTimezone = _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const onSnackbar = useContext(SnackbarContext);
    const [userNoteChange, setUserNoteChange] = useState('');
    const [showUserNoteDialog, setShowUserNoteDialog] = useState(false);

    const [lastStatusUpdate, setLastStatusUpdate] = useState({});
    const [lastNameChange, setLastNameChange] = useState({});
    const [lastPhoneChange, setLastPhoneChange] = useState({});
    const [lastAddressChange, setLastAddressChange] = useState({});
    const [lastPasswordRequest, setLastPasswordRequest] = useState({});
    const [lastPasswordChange, setLastPasswordChange] = useState({});

    const [yearlyRedemptionTotal, setYearlyRedemtionTotal] = useState(0);
    const [averageBagValue, setAverageBagValue] = useState(0);

    const [garbageStrikesRemaining, setGarbageStrikesRemaining] = useState(0);
    const [hazardousStrikesRemaining, setHazardousStrikesRemaining] = useState(0);
    const [numberOfLostBags, setNumberOfLostBags] = useState(0);
    const [lostBagAmountCredited, setLostBagAmountCredited] = useState(0);
    const [numberOfMiscounts, setNumberOfMiscounts] = useState(0);
    const [miscountAmountCredited, setMiscountAmountCredited] = useState(0);
    const [lifetimeSuspensions, setLifeTimeSuspensions] = useState(0);

    const [garbageComplaintEnabled, setGarbageComplaintEnabled] = useState(0);
    const [hazardousComplaintEnabled, setHazardousComplaintEnabled] = useState(0);

    const [customerInfoExpanded, setCustomerInfoExpanded] = useState(false);
    // const [generalExpanded, setGeneralExpanded] = useState(false);
    // const [statusExpanded, setStatusExpanded] = useState(false);
    // const [notesExpanded, setNotesExpanded] = useState(false);
    // const [authenticationExpanded, setAuthenticationExpanded] = useState(false);
    // const [totalsExpanded, setTotalsExpanded] = useState(false);
    // const [violationsExpanded, setViolationsExpanded] = useState(false);

    const [previousAccountStatus, setPreviousAccountStatus] = useState({
        ..._.pick(customer, ['suspended', 'banned', 'closed', 'stale', 'deleted', 'underReview'])
    });

    const handleUpdateNote = async () => {
        const res = await http.post(`/users/${customer._id}/addNote`, { note: userNoteChange });

        if (res.ok) {
            await reloadCustomer();
            onSnackbar(`Successfully updated user notes.`);
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }
    };

    const handleUnlock2FA = async () => {
        const res = await http.post(`/users/${customer._id}/unlockAccount`);

        if (res.ok) {
            await reloadCustomer();
            onSnackbar(`Account has been unlocked.`);
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }
    };

    const fetchCustomerInfo = async () => {
        const res = await http.getJSON(`/users/${customer._id}/customerInformation`);
        if (res.ok) {
            setLastStatusUpdate(res.data.lastStatusUpdate);
            setLastPasswordChange(res.data.lastPasswordChange);
            setLastPasswordRequest(res.data.lastPasswordRequest);
            setLastNameChange(res.data.lastNameChange);
            setLastPhoneChange(res.data.lastPhoneChange);
            setLastAddressChange(res.data.lastAddressChange);
            if (!_.isNil(res.data.lastStatusUpdate)) {
                setPreviousAccountStatus({
                    ..._.pick(customer, ['suspended', 'banned', 'closed', 'stale', 'deleted', 'underReview']),
                    ..._.get(res.data, 'lastStatusUpdate.container.oldInfo', {})
                });
            }

            setAverageBagValue(res.data.averageBagValue);

            setGarbageComplaintEnabled(res.data.garbageComplaintEnabled);
            setHazardousComplaintEnabled(res.data.hazardousComplaintEnabled);

            setNumberOfLostBags(res.data.numberOfLostBags);
            setLostBagAmountCredited(res.data.lostBagAmountCredited);
            setNumberOfMiscounts(res.data.numberOfMiscounts);
            setMiscountAmountCredited(res.data.miscountAmountCredited);
            setLifeTimeSuspensions(res.data.lifetimeSuspensions);
            setGarbageStrikesRemaining(res.data.garbageStrikesRemaining);
            setHazardousStrikesRemaining(res.data.hazardousStrikesRemaining);
        }

        const eTransfersForCurrentYear = _.filter(redemptions, redemption => {
            if (!redemption.createdAt) {
                return false;
            }

            if (redemption.paymentModel !== 'INTERAC_ETRANSFERS') {
                return false;
            }

            const redeemedDate = moment(redemption.createdAt);
            if (redeemedDate.isBefore(moment().startOf('year'))) {
                return false;
            }

            if (redemption.cancelled) {
                return false;
            }

            return true;
        });

        const redemptionAmountForCurrentYear = eTransfersForCurrentYear.reduce((total, curr) => {
            return total + _.get(curr, 'amount', 0) - _.get(curr, 'fee', 0);
        }, 0);

        setYearlyRedemtionTotal(redemptionAmountForCurrentYear);
    };

    useEffect(() => {
        fetchCustomerInfo();
    }, []);

    const generalInfo = (
        <table
            style={{
                width: '100%'
            }}
        >
            <tbody>
                <tr>
                    <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                2FA Lockout:
                            </Typography>

                            <Typography variant="caption" style={{ marginRight: theme.spacing.unit / 2 }}>
                                {!_.isNil(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')) &&
                                moment(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')).isAfter(
                                    moment()
                                ) ? (
                                    <span style={{ fontWeight: 700, color: colors.red[500] }}>Yes</span>
                                ) : (
                                    <span style={{ fontWeight: 700, color: colors.green[500] }}>No</span>
                                )}
                            </Typography>

                            {!_.isNil(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')) &&
                                moment(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')).isAfter(
                                    moment()
                                ) && (
                                    <Typography variant="caption" style={{ marginRight: theme.spacing.unit / 2 }}>
                                        {_.get(customer, 'twoFactorAuthentication.lockAccountReason', 'N/A')}
                                    </Typography>
                                )}

                            {!_.isNil(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')) &&
                                moment(_.get(customer, 'twoFactorAuthentication.lockAccountUntil')).isAfter(
                                    moment()
                                ) && (
                                    <IconButton
                                        style={{ padding: 5 }}
                                        onClick={() =>
                                            warnAction(() => {
                                                return handleUnlock2FA();
                                            }, `Are you sure you want to unlock ${getCustomerFirstName(customer)}'s account?`)
                                        }
                                    >
                                        <Icon>lock_open</Icon>
                                    </IconButton>
                                )}
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                Timezone:
                            </Typography>
                            <Typography variant="caption">
                                {`${_.get(customer, 'location.timezone', 'N/A')}`} |{' '}
                                {locDate(new Date(), 'Z', lang, relativeTimezone)}
                            </Typography>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                User Time:
                            </Typography>

                            <Typography variant="caption">
                                <CurrentTime
                                    timezone={_.get(customer, 'location.timezone', undefined)}
                                    format="MMM. DD, YYYY h:mm A"
                                />
                            </Typography>
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                Signed Up:
                            </Typography>

                            <Typography variant="caption">
                                {locDate(customer.createdAt, 'MMM. DD, YYYY h:mm A', lang, relativeTimezone)}
                            </Typography>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                Last Login:
                            </Typography>

                            <Typography variant="caption">
                                {!_.isNil(customer.lastActivity)
                                    ? locDate(customer.lastActivity, 'MMM. DD, YYYY h:mm A', lang, relativeTimezone)
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                Login Type:
                            </Typography>

                            <Typography variant="caption">{!_.isEmpty(loginType) ? loginType : 'Manual'}</Typography>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div style={{ display: 'flex' }}>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                            >
                                Device Info:
                            </Typography>
                            <Typography variant="caption">
                                {_.get(customer, 'deviceInfo.device', 'N/A')} |{' '}
                                {_.get(customer, 'deviceInfo.browser', 'N/A')}
                            </Typography>
                        </div>
                    </td>
                </tr>
                <tr style={{ marginBottom: theme.spacing.unit * 2 }}>
                    <td colSpan={2} />
                </tr>
                {isEXPRegion() && (
                    <>
                        <tr>
                            <td>
                                <div style={{ display: 'flex' }}>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                    >
                                        Express Legacy Account:
                                    </Typography>
                                    <Typography variant="caption">
                                        {_.get(customer, 'isExpressLegacyAccount', false).toString()}
                                    </Typography>
                                </div>
                            </td>
                            {_.get(customer, 'isExpressLegacyAccount') && (
                                <div style={{ display: 'flex' }}>
                                    <td>
                                        <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                        >
                                            Express Legacy Authorized:
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="caption">
                                            {_.get(customer, 'isExpressLegacyAuthorized', false).toString()}
                                        </Typography>
                                    </td>
                                </div>
                            )}
                        </tr>
                    </>
                )}
            </tbody>
        </table>
    );

    if (!(_user.isSystemAdmin(auth) || _user.isInternalRole(auth))) return null;

    return (
        <Paper
            elevated={elevated ? 4 : 2}
            style={{
                width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                margin: theme.spacing.unit * 2,
                padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`
            }}
        >
            <UserNoteDialog
                open={showUserNoteDialog}
                onSave={() => {
                    handleUpdateNote();
                    setShowUserNoteDialog(false);
                    setUserNoteChange('');
                }}
                onCancel={() => {
                    setShowUserNoteDialog(false);
                }}
                title={'Add User Note'}
                note={userNoteChange}
                notes={_.get(customer, 'notes', [])}
                onChangeNote={(event, newValue) => {
                    setUserNoteChange(event.target.value);
                }}
            />

            {_user.isSystemAdmin(auth) || _user.isInternalRole(auth) ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5">Admin Information</Typography>
                        <Tooltip title="Only visible to administrators">
                            <Icon
                                style={{
                                    fontSize: 20,
                                    color: theme.palette.text.disabled,
                                    marginLeft: theme.spacing.unit
                                }}
                            >
                                info
                            </Icon>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton style={{ padding: 5 }} onClick={() => setShowUserNoteDialog(true)}>
                            <Icon>post_add</Icon>
                        </IconButton>
                        <IconButton
                            style={{ padding: 5 }}
                            onClick={() => setCustomerInfoExpanded(!customerInfoExpanded)}
                        >
                            <Icon>{customerInfoExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </IconButton>
                    </div>
                </div>
            ) : (
                <Typography variant="h5">{loc('customerInfo', lang)}</Typography>
            )}
            {customerInfoExpanded && (
                <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit / 2 }} />
            )}

            {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <Collapse in={customerInfoExpanded}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">General</Typography>
                        {/* <IconButton style={{ padding: 5 }} onClick={() => setGeneralExpanded(!generalExpanded)}>
                            <Icon>{generalExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </IconButton> */}
                    </div>
                    {generalInfo}
                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </Collapse>
            )}

            {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <Collapse in={customerInfoExpanded}>
                    <Typography variant="h6">Status</Typography>
                    {/* <IconButton style={{ padding: 5 }} onClick={() => setStatusExpanded(!statusExpanded)}>
                            <Icon>{statusExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </IconButton> */}

                    <table
                        style={{
                            width: '100%'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginRight: '5px'
                                            }}
                                            variant="caption"
                                        >
                                            <span style={{ fontWeight: 'bold' }}>Current Status:</span>
                                            {` ${getUserAccountStatusAndModifiers(customer)}`}
                                        </Typography>
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginRight: '5px'
                                            }}
                                            variant="caption"
                                        >
                                            <span style={{ fontWeight: 'bold' }}>Previous Status:</span>
                                            {` ${getUserAccountStatusAndModifiers(previousAccountStatus)}`}
                                        </Typography>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <div style={{ display: 'flex' }}>
                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                marginRight: '5px'
                                            }}
                                            variant="caption"
                                        >
                                            Last Update:
                                        </Typography>

                                        <Typography
                                            variant="caption"
                                            style={{
                                                flex: 1,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {_.isNil(lastStatusUpdate) || _.isEmpty(lastStatusUpdate)
                                                ? 'N/A'
                                                : `Marked as ${
                                                      _.first(_.values(lastStatusUpdate.container.newInfo))
                                                          ? ''
                                                          : 'not '
                                                  }${
                                                      _.first(_.keys(lastStatusUpdate.container.newInfo)) ===
                                                      'underReview'
                                                          ? 'under review'
                                                          : _.first(_.keys(lastStatusUpdate.container.newInfo))
                                                  }. Reason: ${lastStatusUpdate.container.reason}. By ${
                                                      lastStatusUpdate.container.initiatedBy
                                                  } on ${moment(lastStatusUpdate.createdAt)
                                                      .tz(relativeTimezone)
                                                      .locale('en')
                                                      .format('MMM. DD, YYYY h:mm A')}`}
                                        </Typography>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="caption" style={{ fontWeight: 'bold', marginRight: '5px' }}>
                                        Notes:
                                    </Typography>
                                    {/* Rendering the first note inline */}
                                    {_.get(customer, 'notes[0]', null) && (
                                        <td colSpan={2}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    noWrap
                                                    style={{ fontSize: '70%', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    {` ${moment(_.get(customer, 'notes[0].dateAdded'))
                                                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                        .locale('en')
                                                        .format('MMM. DD, YYYY')} ${_.get(
                                                        customer,
                                                        'notes[0].addedBy'
                                                    )}: `}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '70%',
                                                        fontStyle: 'italic'
                                                    }}
                                                    variant="caption"
                                                >
                                                    {`\'\'${_.get(customer, 'notes[0].note')}\'\'`}
                                                </Typography>
                                            </div>
                                        </td>
                                    )}
                                </div>
                            </tr>
                            {/* Rendering subsequent notes */}
                            {_.get(customer, 'notes', [])
                                .slice(1)
                                .map((note, i) => (
                                    <tr key={i}>
                                        <td colSpan={2}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    noWrap
                                                    style={{
                                                        fontSize: '70%',
                                                        marginRight: theme.spacing.unit / 2,
                                                        marginLeft: '42px'
                                                    }}
                                                >
                                                    {` ${moment(note.dateAdded)
                                                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                        .locale('en')
                                                        .format('MMM. DD, YYYY')} ${note.addedBy}: `}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '70%',
                                                        maxWidth: 250,
                                                        fontStyle: 'italic'
                                                    }}
                                                    variant="caption"
                                                >
                                                    {`\'\'${note.note}\'\'`}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </Collapse>
            )}

            {/* {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <>
                    {' '}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">Admin Note</Typography>
                        <div style={{ display: 'flex' }}>
                            <IconButton style={{ padding: 5 }} onClick={() => setShowUserNoteDialog(true)}>
                                <Icon>post_add</Icon>
                            </IconButton>
                            {_.get(customer, 'notes', []).length > 0 && (
                                <IconButton style={{ padding: 5 }} onClick={() => setNotesExpanded(!notesExpanded)}>
                                    <Icon>{notesExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                </IconButton>
                            )}
                        </div>
                    </div>
                    <Collapse in={customerInfoExpanded}>
                        <table
                            style={{
                                width: '100%'
                            }}
                        >
                            <tbody />
                        </table>
                    </Collapse>
                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </>
            )} */}

            {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <Collapse in={customerInfoExpanded}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">Last Updated</Typography>
                    </div>

                    <table
                        style={{
                            width: '100%'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                    >
                                        Password Request:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography noWrap style={{ fontSize: '70%' }}>
                                        {_.isNil(lastPasswordRequest)
                                            ? 'N/A'
                                            : `${_.get(
                                                  lastPasswordRequest,
                                                  'container.email',
                                                  getUserName(lastPasswordRequest.actor)
                                              )} (${moment(lastPasswordRequest.createdAt)
                                                  .tz(relativeTimezone)
                                                  .locale('en')
                                                  .format('MMM. DD, YYYY h:mm A')})`}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        Password Update:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography noWrap style={{ fontSize: '70%' }}>
                                        {_.isNil(lastPasswordChange)
                                            ? 'N/A'
                                            : `${getUserName(lastPasswordChange.actor)} (${moment(
                                                  lastPasswordChange.createdAt
                                              )
                                                  .tz(relativeTimezone)
                                                  .locale('en')
                                                  .format('MMM. DD, YYYY h:mm A')})`}
                                    </Typography>
                                </td>
                                {/* <Typography
                                            noWrap
                                            style={{
                                                overflow: 'hidden',
                                                fontSize: '70%'
                                            }}
                                        >
                                            {_.isNil(lastPasswordChange)
                                                ? 'N/A'
                                                : moment(lastPasswordChange.createdAt)
                                                      .tz(relativeTimezone)
                                                      .locale('en')
                                                      .format('MMM. DD, YYYY h:mm A')}
                                        </Typography> */}
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        Name:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="caption">
                                        {_.isNil(lastNameChange)
                                            ? 'N/A'
                                            : `${getUserName(
                                                  _.get(lastNameChange, 'container.oldInfo')
                                              )} ➡ ${getUserName(_.get(lastNameChange, 'container.newInfo'))}`}
                                    </Typography>

                                    <Typography noWrap style={{ fontSize: '70%' }}>
                                        {_.isNil(lastNameChange)
                                            ? 'N/A'
                                            : `${getUserName(lastNameChange.actor)} (${moment(lastNameChange.createdAt)
                                                  .tz(relativeTimezone)
                                                  .locale('en')
                                                  .format('MMM. DD, YYYY h:mm A')})`}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                    >
                                        Phone:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="caption">
                                        {_.isNil(lastPhoneChange)
                                            ? 'N/A'
                                            : `${formatAsPhoneNumber(
                                                  _.get(lastPhoneChange, 'container.oldInfo.phone', 'N/A')
                                              )} ➡ ${formatAsPhoneNumber(
                                                  _.get(lastPhoneChange, 'container.newInfo.phone', 'N/A')
                                              )}`}
                                    </Typography>

                                    <Typography noWrap style={{ fontSize: '70%' }}>
                                        {_.isNil(lastPhoneChange)
                                            ? 'N/A'
                                            : `${getUserName(lastPhoneChange.actor)} (${moment(
                                                  lastPhoneChange.createdAt
                                              )
                                                  .tz(relativeTimezone)
                                                  .locale('en')
                                                  .format('MMM. DD, YYYY h:mm A')})`}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        Address:
                                    </Typography>
                                </td>
                                <td width="70%">
                                    <Typography variant="caption">
                                        {_.isNil(lastAddressChange)
                                            ? 'N/A'
                                            : `${_.get(
                                                  lastAddressChange,
                                                  'container.oldInfo.location.description'
                                              )} ➡ ${_.get(
                                                  lastAddressChange,
                                                  'container.newInfo.location.description'
                                              )}`}
                                    </Typography>

                                    <Typography style={{ fontSize: '70%' }}>
                                        {_.isNil(lastAddressChange)
                                            ? 'N/A'
                                            : `${getUserName(lastAddressChange.actor)} (${moment(
                                                  lastAddressChange.createdAt
                                              )
                                                  .tz(relativeTimezone)
                                                  .locale('en')
                                                  .format('MMM. DD, YYYY h:mm A')})`}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </Collapse>
            )}

            {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <Collapse in={customerInfoExpanded}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">Totals</Typography>
                    </div>

                    <table
                        style={{
                            width: '100%'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        Average Bag Value:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="caption">{formatAsCurrency(averageBagValue)}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                        Total e-Transfer Redemptions For Year:
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="caption">{formatAsCurrency(yearlyRedemptionTotal)}</Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </Collapse>
            )}

            {(_user.isSystemAdmin(auth) || _user.isInternalRole(auth)) && (
                <Collapse in={customerInfoExpanded}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6">Violations</Typography>
                        {/* <IconButton style={{ padding: 5 }} onClick={() => setViolationsExpanded(!violationsExpanded)}>
                            <Icon>{violationsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                        </IconButton> */}
                    </div>

                    <table
                        style={{
                            width: '100%'
                        }}
                    >
                        <tbody>
                            <tr>
                                {hazardousComplaintEnabled && (
                                    <>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    Hazardous Strikes Remaining:
                                                </Typography>

                                                <Typography variant="caption">{hazardousStrikesRemaining}</Typography>
                                            </div>
                                        </td>
                                    </>
                                )}
                                {garbageComplaintEnabled && (
                                    <>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    Garbage Strikes Remaining:
                                                </Typography>
                                                <Typography variant="caption">{garbageStrikesRemaining}</Typography>
                                            </div>
                                        </td>
                                    </>
                                )}
                            </tr>
                            {isEXPRegion() && (
                                <>
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    No. Lost Bags Issued:
                                                </Typography>

                                                <Typography variant="caption">{numberOfLostBags}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    Lost Bags Amount Credited:
                                                </Typography>

                                                <Typography variant="caption">
                                                    {formatAsCurrency(lostBagAmountCredited)}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    No. Bag Miscounts Issued:
                                                </Typography>

                                                <Typography variant="caption">{numberOfMiscounts}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    Bag Miscounts Amount Credited:
                                                </Typography>
                                                <Typography variant="caption">
                                                    {formatAsCurrency(miscountAmountCredited)}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td>
                                    <div style={{ display: 'flex' }}>
                                        <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                        >
                                            Lifetime Suspensions:
                                        </Typography>

                                        <Typography variant="caption">{lifetimeSuspensions}</Typography>
                                    </div>
                                </td>
                                {garbageComplaintEnabled && (
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                            >
                                                Lifetime Garbage Complaints:
                                            </Typography>

                                            <Typography variant="caption">
                                                {_.get(customer, 'garbageStrikes', []).length}
                                            </Typography>
                                        </div>
                                    </td>
                                )}
                                {hazardousComplaintEnabled && !garbageComplaintEnabled && (
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                            >
                                                Lifetime Hazardous Complaints:
                                            </Typography>

                                            <Typography variant="caption">
                                                {_.get(customer, 'hazardousStrikes', []).length}
                                            </Typography>
                                        </div>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {garbageComplaintEnabled && hazardousComplaintEnabled && (
                                    <>
                                        <td>
                                            <div style={{ display: 'flex' }}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 'bold', marginRight: theme.spacing.unit / 2 }}
                                                >
                                                    Lifetime Hazardous Complaints:
                                                </Typography>

                                                <Typography variant="caption">
                                                    {_.get(customer, 'hazardousStrikes', []).length}
                                                </Typography>
                                            </div>
                                        </td>
                                    </>
                                )}
                            </tr>
                        </tbody>
                    </table>

                    <Divider style={{ marginBottom: theme.spacing.unit / 2 }} />
                </Collapse>
            )}
        </Paper>
    );
}

export default withTheme()(CustomerInfoWidget);

function generateUsersTime(customer) {
    return moment(new Date())
        .tz(_.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
        .format('MMM D hh:mm A');
}
