import React, { useContext } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    Icon,
    LinearProgress
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { isCONRegion } from '../../../utils/misc';

function UserNoteDialog(props) {
    const { open, onSave, onCancel, onDelete, title, note, onChangeNote, viewOnly, notes, isLoading = false } = props;
    
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} fullWidth onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!viewOnly && (
                    <TextField
                        variant="outlined"
                        disabled={isLoading}
                        fullWidth
                        multiline
                        autoFocus
                        value={note}
                        onChange={onChangeNote}
                        placeholder={loc('addNote', lang)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        data-cy="user-comments-dialog-save"
                                        color="primary"
                                        disabled={isLoading || _.isEmpty(note)}
                                        onClick={onSave}
                                    >
                                        <Icon>add_circle</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                <table
                    style={{
                        width: '100%'
                    }}
                >
                    <tbody>
                        {(notes || []).map((note, i) => (
                            <tr key={i}>
                                <td data-cy={`user-note-${i}-note`}>
                                    <Typography
                                        style={{
                                            display: 'inline-block',
                                            'word-break': 'break-word'
                                        }}
                                    >
                                        {note.note}
                                    </Typography>
                                    <br />
                                    <Typography
                                        data-cy={`user-note-${i}-info`}
                                        noWrap
                                        style={{ fontSize: '85%', fontWeight: 500 }}
                                    >
                                        {note.addedBy} {loc('on', lang)}{' '}
                                        {moment(note.dateAdded)
                                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                            .locale('en')
                                            .format('MMM. DD, YYYY')}
                                    </Typography>
                                </td>
                                {onDelete && (
                                    <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton
                                            color="primary"
                                            disabled={isLoading}
                                            onClick={async () => await onDelete(i)}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button data-cy="user-comments-dialog-close" onClick={onCancel}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </Dialog>
    );
}

export default withTheme()(UserNoteDialog);
