import React, { useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { withTheme, Typography, CircularProgress, Grid } from '@material-ui/core';

import { List, ListItem } from '@material-ui/core';

import { loc } from '../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function DriverIncidentsWidget(props) {
    const { lang } = useContext(LocalizationContext);

    const { theme, loading, height, noPadding, lateDriverTrips } = props;

    if (loading) {
        return (
            <div style={{ padding: theme.spacing.unit }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            </div>
        );
    }

    if (_.isEmpty(lateDriverTrips)) {
        return (
            <div style={{ padding: theme.spacing.unit, height: height ? height + 16 : undefined }}>
                <Typography style={{ margin: theme.spacing.unit * 2 }}>{loc('noDriverIncidents', lang)}</Typography>
            </div>
        );
    }

    return (
        <>
            <div style={{ padding: noPadding ? 0 : theme.spacing.unit, width: '100%' }}>
                <List
                    dense
                    style={{
                        maxHeight: height ? height : 200,
                        height: height ? height : undefined,
                        overflowY: 'auto'
                    }}
                >
                    {lateDriverTrips.map((trip, index) => {
                        const driverName = `${_.get(trip, 'transporter.name.first', 'Unknown')} ${_.get(
                            trip,
                            'transporter.name.last',
                            'Driver'
                        )}`;
                        const lateReason = _.get(trip, 'lateReason', 'N/A');
                        const dateOfTrip = moment(_.get(trip, 'actualStartTime', null)).format('YYYY-MM-DD');

                        return (
                            <ListItem key={index}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography variant="body2">{driverName}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">{lateReason}</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Typography variant="body1" fontWeight="bold">
                                            {dateOfTrip}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </>
    );
}

export default withTheme()(DriverIncidentsWidget);
