import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import * as qs from 'query-string';

import bulkHelper from 'helpers/bulkHelper';

import { truncateBagtag } from 'utils/misc';

import useCharitySelect from 'components/CharitySelect/hooks/useCharitySelect';

import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { useContext } from 'react';
import { _user } from 'std';

const BULK_SKU_FILTER_LOCALSTORAGE_STRING = 'skuTypeFilter';
const BULK_SKU_FILTER_LOCALSTORAGE_STRING_MULTI = 'skuTypeFilterMulti';
const BULK_TYPE_FILTER_LOCALSTORAGE_STRING = 'bulkTypeFilter';
const BULK_RECEIVER_FILTER_LOCALSTORAGE_STRING = 'bulkReceiverFilter';
const BULK_TRIP_FILTER_LOCALSTORAGE_STRING = 'bulkTripFilter';
const BULK_PROCESSOR_FILTER_LOCALSTORAGE_STRING = 'bulkProcessorFilter';
const BULK_COLLECTOR_FILTER_LOCALSTORAGE_STRING = 'bulkCollectorFilter';
const GLOBAL_BULK_FILTER_LOCALSTORAGE_STRING = 'globalBulkFilter';

function useBulkCounter({
    bulks,
    receivers,
    trips,
    collectors,
    loadingBulks,
    warnAction,
    initialCollector = null,
    skus,
    commodities
}) {
    const history = useHistory();
    const location = useLocation();
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const {
        charityPreferred,
        subdivisionPreferred,
        donate,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        handleSetDonation
    } = useCharitySelect({});

    // large lists filters
    const [skuTypeFilter, setSkuTypeFilter] = useState(getInitialSkuTypeFilterState());
    const [skuTypeFilterMulti, setSkuTypeFilterMulti] = useState(getInitialSkuTypeFilterStateMulti(commodities));
    const [bulkTypeFilter, setBulkTypeFilter] = useState(getInitialBulkTypeFilterState());
    const [bulkReceiverFilter, setBulkReceiverFilter] = useState(getInitialBulkReceiverFilterState(receivers));
    const [bulkTripFilter, setBulkTripFilter] = useState(getInitialBulkTripFilterState(trips));
    const [bulkCollectorFilter, setBulkCollectorFilter] = useState(
        getInitialBulkCollectorFilterState(collectors, initialCollector)
    );
    const [bulkProcessorFilter, setBulkProcessorFilter] = useState(
        getInitialBulkProcessorFilterState(collectors, initialCollector)
    );
    const [globalBulkFilter, setGlobalBulkFilter] = useState(getInitialGlobalBulkFilterState());

    const [selfishMode, setSelfishMode] = useState(JSON.parse(localStorage.getItem('selfishMode') || false));

    const [inProgress, setInProgress] = useState(false);
    const [continuousCountingEnabled, setContinuousCountingEnabled] = useState(
        JSON.parse(localStorage.getItem('continuousCountingEnabled') || 'true')
    );

    const [filteredReceivers, setFilteredReceivers] = useState(receivers);

    // bulk search dialog states
    const [bulkSearchDialogOpen, setBulkSearchDialogOpen] = useState(false);
    const [bulkSearchSkuType, setBulkSearchSkuType] = useState('beverage'); // NOTE: hardcoded to be default set to beverage
    const [bulkSearchDialogError, setBulkSearchDialogError] = useState(null); // will be a string, if null it wont show
    const [bulkSearchDialogQuery, setBulkSearchDialogQuery] = useState(''); // search query

    // bulk create dialog states
    const [bulkCreateDialogOpen, setBulkCreateDialogOpen] = useState(false);
    const [bulkCreateSkuType, setBulkCreateSkuType] = useState('beverage'); // NOTE: hardcoded to be default set to beverage
    const [bulkCreateBulkType, setBulkCreateBulkType] = useState('adjustment'); // NOTE: hardcoded to be default set to beverage
    const [bulkCreateDialogError, setBulkCreateDialogError] = useState(null); // will be a string, if null it wont show
    const [customerID, setCustomerID] = useState(''); // search query
    const [disableChangingBulkType, setDisableChangingBulkType] = useState(false); // search query
    const [numberOfBags, setNumberOfBags] = useState(1);
    const [donateQuickdrop, setDonateQuickdrop] = useState(false);
    //const [commoditiesProcessed, setCommoditiesProcessed] = useState(1);
    const [lostAndFoundBulkType, setLostAndFoundBulkType] = useState('mobile');

    useEffect(() => {
        setSkuTypeFilterMulti(getInitialSkuTypeFilterStateMulti(commodities));
    }, [commodities]);

    useEffect(() => {
        const query = qs.parse(location.search);

        if (query.openSearchDialog) {
            setBulkSearchDialogOpen(true);
        }

        if (query.clearSearchFilter) {
            setGlobalBulkFilter(null);
        }
    }, [location]);
    useEffect(() => {
        setBulkTripFilter(getInitialBulkTripFilterState(trips));
    }, [trips]);

    useEffect(() => {
        if (!_.isNil(initialCollector)) {
            setBulkProcessorFilter(initialCollector._id);
        }
    }, [initialCollector]);

    useEffect(() => {
        // once all bulks are loaded or a new collector is selected
        if (!loadingBulks) {
            const bulksWithReceivers = _(bulks)
                .filter(b => !_.isNil(b.receiver))
                .value();

            let resetReceiverFilter = bulkCollectorFilter !== 'all';
            let resetTripFilter = bulkCollectorFilter !== 'all';

            const updatedFilteredReceivers = receivers.map(r => {
                // Do not reset receiver filter to all if it is a valid receiver for the selected collector
                if (r._id.toString() === bulkReceiverFilter && r.collector === bulkCollectorFilter) {
                    resetReceiverFilter = false;
                }

                const bulksForReceiver = _(bulksWithReceivers)
                    .filter(b => b.receiver._id.toString() === r._id.toString())
                    .value();

                return {
                    ...r,
                    numVal: _(bulksForReceiver) // number of incomplete bulks
                        .filter(b => _.isNil(b.dateCompleted))
                        .value().length,
                    show:
                        (bulkCollectorFilter === 'all' || bulkCollectorFilter === r.collector) &&
                        !_.isEmpty(bulksForReceiver)
                };
            });

            const temp = _(updatedFilteredReceivers)
                .filter(r => r.show)
                .value();

            const orderedAndFilteredReceivers = _.orderBy(temp, ['numVal'], ['desc']);

            if (resetReceiverFilter) setBulkReceiverFilter('all');
            if (resetTripFilter) setBulkTripFilter('all');
            setFilteredReceivers(orderedAndFilteredReceivers);
        }
    }, [loadingBulks, bulkCollectorFilter]);

    const handleOpenSearchDialog = () => {
        setInProgress(true);
        if (_.isNil(skuTypeFilter) || skuTypeFilter === 'all') {
            // default to beverage
            setBulkSearchSkuType('beverage'); // HARDCODED:
        } else {
            setBulkSearchSkuType(skuTypeFilter);
        }
        setBulkSearchDialogOpen(true);
        setInProgress(false);
    };

    const handleCloseSearchDialog = () => {
        setBulkSearchDialogQuery('');
        setBulkSearchDialogOpen(false);
    };

    const handleBulkSearchQueryChange = e => {
        setBulkSearchDialogError(null);
        setBulkSearchDialogQuery(
            e.target.value
                .toString()
                // .replace(/\W/g, '')
                .toUpperCase()
        );
    };

    const handleSearchSkuTypeChange = e => {
        const newValue = e.target.value;
        setBulkSearchSkuType(newValue);
        setBulkCreateSkuType(newValue);
    };

    const handleGlobalBulkSearchChange = e => {
        // allow numbers, letters, and spaces
        const newValue = e.target.value.replace(/[^a-z0-9_ ]/gi, '');
        setGlobalBulkFilter(newValue);
        localStorage.setItem(GLOBAL_BULK_FILTER_LOCALSTORAGE_STRING, JSON.stringify(newValue));
    };

    // TODO: re-implement somehow or delete
    const handleToggleContinuousCounting = () => {
        const newValue = !continuousCountingEnabled;
        localStorage.setItem('continuousCountingEnabled', JSON.stringify(newValue));
        setContinuousCountingEnabled(newValue);
    };

    // filter
    const handleSkuTypeFilterChange = e => {
        const newValue = e.target.value;
        setSkuTypeFilter(newValue);
        if (newValue === 'all') {
            setBulkCreateSkuType('beverage');
        } else {
            setBulkCreateSkuType(newValue);
        }

        // save on localstorage
        localStorage.setItem(BULK_SKU_FILTER_LOCALSTORAGE_STRING, newValue);
    };
    const handleSkuTypeFilterChangeMulti = e => {
        const newValue = e.target.value;
        setSkuTypeFilterMulti(newValue);
        // save on localstorage
        localStorage.setItem(BULK_SKU_FILTER_LOCALSTORAGE_STRING_MULTI, newValue);
    };

    const handleBulkTypeFilterChange = e => {
        const newValue = e.target.value;
        setBulkTypeFilter(newValue);
        // save on localstorage
        localStorage.setItem(BULK_TYPE_FILTER_LOCALSTORAGE_STRING, newValue);
    };

    const handleBulkReceiverFilterChange = e => {
        const newValue = e.target.value;
        setBulkReceiverFilter(newValue.toString());
        localStorage.setItem(BULK_RECEIVER_FILTER_LOCALSTORAGE_STRING, newValue);
    };

    const handleBulkTripFilterChange = e => {
        const newValue = e.target.value;
        setBulkTripFilter(newValue.toString());
        localStorage.setItem(BULK_TRIP_FILTER_LOCALSTORAGE_STRING, newValue);
    };

    const handleBulkCollectorFilterChange = e => {
        const newValue = e.target.value;
        setBulkCollectorFilter(newValue.toString());
        localStorage.setItem(BULK_COLLECTOR_FILTER_LOCALSTORAGE_STRING, newValue);
    };

    const handleBulkProcessorFilterChange = e => {
        const newValue = e.target.value;
        setBulkProcessorFilter(newValue.toString());
        localStorage.setItem(BULK_PROCESSOR_FILTER_LOCALSTORAGE_STRING, newValue);
    };

    /**
     * As the user enters in a bagtag, the modified bulks are filtered through in search of a match
     * If there is no match when the user submits, it will send an api call to verify and return a bulk (if found)
     * If there is a bulk that matches, it will open that bulk to count
     * If there are multiple matches (one counted and one not counted bulk), it will choose the uncounted bulk.
     * This assumes that no uncounted bulks contain the same bagtag
     */
    const handleSearchForBulkToCount = async () => {
        // based on query grab all possible bulks
        const possibleBulks = _.filter(
            bulks,
            bulk =>
                (truncateBagtag(bulk.label) === bulkSearchDialogQuery ||
                    bulk.customerUniqueID === bulkSearchDialogQuery) &&
                bulk.skuType === bulkSearchSkuType &&
                !bulkHelper.isRedeemed(bulk)
        );

        // split possible bulks into counted/uncounted
        const uncountedBulks = _.filter(possibleBulks, bulk => _.isNil(bulk.dateCompleted));
        const countedBulks = _.filter(possibleBulks, bulk => !_.isNil(bulk.dateCompleted));

        // if there is one uncounted bulk, use that one
        if (!_.isEmpty(uncountedBulks)) {
            if (uncountedBulks.length > 1) {
                // this should never happen, but if it does, the counter should select the bulk manually with the list
                onSnackbar(
                    `There appears to be multiple incomplete bulks with the bagtag [${bulkSearchDialogQuery.toString()}]. Please select them manually from the lists in order to count them.`,
                    'info'
                );
            } else {
                const bulk = _.first(uncountedBulks);
                /*const res = await http.postJSON(
                    `/bulks/${bulk._id}/updateCommoditiesProcessed`,
                    {
                        amountToAddOrRemove: commoditiesProcessed
                    },
                    true
                );*/

                history.push(`/operators/${operator._id}/bulks/${bulk._id}`);
                handleCloseSearchDialog();
            }
        } else if (!_.isEmpty(countedBulks)) {
            // grab the first counted bulk which should already be the most recent one at the first position
            const bulk = _.first(countedBulks);

            history.push(`/operators/${operator._id}/bulks/${bulk._id}`);
            handleCloseSearchDialog();
        } else {
            // if the bulk has been redeemed, or does not exist on the depot, make a api call to create a quickdrop/adjustment bulk
            const res = await http.getJSON(`/bulks/verifyLabel/${bulkSearchDialogQuery}`, true);
            if (res.ok) {
                const bulk_id = _.get(res, 'data.bulk_id', null);
                // if there is an existing old bulk, open that one
                if (!_.isNil(bulk_id)) {
                    history.push(`/operators/${operator._id}/bulks/${bulk_id}`);
                } else {
                    setCustomerID(bulkSearchDialogQuery);

                    handleCloseSearchDialog();

                    handleOpenCreateBulkDialog(bulkSearchSkuType);
                }
            } else {
                setBulkSearchDialogError('Cannot find tag or customer ID in system');
            }
        }
    };

    const handleBulkCreateCustomerIDChange = e => {
        setBulkCreateDialogError(null);
        setCustomerID(e.target.value.toString().replace(/\s/g, ''));
    };

    const handleCreateBulk = async () => {
        setInProgress(true);
        /* first check if its valid and get customer obj, for quickdrops if no customerID was entered and a 
        charity entered instead it will get the charity manager*/
        let bulkType = bulkCreateBulkType;
        let charityId = (bulkType === 'inhouse' || bulkType === 'walk-in') && donateQuickdrop ? charityPreferred : null;
        let anonymousDonating = !_.isNil(charityPreferred) && _.isEmpty(customerID);
        let isLostAndFound = bulkCreateBulkType === 'lostandfound';
        if (isLostAndFound) {
            charityId = null;
            if (lostAndFoundBulkType === 'mobile') {
                bulkType = 'adjustment';
            } else {
                bulkType = lostAndFoundBulkType;
            }
        }
        const res1 = await http.getJSON(
            `/bulks/customerInfoForBulkCreation?bulkType=${bulkType}&customerIDOrEmail=${customerID}&charityId=${charityId}&isLostAndFound=${isLostAndFound}`,
            true
        );

        if (res1.ok) {
            const { customer, warningConfirmation } = res1.data;

            const createBulkFunc = async () => {
                const res2 = await http.postJSON(
                    `/bulks/create`,
                    {
                        skuType: bulkCreateSkuType,
                        bulkType: bulkType,
                        customer_id: _.get(customer, '_id', null),
                        commodityAmount: numberOfBags,
                        charityId:
                            (bulkType === 'inhouse' || bulkType === 'walk-in') && donateQuickdrop
                                ? charityPreferred
                                : null,
                        isLostAndFound: isLostAndFound,
                        anonymousDonation: anonymousDonating
                    },
                    true
                );

                if (res2.ok) {
                    setBulkCreateDialogOpen(false);
                    onSnackbar('Created new bulk!');
                    history.push(`/operators/${operator._id}/bulks/${res2.data.bulk_id}`);
                } else {
                    setBulkCreateDialogError(res2.errorMessage);
                }
            };

            if (!_.isEmpty(warningConfirmation)) {
                warnAction(() => {
                    createBulkFunc();
                }, warningConfirmation);
            } else {
                createBulkFunc();
            }
        } else {
            setBulkCreateDialogError(res1.errorMessage);
        }

        setInProgress(false);
    };

    const handleOpenCreateBulkDialog = (skuType, bulkType = null) => {
        if (
            _user.isCollectorAdmin(operator) ||
            _user.isSystemAdmin(operator) ||
            _user.isInternalRole(operator) ||
            bulkType !== 'adjustment'
        ) {
            setBulkCreateBulkType(bulkType); //if bulkType is null the dialog will show the bulkType select screen first

            if (skuType === 'all') {
                setBulkCreateSkuType('beverage');
            } else {
                setBulkCreateSkuType(skuType);
            }

            setBulkCreateDialogOpen(true);
        } else {
            onSnackbar('Only collector managers can do this, please contact a manager.', 'info');
        }
    };

    const handleCloseCreateBulkDialog = () => {
        setBulkCreateDialogError(null);
        setCustomerID('');
        setBulkCreateDialogOpen(false);
        setDonateQuickdrop(false);
        handleSetDonation(false);
    };

    const handleCreateSkuTypeChange = e => {
        const newValue = e.target.value;
        setBulkCreateSkuType(newValue);
        // credit bulks are locked to adjustment only
        if (newValue === 'credit') {
            setBulkCreateBulkType('adjustment');
            setDisableChangingBulkType(true);
        } else {
            setDisableChangingBulkType(false);
        }
    };
    // LAF = lost and found
    const handleLAFBulkTypeChange = e => {
        const newValue = e.target.value;
        setLostAndFoundBulkType(newValue);
    };

    const handleCreateBulkTypeChange = bulkType => {
        if (
            _user.isCollectorAdmin(operator) ||
            _user.isSystemAdmin(operator) ||
            _user.isInternalRole(operator) ||
            bulkType !== 'adjustment'
        ) {
            setBulkCreateBulkType(bulkType);
        } else {
            onSnackbar('Only collector managers can do this, please contact a manager.', 'info');
        }
    };

    const handlePayload = e => {
        const value = e.target.value;
        setNumberOfBags(value);
    };

    /*const handleUpdateCommoditiesProcessed = e => {
        setCommoditiesProcessed(e.target.value);
    };*/

    const handleToggleSelfishMode = () => {
        const updatedVal = !selfishMode;
        localStorage.setItem('selfishMode', updatedVal);
        setSelfishMode(updatedVal);
    };

    /*const handleIncrementCommoditiesProcessed = () => setCommoditiesProcessed(parseInt(commoditiesProcessed) + 1);
    const handleDecrementCommoditiesProcessed = () => setCommoditiesProcessed(parseInt(commoditiesProcessed) - 1);*/

    return {
        inProgress,
        skuTypeFilter,
        bulkTypeFilter,
        bulkReceiverFilter,
        bulkTripFilter,
        bulkCollectorFilter,
        bulkProcessorFilter,
        globalBulkFilter,
        filteredReceivers,
        continuousCountingEnabled,
        bulkSearchDialogOpen,
        handleOpenSearchDialog,
        handleCloseSearchDialog,
        handleBulkSearchQueryChange,
        bulkSearchDialogQuery,
        bulkSearchDialogError,
        bulkSearchSkuType,
        bulkCreateDialogOpen,
        bulkCreateBulkType,
        bulkCreateSkuType,
        customerID,
        numberOfBags,
        selfishMode,
        disableChangingBulkType,
        bulkCreateDialogError,
        //commoditiesProcessed,
        donateQuickdrop,
        charityPreferred,
        subdivisionPreferred,
        donate,
        handlePayload,
        handleGlobalBulkSearchChange,
        handleToggleContinuousCounting,
        handleSkuTypeFilterChange,
        handleBulkTypeFilterChange,
        handleBulkReceiverFilterChange,
        handleBulkTripFilterChange,
        handleBulkCollectorFilterChange,
        handleBulkProcessorFilterChange,
        handleSearchSkuTypeChange,
        handleSearchForBulkToCount,
        handleCreateBulk,
        handleOpenCreateBulkDialog,
        handleCloseCreateBulkDialog,
        handleCreateSkuTypeChange,
        handleCreateBulkTypeChange,
        handleBulkCreateCustomerIDChange,
        handleToggleSelfishMode,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        handleSetDonation,
        handleSetDonateQuickdrop: setDonateQuickdrop,
        lostAndFoundBulkType,
        handleLAFBulkTypeChange,
        skuTypeFilterMulti,
        setSkuTypeFilterMulti,
        handleSkuTypeFilterChangeMulti
        /*handleUpdateCommoditiesProcessed,
        handleIncrementCommoditiesProcessed,
        handleDecrementCommoditiesProcessed*/
    };
}

export default useBulkCounter;

// initial state functions that grab from localstorage
function getInitialSkuTypeFilterState() {
    return localStorage.getItem(BULK_SKU_FILTER_LOCALSTORAGE_STRING) || 'beverage';
}
function getInitialSkuTypeFilterStateMulti(commodities) {
    let initSkuTypeFilter = [];
    commodities.forEach(c => {
        if (c.includeContainersInStats) {
            initSkuTypeFilter.push(c.skuType);
        }
    });
    return localStorage.getItem(BULK_SKU_FILTER_LOCALSTORAGE_STRING_MULTI)
        ? (localStorage.getItem(BULK_SKU_FILTER_LOCALSTORAGE_STRING_MULTI) || '').split(',')
        : initSkuTypeFilter;
}

function getInitialBulkTypeFilterState() {
    return localStorage.getItem(BULK_TYPE_FILTER_LOCALSTORAGE_STRING) || 'all';
}

function getInitialBulkReceiverFilterState(receivers) {
    const receiverFromLocalStorage = localStorage.getItem(BULK_RECEIVER_FILTER_LOCALSTORAGE_STRING);
    const receiver_ids = receivers.map(r => r._id.toString());

    if (!_.isNil(receiverFromLocalStorage) && receiver_ids.includes(receiverFromLocalStorage.toString())) {
        return receiverFromLocalStorage;
    } else {
        return 'all';
    }
}
function getInitialBulkTripFilterState(trips) {
    const tripFromLocalStorage = localStorage.getItem(BULK_TRIP_FILTER_LOCALSTORAGE_STRING);
    const trip_ids = trips.map(t => t._id.toString());
    let newValue = 'all';
    if (!_.isNil(tripFromLocalStorage) && trip_ids.includes(tripFromLocalStorage.toString())) {
        newValue = tripFromLocalStorage.toString();
    }
    return newValue;
}

function getInitialBulkCollectorFilterState(collectors, initialCollector = null) {
    const collectorFromLocalStorage = localStorage.getItem(BULK_COLLECTOR_FILTER_LOCALSTORAGE_STRING);
    const collector_ids = collectors.map(c => c._id.toString());

    if (!_.isNil(collectorFromLocalStorage) && collector_ids.includes(collectorFromLocalStorage.toString())) {
        return collectorFromLocalStorage;
    } else {
        return 'all';
    }
}

function getInitialBulkProcessorFilterState(collectors, initialProcessor = null) {
    const processorFromLocalStorage = localStorage.getItem(BULK_PROCESSOR_FILTER_LOCALSTORAGE_STRING);
    const collector_ids = collectors.map(c => c._id.toString());

    if (!_.isNil(processorFromLocalStorage) && collector_ids.includes(processorFromLocalStorage.toString())) {
        return processorFromLocalStorage;
    } else if (initialProcessor) {
        return initialProcessor._id.toString();
    } else {
        return 'all';
    }
}

function getInitialGlobalBulkFilterState() {
    const previousSearch = JSON.parse(localStorage.getItem(GLOBAL_BULK_FILTER_LOCALSTORAGE_STRING));
    if (!_.isNil(previousSearch)) {
        return previousSearch;
    } else {
        return '';
    }
}
