import React, { useState, useEffect, useContext, useMemo } from 'react';
import { _commodity, _user } from 'std';
import _ from 'lodash';
import moment from 'moment-timezone';
import mustache from 'mustache';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import InvalidBagtagDialog from 'components/InvalidBagtagDialog/InvalidBagtagDialog';
import UpdateGPSDialog from 'components/Dialogs/PickupCompletion/UpdateGPSDialog';

import * as colors from '@material-ui/core/colors';

import {
    Button,
    Typography,
    TextField,
    FormControl,
    DialogContent,
    DialogActions,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Checkbox,
    Icon,
    IconButton,
    InputAdornment,
    Dialog,
    MobileStepper,
    Fade,
    LinearProgress,
    withMobileDialog,
    FormControlLabel,
    Radio,
    Paper,
    CircularProgress
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import InProgress from './InProgress';

import { padBagtag, formatAsAddress, uppercaseFirstLetter } from 'utils/misc';
import pickupHelper from 'helpers/pickupHelper';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageCapture from 'components/ImageUploads/ImageCapture';
import useImageUpload from 'components/ImageUploads/useImageUpload';
import PayloadInput from 'components/InputComponents/PayloadInput';
import { isCONRegion } from '../../../utils/misc';

function MultistepPickupDialog(props) {
    const {
        admin,
        fullScreen,
        theme,
        http,
        socket,
        open,
        operator,
        pickupSelected,
        getNextPickup,
        currentLocation,
        onClose,
        onMode,
        setAfterCompleteDialogOpen,
        filterDate,
        handleCenterMapOnPickups,
        onSnackbar,
        setRecalculatingTrips,
        onManuallySetLocation,
        gpsNotWorking,
        completePickupImageTitle,
        completePickupImageInstructions,
        commoditiesAvailable,
        fetchTrips,
        inProgress,
        setInProgress
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [activeStep, setActiveStep] = useState(0);
    const [payload, setPayload] = useState(pickupHelper.createEmptyPayload());
    const [subPayloads, setSubPayloads] = useState([]);
    const [cumulativePayload, setCumulativePayload] = useState({});

    const [bagtag, setBagtag] = useState('');
    const [bagtagInputError, setBagtagInputError] = useState(null);

    const [complaintOptions, setComplaintOptions] = useState([]);

    const [driverNote, setDriverNote] = useState('');

    const [invalidBagtagDialogOpen, setInvalidBagtagDialogOpen] = useState(false);
    const [invalidBagtagDialogMessage, setInvalidBagtagDialogMessage] = useState(null);

    const [updateGPSDialogOpen, setUpdateGPSDialogOpen] = useState(false);
    const [updateGPS, setUpdateGPS] = useState(false);

    const [checked, setChecked] = useState([]);
    const [issueDescription, setIssueDescription] = useState('');

    const [showLastImageButton, setShowLastImageButton] = useState(false);

    const {
        imagePreviews,
        uploadingImage,
        lastImageBlob,
        lastImagePreview,
        handleDeleteImage,
        handleUploadImage,
        setImagePreviews
    } = useImageUpload({});

    const handleAddImageToPickup = async (image, urlPrefix) => {
        const newImg = await handleUploadImage(image, urlPrefix);
        const images = _.get(pickupSelected, 'images', {});
        if (_.isNil(images.pickupImages)) {
            images.pickupImages = [];
        }
        images.pickupImages.push(newImg);

        setShowLastImageButton(true);
        await http.post(`/pickups/${pickupSelected._id}/updateImages`, { images }); //update images on back end so if pickup dialog crashes images will still be there when the driver restarts the app
    };

    const handleRemoveImageFromPickup = async idx => {
        handleDeleteImage(idx);
        const images = _.get(pickupSelected, 'images', {});
        if (_.isNil(images.pickupImages) || _.isEmpty(images.pickupImages)) {
            return;
        }
        images.pickupImages.splice(idx, 1);

        setShowLastImageButton(false);
        await http.post(`/pickups/${pickupSelected._id}/updateImages`, { images }); //update images on back end so if pickup dialog crashes images will still be there when the driver restarts the app
    };

    const loadOptions = async () => {
        const res = await http.getJSON(`/system/configuration/driverComplaintOptions/en`);
        if (res.ok) {
            setComplaintOptions(res.data.options);
        }
    };

    useEffect(() => {
        setImagePreviews(pickupHelper.getPickupImageURL(pickupSelected));
        loadOptions();
    }, []);

    useEffect(() => {
        const handleBackKeyDown = e => {
            e.preventDefault();

            if (activeStep >= 1) {
                setActiveStep(activeStep - 1);
            } else {
                onClose();
            }
        };

        document.addEventListener('backbutton', handleBackKeyDown, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [suggestedBagTag, setSuggestedBagTag] = useState(null);
    const [customerBagTag, setCustomerBagTag] = useState(null);
    const [schemeIDBagTag, setSchemeIDBagTag] = useState(null);

    useEffect(() => {
        // only do this for drivers
        if (
            _user.isDriver(operator) &&
            activeStep === 1 &&
            !_.isNil(pickupSelected.customer) //don't need this for out-of-system stops
        ) {
            (async function() {
                const res = await http.getJSON(`/bulks/nextAvailableBagtag/${operator._id}/${pickupSelected._id}`);
                if (res.ok) {
                    const newBagtag = _.get(res, 'data.bagtag', null);
                    if (!_.isEmpty(newBagtag)) {
                        setSuggestedBagTag(newBagtag);
                    }
                }
            })();
        }
        if (
            activeStep === 1 &&
            !_.isNil(pickupSelected.customer) //don't need this for out-of-system stops
        ) {
            if (_.get(pickupSelected, 'customer.isSchemeIDUser', false)) {
                getAvailableBagTag(schemeIDBagTag);
            } else {
                getAvailableBagTag(customerBagTag);
            }
        }
    }, [activeStep, operator]);

    const createInitialPayload = () => {
        // const feeSubPayloads = _commodity.generateSubPayloadsForFees(pickupSelected, commoditiesAvailable);

        // if (!_.isEmpty(feeSubPayloads)) {
        //     // Force subpayloads to fee payloads if applicable
        //     setPayload(pickupHelper.createEmptyPayload());
        //     setSubPayloads(feeSubPayloads);
        //     return;
        // }

        const payload = _.get(pickupSelected, 'payload', {});
        const subPayloads = _.get(pickupSelected, 'subPayloads', []);

        const updatedPayload = _.cloneDeep(payload);
        const updatedSubPayloads = _.cloneDeep(subPayloads);

        _commodity.populateSubPayloadsSubCommodities(updatedSubPayloads, commoditiesAvailable);
        _commodity.removeSubPayloadsFromPayload(updatedPayload, updatedSubPayloads);

        setPayload(updatedPayload);
        setSubPayloads(updatedSubPayloads);
    };

    useEffect(() => {
        createInitialPayload();
    }, [pickupSelected._id, commoditiesAvailable]);

    useEffect(() => {
        setActiveStep(0);
        setImagePreviews(pickupHelper.getPickupImageURL(pickupSelected));
        setBagtag('');

        if (_.get(pickupSelected, 'customer.isSchemeIDUser', false)) {
            setSchemeIDBagTag(_.get(pickupSelected, 'customer.schemeID', 'N/A'));
        } else if (_.get(pickupSelected, 'customer.uniqueID')) {
            setCustomerBagTag(pickupSelected.customer.uniqueID);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupSelected._id]);

    useEffect(() => {
        const updatedPayload = _.cloneDeep(payload);
        _commodity.addSubPayloadsToPayload(updatedPayload, subPayloads);

        setCumulativePayload(updatedPayload);
    }, [payload, subPayloads]);

    if (_.isNil(pickupSelected) || _.isEmpty(pickupSelected)) {
        return null;
    }

    const getAvailableBagTag = async (bagtag, padding = 1) => {
        const newBagTag = `${bagtag}${padding > 1 ? '-' + padding : ''}`;
        const paddedBagtag = padBagtag(newBagTag);
        const res = await http.getJSON(
            '/bulks/getBagStatusByBagtag/' +
                paddedBagtag +
                '/' +
                pickupSelected.customer._id +
                '/' +
                pickupSelected.collector._id
        );
        if (res.ok) {
            if (res.data.inuse) {
                getAvailableBagTag(bagtag, padding + 1);
            } else {
                if (_.get(pickupSelected, 'customer.isSchemeIDUser', false)) {
                    setSchemeIDBagTag(newBagTag);
                } else {
                    setCustomerBagTag(newBagTag);
                }
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleVerifyBagTag = async () => {
        setInProgress(true);
        const paddedBagtag = padBagtag(bagtag);
        const res = await http.getJSON(
            '/bulks/getBagStatusByBagtag/' +
                paddedBagtag +
                '/' +
                pickupSelected.customer._id +
                '/' +
                pickupSelected.collector._id
        );
        if (res.data.inuse) {
            setInvalidBagtagDialogOpen(true);
            setInvalidBagtagDialogMessage(res.data.reason);

            return setInProgress(false);
        }

        setInProgress(false);
        setActiveStep(activeStep + 1);
    };

    const handleBagtag = newBagtag => {
        setBagtag(newBagtag.toUpperCase());
        if (newBagtag.length > 24) {
            setBagtagInputError('Bagtag too long');
        } else {
            setBagtagInputError(null);
        }
    };

    const handleNumberOfBagsChange = e => {
        const path = e.target.name;
        const value = e.target.value;

        const updatedPayload = _.cloneDeep(payload);
        setPayload(_.set(updatedPayload, path, value));
    };

    const handleSubPayloadsChange = e => {
        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSubPayloads = _.cloneDeep(subPayloads);

        const commodity = _commodity.getCommodityBySubCommodityId(commoditiesAvailable, path);
        const subCommodity = _commodity.getSubCommodityById(commoditiesAvailable, path);

        let subPayload = _.find(updatedSubPayloads, subPayload => {
            const id = _.get(subPayload, 'subCommodity._id');
            return id === path;
        });

        if (!commodity || !subCommodity) return;

        if (subPayload) {
            subPayload.amount = value;
        } else {
            updatedSubPayloads.push({
                skuType: commodity.skuType,
                amount: value,
                subCommodity: subCommodity
            });
        }

        setSubPayloads(updatedSubPayloads);
    };

    const handleUseSchemeIDAsBagtag = () => {
        setBagtag(schemeIDBagTag);
    };

    const handleUseCustomerIDAsBagtag = () => {
        setBagtag(customerBagTag);
    };

    const acceptGPSUpdate = () => {
        setUpdateGPS(true);
        setUpdateGPSDialogOpen(false);
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            if (value === 'D6') {
                setUpdateGPSDialogOpen(true);
            }
            newChecked.push(value);
        } else {
            if (value === 'D6') {
                setUpdateGPS(false);
            }
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleIssueChange = e => {
        setIssueDescription(e.target.value);
    };

    const handleSubmit = async () => {
        setInProgress(true);
        const paddedBagtag = padBagtag(bagtag);

        const formData = new FormData();

        formData.append(
            'form',
            JSON.stringify({
                pickup_id: pickupSelected._id,
                bagtags: [paddedBagtag],
                receiverLocation: currentLocation,
                filterDate,
                payload: cumulativePayload,
                subPayloads,
                user: operator,
                driverIssues: checked,
                driverIssueDescription: issueDescription,
                updateGPS,
                images: imagePreviews
            })
        );

        const pickupRes = await http.post('/pickups/completePickup', formData, false, true);

        if (pickupRes.ok) {
            setChecked([]);
            setIssueDescription('');
            setUpdateGPS(false);
            setAfterCompleteDialogOpen(true);
            onMode('SELECT');
            onSnackbar('Pickup completed. Updating trip...');
            setSuggestedBagTag(null);
            setShowLastImageButton(false);
            fetchTrips();

            if (!admin) handleCenterMapOnPickups();

            if (_user.isDriver(operator)) getNextPickup(pickupSelected._id);

            onClose();

            if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(true);

            socket.emit(
                'pickup-complete',
                {
                    filterDate,
                    timezone: filterDate && filterDate.tz() ? filterDate.tz() : moment.tz.guess()
                },
                success => {
                    if (success) {
                        onSnackbar('Trip Update complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }

                    if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(false);
                }
            );

            if (gpsNotWorking && _user.isDriver(operator)) {
                onManuallySetLocation(pickupSelected.location);
            }
        } else {
            onSnackbar('Unable to complete pickup, please try again.', 'error');
            setInProgress(false);
        }
    };

    const handleStopSubmit = async () => {
        setInProgress(true);

        const formData = new FormData();

        formData.append(
            'form',
            JSON.stringify({
                outOfSystemStop: true,
                pickup_id: pickupSelected._id,
                receiverLocation: currentLocation,
                payload: cumulativePayload,
                subPayloads,
                driverNote,
                filterDate,
                user: operator,
                driverIssues: [],
                driverIssueDescription: '',
                images: imagePreviews
            })
        );

        const pickupRes = await http.post('/pickups/completePickup', formData, false, true);

        if (pickupRes.ok) {
            onMode('SELECT');
            onSnackbar('Pickup completed. Recalculating trip...');
            if (!admin) handleCenterMapOnPickups();
            onClose();

            socket.emit(
                'pickup-complete',
                {
                    filterDate,
                    timezone: filterDate && filterDate.tz() ? filterDate.tz() : moment.tz.guess()
                },
                success => {
                    if (success) {
                        onSnackbar('Trip recalculation complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                }
            );
        } else {
            onSnackbar('Unable to complete stop, please try again.');
            setInProgress(false);
        }
    };

    let steps = [];
    let stepTitles = [];

    if (_.isNil(pickupSelected.customer)) {
        steps = [
            <CameraDialog
                theme={theme}
                imagePreviews={imagePreviews}
                inProgress={inProgress}
                uploadingImage={uploadingImage}
                onNext={handleNext}
                handleUploadImage={handleAddImageToPickup}
                handleDeleteImage={handleRemoveImageFromPickup}
                onSnackbar={onSnackbar}
                disableBack={true}
                completePickupImageTitle={completePickupImageTitle}
                completePickupImageInstructions={completePickupImageInstructions}
                commoditiesAvailable={commoditiesAvailable}
                payload={payload}
                admin={admin}
                pickupSelected={pickupSelected}
            />,
            <CompleteStopDialog
                theme={theme}
                driverNote={driverNote}
                setDriverNote={setDriverNote}
                inProgress={inProgress}
                onSubmit={handleStopSubmit}
                lang={lang}
            />
        ];

        stepTitles = ['Capture Stop', formatAsAddress(pickupSelected.location)];
    } else {
        steps = [
            /*<CameraDialog
                theme={theme}
                imagePreviews={imagePreviews}
                inProgress={inProgress}
                uploadingImage={uploadingImage}
                onClose={onClose}
                onBack={handleBack}
                onNext={handleNext}
                handleUploadImage={handleAddImageToPickup}
                handleDeleteImage={handleRemoveImageFromPickup}
                onSnackbar={onSnackbar}
                completePickupImageTitle={completePickupImageTitle}
                completePickupImageInstructions={completePickupImageInstructions}
                commoditiesAvailable={commoditiesAvailable}
                payload={cumulativePayload}
                admin={admin}
            />,*/
            <ConfirmDialog
                theme={theme}
                pickupSelected={pickupSelected}
                payload={payload}
                cumulativePayload={cumulativePayload}
                subPayloads={subPayloads}
                inProgress={inProgress}
                onClose={onClose}
                commoditiesAvailable={commoditiesAvailable}
                onNext={handleNext}
                onNumberOfBagsChange={handleNumberOfBagsChange}
                onSubPayloads={handleSubPayloadsChange}
                imagePreviews={imagePreviews}
                uploadingImage={uploadingImage}
                handleAddImageToPickup={handleAddImageToPickup}
                handleRemoveImageFromPickup={handleRemoveImageFromPickup}
                showLastImageButton={showLastImageButton}
                completePickupImageTitle={completePickupImageTitle}
                completePickupImageInstructions={completePickupImageInstructions}
                lastImageBlob={lastImageBlob}
                lastImagePreview={lastImagePreview}
                admin={admin}
            />,
            <BagTagDialog
                theme={theme}
                http={http}
                customerBagTag={customerBagTag}
                suggestedBagTag={suggestedBagTag}
                pickupSelected={pickupSelected}
                bagtag={bagtag}
                operator={operator}
                inProgress={inProgress}
                setBagtag={handleBagtag}
                bagtagInputError={bagtagInputError}
                onBack={handleBack}
                onUseCustomerIDAsBagtag={handleUseCustomerIDAsBagtag}
                UseSchemeIDAsBagtag={handleUseSchemeIDAsBagtag}
                onUseSuggestedBagtag={tag => handleBagtag(tag)}
                onNext={handleVerifyBagTag}
                schemeIDBagTag={schemeIDBagTag}
            />,
            <CompleteDialog
                theme={theme}
                inProgress={inProgress}
                pickupSelected={pickupSelected}
                onBack={handleBack}
                onSubmit={handleSubmit}
                checked={checked}
                issueDescription={issueDescription}
                handleToggle={handleToggle}
                handleIssueChange={handleIssueChange}
                complaintOptions={complaintOptions}
            />
        ];

        stepTitles = [loc('confirmBags', lang), loc('recordBagtag', lang), loc('completePickup', lang)];
    }
    return (
        <>
            <Dialog data-cy="multi-step-dialog" fullScreen={fullScreen} fullWidth open={open}>
                <DialogTitlePrimary closeButtonShown onClose={inProgress ? () => undefined : onClose}>
                    {inProgress ? 'In progress...' : stepTitles[activeStep]}
                </DialogTitlePrimary>
                {steps.length > 1 && (
                    <MobileStepper
                        position="static"
                        variant="dots"
                        steps={steps.length}
                        activeStep={activeStep}
                        nextButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        backButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 0 }}
                    />
                )}
                {inProgress ? (
                    <DialogContent>
                        <InProgress />
                    </DialogContent>
                ) : (
                    steps[activeStep]
                )}
            </Dialog>

            <InvalidBagtagDialog
                open={invalidBagtagDialogOpen}
                message={invalidBagtagDialogMessage}
                onClose={() => setInvalidBagtagDialogOpen(false)}
            />
            <UpdateGPSDialog
                open={updateGPSDialogOpen}
                onAccept={acceptGPSUpdate}
                onClose={() => setUpdateGPSDialogOpen(false)}
            />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(MultistepPickupDialog));

function ConfirmDialog(props) {
    const {
        theme,
        pickupSelected,
        payload,
        cumulativePayload,
        subPayloads,
        inProgress,
        onNumberOfBagsChange,
        onSubPayloads,
        onClose,
        imagePreviews,
        uploadingImage,
        handleAddImageToPickup,
        handleRemoveImageFromPickup,
        showLastImageButton,
        completePickupImageTitle,
        completePickupImageInstructions,
        commoditiesAvailable,
        lastImageBlob,
        lastImagePreview,
        admin
    } = props;

    const { lang } = useContext(LocalizationContext);
    const [showImageCapture, setShowImageCapture] = useState(false);
    const [displayImageIdx, setDisplayImageIdx] = useState(-1);
    const [commoditySelected, setCommoditySelected] = useState(null);

    const filteredImages = useMemo(() => {
        return _.filter(imagePreviews, url => {
            const lastSlashIndex = url.lastIndexOf('/');
            const fileName = url.substring(lastSlashIndex, url.length);

            const commodityName = _.get(commoditySelected, 'skuType', _.get(commoditySelected, '_id', ''));
            const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

            if (fileName.includes(commodityNameFormatted)) return true;
            return false;
        });
    }, [imagePreviews, commoditySelected]);

    const allRequiredImagesUploaded = useMemo(() => {
        let requiredImageMissing = false;
        for (const skuType of Object.keys(payload)) {
            const amount = _.get(payload, skuType, 0);
            const commodity = _.find(commoditiesAvailable, { skuType });
            const payloadAccepted = _.get(pickupSelected, 'zone.payloadAccepted');
            if (
                !_.get(commodity, 'imageRequired', false) ||
                !payloadAccepted.map(acceptedCommodity => acceptedCommodity.skuType).includes(skuType)
            ) {
                continue;
            }
            if (amount > 0) {
                const commodityHasImage = _.some(imagePreviews, url => {
                    const lastSlashIndex = url.lastIndexOf('/');
                    const fileName = url.substring(lastSlashIndex, url.length);

                    const commodityName = _.get(commodity, 'skuType', _.get(commodity, '_id', ''));
                    const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

                    if (fileName.includes(commodityNameFormatted)) return true;
                    return false;
                });

                if (!commodityHasImage) {
                    requiredImageMissing = true;
                    break;
                }
            }
            for (const subPayload of _.filter(subPayloads, subPayload => subPayload.skuType === skuType)) {
                const subCommodity = _.find(
                    _.get(commodity, 'subCommodities', []),
                    sc =>
                        sc._id.toString() ===
                        _.get(subPayload, 'subCommodity._id', _.get(subPayload, 'subCommodity', '')).toString()
                );
                if (subCommodity && subPayload.amount > 0) {
                    const subCommodityHasImage = _.some(imagePreviews, url => {
                        const lastSlashIndex = url.lastIndexOf('/');
                        const fileName = url.substring(lastSlashIndex, url.length);

                        const commodityName = _.get(subCommodity, 'skuType', _.get(subCommodity, '_id', ''));
                        const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

                        if (fileName.includes(commodityNameFormatted)) return true;
                        return false;
                    });

                    if (!subCommodityHasImage) {
                        requiredImageMissing = true;
                        break;
                    }
                }
            }
            if (requiredImageMissing) {
                break;
            }
        }

        return !requiredImageMissing;
    }, [payload, imagePreviews, commoditiesAvailable, subPayloads]);

    const imageIcons = useMemo(() => {
        const icons = [];

        _.forEach(filteredImages, url => {
            icons.push({
                name: _.get(commoditySelected, 'iconName', ''),
                color: _.get(commoditySelected, 'color')
            });
        });

        return icons;
    }, [filteredImages, commoditySelected]);

    const handleUploadImageCommodity = image => {
        const commodityName = _.get(commoditySelected, 'skuType', _.get(commoditySelected, '_id', ''));
        const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

        handleAddImageToPickup(image, { urlPrefix: commodityNameFormatted });
    };

    const handleRemoveFilteredImages = idx => {
        const imageUrl = filteredImages[idx];
        const idxForAllImagePreviews = _.findIndex(imagePreviews, url => url === imageUrl);
        if (idxForAllImagePreviews >= 0) {
            handleRemoveImageFromPickup(idxForAllImagePreviews);
        }
    };

    const clothingBags = _.get(payload, 'clothing', 0);

    const clothingBagsWarning = clothingBags <= 0 && pickupSelected.expectedNumberOfClothingBags > 0 ? true : false;

    return (
        <>
            {showImageCapture ? (
                <DialogContent>
                    <ImageCapture
                        imageURLs={filteredImages}
                        imageIcons={imageIcons}
                        handleAddImage={handleUploadImageCommodity}
                        handleDeleteImage={handleRemoveFilteredImages}
                        uploadingImage={uploadingImage}
                        placeholderText={
                            uploadingImage ? (
                                <CircularProgress size={44} color="primary" />
                            ) : (
                                <span>
                                    <div
                                        style={{
                                            marginBottom: theme.spacing.unit * 2
                                        }}
                                    >
                                        <Typography variant="h6" textAlign="center">
                                            {loc('take', lang)}{' '}
                                            {_.startCase(_commodity.getPayloadInputName(commoditySelected))}{' '}
                                            {loc('picture', lang)}{' '}
                                        </Typography>
                                    </div>

                                    {completePickupImageTitle
                                        ? mustache.render(completePickupImageTitle, {
                                              commodityUnit: _commodity.getUnit(commoditySelected)
                                          })
                                        : 'Upload or take a photo of the bags for pickup verification.'}
                                    <br />
                                    {completePickupImageInstructions
                                        ? completePickupImageInstructions.map(item => <li>{item}</li>)
                                        : 'This will be displayed to the customer.'}
                                </span>
                            )
                        }
                        onSnackbar={props.onSnackbar}
                        displayImageIdx={displayImageIdx}
                        onDisplayImageIdxChange={value => setDisplayImageIdx(value)}
                        showLastImageButton={showLastImageButton}
                        lastImageBlob={lastImageBlob}
                        lastImagePreview={lastImagePreview}
                        autoDisplayLastImage
                    />
                </DialogContent>
            ) : (
                <DialogContent>
                    <CustomFormTitle titleText={loc('confirmThePlayload', lang)} iconName="check" />
                    <PayloadInput
                        showPhotoInput
                        payload={payload}
                        onPayload={onNumberOfBagsChange}
                        subPayloads={subPayloads}
                        onSubPayloads={onSubPayloads}
                        payloadAccepted={_.get(pickupSelected, 'zone.payloadAccepted')}
                        payloadRequired={_.get(pickupSelected, 'zone.payloadRequired')}
                        imagePreviews={imagePreviews}
                        imageIcons={imageIcons}
                        onPhotoButton={commodity => {
                            setCommoditySelected(commodity);
                            setShowImageCapture(true);
                        }}
                        hideHelpButtons
                        driverView={true}
                    />
                    {clothingBagsWarning && (
                        <Typography variant="caption" style={{ color: colors.yellow[800] }}>
                            ATTENTION! This pickup request originally included clothing. Proceeding will confirm that
                            you did not collect any clothing.
                        </Typography>
                    )}
                </DialogContent>
            )}
            {showImageCapture ? (
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setShowImageCapture(false)}
                        data-cy="payload-input-back-button"
                    >
                        {loc('next', lang)}
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                    <Button
                        color="primary"
                        disabled={
                            inProgress ||
                            pickupHelper.payloadIsEmpty(cumulativePayload) ||
                            !payloadIsValid(cumulativePayload) ||
                            (!allRequiredImagesUploaded && !admin)
                        }
                        onClick={props.onNext}
                        data-cy="0-multistep-pickup-dialog-next-button"
                    >
                        {loc('next', lang)}
                    </Button>
                </DialogActions>
            )}
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
    function payloadIsValid(payload) {
        for (let key in payload) {
            if (payload[key] < 0) return false;
        }
        return true;
    }
}

function CameraDialog(props) {
    const {
        theme,
        imagePreviews,
        inProgress,
        disableBack = false,
        uploadingImage,
        completePickupImageTitle,
        completePickupImageInstructions,
        handleUploadImage,
        handleDeleteImage,
        commoditiesAvailable,
        payload,
        admin,
        onClose,
        pickupSelected
    } = props;
    const isOTCPickup = !_.isNil(_.get(pickupSelected, 'externalLocation'));

    const [pickupCommodities, setPickupCommodities] = useState([]);
    const [commoditySelected, setCommoditySelected] = useState({});
    const [displayImageIdx, setDisplayImageIdx] = useState(-1);

    const { lang } = useContext(LocalizationContext);

    const commoditiySelectedIndex = _.findIndex(pickupCommodities, c => c === commoditySelected);

    const filteredImages = _.filter(imagePreviews, url => {
        const lastSlashIndex = url.lastIndexOf('/');
        const fileName = url.substring(lastSlashIndex, url.length);

        const commodityName = _.get(commoditySelected, 'skuType', _.get(commoditySelected, '_id', ''));
        const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

        if (fileName.includes(commodityNameFormatted)) return true;
        return false;
    });

    const imageIcons = useMemo(() => {
        const icons = [];

        _.forEach(imagePreviews, url => {
            const lastSlashIndex = url.lastIndexOf('/');
            const fileName = url.substring(lastSlashIndex, url.length);

            const commodityForFile = _.find(pickupCommodities, commodity => {
                const commodityName = _.get(commodity, 'skuType', _.get(commodity, '_id', ''));
                const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

                if (fileName.includes(commodityNameFormatted)) return true;
                return false;
            });

            if (commodityForFile) {
                icons.push({
                    name: _.get(commodityForFile, 'iconName', ''),
                    color: _.get(commodityForFile, 'color')
                });
            } else {
                icons.push(null);
            }
        });

        return icons;
    }, [imagePreviews, pickupCommodities]);

    const handleUploadImageCommodity = image => {
        const commodityName = _.get(commoditySelected, 'skuType', _.get(commoditySelected, '_id', ''));
        const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

        handleUploadImage(image, { urlPrefix: commodityNameFormatted });

        const imageIndex = _.findIndex(pickupCommodities, c => c === commoditySelected);

        if (imageIndex < pickupCommodities.length - 1) {
            setCommoditySelected(pickupCommodities[imageIndex + 1]);
        }
    };

    const handleNext = () => {
        if (admin) {
            props.onNext();
        }
        const imageIndex = _.findIndex(pickupCommodities, c => c === commoditySelected);

        if (imageIndex < pickupCommodities.length - 1) {
            setCommoditySelected(pickupCommodities[imageIndex + 1]);
            return;
        }

        // Goes back to a commodity if an image hasn't been uploaded for it yet
        for (let i = 0; i < pickupCommodities.length; i++) {
            let pickupCommodity = pickupCommodities[i];
            let skuType = pickupCommodity.skuType;

            let foundImageForSku = false;
            for (let url of imagePreviews) {
                const lastSlashIndex = url.lastIndexOf('/');
                const fileName = url.substring(lastSlashIndex, url.length);

                const commodityNameFormatted = skuType.toLowerCase().replace(/\s/g, '');

                if (fileName.includes(commodityNameFormatted)) {
                    foundImageForSku = true;
                }
            }

            if (!foundImageForSku) {
                setCommoditySelected(pickupCommodities[i]);
                return;
            }
        }

        props.onNext();
    };

    const handleDelete = idx => {
        // Get index according to filtered images and update it
        const imageToDelete = imagePreviews[idx];
        const indexToDelete = _.findIndex(imagePreviews, img => img === imageToDelete);

        handleDeleteImage(indexToDelete);
    };

    const handleCommoditySelected = commodity => {
        setCommoditySelected(commodity);
        setDisplayImageIdx(-1);
    };

    useEffect(() => {
        const commoditiesForPickup = _.filter(commoditiesAvailable, commodity => {
            if (commodity.imageRequired === false) return false;
            return payload[commodity.skuType] > 0;
        });

        setPickupCommodities(commoditiesForPickup);
        setCommoditySelected(_.first(commoditiesForPickup));
    }, []);

    return (
        <>
            <DialogContent>
                <ImageCapture
                    imageURLs={imagePreviews}
                    imageIcons={imageIcons}
                    handleAddImage={handleUploadImageCommodity}
                    handleDeleteImage={handleDelete}
                    uploadingImage={uploadingImage}
                    placeholderText={
                        uploadingImage ? (
                            <CircularProgress size={44} color="primary" />
                        ) : (
                            <span>
                                <div
                                    style={{
                                        marginBottom: theme.spacing.unit * 2
                                    }}
                                >
                                    <Typography variant="h6" textAlign="center">
                                        {loc('take', lang)}{' '}
                                        {_.startCase(_commodity.getPayloadInputName(commoditySelected))}{' '}
                                        {loc('picture', lang)}{' '}
                                    </Typography>
                                    <Typography variant="h6" textAlign="center">
                                        {isOTCPickup
                                            ? `${imagePreviews.length} of 1`
                                            : `${commoditiySelectedIndex + 1} of ${pickupCommodities.length}`}
                                    </Typography>
                                </div>

                                {completePickupImageTitle
                                    ? mustache.render(completePickupImageTitle, {
                                          commodityUnit: isOTCPickup
                                              ? 'recycling'
                                              : _commodity.getUnit(commoditySelected)
                                      })
                                    : 'Upload or take a photo of the bags for pickup verification.'}
                                <br />
                                {completePickupImageInstructions
                                    ? completePickupImageInstructions.map(item => <li>{item}</li>)
                                    : 'This will be displayed to the customer.'}
                            </span>
                        )
                    }
                    onSnackbar={props.onSnackbar}
                    altContentA={
                        <div
                            style={{
                                width: '100%',
                                maxWidth: '420px',
                                display: 'flex',
                                //flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                        >
                            {pickupCommodities.map((commodity, idx) => {
                                const skuType = commodity.skuType;
                                const commodityIcon = _.get(commodity, 'iconName');
                                const checked = commodity === commoditySelected;

                                let foundImageForSku = false;
                                for (let url of imagePreviews) {
                                    const lastSlashIndex = url.lastIndexOf('/');
                                    const fileName = url.substring(lastSlashIndex, url.length);

                                    const commodityName = _.get(commodity, 'skuType', _.get(commodity, '_id', ''));
                                    const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

                                    if (fileName.includes(commodityNameFormatted)) {
                                        foundImageForSku = true;
                                    }
                                }

                                return (
                                    <Paper
                                        elevation={1}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginLeft: idx !== 0 && theme.spacing.unit / 2,
                                            padding: theme.spacing.unit / 2,
                                            cursor: 'pointer',
                                            backgroundColor: checked && _.get(commodity, 'color')
                                        }}
                                        onClick={() => handleCommoditySelected(commodity)}
                                        key={`${skuType}-image-upload-${idx}`}
                                    >
                                        <MDIcon
                                            path={_.get(allIcons, commodityIcon)}
                                            size={1}
                                            color={checked ? 'white' : _.get(commodity, 'color')}
                                        />
                                        <Typography style={{ color: checked ? 'white' : _.get(commodity, 'color') }}>
                                            {_commodity.getPayloadInputName(commodity)}
                                        </Typography>
                                        {foundImageForSku && (
                                            <Icon style={{ color: 'green', marginRight: theme.spacing.unit / 2 }}>
                                                check
                                            </Icon>
                                        )}
                                        {!foundImageForSku && (
                                            <Icon style={{ color: 'red', marginRight: theme.spacing.unit / 2 }}>
                                                clear
                                            </Icon>
                                        )}
                                    </Paper>
                                );
                            })}
                        </div>
                    }
                    displayImageIdx={displayImageIdx}
                    onDisplayImageIdxChange={value => setDisplayImageIdx(value)}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={inProgress} color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    disabled={
                        inProgress ||
                        uploadingImage ||
                        (_.isEmpty(filteredImages) &&
                            !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE) &&
                            !admin)
                    }
                    onClick={() => handleNext()}
                    data-cy="1-multistep-pickup-dialog-next-button"
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}

function BagTagDialog(props) {
    const {
        theme,
        pickupSelected,
        bagtag,
        inProgress,
        setBagtag,
        bagtagInputError,
        customerBagTag,
        suggestedBagTag,
        onBack,
        onUseCustomerIDAsBagtag,
        onUseSuggestedBagtag,
        onNext,
        schemeIDBagTag,
        UseSchemeIDAsBagtag
    } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <DialogContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}
            >
                <CustomFormTitle titleText={loc('recordBagIdentifier', lang)} iconName="check" />
                {!_.isEmpty(pickupSelected) && (
                    <React.Fragment>
                        <Typography variant="subtitle1" color="textSecondary">
                            {formatAsAddress(pickupSelected.location)}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{ marginBottom: theme.spacing.unit * 3, color: theme.palette.text.hint }}
                        >
                            {_.get(pickupSelected, 'customer.isSchemeIDUser', false) ? (
                                <>
                                    SchemeID:{' '}
                                    <span data-cy="multistep-pickup-dialog-schemeID">
                                        {_.get(pickupSelected, 'customer.schemeID', 'N/A')}
                                    </span>
                                </>
                            ) : (
                                <>
                                    {loc('customer', lang)} ID:{' '}
                                    <span data-cy="multistep-pickup-dialog-customerId">
                                        {pickupSelected.customer.uniqueID}
                                    </span>
                                </>
                            )}
                        </Typography>
                    </React.Fragment>
                )}
                <Typography variant="h3" id="scanned-bags-counter">
                    {_.isEmpty(bagtag) ? (
                        <span>
                            {_.get(pickupSelected, 'customer.isSchemeIDUser', false) ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={UseSchemeIDAsBagtag}
                                    data-cy="scanned-bags-counter-schemeID"
                                >
                                    {loc('apply', lang)} {schemeIDBagTag}
                                </Button>
                            ) : (
                                <>
                                    {!_.isNil(suggestedBagTag) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            style={{ marginRight: theme.spacing.unit }}
                                            onClick={() => onUseSuggestedBagtag(suggestedBagTag)}
                                            data-cy="multistep-pickup-dialog-suggested-bagtag-btn"
                                        >
                                            {loc('apply', lang)}{' '}
                                            <span
                                                style={{ marginLeft: theme.spacing.unit - 2 }} // for some reason the space is lost here so this was needed
                                                data-cy="multistep-pickup-dialog-suggested-bagtag"
                                            >
                                                {suggestedBagTag}
                                            </span>
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="large"
                                        onClick={onUseCustomerIDAsBagtag}
                                        data-cy="scanned-bags-counter"
                                    >
                                        {loc('apply', lang)} {customerBagTag}
                                    </Button>
                                </>
                            )}
                        </span>
                    ) : (
                        bagtag
                    )}
                </Typography>

                {!_.isEmpty(bagtag) && bagtag !== _.get(pickupSelected, 'customer.uniqueID', null) && (
                    <Typography data-cy="scanned-bags-counter" variant="subtitle1">
                        {loc('makeSureWriteOnBag', lang)}
                    </Typography>
                )}

                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="multistep-pickup-dialog-bagtag-input"
                        disabled={inProgress || _.get(pickupSelected, 'customer.isSchemeIDUser', false)}
                        variant="outlined"
                        name="Bagtag"
                        label={loc('bagtag', lang)}
                        value={bagtag}
                        error={!_.isNil(bagtagInputError)}
                        helperText={_.isNil(bagtagInputError) ? loc('bagTagMustNotBeUsed', lang) : bagtagInputError}
                        onChange={e => setBagtag(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={inProgress || _.get(pickupSelected, 'customer.isSchemeIDUser', false)}
                                        onClick={() => setBagtag('')}
                                    >
                                        <Icon>backspace</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="2-multistep-pickup-dialog-next-button"
                    disabled={inProgress || _.isEmpty(bagtag) || !_.isNil(bagtagInputError)}
                    color="primary"
                    onClick={onNext}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}

function CompleteDialog(props) {
    const {
        theme,
        checked,
        issueDescription,
        inProgress,
        onBack,
        onSubmit,
        handleToggle,
        handleIssueChange,
        complaintOptions,
        pickupSelected
    } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('anyIssuesWithBags', lang)} iconName="warning" />
                <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 3 }}>
                    <List>
                        {complaintOptions.map((issue, idx) => {
                            const hasClothingBin = _.get(pickupSelected, 'hasClothingBin', false);
                            const isClothingBinComplaint = _.get(issue, 'clothingBinIssue', false);

                            if (hasClothingBin != isClothingBinComplaint) return <></>;

                            const labelId = `checkbox-list-secondary-label-${idx}`;
                            return (
                                <>
                                    <ListItem
                                        data-cy={'pickup-issue-' + _.kebabCase(issue.label)} // TODO: change label to code for test
                                        key={issue.code}
                                        role={undefined}
                                        button
                                        onClick={handleToggle(issue.code)}
                                    >
                                        <ListItemText id={labelId} primary={issue.label} />
                                        <ListItemIcon>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    checked={checked.indexOf(issue.code) !== -1}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    disableRipple
                                                    tabIndex={-1}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider variant="fullWidth" component="li" />
                                </>
                            );
                        })}
                    </List>
                    <TextField
                        data-cy="multistep-pickup-dialog-issues-description"
                        name="comments"
                        label={loc('additionalDetails', lang)}
                        value={issueDescription}
                        variant="outlined"
                        onChange={handleIssueChange}
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                    {/*<Typography variant="caption" style={{ color: colors.grey[500], paddingTop: theme.spacing.unit }}>
                        Note: this message will be displayed to the customer.
                    </Typography>*/}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button disabled={inProgress} color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="multistep-pickup-dialog-complete-button"
                    disabled={inProgress}
                    color="primary"
                    onClick={onSubmit}
                >
                    {loc('complete', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}

function CompleteStopDialog(props) {
    const { theme, driverNote, setDriverNote, inProgress, onSubmit, lang } = props;

    return (
        <>
            <DialogContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: theme.spacing.unit * 3
                }}
            >
                <CustomFormTitle titleText={loc('additionalInformation', lang)} iconName="check" />
                <FormControl fullWidth>
                    <TextField
                        data-cy="oos-enter-additional-info"
                        disabled={inProgress}
                        variant="outlined"
                        label={loc('notes', lang)}
                        value={driverNote}
                        onChange={e => setDriverNote(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled={inProgress} onClick={() => setDriverNote('')}>
                                        <Icon>backspace</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    data-cy="oos-dialog-complete-button"
                    color="primary"
                    // disabled={_.isEmpty(driverNote) || inProgress}
                    disabled={inProgress}
                    onClick={onSubmit}
                >
                    {loc('complete', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}
