import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { formatAsPhoneNumber } from '../../utils/misc';

import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    TableHead,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Button,
    Checkbox,
    ListItemText
} from '@material-ui/core';

import { Icon, Tooltip, Input, InputAdornment, colors } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';

function CustomTable(props) {
    const {
        data,
        columns,
        defaultRowsPerPage,
        orderBy, // column to sort by
        order, // asc or desc order
        onOrderByChange,
        onOrderChange,
        renderActions,
        headerText,
        defaultColumnsToShow,
        theme,
        initialCollectorFilter = null,
        footerContent,
        collectorTypeFilter,
        showCollectorFilter = true,
        customFilters = null
    } = props;

    const [searchString, setSearchString] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [columnsToShow, setColumnsToShow] = useState(
        !_.isNil(defaultColumnsToShow) ? defaultColumnsToShow : columns.map(column => column.key)
    );
    const [collectorsSelected, setCollectorsSelected] = useState(() => {
        if (!_.isNil(initialCollectorFilter)) {
            return [initialCollectorFilter._id];
        } else {
            return data.reduce(
                (init, obj) =>
                    init.includes(_.get(obj, 'collector._id')) ? init : [...init, _.get(obj, 'collector._id')],
                []
            );
        }
    });
    const [collectorTypeSelected, setCollectorTypeSelected] = useState('All');

    const [page, setPage] = useState(0);

    useEffect(() => {
        if (!_.isNil(initialCollectorFilter)) {
            setCollectorsSelected([initialCollectorFilter._id]);
        } else {
            setCollectorsSelected(
                data.reduce(
                    (init, obj) =>
                        init.includes(_.get(obj, 'collector._id')) ? init : [...init, _.get(obj, 'collector._id')],
                    []
                )
            );
        }
    }, [data, initialCollectorFilter]);

    //https://v3.material-ui.com/demos/tables/
    const handleRequestSort = (event, property) => {
        const isDesc = _.get(orderBy, 'key', '') === property.key && order === 'desc';
        onOrderChange(isDesc ? 'asc' : 'desc');
        onOrderByChange(property);
    };
    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    const onPageChange = (event, newPage) => {
        setPage(newPage);
    };

    const onRowsPerPageChanged = rowsPerPage => {
        setRowsPerPage(rowsPerPage);
    };

    const handleSearch = event => {
        setSearchString(event.target.value);
    };

    const handleDepotSelected = event => {
        setCollectorsSelected(event.target.value);
    };
    const handleCollectorTypeSelected = event => {
        setCollectorTypeSelected(event.target.value);
    };

    const handleDepotAllFilter = all => event => {
        event.stopPropagation();
        if (all) {
            let initialDepotFilter = data.reduce(
                (init, obj) =>
                    init.includes(_.get(obj, 'collector._id')) ? init : [...init, _.get(obj, 'collector._id')],
                []
            );
            setCollectorsSelected(initialDepotFilter);
        } else {
            setCollectorsSelected([]);
        }
    };

    const splitSearchString = searchString.split(/[ ]+/);
    const splitSearchStringWithoutEmpty = _.filter(splitSearchString, string => string !== '');

    const documentContainsSearchValue = (document, searchValue) => {
        return _.some(columns, column => {
            const value = _.get(document, column.key, '');
            if (_.isNil(value)) return false;
            const formatedString = value
                .toString()
                .toLowerCase()
                .trim();
            return formatedString.includes(searchValue.toLowerCase().trim());
        });
    };
    //const searchFields = _.filter(columns, column => column.isSearchField);
    let filteredData = _.filter(data, document => {
        // filter by selected collector, if collector._id property exists
        let collectorId = _.get(document, 'collector._id', undefined);
        if (collectorId !== undefined) {
            if (!collectorsSelected.includes(collectorId)) {
                return false;
            }
        }

        // filter by search string
        for (let searchValue of splitSearchStringWithoutEmpty) {
            if (!documentContainsSearchValue(document, searchValue)) {
                return false;
            }
        }
        if (collectorTypeFilter && collectorTypeSelected) {
            if (collectorTypeSelected === 'All') {
                return true;
            } else if (collectorTypeSelected === 'Both') {
                return document.configuration.enablePickups && document.configuration.enableCounting;
            } else if (collectorTypeSelected === 'Transporter') {
                return document.configuration.enablePickups && !document.configuration.enableCounting;
            } else if (collectorTypeSelected === 'Processor') {
                return document.configuration.enableCounting && !document.configuration.enablePickups;
            }
            return false;
        }
        return true;
    });
    const columnInColumnsToShow = column => _.some(columnsToShow, columnToShow => columnToShow === column.key);

    filteredData = stableSort(filteredData, getSorting(order, orderBy));

    const inputStyle = {
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        minWidth: 100,
        maxWidth: 225
    };

    const showFooter = _.some(columns, column => column.displayTotal);
    // const collectors = _.uniqBy(data.map(obj => _.get(obj, 'collector')).filter(obj => obj !== undefined), '_id');
    const collectors = _.uniqBy(
        [...data.map(obj => _.get(obj, 'collector')), ...data.map(obj => _.get(obj, 'collectors')).flat()].filter(
            obj => obj !== undefined
        ),
        '_id'
    );
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    margin: theme.spacing.unit
                }}
            >
                <Typography variant="h6">{headerText}</Typography>
                <span>
                    {customFilters}
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="collection">Column Filters</InputLabel>
                        <Select
                            multiple
                            input={<OutlinedInput id="collection" name="collection" labelWidth={104} />}
                            value={columnsToShow}
                            onChange={e => setColumnsToShow(e.target.value) /*TODO: local storage?*/}
                            style={inputStyle}
                        >
                            {columns.map(column => (
                                <MenuItem key={column.key} value={column.key}>
                                    {column.header}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {!!collectors.length && showCollectorFilter && (
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="collection">Depot Filters</InputLabel>
                            <Select
                                multiple
                                onChange={handleDepotSelected}
                                style={{
                                    width: '250px',
                                    margin: theme.spacing.unit
                                }}
                                value={collectorsSelected}
                                renderValue={s => `${s.length} Collectors`}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button onClick={handleDepotAllFilter(true)}>All</Button>
                                    <Button onClick={handleDepotAllFilter(false)}>None</Button>
                                </div>
                                {collectors.map(
                                    collector =>
                                        collector && (
                                            <MenuItem key={collector._id} value={collector._id}>
                                                <Checkbox checked={collectorsSelected.includes(collector._id)} />
                                                <ListItemText>{collector.name}</ListItemText>
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                        </FormControl>
                    )}
                    {collectorTypeFilter && (
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="collection">Collector Type</InputLabel>
                            <Select
                                onChange={handleCollectorTypeSelected}
                                style={{
                                    width: '250px',
                                    margin: theme.spacing.unit
                                }}
                                value={collectorTypeSelected}
                                renderValue={s => `${s}`}
                            >
                                {['All', 'Transporter', 'Processor', 'Both'].map(name => (
                                    <MenuItem key={name} value={name}>
                                        <ListItemText>{name}</ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Input
                        data-cy="custom-table-search"
                        placeholder="Search"
                        variant="outlined"
                        onChange={handleSearch}
                        value={searchString}
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>search</Icon>
                            </InputAdornment>
                        }
                        style={{ margin: theme.spacing.unit }}
                    />
                </span>
            </div>
            <div style={{ overflow: 'auto', width: '100%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {!_.isNil(renderActions) && (
                                <TableCell
                                    style={{
                                        paddingLeft: theme.spacing.unit,
                                        paddingRight: theme.spacing.unit
                                    }}
                                >
                                    Actions
                                </TableCell>
                            )}
                            {columns.map(column => {
                                return (
                                    columnInColumnsToShow(column) && (
                                        <TableCell
                                            key={column.header}
                                            style={{
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: theme.spacing.unit
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: column.width,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={_.get(orderBy, 'key', '') === column.key}
                                                    direction={order}
                                                    onClick={createSortHandler(column)}
                                                >
                                                    {column.header}
                                                </TableSortLabel>
                                            </span>
                                        </TableCell>
                                    )
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ whiteSpace: 'nowrap' }}>
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((document, index) => (
                                <TableRow key={document._id}>
                                    {!_.isNil(renderActions) && <TableCell>{renderActions(document)}</TableCell>}
                                    {columns
                                        .filter(column => columnInColumnsToShow(column))
                                        .map(column => (
                                            <TableCell
                                                key={column.key}
                                                style={{
                                                    paddingLeft: theme.spacing.unit,
                                                    paddingRight: theme.spacing.unit
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: column.width,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {formatValue(_.get(document, column.key), column, document)}
                                                </div>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            ))}
                    </TableBody>
                    {showFooter && (
                        <TableFooter>
                            <TableRow style={{ height: theme.spacing.unit }}>
                                {columns.map(
                                    column =>
                                        columnInColumnsToShow(column) && (
                                            <TableCell
                                                key={column.header}
                                                align="left"
                                                style={{
                                                    paddingRight: theme.spacing.unit,
                                                    paddingLeft: theme.spacing.unit,
                                                    width: column.width,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {!_.isNil(column.displayTotal) && (
                                                    <Tooltip title="Total">
                                                        <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                                            {column.displayTotal(_.sumBy(data, column.key))}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', overflow: 'auto' }}>
                <div>{footerContent}</div>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 'All']}
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage === 'All' ? filteredData.length : rowsPerPage}
                    page={page}
                    onChangePage={onPageChange}
                    onChangeRowsPerPage={rowsPerPage => {
                        onRowsPerPageChanged(
                            rowsPerPage.target.value === 'All' ? filteredData.length : rowsPerPage.target.value
                        );
                    }}
                />
            </div>
        </>
    );
}

export default withTheme()(CustomTable);

function formatValue(value, column, document, locale) {
    const { isDate, isBoolean, isPhoneNumber } = column;
    if (!_.isNil(column.formatValue)) {
        return column.formatValue(value, document);
    } else if (isDate) {
        return _.isNil(value) ? '' : moment(value).format('DD/MM/YYYY HH:mm:ss');
    } else if (isBoolean) {
        return <Icon style={{ color: value ? colors.green[500] : colors.red[500] }}>{value ? 'check' : 'close'}</Icon>;
    } else if (isPhoneNumber) {
        return formatAsPhoneNumber(value);
    } else if (Array.isArray(value)) {
        if (value.every(item => typeof item === 'object' && item !== null)) {
            return value
                .map(item => _.get(item, 'name'))
                .filter(Boolean)
                .join('/');
        } else {
            return value.join('/');
        }
    } else {
        return value;
    }
}

//following three functions modified from the Sorting & Selecting example given on https://v3.material-ui.com/demos/tables/
function desc(a, b, orderBy) {
    if (_.isNil(orderBy)) return -1;
    let aOrderByVal = _.get(a, orderBy.key);
    let bOrderByVal = _.get(b, orderBy.key);

    if (!_.isNil(orderBy.toSortValue)) {
        aOrderByVal = orderBy.toSortValue(aOrderByVal);
        bOrderByVal = orderBy.toSortValue(bOrderByVal);
    }

    if (_.isNil(bOrderByVal)) {
        return -1;
    }
    if (_.isNil(aOrderByVal)) {
        return 1;
    }

    if (typeof aOrderByVal === 'string') {
        aOrderByVal = aOrderByVal.toLowerCase().trim();
    }
    if (typeof bOrderByVal === 'string') {
        bOrderByVal = bOrderByVal.toLowerCase().trim();
    }

    if (bOrderByVal < aOrderByVal) {
        return -1;
    }
    if (bOrderByVal > aOrderByVal) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
