import React, { useState } from 'react';

import _ from 'lodash';

import {
    TextField,
    withTheme,
    Card,
    CardHeader,
    CardContent,
    Collapse,
    IconButton,
    Icon,
    colors,
    FormControl,
    Button,
    Typography
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiTshirtCrew } from '@mdi/js';

import { getSwitch } from '../helperFunctions';
import { useContext } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { maxFileSize } from '../../../constants';

const imageFileTypes = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif'
];

function ObjectTextInput({
    path,
    togglePath,
    formik,
    headerText,
    obj = {},
    theme,
    type = 'default',
    onDeleteOption,
    disableEditing = false
}) {
    const [expanded, setExpanded] = useState(false);
    const [defaultImageFile, setDefaultImageFile] = useState(
        _.get(formik.getFieldProps(togglePath), 'value.defaultImage', null)
    );
    const [defaultImageError, setDefaultImageError] = useState(false);
    const warnAction = useContext(ConfirmDialogContext);
    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const emailToCustomer = _.get(formik.getFieldProps(togglePath), 'value.emailToCustomer', null);
    const appliedBySystem = _.get(formik.getFieldProps(togglePath), 'value.appliedBySystem', null);
    const sendToCustomer = _.get(formik.getFieldProps(togglePath), 'value.sendToCustomer', null);
    const driverIssue = type === 'counter' && _.get(formik.getFieldProps(togglePath), 'value.driverIssue', null);
    const clothingBinIssue =
        type === 'driver' && _.get(formik.getFieldProps(togglePath), 'value.clothingBinIssue', null);
    const defaultImageURL = _.get(formik.getFieldProps(togglePath), 'value.defaultImageURL', '');

    // text field only
    const objKeys = Object.keys(obj);
    const hasError = objKeys.some(
        key => _.get(formik.touched, `${path}.${key}`, false) && _.get(formik.errors, `${path}.${key}`, false)
    );

    const handleDefaultImageFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            formik.setFieldValue(`${togglePath}.defaultImage`, e.target.files[0]);
            setDefaultImageFile(img);
        } else {
            // onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };
    return (
        <Card
            style={{
                marginTop: theme.spacing.unit * 2,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)'
            }}
        >
            <CardHeader
                titleTypographyProps={{ variant: 'body2' }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {driverIssue && <Icon style={{ marginRight: theme.spacing.unit }}>local_shipping</Icon>}
                        {clothingBinIssue && (
                            <MDIcon
                                path={mdiTshirtCrew}
                                size={0.9}
                                style={{ marginRight: theme.spacing.unit }}
                                color={theme.palette.text.primary}
                            />
                        )}
                        {`${_.get(formik.values, `${path}.label`, headerText)}${
                            obj.code ? ' - ' + _.get(formik.values, `${path}.code`, obj.code) : ''
                        }`}
                    </div>
                }
                action={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {hasError && <Icon style={{ color: colors.red[500] }}>error</Icon>}
                        <IconButton
                            disabled={disableEditing}
                            onClick={() =>
                                warnAction(
                                    () => onDeleteOption(),
                                    'Are you sure you want to remove this option from the system?'
                                )
                            }
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                        <IconButton onClick={handleExpand}>
                            <Icon>keyboard_arrow_down</Icon>
                        </IconButton>
                    </span>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    {Object.keys(obj).map((objKey, idx) => {
                        // if contain array of strings, create textfield for each element
                        if (Array.isArray(obj[objKey])) {
                            return obj[objKey].map((item, childIdx) => (
                                <div style={{ marginTop: theme.spacing.unit }} key={`${objKey} - ${idx} -${childIdx}`}>
                                    <TextField
                                        {...formik.getFieldProps(`${path}.${objKey}[${childIdx}]`)}
                                        error={
                                            _.get(formik.touched, `${path}.${objKey}[${childIdx}]`, false) &&
                                            _.get(formik.errors, `${path}.${objKey}[${childIdx}]`, false)
                                                ? true
                                                : null
                                        }
                                        InputLabelProps={{
                                            shrink: !_.isEmpty(
                                                formik.getFieldProps(`${path}.${objKey}[${childIdx}]`).value
                                            )
                                        }}
                                        label={`${objKey} - field ${childIdx}`}
                                        style={{ marginTop: theme.spacing.unit }}
                                        multiline
                                        variant="outlined"
                                        helperText={
                                            _.get(formik.touched, `${path}.${objKey}[${childIdx}]`, false) &&
                                            _.get(formik.errors, `${path}.${objKey}[${childIdx}]`, false)
                                                ? _.get(formik.errors, `${path}.${objKey}[${childIdx}]`, false)
                                                : null
                                        }
                                        fullWidth
                                        disabled={disableEditing}
                                    />
                                </div>
                            ));
                        } else {
                            return (
                                <div style={{ marginTop: theme.spacing.unit }} key={`${objKey} - ${idx}`}>
                                    {!['code', 'emailToCustomer', 'driverIssue', 'defaultImageURL'].includes(
                                        objKey
                                    ) && (
                                        <TextField
                                            {...formik.getFieldProps(`${path}.${objKey}`)}
                                            error={
                                                _.get(formik.touched, `${path}.${objKey}`, false) &&
                                                _.get(formik.errors, `${path}.${objKey}`, false)
                                                    ? true
                                                    : null
                                            }
                                            InputLabelProps={{
                                                shrink: !_.isEmpty(formik.getFieldProps(`${path}.${objKey}`).value)
                                            }}
                                            label={objKey}
                                            style={{ marginTop: theme.spacing.unit }}
                                            multiline
                                            variant="outlined"
                                            helperText={
                                                _.get(formik.touched, `${path}.${objKey}`, false) &&
                                                _.get(formik.errors, `${path}.${objKey}`, false)
                                                    ? _.get(formik.errors, `${path}.${objKey}`, false)
                                                    : null
                                            }
                                            fullWidth
                                            disabled={disableEditing}
                                        />
                                    )}
                                </div>
                            );
                        }
                    })}
                    {expanded &&
                        type === 'counter' &&
                        (_.isNil(defaultImageFile) && _.isEmpty(defaultImageURL) ? (
                            <FormControl fullWidth data-cy="upload-default-image-input">
                                <input
                                    accept={'image/*'}
                                    style={{ display: 'none' }}
                                    id={`raised-button-file-default-image-${obj.code}`}
                                    multiple={false}
                                    type="file"
                                    onChange={handleDefaultImageFile}
                                />
                                <label htmlFor={`raised-button-file-default-image-${obj.code}`}>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="span"
                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                    >
                                        Upload Image
                                    </Button>
                                </label>
                            </FormControl>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                {!_.isNil(defaultImageFile) ? (
                                    <img
                                        src={URL.createObjectURL(defaultImageFile)}
                                        style={{ maxWidth: '100%' }}
                                        alt="Drop Location Marker"
                                    />
                                ) : !defaultImageError && imageFileTypes.includes(defaultImageURL.split('.').pop()) ? (
                                    <img
                                        src={defaultImageURL}
                                        alt="charity-high-resolution-logo"
                                        onError={() => setDefaultImageError(true)}
                                        style={{
                                            maxWidth: '128px',
                                            maxHeight: '128px'
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                            check_circle
                                        </Icon>
                                        <Typography
                                            style={{
                                                color: colors.green[500],
                                                margin: theme.spacing.unit * 2,
                                                textAlign: 'center'
                                            }}
                                            variant="subtitle2"
                                        >
                                            Image Uploaded
                                        </Typography>
                                    </div>
                                )}
                                <FormControl fullWidth data-cy="upload-default-image-input">
                                    <input
                                        accept={'image/*'}
                                        style={{ display: 'none' }}
                                        id={`raised-button-file-default-image-${obj.code}`}
                                        multiple={false}
                                        type="file"
                                        onChange={handleDefaultImageFile}
                                    />
                                    <label htmlFor={`raised-button-file-default-image-${obj.code}`}>
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            style={{
                                                width: '100%',
                                                marginTop: theme.spacing.unit
                                            }}
                                        >
                                            Replace Image
                                        </Button>
                                    </label>
                                </FormControl>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    component="span"
                                    style={{
                                        width: '100%',
                                        marginTop: theme.spacing.unit
                                    }}
                                    onClick={() => {
                                        setDefaultImageFile(undefined);
                                        formik.setFieldValue(`${togglePath}.defaultImage`, null);
                                        formik.setFieldValue(`${togglePath}.defaultImageURL`, '');
                                    }}
                                >
                                    Remove Image
                                </Button>
                            </div>
                        ))}
                    {type === 'counter' &&
                        getSwitch(
                            theme,
                            `${togglePath}.driverIssue`,
                            'Driver Issue',
                            formik,
                            {},
                            {},
                            undefined,
                            '',
                            disableEditing
                        )}
                    {type === 'driver' &&
                        getSwitch(
                            theme,
                            `${togglePath}.clothingBinIssue`,
                            'Clothing Bin Issue',
                            formik,
                            {},
                            {},
                            undefined,
                            '',
                            disableEditing
                        )}
                    {type === 'counter' &&
                        getSwitch(
                            theme,
                            `${togglePath}.suppressWarnings`,
                            'Suppress Warnings',
                            formik,
                            {},
                            {},
                            undefined,
                            '',
                            disableEditing
                        )}
                    {(!_.isNull(emailToCustomer) || !_.isNull(sendToCustomer)) &&
                        !driverIssue &&
                        getSwitch(
                            theme,
                            `${togglePath}.emailToCustomer`,
                            'Email to Customer',
                            formik,
                            {},
                            {},
                            undefined,
                            '',
                            disableEditing
                        )}
                    {appliedBySystem &&
                        getSwitch(
                            theme,
                            `${togglePath}.appliedBySystem`,
                            'Applied by System',
                            formik,
                            {},
                            {},
                            undefined,
                            '',
                            true
                        )}
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default withTheme()(ObjectTextInput);
