import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { InputAdornment, Icon, Chip, withTheme } from '@material-ui/core';

import CustomWrapper from 'components/BulkComponents/CustomWrapper';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

function DateListInput(props) {
    const {
        values,
        onDatesChanged,
        dateFormat = null, //if date format is provided dates will be returned as a string of that format
        disabled,
        label,
        renderDate,
        timezone = process.env.REACT_APP_REGION_TIMEZONE,
        style,
        helperText,
        theme,
        disablePast = false,
        inputStyle = {}
    } = props;

    const [lastSelectedDate, setLastSelectedDate] = useState(moment());

    const onAddDate = date => {
        setLastSelectedDate(date);
        let datesCopy = _.cloneDeep(values) || [];
        let dateFormated;
        if (!_.isNil(dateFormat)) {
            dateFormated = moment(date).format(dateFormat);
        } else {
            dateFormated = date.toDate();
        }
        if (_.every(datesCopy, date => !moment(dateFormated, dateFormat).isSame(date, 'day'))) {
            datesCopy.push(dateFormated);
            onDatesChanged(datesCopy);
        }
    };

    const onRemoveDate = date => {
        let datesCopy = _.cloneDeep(values);
        let dateFormated = date;
        if (!_.isNil(dateFormat)) {
            dateFormated = moment(date).format(dateFormat);
        }
        _.remove(datesCopy, date => moment(dateFormated, dateFormat).isSame(date, 'day'));
        onDatesChanged(datesCopy);
    };

    return (
        <div style={style}>
            <CustomWrapper title={label} titleFontSize="13px">
                <div style={{ margin: theme.spacing.unit }}>
                    <DatePicker
                        autoOk
                        data-cy="recurring-fee-dialog-start-date"
                        disabled={disabled}
                        disablePast={disablePast}
                        fullWidth
                        timezone={timezone}
                        label="Add date"
                        name="date"
                        format="MMMM Do, YYYY"
                        value={lastSelectedDate}
                        invalidDateMessage={''}
                        emptyLabel={''}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                        onChange={date => onAddDate(date)}
                        style={{ marginBottom: theme.spacing.unit }}
                        inputStyle={inputStyle}
                    />
                    {(values || []).map(date =>
                        !_.isNil(renderDate) ? (
                            renderDate(date, onRemoveDate)
                        ) : (
                            <Chip
                                key={date}
                                label={moment(date, dateFormat).format('MMM DD, YYYY')}
                                style={{ margin: theme.spacing.unit / 2 }}
                                onDelete={() => onRemoveDate(date)}
                                deleteIcon={
                                    //needed for adding a cypress tag to the button
                                    <Icon
                                        data-cy={`date-list-input-remove-${moment(date, dateFormat).format(
                                            'YYYY-MM-DD'
                                        )}`}
                                    >
                                        cancel
                                    </Icon>
                                }
                            />
                        )
                    )}
                </div>
            </CustomWrapper>
            {!_.isNil(helperText) && helperText}
        </div>
    );
}

export default withTheme()(DateListInput);
