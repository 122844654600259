import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import { _bulk } from 'std';

import { DialogTitle, withMobileDialog, withTheme } from '@material-ui/core';

import { Dialog, DialogActions, DialogContent, Button, TextField, InputAdornment, Icon } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function EditNumberOfBagsDialog({
    theme,
    open,
    bulk,
    onClose,
    onUpdateCommodityAmount
    // setIdleCountdown,
    // resetTimeoutCount
}) {
    const { lang } = useContext(LocalizationContext);
    const [bagsToProcess, setBagsToProcess] = useState(Number(_.get(bulk, 'commodityAmount', 0)));
    const [bagsProcessed, setBagsProcessed] = useState(Number(_.get(bulk, 'commoditiesProcessed', 0)));

    useEffect(() => {
        setBagsToProcess(_.get(bulk, 'commodityAmount', bagsToProcess));
        setBagsProcessed(_.get(bulk, 'commoditiesProcessed', bagsProcessed));
    }, [bulk]);

    let error = '';
    if (bagsToProcess === '' || !isValidCommodityAmount(bagsToProcess, 1, 99)) {
        error = `Invalid number of ${bulk.commodityUOM}s to process.`;
    }

    // if (bagsProcessed === '' || !isValdCommodityAmount(bagsProcessed, 0, 99)) {
    //     error = `Invalid number of processed ${bulk.commodityUOM}s.`;
    // }

    const handleSubmit = () => {
        onUpdateCommodityAmount(bagsToProcess, bagsProcessed);
        onClose();
    };

    return (
        <Dialog fullWidth open={open && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)} maxWidth="xs">
            <DialogTitle>Update number of {_bulk.getCommodityUOMFormatted(bulk)}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    data-cy="number-of-items-input"
                    name="number-of-items-input"
                    label={`Number of ${bulk.commodityUOM}s to process`}
                    value={bagsToProcess}
                    type="number"
                    variant="outlined"
                    style={{
                        marginTop: theme.spacing.unit * 2
                    }}
                    InputProps={{
                        inputProps: { min: 1, max: 99 },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    disabled={bagsToProcess <= 1}
                                    onClick={() => {
                                        setBagsToProcess(Number(bagsToProcess) - 1);
                                        // setIdleCountdown(0);
                                        // resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        marginRight: theme.spacing.unit,
                                        padding: 0
                                    }}
                                >
                                    <Icon>remove</Icon>
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setBagsToProcess(Number(bagsToProcess) + 1);
                                        // setIdleCountdown(0);
                                        // resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        marginRight: theme.spacing.unit,
                                        padding: 0
                                    }}
                                >
                                    <Icon>add</Icon>
                                </Button>
                            </InputAdornment>
                        )
                    }}
                    onChange={e => {
                        if (isValidCommodityAmount(e.target.value, 1, 99)) {
                            setBagsToProcess(e.target.value);
                        }
                    }}
                />
                {/* <TextField
                    fullWidth
                    data-cy="number-of-items-counted-input-dialog"
                    name="number-of-items-counted-input"
                    label={`Number of ${bulk.commodityUOM}s processed`}
                    value={bagsProcessed}
                    type="number"
                    variant="outlined"
                    style={{
                        marginTop: theme.spacing.unit * 2
                    }}
                    InputProps={{
                        inputProps: { min: 0, max: 99 },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    disabled={bagsProcessed < 1}
                                    onClick={() => {
                                        setBagsProcessed(Number(bagsProcessed) - 1);
                                        // setIdleCountdown(0);
                                        // resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        marginRight: theme.spacing.unit,
                                        padding: 0
                                    }}
                                >
                                    <Icon>remove</Icon>
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setBagsProcessed(Number(bagsProcessed) + 1);
                                        // setIdleCountdown(0);
                                        // resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        marginRight: theme.spacing.unit,
                                        padding: 0
                                    }}
                                >
                                    <Icon>add</Icon>
                                </Button>
                            </InputAdornment>
                        )
                    }}
                    onChange={e => {
                        if (isValdCommodityAmount(e.target.value, 0, 99)) {
                            setBagsProcessed(e.target.value);
                        }
                    }}
                /> */}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
                <Button
                    data-cy="bulk-counter-bags-submit"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!_.isEmpty(error)}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EditNumberOfBagsDialog));

const isValidCommodityAmount = (rawNum, min, max) => {
    if (rawNum === '') {
        return true;
    }

    const number = Number(rawNum);
    if (!_.isNaN(number) && Number.isInteger(number) && number >= min && number <= max) {
        return true;
    }

    return false;
};
