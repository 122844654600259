import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _time, _pickup } from 'std';

import LargeMap from 'components/LargeMap/LargeMap';
import Disconnected from './Disconnected';
import StatsContainer from './StatsContainer';
import InfoPanel from './InfoPanel';
import MultistepPickupDialog from './MultistepPickupDialog';
import MultistepAbortDialog from './MultistepAbortDialog';
import AfterCompleteDialog from './AfterCompleteDialog';
import SkipDialog from './SkipDialog';
import AlertDialog from './AlertDialog';

import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

// Custom hooks
import usePrevious from 'utils/hooks/usePrevious';
import useDriverSocketIO from './hooks/useDriverSocketIO';
import useDriverSelection from './hooks/useDriverSelection';
import useGPSLocation from './hooks/useGPSLocation';
import useMode from './hooks/useMode';
import useGetMapMetaData from './hooks/useGetMapMetaData';

// Misc logic
import { DRIVER_REASONS_AVAILABLE } from './constants';
import { colorLegs } from './functions';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';

function PickupsDriver(props) {
    const {
        location,
        history,
        theme,
        http,
        google,
        operator,
        onSnackbar,
        onCustomHeader,
        collector,
        reloadOperator
    } = props;
    const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
    const [abortDialogOpen, setAbortDialogOpen] = useState(false);
    const [skipDialogOpen, setSkipDialogOpen] = useState(false);
    const [afterCompleteDialogOpen, setAfterCompleteDialogOpen] = useState(false);
    const [afterCompleteDialogEnabled, setAfterCompleteDialogEnabled] = useState(false);
    const [afterCompleteDialogTitle, setAfterCompleteDialogTitle] = useState('');
    const [afterCompleteDialogContent, setAfterCompleteDialogContent] = useState('');
    const [afterCompleteDialogExamples, setAfterCompleteDialogExamples] = useState([]);
    const [completePickupImageTitle, setCompletePickupImageTitle] = useState('');
    const [completePickupImageInstructions, setCompletePickupImageInstructions] = useState([]);
    const [abortPickupImageTitle, setAbortPickupImageTitle] = useState('');
    const [abortPickupImageInstructions, setAbortPickupImageInstructions] = useState([]);
    const [generalOffloadReminders, setGeneralOffloadReminders] = useState([
        'Offload truck contents at end location.',
        'Stock up truck with bags or any other needed supplies if low.'
    ]);
    const [lastTripOffloadReminders, setLastTripOffloadReminders] = useState([
        'Send any receipts to CSR team.',
        'Lock truck.'
    ]);
    const [startingTripTasks, setStartingTripTasks] = useState([]);
    const [maxEarlyStartRouteTime, setMaxEarlyStartRouteTime] = useState(30);
    const [maxDistanceFromStartLocation, setMaxDistanceFromStartLocation] = useState(5); //km
    const [refreshSettings, setRefreshSettings] = useState(true);
    const [pickupsToRender, setPickupsToRender] = useState([]);
    const [centerOnMarkersTrigger, setCenterOnMarkersTrigger] = useState(false);
    const [startRouteTimerStartTime, setStartRouteTimerStartTime] = React.useState(null);
    const [dropOffTimerStartTime, setDropOffTimerStartTime] = React.useState(null);
    const [etaCountDownTime, setETACountDownTime] = React.useState(null);
    const [updatingETA, setUpdatingETA] = React.useState(false);
    const [lunchCountDownTime, setLunchCountDownTime] = React.useState(null);
    const [updatingLunchTime, setUpdatingLunchTime] = React.useState(false);
    const [trucks, setTrucks] = useState([]);

    const [trips, setTrips] = useState([]);
    const [loadingTrips, setLoadingTrips] = useState(false);
    const [loadingNextPickup, setLoadingNextPickup] = useState(false);

    const { defaultCoordinates } = useDefaultCoordinates(http);

    const { loading, socket, disconnected, trip, alertDriver, setAlertDriver } = useDriverSocketIO(
        location,
        onSnackbar,
        http,
        operator,
        setLoadingNextPickup
    );

    const pickups = useMemo(() => {
        let modifiedPickups = _.get(trip, 'pickups', []) || [];

        return modifiedPickups;
    }, [trip]); // handles both null and undefined property

    const route = useMemo(() => {
        if (trip && trip.route) {
            return colorLegs(trip);
        }
        return {};
    }, [trip]); // handles both null and undefined property
    const stats = useMemo(() => _.get(trip, 'stats', null), [trip]);

    const tripStartScreenEnabled = useMemo(() => {
        return (
            _.get(collector, 'configuration.enableClockin', false) &&
            _.isNil(_.get(trip, 'startTripTime')) &&
            _.isNil(
                _.find(
                    _.filter(trips, t => t && t._id !== _.get(trip, '_id')),
                    {
                        upcoming: false
                    }
                )
            )
        );
    }, [trip, collector]);

    const destination = !_.isEmpty(trip) && !_.isNil(trip) ? trip.collector.location : collector.location;
    const { pickupSelected, handlePickupClick, resetPickupIdSelected, getNextPickup } = useDriverSelection(
        pickups,
        route
    );

    const { commoditiesAvailable } = useGetMapMetaData({ http, operator, collector });

    const prevPickupSelected = usePrevious(pickupSelected);

    const { mode, handleMode } = useMode(pickups, pickupSelected, prevPickupSelected, tripStartScreenEnabled);

    const { currentLocation, gpsNotWorking, handleManuallySetLocation, getGPSLocation } = useGPSLocation(
        history,
        socket,
        destination
    );

    const handleCenterMapOnPickups = () => {
        setCenterOnMarkersTrigger(!centerOnMarkersTrigger);
    };

    const getLastPickupCompletionDate = async function() {
        const res = await http.getJSON(`/trips/${trip._id}/getOffloadStartTime`);
        if (res.ok) {
            setDropOffTimerStartTime(moment(_.get(res, 'data.completionDate', '')));
        } else {
            setDropOffTimerStartTime(null);
        }
    };

    const getStartTripTimerStartTime = async function() {
        if (_.get(collector, 'configuration.enableClockin', false)) {
            await http.post(`/timeSheets/${operator._id}/clockIn`);
        }
        const res = await http.getJSON(`/trips/${trip._id}/getStartRouteStartTime`);
        if (res.ok) {
            setStartRouteTimerStartTime(moment(_.get(res, 'data.actualStartTime', '')));
            return moment(_.get(res, 'data.actualStartTime', ''));
        } else {
            setStartRouteTimerStartTime(null);
            return null;
        }
    };

    const updateTripTruckData = async function({ truckId, odometer, transporter }) {
        // updates truck ODO
        const res = await http.post(`/trips/${trip._id}/updateTripTruck`, { truckId, odometer, transporter }, true);
        await http.post(`/timeSheets/${transporter}/updateTruck`, { truckId: truckId }, true);
        return res;
    };

    const recordLateStart = async function({ reason }) {
        const res = await http.post(`/trips/${trip._id}/recordLateStart`, { reason });
        return res;
    };

    const startLunch = async function() {
        if (!operator.enableLunch) {
            return;
        }
        const res = await http.post(`/timeSheets/${operator._id}/startLunch`);
        if (res.ok) {
            await getLunchCountDownTime();
        }
    };

    const endLunch = async function() {
        setUpdatingLunchTime(true);

        const res = await http.post(`/timeSheets/${operator._id}/endLunch`);
        if (res.ok) {
            setLunchCountDownTime(null);
        }
        handleMode('SELECT');
        setTimeout(() => {
            setUpdatingETA(false);
        }, 5);
    };

    async function getLunchCountDownTime() {
        if (!operator.enableLunch) {
            return;
        }
        const res = await http.getJSON('/timeSheets/' + operator._id + '/getTodayTimeSheet');
        if (res.ok) {
            const timeSheet = _.get(res.data, 'timeSheet');
            if (!_.isNil(timeSheet) && !_.isNil(timeSheet.lunchStartTime) && _.isNil(timeSheet.lunchEndTime)) {
                const leg = _.get(trip, 'route.legs', []).find(
                    leg => _.get(leg, 'pickup_id', '').toString() === _.get(pickupSelected, '_id', '').toString()
                );
                if (!_.isNil(leg) && !_.isNil(leg.lunchBreakDuration)) {
                    setUpdatingLunchTime(true);
                    setLunchCountDownTime(moment(timeSheet.lunchStartTime).add(leg.lunchBreakDuration, 'seconds'));
                    setTimeout(() => {
                        setUpdatingLunchTime(false);
                    }, 5);
                }
                handleMode('LUNCH');
            }
        }
    }

    useEffect(() => {
        getLunchCountDownTime();
    }, []);

    useEffect(() => {
        setPickupsToRender(pickups);
    }, [pickups]);

    useEffect(() => {
        if (_.isEmpty(pickups) && trip && trip._id) {
            getLastPickupCompletionDate();
        } else {
            setDropOffTimerStartTime(null);
        }
    }, [pickups, trip]);

    useEffect(() => {
        if (!_.isEmpty(pickupSelected) && _.get(prevPickupSelected, '_id') !== pickupSelected._id) {
            socket.emit('driver-pickup-selected', { pickup_id: pickupSelected._id });
        }
    }, [pickupSelected]);

    useEffect(() => {
        let newETA = _.last(_.get(pickupSelected, 'estimatedCompletionDates', []));
        if (!_.isEmpty(newETA) && !_.isNil(newETA) && newETA !== etaCountDownTime) {
            setUpdatingETA(true);
            setETACountDownTime(_.last(_.get(pickupSelected, 'estimatedCompletionDates', [])));
            //TODO -> find a more elegant solution for timer flicker
            setTimeout(() => {
                setUpdatingETA(false);
            }, 5);
        }
        if (operator.enableLunch && _.isNil(lunchCountDownTime)) {
            const leg = _.get(trip, 'route.legs', []).find(
                leg => _.get(leg, 'pickup_id', '').toString() === _.get(pickupSelected, '_id', '').toString()
            );
            if (!_.isNil(leg) && !_.isNil(leg.lunchBreakDuration)) {
                startLunch();
            }
        }
    }, [pickupSelected]);

    useEffect(() => {
        (async function() {
            if (!refreshSettings) return;
            const res = await http.getJSON('/system/driverDialogConfig');
            if (res.ok) {
                setAfterCompleteDialogEnabled(_.get(res, 'data.afterCompleteDialogEnabled', true));
                setAfterCompleteDialogTitle(_.get(res, 'data.afterCompleteDialogTitle', ''));
                setAfterCompleteDialogContent(_.get(res, 'data.afterCompleteDialogContent', ''));
                setAfterCompleteDialogExamples(_.get(res, 'data.afterCompleteDialogExamples', []));
                setCompletePickupImageTitle(_.get(res, 'data.completePickupImageTitle', ''));
                setCompletePickupImageInstructions(_.get(res, 'data.completePickupImageInstructions', []));
                setAbortPickupImageTitle(_.get(res, 'data.abortPickupImageTitle', ''));
                setAbortPickupImageInstructions(_.get(res, 'data.abortPickupImageInstructions', []));
            }
            const res2 = await http.getJSON('/system/tripConfig');
            if (res2.ok) {
                setGeneralOffloadReminders(_.get(res2, 'data.generalOffloadReminders', []));
                setLastTripOffloadReminders(_.get(res2, 'data.lastTripOffloadReminders', []));
                setStartingTripTasks(_.get(res2, 'data.startingTripTasks', []));
                setMaxEarlyStartRouteTime(_.get(res2, 'data.maxEarlyStartRouteTime', []));
                setMaxDistanceFromStartLocation(_.get(res2, 'data.maxDistanceFromStartLocation', 5));
            }
            const res3 = await http.getJSON('/trucks/getEnabledTrucks');
            if (res3.ok) {
                setTrucks(_.get(res3, 'data.trucks', []));
            }
            setRefreshSettings(false);
        })();
    }, [refreshSettings]);

    async function fetchTrips() {
        const res = await http.getJSON('/users/operators/' + operator._id + '/getDayTrips/');
        if (res.ok) {
            const driverTrips = _.get(res, 'data.trips', []);
            setTrips(driverTrips);
        }
    }

    useEffect(() => {
        (async function() {
            setLoadingTrips(true);
            await fetchTrips();
            setLoadingTrips(false);
            handleCenterMapOnPickups();
        })();
    }, [_.get(trip, '_id', '')]);

    useEffect(() => {
        pickups.forEach(p => {
            p.numberOfBins = _pickup.getNumberOfBins(p, commoditiesAvailable);
        });
    }, [pickups, commoditiesAvailable]);

    if (loading) {
        return (
            <React.Fragment>
                <LinearProgress />

                <Typography style={{ padding: theme.spacing.unit * 2 }}>
                    Loading your route. This may take a moment.
                </Typography>
            </React.Fragment>
        );
    }

    if (disconnected) {
        return <Disconnected />;
    }
    return (
        <Grid container spacing={0} style={{ height: '100%', padding: theme.spacing.unit * 2 * 0 }}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <LargeMap
                    centerOnMarkersTrigger={centerOnMarkersTrigger}
                    defaultCoordinates={defaultCoordinates}
                    theme={theme}
                    google={google}
                    history={history}
                    visible
                    closeViewAllowed
                    mode={mode}
                    showDirections
                    showCustomerInfo={false}
                    accountType="Collector Employee"
                    seeAllPendingPickups={_.get(operator, 'permissions.seeAllPendingPickups', false)}
                    routes={[route]}
                    pickups={pickupsToRender}
                    pickupSelected={pickupSelected}
                    startLocation={_.get(trip, 'startLocation')}
                    destination={destination}
                    currentLocation={currentLocation}
                    style={{
                        position: 'relative',
                        boxShadow: theme.shadows[2],
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    }}
                    onPickupClick={handlePickupClick}
                    commoditiesAvailable={commoditiesAvailable}
                />

                {!_.isNil(stats) && (
                    <StatsContainer
                        theme={theme}
                        stats={stats}
                        trips={trips}
                        operator={operator}
                        selectedTrip={trip}
                        loadingTrips={loadingTrips}
                        onCenterMapOnPickups={handleCenterMapOnPickups}
                    />
                )}
                <InfoPanel
                    http={http}
                    history={history}
                    socket={socket}
                    mode={mode}
                    trips={trips}
                    selectedTrip={trip}
                    operator={operator}
                    pickup={pickupSelected} // TODO: rename into pickupSelected
                    commoditiesAvailable={commoditiesAvailable}
                    destination={destination}
                    currentLocation={currentLocation}
                    handlRefreshGPS={getGPSLocation}
                    getStartTripTimerStartTime={getStartTripTimerStartTime}
                    updateTripTruckData={updateTripTruckData}
                    recordLateStart={recordLateStart}
                    resetPickupIdSelected={resetPickupIdSelected}
                    onMode={handleMode}
                    setCompleteDialogOpen={setCompleteDialogOpen}
                    setAbortDialogOpen={setAbortDialogOpen}
                    setSkipDialogOpen={setSkipDialogOpen}
                    onSnackbar={onSnackbar}
                    loadingTrips={loadingTrips}
                    onCustomHeader={onCustomHeader}
                    startRouteTimerStartTime={startRouteTimerStartTime}
                    dropOffTimerStartTime={dropOffTimerStartTime}
                    etaCountDownTime={(mode.index === 2 || mode.index === 3) && !updatingETA ? etaCountDownTime : null}
                    lunchCountDownTime={!updatingLunchTime && mode.index === 6 ? lunchCountDownTime : null}
                    endLunch={endLunch}
                    generalOffloadReminders={generalOffloadReminders}
                    lastTripOffloadReminders={lastTripOffloadReminders}
                    startingTripTasks={startingTripTasks}
                    maxEarlyStartRouteTime={maxEarlyStartRouteTime}
                    maxDistanceFromStartLocation={maxDistanceFromStartLocation}
                    trucks={trucks}
                    reloadOperator={reloadOperator}
                    onCenterMapOnPickups={handleCenterMapOnPickups}
                    setRefreshSettings={setRefreshSettings}
                    loadingNextPickup={loadingNextPickup}
                />
            </Grid>

            <MultistepPickupDialog
                http={http}
                operator={operator}
                pickups={pickups}
                socket={socket}
                open={completeDialogOpen}
                pickupSelected={pickupSelected}
                getNextPickup={getNextPickup}
                currentLocation={currentLocation}
                onClose={() => setCompleteDialogOpen(false)}
                onMode={handleMode}
                setAfterCompleteDialogOpen={setAfterCompleteDialogOpen}
                handleCenterMapOnPickups={handleCenterMapOnPickups}
                onSnackbar={onSnackbar}
                onManuallySetLocation={handleManuallySetLocation}
                gpsNotWorking={gpsNotWorking}
                completePickupImageTitle={completePickupImageTitle}
                completePickupImageInstructions={completePickupImageInstructions}
                commoditiesAvailable={commoditiesAvailable}
                fetchTrips={fetchTrips}
                inProgress={loadingNextPickup}
                setInProgress={setLoadingNextPickup}
            />

            <MultistepAbortDialog
                socket={socket}
                operator={operator}
                http={http}
                open={abortDialogOpen}
                pickupSelected={pickupSelected}
                getNextPickup={getNextPickup}
                currentLocation={currentLocation}
                reasonsAvailable={DRIVER_REASONS_AVAILABLE}
                onClose={() => setAbortDialogOpen(false)}
                onMode={handleMode}
                handleCenterMapOnPickups={handleCenterMapOnPickups}
                onSnackbar={onSnackbar}
                onManuallySetLocation={handleManuallySetLocation}
                gpsNotWorking={gpsNotWorking}
                abortPickupImageTitle={abortPickupImageTitle}
                abortPickupImageInstructions={abortPickupImageInstructions}
            />

            <SkipDialog
                open={skipDialogOpen}
                handleClose={() => setSkipDialogOpen(false)}
                pickupSelected={pickupSelected}
                getNextPickup={getNextPickup}
                handleCenterMapOnPickups={handleCenterMapOnPickups}
                onSnackbar={onSnackbar}
                socket={socket}
                onManuallySetLocation={handleManuallySetLocation}
                gpsNotWorking={gpsNotWorking}
            />
            {afterCompleteDialogEnabled && (
                <AfterCompleteDialog
                    open={afterCompleteDialogOpen && afterCompleteDialogEnabled}
                    onClose={() => setAfterCompleteDialogOpen(false)}
                    afterCompleteDialogTitle={afterCompleteDialogTitle}
                    afterCompleteDialogContent={afterCompleteDialogContent}
                    afterCompleteDialogExamples={afterCompleteDialogExamples}
                />
            )}
            <AlertDialog open={alertDriver} handleClose={() => setAlertDriver(false)} />
        </Grid>
    );
}

export default withTheme()(PickupsDriver);
