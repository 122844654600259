import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme } from '@material-ui/core/styles';
import {
    Tooltip,
    IconButton,
    Icon,
    Button,
    colors,
    withStyles,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    InputAdornment,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    ListItemText,
    List,
    TextField,
    ListItemSecondaryAction,
    Badge,
    ListItem
} from '@material-ui/core';

import OTCLocationForm from './Forms/OTCLocationForm';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import { useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import { TIMEZONES } from 'constants.js';
import { _commodity, _user } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';
import { getReceiverName } from 'utils/misc';

const styles = theme => ({
    badgeOk: {
        backgroundColor: colors.green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

const frequency_to_text = {
    0: 'Only Once',
    7: 'Weekly',
    14: 'Every 2 Weeks',
    21: 'Every 3 Weeks',
    28: 'Every 4 Weeks',
    35: 'Every 5 Weeks'
};
const recurring_stop_frequencies = {
    'Only Once': 0,
    Weekly: 7,
    'Every 2 Weeks': 14,
    'Every 3 Weeks': 21,
    'Every 4 Weeks': 28,
    'Every 5 Weeks': 35
};
const phoneNumberGroupings = process.env.REACT_APP_PHONE_NUMBER_GROUPING.split(' ');
function OTCLocationTable(props) {
    const { theme, operator, onSnackbar, google, setGroupFilter, groupFilter, allCollectors } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const [showBookingDialog, setShowBookingDialog] = useState(false);
    const [selectedCollector, setSelectedCollector] = useState('all');
    const [showPickupsDialog, setShowPickupsDialog] = useState(false);
    const [frequency, setFrequency] = useState(0);
    const [collectors, setCollectors] = useState([]);
    const OTClocationEndpoints = {
        getEndPoint: '/getAllExternalLocations',
        getEditEndPoint: _id => `/updateExternalLocation/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalLocation' // url to add new documents
    };
    let OTCLocationQuery = `&collector=${selectedCollector}&group=${groupFilter}`;
    if (_user.isCollectorAdmin(operator)) {
        OTCLocationQuery = `&collector=${_.get(operator, 'collector._id', null)}&group=${groupFilter}`;
    }
    const { data: allOTCLocations, loading, handleCreate, handleEdit, handleReloadData } = useCRUD({
        endpoints: OTClocationEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar,
        getQuery: OTCLocationQuery
    });
    useEffect(() => {
        if (!_.isEmpty(collectors)) return;
        const newCollectors = _.uniqBy(
            [...allOTCLocations.map(obj => _.get(obj, 'collector'))].filter(obj => obj !== undefined),
            '_id'
        );
        setCollectors(newCollectors);
    }, [allOTCLocations]);
    useEffect(() => {
        handleReloadData();
    }, [selectedCollector, groupFilter]);
    useEffect(() => {
        for (let i = 0; i < allOTCLocations.length; i++) {
            if (selectedLocationId === allOTCLocations[i]._id) {
                setSelectedLocation(allOTCLocations[i]);
                setFrequency(allOTCLocations[i].frequency);
                break;
            }
        }
    }, [allOTCLocations, selectedLocationId]);

    const OTCGroupEndpoints = {
        getEndPoint: '/getAllExternalGroups',
        getEditEndPoint: _id => `/updateExternalGroup/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalGroup' // url to add new documents
    };
    let OTCGroupQuery = '';
    if (_user.isCollectorAdmin(operator)) {
        OTCGroupQuery = `&collector=${_.get(operator, 'collector._id', null)}`;
    }

    const { data: allOTCGroups } = useCRUD({
        endpoints: OTCGroupEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar,
        getQuery: OTCGroupQuery
    });

    let columns = [
        {
            key: 'name',
            header: 'Name'
        },
        { key: 'location.description', header: 'Address' },
        {
            key: 'phone',
            header: 'Phone',
            formatValue: value => {
                if (value)
                    return value.replace(
                        new RegExp(
                            `(\\d{${phoneNumberGroupings[0]}})(\\d{${phoneNumberGroupings[1]}})(\\d{${phoneNumberGroupings[2]}})`
                        ),
                        process.env.REACT_APP_PHONE_NUMBER_FORMAT
                    );
                return value;
            }
        },
        { key: 'group.name', header: 'Group' },
        {
            key: 'frequency',
            header: 'Frequency',
            formatValue: value => frequency_to_text[value]
        },
        {
            key: 'pickup.date',
            header: 'Next Date',
            formatValue: (value, rowData) => {
                let earliestPickup = null;
                let earliestPickupDate = null;
                _.get(rowData, 'pickups', []).forEach(pickup => {
                    const pickupDate = _.get(pickup, 'date');
                    if (
                        _.isNil(earliestPickup) ||
                        new Date(pickupDate).getTime() < new Date(earliestPickupDate).getTime()
                    ) {
                        earliestPickup = pickup;
                        earliestPickupDate = pickupDate;
                    }
                });

                return earliestPickupDate
                    ? moment(earliestPickupDate)
                          .tz(process.env.REACT_APP_REGION_TIMEZONE)
                          .format('MMM D, YYYY')
                    : 'N/A';
            }
        }
    ];
    if (!_user.isCollectorAdmin(operator)) {
        columns.push({
            key: 'collector.name',
            header: 'Collector',
            formatValue: value => (value ? value : 'N/A')
        });
    }
    columns.push({
        key: 'receiver',
        header: 'Driver',
        formatValue: value => (value ? getReceiverName(value) : 'N/A')
    });
    columns.push({ key: 'accessCode', header: 'Access Code' });

    const defaultColumnFilters = columns.map(c => c.key);
    const editForm = (
        <OTCLocationForm
            OTCLocation={selectedLocation}
            allOTCLocations={allOTCLocations}
            allOTCGroups={allOTCGroups}
            onSubmit={_.isNil(selectedLocation) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedLocation)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            operator={operator}
            http={http}
            google={google}
            allCollectors={allCollectors}
        />
    );
    const onEditRow = document => {
        setSelectedLocation(document);
        setSelectedLocationId(document._id);
        setEditDialogOpen(true);
    };

    async function bookPickup(OTCLocation, date) {
        let createStopPayload = {
            date,
            stopName: _.get(OTCLocation, 'name', ''),
            location: _.get(OTCLocation, 'location', ''),
            comment: _.get(OTCLocation, 'description', ''),
            stopDuration: _.get(OTCLocation, 'stopDuration', 600),
            stopPayAmount: 0,
            frequency: frequency,
            stopIcon: _.get(OTCLocation, 'group.defaultIcon', 'truck'),
            stopIconColor: _.get(OTCLocation, 'group.defaultColor', 'black'),
            OTCLocationId: _.get(OTCLocation, '_id', '')
        };
        if (!_.isNil(_.get(OTCLocation, 'collector._id', null))) {
            createStopPayload.collector_id = _.get(OTCLocation, 'collector._id');
        }
        if (!_.isNil(_.get(OTCLocation, 'receiver._id', null))) {
            createStopPayload.receiver_id = _.get(OTCLocation, 'receiver._id');
        }
        const res = await http.postJSON('/stops/create', createStopPayload, true);
        if (res.ok) {
            onSnackbar('Pickup booked for the location ' + _.get(OTCLocation, 'name', ''));
            setShowBookingDialog(false);
            setShowPickupsDialog(true);
            handleReloadData();
        } else {
            onSnackbar(_.get(res, 'errorMessage', ''), 'error');
        }
    }
    const handleDeleteStop = async (pickup_id, OTCLocationId) => {
        const res = await http.post(`/stops/${pickup_id}/delete`, { OTCLocationId: OTCLocationId });
        if (res.ok) {
            onSnackbar('Pickup cancelled');
            handleReloadData();
        }
    };
    const handleDeleteOTCLocation = async OTCLocationId => {
        const res = await http.post(`/deleteExternalLocation`, { _id: OTCLocationId });
        if (res.ok) {
            onSnackbar('Location Removed');
            handleReloadData();
        }
    };

    const renderExtraActions = document => {
        const locationId = _.get(document, '_id', '');
        return (
            <>
                <Tooltip title="Edit">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            onEditRow(document);
                        }}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View Pickups">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            setSelectedLocation(document);
                            setSelectedLocationId(document._id);
                            setShowPickupsDialog(true);
                        }}
                    >
                        <Badge badgeContent={document.pickups.length} color="primary" showZero>
                            <Icon>local_shipping</Icon>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View past pickups">
                    <IconButton
                        data-cy={`history-button`}
                        onClick={e => {
                            props.history.push(`/operators/${operator._id}/stops?OTCLocationFilter=${locationId}`);
                        }}
                    >
                        <Icon>history</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            if (!_.isNil(_.get(document, 'pickup._id'))) {
                                onSnackbar('Cannot delete locations with existing pickup booking', 'error');
                                return;
                            }
                            warnAction(() => {
                                handleDeleteOTCLocation(_.get(document, '_id'));
                            }, 'Are you sure you want to delete this location?');
                        }}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <PickupsDialog
                open={showPickupsDialog}
                onClose={() => {
                    setShowPickupsDialog(false);
                }}
                selectedLocation={selectedLocation}
                onNewPickup={() => {
                    setShowPickupsDialog(false);
                    setShowBookingDialog(true);
                }}
                theme={theme}
                handleDeleteStop={handleDeleteStop}
                warnAction={warnAction}
            />
            <BookingDialog
                open={showBookingDialog}
                onClose={() => {
                    setShowBookingDialog(false);
                }}
                onSubmit={date => {
                    bookPickup(selectedLocation, date);
                }}
                frequency={frequency}
                onChangeFrequency={e => {
                    setFrequency(e.target.value);
                }}
                theme={theme}
            />
            <CRUDTable
                operator={operator}
                columns={columns}
                data={allOTCLocations}
                editForm={editForm}
                documentIsDisabled={g => _.get(g, 'disabled', false)}
                defaultRowsPerPage={10}
                selectedDocument={selectedLocation}
                setSelectedDocument={doc => {
                    setSelectedLocation(doc);
                    setSelectedLocationId(_.get(doc, '_id', null));
                }}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'OTC Locations'}
                loading={loading}
                renderExtraActions={renderExtraActions}
                hideDateFilters
                defaultColumnFilters={defaultColumnFilters}
                hideEditButton={true}
                hideViewJSON={true}
                showEmptyTable
                showCollectorFilter={false}
                customFilters={
                    <>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="group">Group</InputLabel>
                            <Select
                                input={<OutlinedInput id="group" name="group" labelWidth={55} />}
                                value={groupFilter}
                                onChange={e => setGroupFilter(e.target.value)}
                                style={{
                                    width: '200px',
                                    marginRight: 8
                                }}
                            >
                                <MenuItem key={'all'} value={'all'}>
                                    All
                                </MenuItem>
                                {allOTCGroups.map(c => (
                                    <MenuItem key={_.get(c, '_id')} value={_.get(c, '_id')}>
                                        {_.get(c, 'name')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {!_user.isCollectorAdmin(operator) && (
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="collector">Collector</InputLabel>
                                <Select
                                    input={<OutlinedInput id="collector" name="collector" labelWidth={65} />}
                                    value={selectedCollector}
                                    onChange={e => setSelectedCollector(e.target.value)}
                                    style={{
                                        width: '200px',
                                        marginRight: 8
                                    }}
                                >
                                    <MenuItem key={'all'} value={'all'}>
                                        All
                                    </MenuItem>
                                    {collectors.map(c => (
                                        <MenuItem key={_.get(c, '_id')} value={_.get(c, '_id')}>
                                            {_.get(c, 'name')}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </>
                }
            />
        </>
    );
}

export default withStyles(styles)(withTheme()(OTCLocationTable));

function BookingDialog({ open, onClose, onSubmit, frequency, onChangeFrequency, theme }) {
    const { date, timezone, handleChangeDate, handleChangeTimezone } = useDatePicker({
        saveStateInURL: false,
        timezones: TIMEZONES
    });
    return (
        <Dialog open={open}>
            <DialogTitle style={{ paddingBottom: 0 }}>Select Pickup Date</DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }}>
                <div style={{ marginTop: 10 }}>
                    <DatePicker
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        data-cy="pickup-date-select"
                        autoOk
                        label="Pickup Date"
                        name="date"
                        value={date}
                        availableDates={getNext60Days(process.env.REACT_APP_REGION_TIMEZONE)}
                        variant="outlined"
                        onChange={handleChangeDate}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                    <TextField
                        value={frequency}
                        onChange={onChangeFrequency}
                        variant="outlined"
                        name="frequency"
                        label="Frequency"
                        select
                    >
                        {Object.keys(recurring_stop_frequencies).map(frequency => {
                            return (
                                <MenuItem key={frequency} value={recurring_stop_frequencies[frequency]}>
                                    {frequency}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    data-cy="submit"
                    onClick={() => {
                        onSubmit(date);
                    }}
                >
                    submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function PickupsDialog({ open, onClose, selectedLocation, onNewPickup, theme, handleDeleteStop, warnAction }) {
    const pickups = _.get(selectedLocation, 'pickups', []);
    return (
        <Dialog open={open}>
            <DialogTitle style={{ paddingBottom: 0 }}>Pickups for this location</DialogTitle>
            <DialogContent style={{ paddingBottom: theme.spacing.unit }}>
                <List style={{ paddingTop: theme.spacing.unit, paddingBottom: theme.spacing.unit }}>
                    {_.isEmpty(pickups) && <Typography style={{ textAlign: 'center' }}>No pickups booked</Typography>}
                    {pickups.map(pickup => {
                        return (
                            <ListItem>
                                <ListItemText
                                    primary={moment(_.get(pickup, 'date'))
                                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                        .format('MMM D, YYYY')}
                                    secondary={frequency_to_text[_.get(pickup, 'frequency')]}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title="Cancel Pickup">
                                        <IconButton
                                            data-cy={`cancel_pickup`}
                                            onClick={e => {
                                                warnAction(() => {
                                                    handleDeleteStop(
                                                        _.get(pickup, '_id'),
                                                        _.get(selectedLocation, '_id')
                                                    );
                                                }, 'Are you sure you want to cancel this pickup?');
                                            }}
                                        >
                                            <Icon>cancel</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={onNewPickup}>
                        New Pickup
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
function getNext60Days(timezone) {
    return _.range(0, 59).map(daysToAdd =>
        moment()
            .tz(timezone)
            .startOf('day')
            .add(daysToAdd, 'days')
            .toDate()
    );
}
