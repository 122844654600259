import React, { useState, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogActions,
    DialogContent,
    withMobileDialog,
    Button,
    withTheme,
    DialogContentText,
    Switch,
    Table,
    TableHead,
    TableRow,
    TableCell,
    DialogTitle,
    TableBody
} from '@material-ui/core';
import { uppercaseFirstLetter, getQuickDropName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function BulkPermissionsDialog(props) {
    const { open, collector, fullScreen, theme, onClose, onSubmit } = props;
    const { lang } = useContext(LocalizationContext);
    const [permissions, setPermissions] = useState(_.get(collector, 'configuration.bulkPermissions'), {});

    const handlePermissionChanged = (bulkType, permission, value) => {
        const permissionsCopy = _.cloneDeep(permissions);
        _.set(permissionsCopy, `${bulkType}.${permission}`, value);
        setPermissions(permissionsCopy);
    };

    const tableCellStyles = {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit
        //borderBottom: 'none'
    };

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} fullWidth>
            <DialogTitle>Permissions</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableCellStyles} />
                            <TableCell style={tableCellStyles}>Create</TableCell>
                            <TableCell style={tableCellStyles}>Update</TableCell>
                            <TableCell style={tableCellStyles}>Re-open</TableCell>
                            <TableCell style={tableCellStyles}>Close</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(permissions).map(bulkType => {
                            if (
                                [
                                    'clerkVideo',
                                    'resolveComplaints',
                                    'customerPayment',
                                    'closeIncompleteOrders',
                                    'accessOTCBooking'
                                ].includes(bulkType)
                            )
                                return;

                            let showCreate = !_.isNil(permissions[bulkType].create);
                            let showUpdate = !_.isNil(permissions[bulkType].update);
                            let showReopen = !_.isNil(permissions[bulkType].reopen);
                            let showClose = !_.isNil(permissions[bulkType].close);

                            let rowName = uppercaseFirstLetter(
                                bulkType === 'inhouse' ? getQuickDropName('en') : bulkType
                            );
                            return (
                                <TableRow key={bulkType}>
                                    <TableCell style={tableCellStyles}>{rowName}</TableCell>
                                    <TableCell style={tableCellStyles}>
                                        {showCreate && (
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'create', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'create', e.target.checked)
                                                }
                                            />
                                        )}
                                    </TableCell>

                                    <TableCell style={tableCellStyles}>
                                        {showUpdate && (
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'update', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'update', e.target.checked)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell style={tableCellStyles}>
                                        {showReopen && (
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'reopen', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'reopen', e.target.checked)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell style={tableCellStyles}>
                                        {showClose && (
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'close', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'close', e.target.checked)
                                                }
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogTitle>Miscellaneous</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead />
                    <TableBody>
                        {Object.keys(permissions).map(bulkType => {
                            if (bulkType === 'clerkVideo')
                                return (
                                    <TableRow key={bulkType}>
                                        <TableCell style={{ width: '116px', ...tableCellStyles }}>
                                            Video Capture
                                        </TableCell>
                                        <TableCell style={tableCellStyles}>
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'create', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'create', e.target.checked)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );

                            if (bulkType === 'resolveComplaints')
                                return (
                                    <TableRow key={bulkType}>
                                        <TableCell style={{ width: '116px', ...tableCellStyles }}>
                                            Resolve Complaints
                                        </TableCell>
                                        <TableCell style={tableCellStyles}>
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'update', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'update', e.target.checked)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );

                            if (bulkType === 'customerPayment') {
                                return (
                                    <TableRow key={bulkType}>
                                        <TableCell style={{ width: '116px', ...tableCellStyles }}>
                                            Customer Payments
                                        </TableCell>
                                        <TableCell style={tableCellStyles}>
                                            <Switch
                                                checked={_.get(permissions[bulkType], 'create', false)}
                                                onChange={e =>
                                                    handlePermissionChanged(bulkType, 'create', e.target.checked)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        })}
                        <TableRow key={'closeIncompleteOrders'}>
                            <TableCell style={{ width: '116px', ...tableCellStyles }}>
                                Close Incomplete Orders
                            </TableCell>
                            <TableCell style={tableCellStyles}>
                                <Switch
                                    checked={_.get(permissions['closeIncompleteOrders'], 'update', true)}
                                    onChange={e =>
                                        handlePermissionChanged('closeIncompleteOrders', 'update', e.target.checked)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key={'accessOTCBooking'}>
                            <TableCell style={{ width: '116px', ...tableCellStyles }}>
                                Can Manage OTC Bookings
                            </TableCell>
                            <TableCell style={tableCellStyles}>
                                <Switch
                                    checked={_.get(permissions['accessOTCBooking'], 'update', false)}
                                    onChange={e =>
                                        handlePermissionChanged('accessOTCBooking', 'update', e.target.checked)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={() => onSubmit(permissions)}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(BulkPermissionsDialog));
