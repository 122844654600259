import React, { useState, useEffect, useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { FormControl, Button } from '@material-ui/core';

import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import ImageScroll from 'components/ImageUploads/ImageScroll';
import CustomMUIDialog from 'components/Misc/CustomMUIDialog';

function ImageViewer(props) {
    const {
        theme,
        displayImages,
        handleDeleteImage,
        disableDelete,
        placeholderText,
        displayImageIdx: displayImageIdxProp = null,
        onDisplayImageIdxChange = null,
        onImageClick = null,
        hideScroller = false,
        outerStyles = {},
        innerStyles = {},
        scrollerStyles = {},
        autoDisplayFirstImage = true,
        autoDisplayLastImage = false,
        imageIcons = [],
        condensed = false,
        handleImageUpload,
        maxImages = null
    } = props;

    const [displayImageIdxState, setDisplayImageIdxState] = useState(-1);
    const [showImageZoomDialog, setShowImageZoomDialog] = useState(false);
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);

    const controlled = !_.isNil(displayImageIdxProp) && !_.isNil(onDisplayImageIdxChange); //if the displayed image is controlled by the parent component
    const displayImageIdx = controlled ? displayImageIdxProp : displayImageIdxState;
    const setDisplayImageIdx = controlled ? onDisplayImageIdxChange : setDisplayImageIdxState;

    const handleSelectImage = idx => {
        if (idx === displayImageIdx) {
            setDisplayImageIdx(-1);
        } else {
            setDisplayImageIdx(idx);
        }
    };

    const handleClickDelete = idx => {
        handleDeleteImage(idx);
        setDisplayImageIdx(displayImages.length - 2);
    };

    useEffect(() => {
        if (displayImages.length < 1) {
            setDisplayImageIdx(-1);
            return;
        }

        if (autoDisplayFirstImage) {
            setDisplayImageIdx(0);
        } else if (autoDisplayLastImage) {
            setDisplayImageIdx(displayImages.length - 1);
        } else {
            setDisplayImageIdx(-1);
        }
    }, [displayImages]);

    return (
        <div
            style={{
                height: 'auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: 'red', // Used for testing
                ...outerStyles
            }}
            data-cy={_.get(props, 'data-cy', '')}
        >
            {!condensed && !disableDelete && displayImageIdx >= 0 && (
                <Button
                    variant="outlined"
                    style={{ width: '100%', maxWidth: 420 }}
                    onClick={() => warnAction(() => handleClickDelete(displayImageIdx), loc('delete_image', lang))}
                >
                    {loc('deleteImage', lang)}
                </Button>
            )}
            {displayImageIdx >= 0 && (
                <div
                    style={{
                        minWidth: 320,
                        maxWidth: '100%',
                        // backgroundColor: 'blue', // Used for testing
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        margin: theme.spacing.unit * 2,
                        ...innerStyles
                    }}
                >
                    <img
                        src={displayImages[displayImageIdx]}
                        alt={'Payload'}
                        style={{
                            maxWidth: '100%',
                            maxHeight: condensed ? 195 : '600px',
                            border: '1px solid rgba(0, 0, 0, 0.26)',
                            borderRadius: theme.spacing.unit / 2
                        }}
                        onClick={() => {
                            if (!_.isNil(onImageClick)) {
                                onImageClick();
                            } else {
                                setShowImageZoomDialog(true);
                            }
                        }}
                    />
                    {condensed && !disableDelete && (
                        <Button
                            variant="outlined"
                            style={{ position: 'absolute', top: 2, right: 2, backgroundColor: 'white' }}
                            onClick={() =>
                                !disableDelete &&
                                warnAction(() => handleDeleteImage(displayImageIdx), loc('delete_image', lang))
                            }
                        >
                            Delete
                        </Button>
                        // </div>
                    )}

                    {hideScroller && displayImages.length > 1 && (
                        <Icon style={{ position: 'absolute', bottom: 8, right: 8, color: 'white' }}>photo_library</Icon>
                    )}
                </div>
            )}

            {displayImageIdx < 0 && (
                <div
                    style={{
                        margin: 'auto',
                        border: '1px solid rgba(0, 0, 0, 0.26)',
                        borderRadius: theme.spacing.unit / 2,
                        maxWidth: '100%',
                        minHeight: 80,
                        height: 'auto',
                        textAlign: 'center',
                        backgroundColor: colors.grey[50],
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...innerStyles
                    }}
                >
                    <Typography
                        style={{
                            margin: theme.spacing.unit * 2,
                            color: colors.grey[800]
                        }}
                        variant="subtitle2"
                    >
                        {placeholderText || loc('noImageToDisplay', lang)}
                    </Typography>
                </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!(disableDelete && displayImages.length <= 1) && displayImages.length > 0 && !hideScroller && (
                    <ImageScroll
                        displayImageIdx={displayImageIdx}
                        disableDelete={disableDelete}
                        displayImages={displayImages}
                        onImageSelect={handleSelectImage}
                        onImageDelete={handleDeleteImage}
                        scrollerStyles={scrollerStyles}
                        imageIcons={imageIcons}
                    />
                )}
                {displayImageIdx >= 0 &&
                    !_.isNil(handleImageUpload) &&
                    (!_.isNil(maxImages) && displayImages.length < maxImages) && (
                        <FormControl>
                            <input
                                accept="image/jpg, image/jpeg, .webp, .heif, .heic, .gif, .jpg"
                                style={{ display: 'none' }}
                                id="add-image-button-file"
                                type="file"
                                onChange={handleImageUpload}
                            />
                            <label
                                htmlFor="add-image-button-file"
                                style={{
                                    width: 'fit-content'
                                }}
                            >
                                <IconButton component="span" style={{ color: 'grey', marginLeft: theme.spacing.unit }}>
                                    <Icon>add_circle</Icon>
                                </IconButton>
                            </label>
                        </FormControl>
                    )}
            </div>
            <CustomMUIDialog open={showImageZoomDialog} onClose={() => setShowImageZoomDialog(false)}>
                <ZoomViewer src={displayImages[displayImageIdx]} />
            </CustomMUIDialog>
        </div>
    );
}
export default withTheme()(ImageViewer);

const ZoomViewer = props => {
    const { src } = props;
    return (
        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                    <div className="tools" style={{ position: 'absolute', zIndex: 1, top: 10, left: 10 }}>
                        <button onClick={() => zoomIn()} style={{ marginRight: 10 }}>
                            Zoom In
                        </button>
                        <button onClick={() => zoomOut()} style={{ marginRight: 10 }}>
                            Zoom Out
                        </button>
                        <button onClick={() => resetTransform()}>Reset</button>
                    </div>
                    <TransformComponent>
                        <img src={src} alt="countImage" style={{ width: '100%', height: '100%' }} />
                    </TransformComponent>
                </React.Fragment>
            )}
        </TransformWrapper>
    );
};
