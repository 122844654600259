import React, { useContext, useState } from 'react';

import { _rates } from 'std';

import _ from 'lodash';

import bulkHelper from 'helpers/bulkHelper';
import Badge from 'components/Badge/Badge';

import * as colors from '@material-ui/core/colors';

import {
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
    Fab,
    Icon,
    Button,
    IconButton
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { formatAsCurrency, getPlural, isAUSRegion, isEXPRegion } from 'utils/misc';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import moment from 'moment-timezone';

function getStatsFromBulks(
    bulks,
    pickups = [],
    commodities = [],
    isLastInteraction = false,
    allRates = [],
    fallbackRates = []
) {
    const beverageCommoditySkus = _.filter(commodities, c => c.includeContainersInStats).map(c => c.skuType);
    // Breaking down the completed unsummarized bulks by datePickedUp and bulkType
    // (need both in the rare case a drop & go/quickdrop/walk-in is created the same time a pickup is completed for the user)
    const bulksByDateBulkType = bulks.reduce((groupedBulks, bulk) => {
        const datePickedUp = moment(_.get(bulk, 'datePickedUp'));
        const bulkType = _.get(bulk, 'bulkType');

        const groupDateKeys = Object.keys(groupedBulks);
        const matchingDateKey = groupDateKeys.find(gk => {
            const [gkDatePickedUp, gkBulkType] = gk.split('|');
            return (
                Math.abs(moment.unix(gkDatePickedUp).diff(datePickedUp, 'seconds', true)) <= 2 &&
                gkBulkType === bulkType
            );
        });

        if (matchingDateKey) {
            const currGroup = groupedBulks[matchingDateKey];
            return { ...groupedBulks, [matchingDateKey]: [...currGroup, bulk] };
        } else {
            return { ...groupedBulks, [`${datePickedUp.format('X')}|${bulkType}`]: [bulk] };
        }
    }, {});

    let bulkStatsByDateBulkType = {},
        bulksToSummarize = [];

    // financial stats - only needed for all bulks in total
    let depositValue = 0;
    let serviceFee = 0;
    let customerValue = 0;
    let returnTotal = 0;
    let tipAmount = 0;

    // determines the header text for the total value
    let isDonation = true;
    let isAnonymousDonation = true;

    for (const dateBulkType in bulksByDateBulkType) {
        const bulks = bulksByDateBulkType[dateBulkType];

        // the bulks whose "summarized" flags will be toggled to true after this summary step
        bulksToSummarize = bulksToSummarize.concat(bulks);

        // if the current grouping (pickup/drop & go/etc.) does not have a beverage or donated bulks then not part of summary display and so skip the to the next
        if (
            !isLastInteraction &&
            !bulks.some(
                bulk => bulkHelper.isBeverage(bulk) || bulkHelper.isCollectionBins(bulk) || bulkHelper.isDonating(bulk)
            )
        ) {
            continue;
        }

        let bulksWithPending = Array.from(bulks);
        // if the bulk grouping is a pickup, grab any pending bulks assigned to the same pickup
        if (dateBulkType.split('|')[1] === 'pickup') {
            const firstBulkPickupId = _.get(bulks[0], 'pickup._id', '').toString();
            const pickupMatchIdx = pickups.findIndex(pickup => pickup._id.toString() === firstBulkPickupId);
            const pickupBulks = pickupMatchIdx > -1 ? _.get(pickups[pickupMatchIdx], 'bulks', []) : undefined;

            if (pickupBulks && pickupBulks.length !== bulks.length) {
                bulksWithPending = pickupBulks;
            }
        }

        // initial values
        let totalFees = [];
        let refundValue = 0;
        let totalEligible = 0;
        let totalNonEligible = 0;
        let totalUnderOneLiter = 0;
        let totalOverOneLiter = 0;
        let totalBags = 0;

        let beverageBulk;
        let alternativeCommodities = [];

        // TODO: this logic is not very intuitive and old, refactor this later
        for (const [idx, bulk] of bulksWithPending.entries()) {
            if (!_.isNil(bulk)) {
                const bulkRates = _.find(allRates, { _id: _.get(bulk, 'rates') });
                const rates = _.get(bulkRates, 'rates', fallbackRates);
                const ignoredSkusForTotals = _rates.getIgnoredSkusForTotals(rates);
                const { customerAmount, collectorAmount, totalValue } = bulkHelper.getAmounts(bulk);
                tipAmount += _.get(bulk, 'tip.adjustedAmount', 0) * 100;

                returnTotal += customerAmount;
                depositValue += totalValue;
                refundValue += customerAmount;

                serviceFee += collectorAmount;
                customerValue += customerAmount;

                if (!beverageCommoditySkus.includes(bulk.skuType)) {
                    let commodity = {
                        label: _.startCase(bulk.skuType),
                        isCompleted: bulkHelper.isCompleted(bulk),
                        amount: bulk.commodityAmount, // eg. num of bags
                        units: _.get(commodities.find(commod => commod.skuType === bulk.skuType), 'units')
                    };
                    alternativeCommodities = [...alternativeCommodities, commodity];
                } else {
                    beverageBulk = bulk;
                }

                if (bulkHelper.isCompleted(bulk)) {
                    // TODO this logic needs to be redone eventually
                    for (const count of bulk.counts) {
                        for (const item of count.items) {
                            const skuForItem = _.get(item, 'sku', 'Invalid Sku');
                            if (ignoredSkusForTotals.includes(skuForItem)) {
                                continue;
                            }

                            if (item.size === '0-1L') {
                                totalUnderOneLiter += item.quantity;
                            } else if (item.size === '> 1L') {
                                totalOverOneLiter += item.quantity;
                            } else if (item.materialType.includes('NON')) {
                                totalNonEligible += item.quantity;
                            } else if (beverageCommoditySkus.includes(bulk.skuType)) {
                                //AUS containers don't have size
                                totalEligible += item.quantity;
                            } else {
                                // do not count for commodity items
                            }
                        }
                    }
                    if (bulk.commodityAmount > 0) {
                        // commodity amount is greater than 0, total bags needs to be incremented by processed amount
                        totalBags += bulk.commoditiesProcessed;
                    }

                    if (!isAUSRegion() || bulk.feeApplication === 'Apply to balance') {
                        _.get(bulk, 'customFees', []).forEach(fee => {
                            totalFees.push({
                                amount: _.get(fee, 'amount', 0),
                                description: _.get(fee, 'description', '')
                            });
                        });
                    }
                }

                if (idx === 0) {
                    isDonation = isDonation && !_.isNil(bulk.charity);
                    isAnonymousDonation = isAnonymousDonation && _.get(bulk, 'anonymousDonation', false);
                }
            }
        }

        bulkStatsByDateBulkType[dateBulkType] = {
            totalFees,
            refundValue,
            totalEligible,
            totalNonEligible,
            totalBags,
            totalUnderOneLiter,
            totalOverOneLiter,
            beverageBulk,
            alternativeCommodities,
            bulks
        };
    }

    return {
        bulkStatsByDateBulkType,
        bulksToSummarize,
        tipAmount,
        noRefund: !(customerValue > 0),
        depositValue,
        serviceFee,
        customerValue,
        returnTotal,
        isDonation,
        isAnonymousDonation
    };
}

function SummaryScreen(props) {
    const {
        pickups, // all completed pickups owned by the user
        bulks: bulksToShow, // all unsummarized completed bulks owned by the user
        commodities,
        isLastInteraction,
        completeFeedbackStep,
        theme,
        onClose,
        isAdmin,
        includeFeesText,
        allRates,
        rates
    } = props;
    const { lang } = useContext(LocalizationContext);
    const [selectIdx, setSelectIdx] = useState(0);

    const {
        bulkStatsByDateBulkType,
        bulksToSummarize,
        tipAmount,
        noRefund,
        depositValue,
        serviceFee,
        customerValue,
        returnTotal,
        isDonation,
        isAnonymousDonation
    } = getStatsFromBulks(bulksToShow, pickups, commodities, isLastInteraction, allRates, rates);

    const pickupDatesBulkTypes = Object.keys(bulkStatsByDateBulkType);

    let pickupDates = [],
        bulkTypes = [];
    for (const pdbt of pickupDatesBulkTypes) {
        let [pd, bt] = pdbt.split('|');
        pickupDates.push(pd);
        bulkTypes.push(bt);
    }

    const bulkStats = bulkStatsByDateBulkType[pickupDatesBulkTypes[selectIdx]];

    const totalFees = _.get(bulkStats, 'totalFees', []);
    const totalFeesPositive = _.filter(totalFees, fee => fee.amount >= 0);
    const totalFeesNegative = _.filter(totalFees, fee => fee.amount < 0);
    const totalEligible = _.get(bulkStats, 'totalEligible', 0);
    const totalBags = _.get(bulkStats, 'totalBags', 0);
    const totalNonEligible = _.get(bulkStats, 'totalNonEligible', 0);
    const totalUnderOneLiter = _.get(bulkStats, 'totalUnderOneLiter', 0);
    const totalOverOneLiter = _.get(bulkStats, 'totalOverOneLiter', 0);
    const beverageBulk = _.get(bulkStats, 'beverageBulk');
    const alternativeCommodities = _.get(bulkStats, 'alternativeCommodities', []);
    const bulks = _.get(bulkStats, 'bulks', []);
    const refundValue = _.get(bulkStats, 'refundValue', 0);

    const beverageCommoditySkus = _.filter(commodities, c => c.includeContainersInStats).map(c => c.skuType);

    // cannot use beverageBulk because it might be pending and would attach a complaint/compliment to an uncompleted bulk
    const completedBevBulk = bulks.find(bulk => beverageCommoditySkus.includes(bulk.skuType));
    const bulkForFeedback = completedBevBulk ? completedBevBulk : bulks[0];

    let headerText = isAnonymousDonation
        ? loc('summary1', lang)
        : isDonation
        ? loc('summary2', lang)
        : loc('summary3', lang);

    let amountRecycled = formatAsCurrency(0, lang);
    switch (process.env.REACT_APP_PAYMENT_MODEL) {
        case 'AUS_DIRECT_BANKING': {
            amountRecycled = (
                <>
                    <span>
                        {formatAsCurrency(customerValue - tipAmount, lang)}
                        <br />
                        {includeFeesText && (
                            <span style={{ fontSize: '1rem', color: theme.palette.text.disabled }}>
                                ({formatAsCurrency(depositValue, lang)} less fees of{' '}
                                {formatAsCurrency(serviceFee, lang)}
                                {tipAmount > 0 ? ` and driver tip of ${formatAsCurrency(tipAmount, lang)}` : ''})
                            </span>
                        )}
                    </span>
                </>
            );
            break;
        }
        case 'INTERAC_ETRANSFERS':
            amountRecycled = formatAsCurrency(returnTotal - tipAmount, lang);
            break;
        default:
            throw new Error('REACT_APP_PAYMENT_MODEL not set properly');
    }
    const showRefundAndFees =
        totalEligible +
            totalNonEligible +
            totalOverOneLiter +
            totalUnderOneLiter +
            alternativeCommodities.length +
            totalFeesPositive.length >
            0 ||
        (!_.isNil(beverageBulk) && !bulkHelper.isCompleted(beverageBulk)) ||
        (!_.isNil(beverageBulk) && totalBags > 0);
    const showPayments = totalFeesNegative.length > 0;

    const feeBreakdown = {};
    totalFeesPositive.forEach(fee => {
        if (_.isNil(_.get(feeBreakdown, fee.description))) {
            _.set(feeBreakdown, fee.description, {
                count: _.get(fee, 'count', 1),
                amount: fee.amount,
                baseAmount: fee.amount,
                description: fee.description
            });
        } else {
            let feeCopy = _.cloneDeep(_.get(feeBreakdown, fee.description));
            feeCopy.count += _.get(fee, 'count', 1);
            feeCopy.amount += fee.amount;
            _.set(feeBreakdown, fee.description, feeCopy);
        }
    });
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 4, textAlign: 'center' }}>
                {showRefundAndFees ? headerText : loc('summary17', lang)}
            </Typography>
            <Typography
                data-cy="share-and-feedback-dialog-return-amount"
                variant="h4"
                style={{
                    marginTop: theme.spacing.unit * 2,
                    textAlign: 'center',
                    color: !noRefund ? colors.green[500] : colors.red[500]
                }}
            >
                {amountRecycled}
            </Typography>
            {tipAmount > 0 && (
                <DialogContentText
                    data-cy="share-and-feedback-dialog-tip-amount"
                    style={{ textAlign: 'left', margin: 'auto', width: 'fit-content', color: colors.green[500] }}
                >
                    <div>
                        <strong>{formatAsCurrency(tipAmount || 0, lang)}</strong> tipped to driver
                    </div>
                </DialogContentText>
            )}
            {showRefundAndFees && (
                <>
                    <Typography
                        variant="h6"
                        style={{
                            margin: theme.spacing.unit * 2,
                            marginBottom: theme.spacing.unit / 2,
                            textAlign: 'center'
                        }}
                    >
                        {loc('summary4', lang)}
                    </Typography>
                    {pickupDatesBulkTypes.length > 1 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 0,
                                marginBottom: 5
                            }}
                        >
                            <IconButton
                                onClick={() => setSelectIdx(prev => prev - 1)}
                                style={{ marginLeft: theme.spacing.unit, padding: 0 }}
                                disabled={selectIdx === 0}
                            >
                                <Icon style={{ fontSize: 35 }}>chevron_left</Icon>
                            </IconButton>
                            <Typography>
                                {selectIdx + 1} of {pickupDatesBulkTypes.length}
                            </Typography>
                            <IconButton
                                onClick={() => setSelectIdx(prev => prev + 1)}
                                style={{ marginRight: theme.spacing.unit, padding: 0 }}
                                disabled={selectIdx === pickupDatesBulkTypes.length - 1}
                            >
                                <Icon style={{ fontSize: 35 }}>chevron_right</Icon>
                            </IconButton>
                        </div>
                    )}
                    <div
                        style={{
                            textAlign: 'center',
                            border: `2px solid ${colors.grey[400]}`,
                            paddingTop: '10px',
                            marginRight: '20%',
                            marginLeft: '20%',
                            paddingBottom: '10px'
                        }}
                    >
                        {!_.isNil(_.get(beverageBulk, 'dateCompleted', null)) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 1
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {moment(_.get(beverageBulk, 'dateCompleted', null)).format('MMM Do YYYY')}
                                </Typography>
                            </div>
                        )}
                        {!_.isNil(_.get(beverageBulk, 'pickup.location.description', null)) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 1
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {_.get(beverageBulk, 'pickup.location.description', null)}
                                </Typography>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <DialogContent
                                style={{ paddingTop: theme.spacing.unit, paddingBottom: theme.spacing.unit }}
                            >
                                <DialogContentText
                                    data-cy="share-and-feedback-dialog-contents"
                                    style={{ textAlign: 'center' }}
                                >
                                    {!_.isNil(beverageBulk) && !bulkHelper.isCompleted(beverageBulk) && (
                                        <div>
                                            <strong>{_.get(beverageBulk, 'commodityAmount', 0)}</strong>{' '}
                                            {loc('summary6', lang)} <StatusBadge isCompleted={false} lang={lang} />
                                        </div>
                                    )}
                                    {!_.isNil(beverageBulk) && totalBags > 0 && (
                                        <div>
                                            <strong>{totalBags || 0}</strong>{' '}
                                            {totalBags > 1
                                                ? getPlural(_.get(beverageBulk, 'commodityUOM', ''))
                                                : _.get(beverageBulk, 'commodityUOM', '')}{' '}
                                            <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    )}
                                    {totalEligible > 0 && (
                                        <div>
                                            <strong>{totalEligible || 0}</strong> {loc('summary7', lang)}{' '}
                                            <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    )}
                                    {totalNonEligible > 0 && (
                                        <div>
                                            <strong>{totalNonEligible || 0}</strong> {loc('summary8', lang)}{' '}
                                            <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    )}
                                    {totalOverOneLiter > 0 && (
                                        <div>
                                            <strong>{totalOverOneLiter || 0}</strong> {loc('summary9', lang)}{' '}
                                            <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    )}
                                    {totalUnderOneLiter > 0 && (
                                        <div>
                                            <strong>{totalUnderOneLiter || 0}</strong> {loc('summary10', lang)}{' '}
                                            <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    )}
                                    {alternativeCommodities.length > 0 &&
                                        alternativeCommodities.map(commodity => (
                                            <div key={commodity.label}>
                                                <strong>{commodity.amount}</strong>{' '}
                                                {`${_.get(commodity, `units.${lang}`, '')}${
                                                    commodity.amount > 1 ? 's' : ''
                                                }`}{' '}
                                                {commodity.label}{' '}
                                                <StatusBadge isCompleted={commodity.isCompleted} lang={lang} />
                                            </div>
                                        ))}

                                    {totalFeesPositive.length > 0 &&
                                        Object.values(feeBreakdown).map(fee => (
                                            <div key={fee.description}>
                                                {<strong>{`${fee.count} `}</strong>}
                                                {fee.description} <StatusBadge isFee={true} lang={lang} />
                                            </div>
                                        ))}
                                    {/* {totalFeesPositive.length > 0 &&
                                    totalFeesPositive.map(fee => (
                                        <div key={fee.description}>
                                            <strong>{formatAsCurrency(fee.amount)}</strong> {fee.description}{' '}
                                            <StatusBadge isFee={true} lang={lang} />
                                        </div>
                                    ))} */}
                                </DialogContentText>
                            </DialogContent>
                        </div>
                        <hr style={{ width: '60%', color: colors.grey[300] }} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="subtitle1">
                                <strong>{formatAsCurrency(refundValue || 0, lang)}</strong> total refund
                            </Typography>
                        </div>
                    </div>
                </>
            )}
            {showPayments && (
                <>
                    <Typography
                        variant="h6"
                        style={{
                            margin: theme.spacing.unit * 2,
                            marginBottom: theme.spacing.unit / 2,
                            textAlign: 'center'
                        }}
                    >
                        {loc('summary16', lang)}
                    </Typography>
                    {pickupDatesBulkTypes.length > 1 && !showRefundAndFees && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
                            <IconButton
                                onClick={() => setSelectIdx(prev => prev - 1)}
                                style={{ marginLeft: theme.spacing.unit, padding: 0 }}
                                disabled={selectIdx === 0}
                            >
                                <Icon style={{ fontSize: 35 }}>chevron_left</Icon>
                            </IconButton>
                            <IconButton
                                onClick={() => setSelectIdx(prev => prev + 1)}
                                style={{ marginRight: theme.spacing.unit, padding: 0 }}
                                disabled={selectIdx === pickupDatesBulkTypes.length - 1}
                            >
                                <Icon style={{ fontSize: 35 }}>chevron_right</Icon>
                            </IconButton>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <DialogContent style={{ paddingTop: theme.spacing.unit }}>
                            <DialogContentText
                                data-cy="share-and-feedback-dialog-contents"
                                style={{ textAlign: 'center' }}
                            >
                                {totalFeesNegative.length > 0 &&
                                    totalFeesNegative.map(fee => (
                                        <div key={fee.description}>
                                            <strong style={{ color: colors.green[500] }}>
                                                {formatAsCurrency(Math.abs(fee.amount), lang)}
                                            </strong>{' '}
                                            {fee.description} <StatusBadge isCompleted={true} lang={lang} />
                                        </div>
                                    ))}
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </>
            )}

            {!isEXPRegion() && (
                <>
                    <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 6 }}>
                        {loc('summary11', lang)}
                    </Typography>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Fab
                            data-cy="share-and-feedback-dialog-bad-button"
                            color="secondary"
                            aria-label="Add"
                            style={{
                                margin: theme.spacing.unit * 3,
                                boxShadow: 'none',
                                color: theme.palette.background.default
                            }}
                            onClick={() => completeFeedbackStep(false, bulksToSummarize, bulkForFeedback)}
                        >
                            <Icon>thumb_down</Icon>
                        </Fab>
                        <Fab
                            data-cy="share-and-feedback-dialog-good-button"
                            color="primary"
                            aria-label="Add"
                            style={{
                                margin: theme.spacing.unit * 3,
                                boxShadow: 'none',
                                backgroundColor: colors.green[500],
                                color: theme.palette.background.default
                            }}
                            onClick={() => completeFeedbackStep(true, bulksToSummarize, bulkForFeedback)}
                        >
                            <Icon>thumb_up</Icon>
                        </Fab>
                    </DialogActions>
                </>
            )}
            {isEXPRegion() && (
                <Button
                    data-cy="share-and-feedback-dialog-okay-button"
                    style={{
                        margin: theme.spacing.unit,
                        boxShadow: 'none'
                    }}
                    onClick={() => completeFeedbackStep(true, bulksToSummarize, bulkForFeedback)}
                >
                    {loc('okay', lang)}
                </Button>
            )}
            {isAdmin && (
                <>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button onClick={onClose}>Close</Button>
                    </DialogActions>
                </>
            )}
        </div>
    );
}

export default withTheme()(SummaryScreen);

const StatusBadge = ({ isCompleted, isFee, lang }) => (
    <Badge
        style={{
            backgroundColor: isFee ? colors.red[500] : isCompleted ? colors.green[500] : colors.orange[700],
            color: '#fff',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '70%'
        }}
    >
        {isFee ? loc('summary15', lang) : isCompleted ? loc('summary12', lang) : loc('summary13', lang)}
    </Badge>
);
