// General dependencies:
import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import _ from 'lodash';
import * as qs from 'query-string';

import { deviceHelper } from 'utils/misc';

import { Helmet } from 'react-helmet';

// Containers:
import Terms from 'containers/Legal/Terms/Terms';
import Privacy from 'containers/Legal/Privacy/Privacy';
import Rates from 'containers/Legal/Rates/Rates';
import Registration from '../Registration/Registration';
import CustomerSite from '../Customers/CustomerSite';
import OperatorSite from '../Operators/OperatorSite';
import Verification from '../Verification/Verification';

import { PushNotifications } from '@capacitor/push-notifications';

// contexts
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarConext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmPickup from 'containers/ConfirmPickup/ConfirmPickup';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { LocalizationProvider } from 'utils/contexts/LocalizationContext';
import { mdiMusicAccidentalDoubleSharp } from '@mdi/js';

import useIdleLogout from 'utils/hooks/useIdleLogout';
import Authorization from 'containers/Authorization/Authorization';
import ChangeEmail from 'containers/ChangeEmail/ChangeEmail';
import { ROLES } from 'constants.js';
import DownloadApp from 'containers/DownloadApp/DownloadApp';
import { _user } from 'std';

const FirebaseAdmin = require('../../firebase/firebaseAdmin.js');

function Main(props) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarConext);

    const {
        auth,
        theme,
        google,
        dispatch,
        history,
        location,
        toggleNightMode,
        onAdminTheme,
        refreshRequired,
        showThirdPartyLogins,
        brandLogoOverride,
        promosEnabled,
        pickupsEnabled,
        onShowIdleLogoutDialog,
        charityEnabled,
        idleLogoutTimeoutConfig,
        scanQREnabled,
        showQREnabled,
        registrationConfig,
        helpCenterFunctions,
        darkTheme,
        lightTheme,
        setTheme
    } = props;

    const { lang, setLang } = useContext(LocalizationContext);

    useEffect(() => {
        const { onLoginMessage, onLoginMessageVariant } = props;

        //document.title = process.env.REACT_APP_BRAND_NAME;
        onSnackbar(onLoginMessage, onLoginMessageVariant);
        try {
            if (deviceHelper.isNativeApp()) {
                FirebaseAdmin.enablePushNotifications(async tokenObj => {
                    let pushToken = _.get(tokenObj, 'value', null);

                    if (!_.isNil(pushToken) && pushToken !== '') {
                        let res = await http.post('/notifications/subscribe/push', { pushToken }, false, false);
                        if (!res.ok) {
                            console.error('Error subscribing to push notifications.');
                        }
                    }
                });
                // once logged in remove all notifications and badges
                if (deviceHelper.iOSCordova()) {
                    PushNotifications.removeAllDeliveredNotifications();
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const logOut = async (url = '/login') => {
        await FirebaseAdmin.logOutOAuth();
        localStorage.removeItem('skuTypeFilter');
        localStorage.removeItem('skuTypeFilterMulti');
        fetch(process.env.REACT_APP_API_URL + '/logout', { credentials: 'include' })
            .then(res => {
                console.log('GET /logout', res);
                if (res.status === 200 || res.status === 401) {
                    // NOTE: unathenticated call results in redirect as usual
                    // if (!_.isNil(window.cordova)) {
                    //     window.cordova.plugins.backgroundMode.setEnabled(false); // turn off background mode when they log out
                    // }
                    dispatch({ type: 'SET_AUTH_FALSE' });

                    //Always set back to light theme
                    document.body.style.backgroundColor = lightTheme.palette.background.default;
                    localStorage.setItem('theme', 'light');
                    setTheme(lightTheme);

                    setTimeout(() => {
                        history.push(url);
                    }, 100);
                } else {
                    throw new Error('Unrecoverable error.');
                }
            })
            .catch(err => {
                console.error('GET /logout', err);
            });
    };

    const handleIdleLogout = () => {
        onShowIdleLogoutDialog();
        logOut();
    };

    let timeout = 0;

    if (auth.isAuthenticated) {
        switch (_.get(auth, 'accountType', '')) {
            case 'System Administrator':
                timeout = _.get(idleLogoutTimeoutConfig, 'systemAdministrator', 0);
                break;
            case 'Customer':
                timeout = _.get(idleLogoutTimeoutConfig, 'customer', 0);
                break;
            case 'Collector Administrator':
                timeout = _.get(idleLogoutTimeoutConfig, 'collectorAdministrator', 0);
                break;
            case 'Collector Employee':
                timeout = _.get(idleLogoutTimeoutConfig, 'collectorEmployee', 0);
                break;
            default:
                if (ROLES.includes(_.get(auth, 'accountType', ''))) {
                    timeout = _.get(idleLogoutTimeoutConfig, 'systemAdministrator', 0);
                } else {
                    timeout = 0;
                    break;
                }
        }
    }
    useIdleLogout({ auth, onLogout: handleIdleLogout, timeout: timeout * 60 * 1000 });

    const logOutAll = async (url = '/login') => {
        await FirebaseAdmin.logOutOAuth();
        localStorage.removeItem('skuTypeFilter');
        fetch(process.env.REACT_APP_API_URL + `/users/${auth._id}/adminLogoutUser`, {
            credentials: 'include',
            method: 'post'
        })
            .then(res => {
                if (res.status === 200 || res.status === 401) {
                    // NOTE: unathenticated call results in redirect as usual
                    // if (!_.isNil(window.cordova)) {
                    //     window.cordova.plugins.backgroundMode.setEnabled(false); // turn off background mode when they log out
                    // }
                    dispatch({ type: 'SET_AUTH_FALSE' });
                    history.push(url);
                } else {
                    throw new Error('Unrecoverable error.');
                }
            })
            .catch(err => {
                console.error('GET /logoutAll', err);
            });
    };

    useEffect(() => {
        const pathname = _.get(location, 'pathname');
        const isCustomer = _.get(auth, 'accountType') === 'Customer';
        if (!isCustomer || !pathname || !pathname.startsWith('/customers/')) return;

        const lastPath = pathname.substring(35); // Starts after /customers/_id

        http.post(`/users/${auth._id}/lastPath`, { lastPath });
    }, [location]);

    const { pathname, search } = location;

    if (pathname === '/login' || (pathname === '/' && auth.home !== '/')) {
        return <Redirect to={auth.home} />;
    }

    const query = qs.parse(search);
    const org = !_.isNil(query) ? query.org : undefined;
    const subdivisionWithoutSpaces = _.get(query, 'subdivision', null);

    const isDriver = _user.isDriver(auth)
    
    return (
        <>
            <Helmet>
                <title>{process.env.REACT_APP_BRAND_NAME}</title>
            </Helmet>
            <Switch>
                <Route
                    path="/customers/:_id"
                    render={props => (
                        <CustomerSite
                            {...props}
                            google={google}
                            http={http}
                            auth={auth}
                            history={history}
                            org={org}
                            subdivisionWithoutSpaces={subdivisionWithoutSpaces}
                            logOut={logOut}
                            logOutAll={logOutAll}
                            onSnackbar={onSnackbar}
                            toggleNightMode={toggleNightMode}
                            onAdminTheme={onAdminTheme}
                            lang={lang}
                            setLang={setLang}
                            refreshRequired={refreshRequired}
                            brandLogoOverride={brandLogoOverride}
                            promosEnabled={promosEnabled}
                            pickupsEnabled={pickupsEnabled}
                            charityEnabled={charityEnabled}
                            scanQREnabled={scanQREnabled}
                            showQREnabled={showQREnabled}
                            helpCenterFunctions={helpCenterFunctions}
                            registrationConfig={registrationConfig}
                        />
                    )}
                />
                <Route
                    path="/operators/:_id"
                    render={props => (
                        <LocalizationProvider value={isDriver ? { lang: lang, setLang: setLang } : { lang: 'en', setLang: () => {} }}>
                            <OperatorSite
                                {...props}
                                google={google}
                                auth={auth}
                                logOut={logOut}
                                logOutAll={logOutAll}
                                toggleNightMode={toggleNightMode}
                                onAdminTheme={onAdminTheme}
                                refreshRequired={refreshRequired}
                                brandLogoOverride={brandLogoOverride}
                                promosEnabled={promosEnabled}
                                pickupsEnabled={pickupsEnabled}
                                charityEnabled={charityEnabled}
                                helpCenterFunctions={helpCenterFunctions}
                            />
                        </LocalizationProvider>
                    )}
                />
                <Route
                    exact
                    path="/register"
                    render={props => (
                        <Registration
                            {...props}
                            dispatch={dispatch}
                            google={google}
                            http={http}
                            theme={theme}
                            showThirdPartyLogins={showThirdPartyLogins}
                            brandLogoOverride={brandLogoOverride}
                            charityEnabled={charityEnabled}
                            registrationConfig={registrationConfig}
                            auth={auth}
                        />
                    )}
                />
                <Route exact path="/terms" render={props => <Terms {...props} theme={theme} http={http} />} />
                <Route exact path="/privacy" render={props => <Privacy {...props} theme={theme} http={http} />} />
                <Route
                    exact
                    path="/rates"
                    render={props => (
                        <Rates {...props} theme={theme} http={http} brandLogoOverride={brandLogoOverride} />
                    )}
                />
                <Route
                    exact
                    path="/verify/:email/:token"
                    render={props => <Verification {...props} http={http} brandLogoOverride={brandLogoOverride} />}
                />
                <Route
                    exact
                    path="/authorize/:_id/:email/:token"
                    render={props => <Authorization {...props} http={http} brandLogoOverride={brandLogoOverride} />}
                />
                <Route
                    exact
                    path="/changeEmail/:_id/:email/:token"
                    render={props => <ChangeEmail {...props} http={http} brandLogoOverride={brandLogoOverride} />}
                />
                <Route
                    exact
                    path="/confirm/:pickupID"
                    render={props => <ConfirmPickup {...props} http={http} brandLogoOverride={brandLogoOverride} />}
                />
                {scanQREnabled && (
                    <Route
                        exact
                        path="/qs/:token"
                        render={props => (
                            <DownloadApp {...props} http={http} brandLogoOverride={brandLogoOverride} auth={auth} />
                        )}
                    />
                )}
                <Route
                    exact
                    path="/:url"
                    render={props => (
                        <Redirect to={{ pathname: auth.home, search: '?org=' + props.match.params.url }} />
                    )}
                />
                <Route
                    exact
                    path="/:url/:subdivision"
                    render={props => (
                        <Redirect
                            to={{
                                pathname: auth.home,
                                search: `?org=${_.get(props, 'match.params.url', '')}&subdivision=${_.get(
                                    props,
                                    'match.params.subdivision',
                                    ''
                                )}`
                            }}
                        />
                    )}
                />
                <Redirect to="/" />
            </Switch>
        </>
    );
}

export default withRouter(Main);
