import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatAsAddress, formatAsPhoneNumber } from 'utils/misc';
import { COMMERCIAL, BOTTLE_DRIVE } from 'constants.js';
import { _pickup, _user } from 'std';
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    Icon,
    Button,
    Typography,
    Grid,
    Avatar,
    RootRef,
    Link,
    Badge
} from '@material-ui/core';
import { red, green, blue, yellow } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';
import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';
import UserNoteDialog from 'components/Dialogs/Users/UserNoteDialog';
import ReactMarkdown from 'react-markdown';
import customDriverCommentRenderer from 'utils/Markdown/customRenderer/driverComment/customDriverCommentRenderer';
import { useState } from 'react';
import { useEffect } from 'react';
import { loc } from '../../../../../localizations/localizationHandler';

const pickupTypeColor = {
    Residential: red[500],
    Commercial: blue[500],
    Condo: green[500],
    'Out of System': 'black'
};

const styles = {
    label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

const CurrentPickupUI = props => {
    const {
        updateHeight,
        pickup,
        commoditiesAvailable,
        abortMessageDialogOpen,
        setAbortMessageDialogOpen,
        operator,
        callCustomer,
        eta,
        onMode,
        typeOfPickup,
        highPriority,
        isLate,
        etaCountDownTime,
        commentUpadatedChip,
        buzzerCode,
        comment,
        suggestedBagTag,
        customerBagTag,
        pickupType,
        launchGoogleMaps,
        ETACountDown,
        theme,
        lang,
        http,
        onSnackbar
    } = props;
    const [showUserNoteDialog, setShowUserNoteDialog] = useState(false);
    const [note, setNote] = useState('');
    const [customerNotes, setCustomerNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isOTCPickup = !_.isNil(_.get(pickup, 'externalLocation'));
    const OTCLocationPhone = _.get(pickup, 'externalLocation.phone', '');

    useEffect(() => {
        let customerID = _.get(pickup, 'customer._id', null);
        if (!_.isNil(customerID)) {
            getCustomerNotes(customerID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickup.customer]);

    async function getCustomerNotes(customerID) {
        setIsLoading(true);
        const res = await http.getJSON('/users/' + customerID + '/getNotes');
        if (res.ok) {
            setCustomerNotes(res.data.notes);
        }
        setIsLoading(false);
    }

    const handleUpdateNote = async () => {
        setIsLoading(true);
        let customerID = _.get(pickup, 'customer._id', null);
        if (_.isNil(customerID)) {
            onSnackbar(`Error loading customer info.`, 'error');
            return;
        }
        const res = await http.post(`/users/${customerID}/addNote`, { note: note });
        if (res.ok) {
            await getCustomerNotes(customerID);
            onSnackbar(`Successfully updated user notes.`);
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }
        setIsLoading(false);
    };

    async function handleDelete(indexToDelete) {
        setIsLoading(true);
        if (!_.isEmpty(customerNotes)) {
            let customerID = _.get(pickup, 'customer._id', null);
            if (_.isNil(customerID)) {
                onSnackbar(`Error loading customer info.`, 'error');
                return;
            }

            let currentCustomerNotes = _.cloneDeep(customerNotes);
            currentCustomerNotes.splice(indexToDelete, 1);

            const res = await http.post(`/users/${customerID}/setNotes`, { notes: currentCustomerNotes });
            if (res.ok) {
                await getCustomerNotes(customerID);
                onSnackbar(`Successfully deleted user notes.`);
            } else if (res.status === 400) {
                onSnackbar(res.errorMessage + '.', 'error');
            }
        }
        setIsLoading(false);
    }

    return (
        <div>
            <UserNoteDialog
                open={showUserNoteDialog}
                onSave={() => {
                    handleUpdateNote();
                    setNote('');
                }}
                onCancel={() => {
                    setNote('');
                    setShowUserNoteDialog(false);
                }}
                onDelete={handleDelete}
                title={loc('customerNotes', lang)}
                isLoading={isLoading}
                note={note}
                notes={customerNotes}
                onChangeNote={(event, newValue) => {
                    setNote(event.target.value);
                }}
            />

            <Dialog open={abortMessageDialogOpen} onClose={() => setAbortMessageDialogOpen(false)}>
                <DialogContent>
                    <DialogContentText>Please contact support to abort this pickup.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        data-cy="abort-message-dialog-close"
                        color="primary"
                        onClick={() => setAbortMessageDialogOpen(false)}
                    >
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid
                container
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    flexWrap: 'nowrap',
                    marginBottom: theme.spacing.unit
                }}
            >
                <Button
                    variant="contained"
                    data-cy="info-panel-pickup-address"
                    style={{
                        flex: '1 1 0',
                        fontSize: theme.typography.fontSize * 1.1,
                        background: theme.palette.common.black,
                        color: theme.palette.common.white,
                        justifyContent: 'flex-start',
                        whiteSpace: 'nowrap',
                        fontWeight: 'normal'
                    }}
                    onClick={launchGoogleMaps(pickup.location)}
                >
                    <Icon style={{ marginRight: theme.spacing.unit }}>directions</Icon>
                    <span
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {formatAsAddress(pickup.location)}
                    </span>
                </Button>
            </Grid>
            <Grid
                container
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    flexWrap: 'nowrap'
                }}
            >
                {(!_.isNil(pickup.phone) ||
                    !_.isNil(pickup.customer) ||
                    (isOTCPickup && !_.isEmpty(OTCLocationPhone))) &&
                    _.get(operator, 'permissions.seeCustomerPhoneNumbers', false) && (
                        <Button
                            variant="contained"
                            style={{
                                flex: '1 1 0',
                                fontSize: theme.typography.fontSize * 1.1,
                                background: theme.palette.common.black,
                                color: theme.palette.common.white,
                                justifyContent: 'flex-start',
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal'
                            }}
                            onClick={() => {
                                if (isOTCPickup) {
                                    callCustomer(OTCLocationPhone);
                                } else {
                                    callCustomer();
                                }
                            }}
                        >
                            <Avatar
                                style={{
                                    marginRight: theme.spacing.unit,
                                    background: theme.palette.common.white,
                                    color: theme.palette.text.primary,
                                    width: 24,
                                    height: 24
                                }}
                            >
                                <Icon style={{ fontSize: 'smaller' }}>phoneintalk</Icon>
                            </Avatar>
                            <span
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {isOTCPickup
                                    ? formatAsPhoneNumber(OTCLocationPhone)
                                    : formatAsPhoneNumber(_pickup.getPhoneNumber(pickup, pickup.customer))}
                            </span>
                        </Button>
                    )}{' '}
            </Grid>
            <Grid
                item
                container
                style={{
                    paddingTop: theme.spacing.unit,
                    paddingBottom: theme.spacing.unit,
                    borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    flexWrap: 'nowrap'
                }}
            >
                <Grid
                    item
                    style={{
                        background: pickup.skipped ? yellow[500] : red[800],
                        padding: theme.spacing.unit / 2,
                        borderRadius: theme.shape.borderRadius,
                        marginRight: theme.spacing.unit,
                        alignContent: 'center'
                    }}
                >
                    {pickup.skipped ? (
                        <Typography
                            align="center"
                            style={{
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                                fontSize: theme.typography.fontSize * 0.85,
                                color: theme.palette.common.black
                            }}
                        >
                            {loc('skipped', lang).toUpperCase()}
                        </Typography>
                    ) : (
                        <>
                            <Typography
                                align="center"
                                style={{
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    fontSize: theme.typography.fontSize * 0.85,
                                    color: theme.palette.common.white
                                }}
                            >
                                {eta &&
                                    moment(eta)
                                        .tz(_.get(pickup, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
                                        .format('h:mm A')}
                            </Typography>
                            {eta && isLate && (
                                <Typography
                                    align="center"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontWeight: 'bold',
                                        fontSize: theme.typography.fontSize * 0.85,
                                        color: theme.palette.common.white
                                    }}
                                >
                                    (
                                    {moment(eta)
                                        .tz(_.get(pickup, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
                                        .toNow(true)}
                                    )
                                </Typography>
                            )}
                            <Typography
                                align="center"
                                style={{
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    fontSize: theme.typography.fontSize * 0.85,
                                    color: theme.palette.common.white
                                }}
                            >
                                ETA
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    xs={10}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Grid container style={{ flexWrap: 'nowrap' }}>
                        <Grid item style={{ maxWidth: '50%' }}>
                            <Typography
                                style={{
                                    backgroundColor: _.get(pickupTypeColor, typeOfPickup, 'rgba(0, 0, 0, .54)'),
                                    color: theme.palette.common.white,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    padding: theme.spacing.unit / 2,
                                    borderRadius: theme.shape.borderRadius,
                                    fontSize: theme.typography.fontSize * 0.95,
                                    alignContent: 'center',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                {_pickup.isCommercial(pickup) ? (
                                    <>{_.get(pickup, 'location.businessName', <i>Commercial</i>)}</>
                                ) : (
                                    typeOfPickup
                                )}
                            </Typography>
                        </Grid>
                        <Grid item style={{ alignContent: 'center', maxWidth: '50%' }}>
                            <Typography
                                style={{
                                    color: theme.palette.primary.dark,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontWeight: 'bold'
                                }}
                                data-cy="driver-customer-name"
                            >
                                {isOTCPickup
                                    ? `${_.get(pickup, 'externalLocation.group.name', '')} - ${_.get(
                                          pickup,
                                          'externalLocation.name',
                                          ''
                                      )}`
                                    : pickupType === COMMERCIAL
                                    ? `${_.get(pickup, 'location.businessName', _user.getNameFull(pickup.customer))}`
                                    : pickupType === BOTTLE_DRIVE
                                    ? `${_.get(
                                          pickup,
                                          'location.organizationName',
                                          _user.getNameFull(pickup.customer)
                                      )}`
                                    : _user.getNameFull(pickup.customer)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            marginTop: theme.spacing.unit / 2
                        }}
                    >
                        {!_.isNil(pickup.customer) && (!_.isNil(suggestedBagTag) || !_.isNil(customerBagTag)) && (
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <>
                                    <Icon
                                        style={{
                                            color: theme.palette.text.primary,
                                            fontSize: 20,
                                            marginRight: theme.spacing.unit / 4
                                        }}
                                    >
                                        label
                                    </Icon>
                                    <Typography>
                                        {_.get(pickup, 'customer.isSchemeIDUser', false)
                                            ? pickup.customer.schemeID
                                            : (!_.isNil(suggestedBagTag) && suggestedBagTag) +
                                              (!_.isNil(suggestedBagTag) && !_.isNil(customerBagTag) && ' or ') +
                                              (!_.isNil(customerBagTag) && customerBagTag)}
                                    </Typography>
                                </>
                            </Grid>
                        )}
                        {highPriority && (
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <Icon
                                    style={{
                                        color: theme.palette.text.primary,
                                        fontSize: 20,
                                        marginRight: theme.spacing.unit / 4
                                    }}
                                >
                                    star
                                </Icon>
                                <Typography>{_.capitalize(loc('priority', lang))}</Typography>
                            </Grid>
                        )}
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <DetailedPayloadIndicators
                                showUnits
                                commodities={commoditiesAvailable}
                                indicatorSize={0.75}
                                pickups={[pickup]}
                                style={{ flexWrap: 'nowrap' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {pickup.skipped && (
                <Grid item container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: theme.spacing.unit,
                            marginBottom: theme.spacing.unit
                        }}
                    >
                        <Typography>
                            <b>{loc('skippedReason', lang)}:</b> {pickup.explanation ? pickup.explanation : 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                container
                style={{
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between'
                }}
            >
                <CustomerComment
                    theme={theme}
                    commentUpadatedChip={commentUpadatedChip}
                    comment={comment}
                    numberOfBins={_.get(pickup, 'numberOfBins', null)}
                    buzzerCode={buzzerCode}
                    updateHeight={updateHeight}
                    lang={lang}
                />
            </Grid>
            <Grid item container spacing={theme.spacing.unit} style={{ marginTop: theme.spacing.unit * 2 }}>
                <Grid item xs={2}>
                    <Button fullWidth variant="outlined" color="default" onClick={() => onMode('SELECT')}>
                        <Icon color="action">arrow_back</Icon>
                    </Button>
                </Grid>
                <Grid item xs={!_.isNil(pickup.customer) ? 5 : 7}>
                    <Button
                        fullWidth
                        variant="contained"
                        data-cy="finish-pickup-button"
                        style={{
                            background: theme.palette.common.black,
                            color: theme.palette.common.white
                        }}
                        onClick={() => onMode('PICKUP_SELECT')}
                    >
                        {loc('finishPickup', lang)}
                    </Button>
                </Grid>
                {!_.isNil(pickup.customer) && (
                    <Grid item xs={2}>
                        <Badge color="primary" badgeContent={_.size(customerNotes)}>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="default"
                                onClick={() => {
                                    setShowUserNoteDialog(true);
                                }}
                            >
                                <Icon color="action">add_comment</Icon>
                            </Button>
                        </Badge>
                    </Grid>
                )}
                {!_.isNil(etaCountDownTime) && (
                    <Grid item xs={3}>
                        <Button fullWidth disabled variant="outlined">
                            <ETACountDown eta={etaCountDownTime} theme={theme} />
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

const CustomerComment = ({ theme, commentUpadatedChip, comment, numberOfBins, buzzerCode, updateHeight, lang }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [overFlowHeight, setOverFlowHeight] = useState(110);
    const [isOverFlow, setIsOverFlow] = useState(false);

    let primaryTypographyRef = React.createRef();

    useEffect(() => {
        if (!_.isNil(primaryTypographyRef.current)) {
            const element = primaryTypographyRef.current;
            setIsOverFlow(element.offsetHeight > overFlowHeight);
        }
    }, [primaryTypographyRef, overFlowHeight]);

    useEffect(() => {
        updateHeight();
    }, [isExpanded]);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            {!_.isEmpty(comment) ? (
                <div style={{ maxHeight: isExpanded ? 'unset' : overFlowHeight, overflow: 'hidden' }}>
                    <RootRef rootRef={primaryTypographyRef}>
                        <div>
                            <div style={{ marginTop: theme.spacing.unit }}>{commentUpadatedChip}</div>
                            <Typography>
                                {buzzerCode && `(Buzzer Code: ${buzzerCode})`}
                                {!_.isNull(numberOfBins) && _.size(numberOfBins) > 0 && `(Bins: ${numberOfBins})`}
                            </Typography>
                            <ReactMarkdown
                                allowedTypes={[
                                    'root',
                                    'text',
                                    'break',
                                    'paragraph',
                                    'emphasis',
                                    'strong',
                                    'list',
                                    'listItem',
                                    'image'
                                ]}
                                unwrapDisallowed
                                source={comment}
                                renderers={customDriverCommentRenderer}
                            />
                        </div>
                    </RootRef>
                </div>
            ) : (
                <Typography style={{ color: theme.palette.text.disabled }}>{loc('noDirections', lang)}</Typography>
            )}

            {!_.isEmpty(comment) && isOverFlow && (
                <Link
                    style={{ marginTop: isExpanded ? null : theme.spacing.unit }}
                    component="button"
                    size="small"
                    onClick={handleClick}
                >
                    {isExpanded ? `(${loc('showLess', lang)})` : `(${loc('showMore', lang)})`}
                </Link>
            )}
        </div>
    );
};

export default withStyles(styles)(withTheme()(CurrentPickupUI));
