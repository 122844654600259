import React, { useEffect, useContext, useState } from 'react';

import _ from 'lodash';

import {
    Button,
    Typography,
    Tooltip,
    colors,
    Table,
    TableBody,
    TableRow,
    TableCell,
    withTheme,
    Divider,
    Paper
} from '@material-ui/core';

import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import bulkHelper from 'helpers/bulkHelper';

import { _bulk, _commodity, _user } from 'std';

import {
    isEXPRegion,
    formatAsCurrency,
    uppercaseFirstLetter,
    getPlural,
    getCustomerFirstNameAndLastInitial
} from 'utils/misc';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import HttpContext from 'utils/contexts/HttpContext';
import { openWindow } from 'helpers/windowHelper';

import Bagtags from './Bagtags';

const tableCellStyles1 = { width: '75%' };
const tableCellStyles2 = { width: '25%' };

function ProcessingSummary(props) {
    const {
        bulks,
        customer,
        pickup,
        adminView,
        commoditiesAvailable,
        history,
        auth,
        rates,
        allRates = [],
        onViewCustomerReport,
        onViewComplaintDialog,
        style,
        payloadIssuesTxt,
        theme,
        rolePermissions,
        clerkComplaints
    } = props;

    // const [allRates, setAllRates] = useState([]);
    // const getAllRates = async () => {
    //     let res = await http.getJSON(`/rates/allRates`);
    //     if (res.ok) {
    //         let ratesArr = [];
    //         Object.values(res.data.rates).map((val, index) => {
    //             ratesArr[val._id] = val.rates;
    //         });
    //         setAllRates(ratesArr);
    //     }
    // };
    // useEffect(() => {
    //     getAllRates();
    // }, []);

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const beverageBulks = _.filter(bulks, bulk => _bulk.isBeverage(bulk));
    const firstBulk = _.first(beverageBulks) || _.first(bulks);
    const manuallyCountedBulks = _.filter(
        bulks,
        b => bulkHelper.isCommodityManual(b, commoditiesAvailable) && b.dateCounted
    );
    const nonManuallyCountedBulks = _.filter(
        bulks,
        b => !bulkHelper.isCommodityManual(b, commoditiesAvailable) && b.dateCounted
    );
    const lastManualCountedBulk = _.last(manuallyCountedBulks);
    const lastCount = _.last(_.get(lastManualCountedBulk, 'counts', []));
    const lastCounter = _.get(lastCount, 'operator');

    const sessions = _.get(firstBulk, 'countingSessions', []);

    let machineCounter = '';
    const firstSession = _.first(sessions);
    const tomraVariables = _.get(firstSession, 'tomraVariables');
    if (tomraVariables) {
        const machine = tomraVariables.rvmName;
        const serialNumber = tomraVariables.rvmSerialNo;
        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;
    }

    const allBagTags = bulks.flatMap(b =>
        isEXPRegion() && !_.isNil(b.expectedBagtags) && !_.isEmpty(b.expectedBagtags)
            ? b.expectedBagtags.map(bt => ({ bagtag: bt, dateCounted: b.dateCounted }))
            : { bagtag: b.label, dateCounted: b.dateCounted }
    );

    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const timezone = _.get(firstBulk, 'pickup.location.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const hasComplaints = _.get(firstBulk, 'customerIssues.customerHasIssue', false);

    const payloadIssues = _.filter(_.get(firstBulk, 'payloadIssues.issues', []), issue => {
        const complaint = _.find(clerkComplaints, { code: issue });
        return _.isNil(complaint) || !complaint.suppressWarnings;
    });
    const payloadIssuesImages = _.get(firstBulk, 'payloadIssues.images', []);
    const hasIssues = !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);
    const customerPaymentFees = _.filter(_.get(firstBulk, 'customFees', []), fee =>
        _.get(fee, 'description', '').includes('paid with card ending in')
    );

    const handleViewBulk = async (externalWindow, bulk) => {
        let res;
        if (!_.isNil(pickup)) {
            res = await http.getJSON(`/bulk/?pickup_id=${_.get(pickup, '_id')}`);
        }
        if (_.isNil(pickup) || !res.ok || !_.get(res.data, 'bulk._id', null)) {
            if (!_.isNil(bulk)) {
                const URL = `/operators/${auth._id}/bulks/${bulk._id}`;
                openWindow(history, externalWindow, URL);
            }
            return;
        }

        const URL = `/operators/${auth._id}/bulks/${res.data.bulk._id}`;
        openWindow(history, externalWindow, URL);
    };

    //const commodityAmount = _.reduce(bulks, (sum, b) => sum + _.get(b, 'commodityAmount', 0), 0);

    // Hide component if beverage bulks aren't closed
    if (!adminView) {
        for (let beverageBulk of beverageBulks) {
            if (!beverageBulk.dateCompleted) {
                return <></>;
            }
        }
    }

    return (
        <>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default,
                    ...style
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2, fontSize: '1rem' }}>
                        {loc('dashboardHistory59', lang)}
                    </Typography>
                </div>
                {hasComplaints && (
                    <Complaints
                        adminView={adminView}
                        firstBulk={firstBulk}
                        onViewComplaintDialog={onViewComplaintDialog}
                        lang={lang}
                        theme={theme}
                    />
                )}
                <Typography style={{ marginTop: hasComplaints ? 5 : 0, marginBottom: 10 }} color="textSecondary">
                    {!_bulk.isAdjustment(firstBulk) ? (
                        <>
                            {loc('dashboardHistory72', lang)}{' '}
                            <b>
                                {locDate(
                                    firstBulk.dateCompleted || firstBulk.dateCounted,
                                    'MMM Do, YYYY',
                                    lang,
                                    timezone
                                )}{' '}
                                {loc('at', lang)}{' '}
                                {locDate(firstBulk.dateCompleted || firstBulk.dateCounted, 'h:mm a', lang, timezone)}.
                            </b>{' '}
                        </>
                    ) : (
                        <>
                            {`${
                                _.isEmpty(_.get(firstBulk, 'adjustmentReason', ''))
                                    ? loc('dashboardHistory73b', lang)
                                    : loc('dashboardHistory73', lang, {
                                          adjustmentReason: _.get(firstBulk, 'adjustmentReason', '').toLowerCase()
                                      })
                            }.${
                                _.isEmpty(_.get(firstBulk, 'referenceNote', ''))
                                    ? ''
                                    : ` ${loc('dashboardHistory75', lang, {
                                          referenceNote: _.get(firstBulk, 'referenceNote', '')
                                      })}${_.get(firstBulk, 'referenceNote', '').slice(-1) === '.' ? '' : '.'}`
                            }`}
                        </>
                    )}
                </Typography>
                {hasIssues && (
                    <Issues
                        firstBulk={firstBulk}
                        payloadIssuesTxt={payloadIssuesTxt}
                        lang={lang}
                        onViewCustomerReport={onViewCustomerReport}
                    />
                )}
                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                    <TableBody>
                        {customerPaymentFees.map((fee, i) => (
                            <TableRow key={i} style={{ height: 20 }}>
                                <TableCell style={{ ...tableCellStyles, ...tableCellStyles1 }}>
                                    {loc('paymentReceived', lang)}
                                </TableCell>
                                <TableCell
                                    style={{
                                        ...tableCellStyles,
                                        ...tableCellStyles2,
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {formatAsCurrency(fee.amount * -1)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <ManuallyCountedBulks
                    manuallyCountedBulks={manuallyCountedBulks}
                    commoditiesAvailable={commoditiesAvailable}
                    allRates={allRates}
                    fallbackRates={rates}
                    lang={lang}
                    tableCellStyles={tableCellStyles}
                    theme={theme}
                    allowedToViewBagTag={customer.allowViewBagtags}
                    adminView={adminView}
                />
                {!_.isEmpty(nonManuallyCountedBulks) && (
                    <NonManuallyCountedBulks
                        lang={lang}
                        nonManuallyCountedBulks={nonManuallyCountedBulks}
                        commoditiesAvailable={commoditiesAvailable}
                        allRates={allRates}
                        fullbackRates={rates}
                        tableCellStyles={tableCellStyles}
                        theme={theme}
                    />
                )}
                {(adminView || customer.allowViewBagtags) && (
                    <>
                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div style={{ width: '100%' }}>
                                {adminView && (
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                                        <Tooltip title="View Bulk">
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    padding: theme.spacing.unit / 2,
                                                    margin: theme.spacing.unit / 2,
                                                    minWidth: 32
                                                }}
                                                onClick={e =>
                                                    (_user.isSystemAdmin(auth) ||
                                                        (_user.isInternalRole(auth) &&
                                                            rolePermissions &&
                                                            rolePermissions.orders &&
                                                            rolePermissions.orders.counter)) &&
                                                    handleViewBulk(e.ctrlKey, firstBulk)
                                                }
                                            >
                                                <MDIcon
                                                    path={_.get(allIcons, 'mdiAccountHardHat')}
                                                    size={0.9}
                                                    color={theme.palette.text.secondary}
                                                    style={{ margin: theme.spacing.unit / 2 }}
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                        {machineCounter
                                                            ? machineCounter
                                                            : !_.isNil(lastCounter)
                                                            ? getCustomerFirstNameAndLastInitial(lastCounter)
                                                            : 'TBD'}
                                                    </Typography>
                                                </div>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                                <Bagtags
                                    customer={customer}
                                    bagtags={allBagTags}
                                    sessions={sessions}
                                    collector={_.get(firstBulk, 'collector')}
                                    lang={lang}
                                    theme={theme}
                                />
                                {firstBulk.isLostAndFound && <Typography>{loc('dashboardHistory25', lang)}</Typography>}
                            </div>
                        </div>
                    </>
                )}
            </Paper>
        </>
    );
}

export default withTheme()(ProcessingSummary);

function getRateDescription(rate, lang) {
    const fallbackDescription = _.get(rate, 'description');
    const description = _.get(rate, `descriptions.${lang}`, fallbackDescription);

    return description;
}

function getCombinedCounts(bulk, rates, lang) {
    let countsCombined = [];
    // let conformants = {};
    let nonConformants = {};
    let conformantsByDescriptionAndValue = {};

    if (_.has(bulk, 'counts')) {
        bulk.counts.forEach(count => {
            if (_.has(count, 'items')) {
                count.items.forEach(item => {
                    const rateForSku = _.find(rates, { sku: item.sku });
                    const updatedItemObj = {
                        sku: item.sku,
                        size: item.size,
                        quantity: item.quantity,
                        materialType: item.materialType,
                        fullDescription: (
                            <span>
                                {getRateDescription(rateForSku, lang) || item.materialType}{' '}
                                {!_.isEmpty(item.size) ? (
                                    <span style={{ whiteSpace: 'nowrap' }}>({item.size})</span>
                                ) : null}
                            </span>
                        )
                    };
                    const description = getRateDescription(rateForSku, lang) || item.materialType;
                    const rateValue = _.get(rateForSku, 'value', 0) * -1;
                    let groupName = `${description}${rateValue}`.trim();
                    if (['NON', 'Non-conformant'].includes(item.materialType)) {
                        if (!_.isNil(nonConformants[item.sku])) {
                            updatedItemObj.quantity += nonConformants[item.sku].quantity;
                        }
                        nonConformants[item.sku] = updatedItemObj;
                    } else {
                        if (!_.isNil(conformantsByDescriptionAndValue[groupName])) {
                            updatedItemObj.quantity += conformantsByDescriptionAndValue[groupName].quantity;
                        }

                        conformantsByDescriptionAndValue[groupName] = updatedItemObj;

                        // if (!_.isNil(conformants[item.sku])) {
                        // updatedItemObj.quantity += conformants[item.sku].quantity;
                        // }
                        // conformants[item.sku] = updatedItemObj;
                    }
                });
            }
        });

        // const sortedCounts = _.sortBy(Object.values(conformants), ['size', 'materialType']);
        const sortedCounts = _.sortBy(Object.values(conformantsByDescriptionAndValue), ['size', 'materialType']);
        const nonConformantCounts = Object.values(nonConformants);

        countsCombined = _.concat(sortedCounts, nonConformantCounts);
    }
    return countsCombined;
}

function getCombinedCountsCountingSession(countingSession, rates, lang) {
    let countsCombined = [];
    // let conformants = {};
    let nonConformants = {};
    let conformantsByDescriptionAndValue = {};

    if (_.has(countingSession, 'items')) {
        _.get(countingSession, 'items', []).forEach(item => {
            const rateForSku = _.find(rates, { sku: item.sku });
            const updatedItemObj = {
                sku: item.sku,
                size: item.size,
                quantity: item.quantity,
                materialType: item.materialType,
                fullDescription: (
                    <span>
                        {getRateDescription(rateForSku, lang) || item.materialType}{' '}
                        {!_.isEmpty(item.size) ? <span style={{ whiteSpace: 'nowrap' }}>({item.size})</span> : null}
                    </span>
                )
            };
            const description = getRateDescription(rateForSku, lang) || item.materialType;
            const rateValue = _.get(rateForSku, 'value', 0) * -1;
            let groupName = `${description}${rateValue}`.trim();
            if (['NON', 'Non-conformant'].includes(item.materialType)) {
                if (!_.isNil(nonConformants[item.sku])) {
                    updatedItemObj.quantity += nonConformants[item.sku].quantity;
                }
                nonConformants[item.sku] = updatedItemObj;
            } else {
                if (!_.isNil(conformantsByDescriptionAndValue[groupName])) {
                    updatedItemObj.quantity += conformantsByDescriptionAndValue[groupName].quantity;
                }

                conformantsByDescriptionAndValue[groupName] = updatedItemObj;

                // if (!_.isNil(conformants[item.sku])) {
                // updatedItemObj.quantity += conformants[item.sku].quantity;
                // }
                // conformants[item.sku] = updatedItemObj;
            }
        });

        // const sortedCounts = _.sortBy(Object.values(conformants), ['size', 'materialType']);
        const sortedCounts = _.sortBy(Object.values(conformantsByDescriptionAndValue), ['size', 'materialType']);
        const nonConformantCounts = Object.values(nonConformants);

        countsCombined = _.concat(sortedCounts, nonConformantCounts);
    }
    return countsCombined;
}

function getGroupedCounts({ b, rates, commodityIcon, tableCellStyles, theme, lang, beverageCommoditySkus }) {
    let totalCounts = 0,
        counts = <></>;

    let countsCombined = [];
    countsCombined = getCombinedCounts(b, rates, lang);
    totalCounts = countsCombined.reduce((sum, count) => sum + count.quantity, 0);
    let displayLine =
        bulkHelper.getCustomerAmount(b) !== 0 ||
        // _.get(b, 'skuType', '') === 'beverage' ||
        // _.get(b, 'skuType', '') === 'collectionbins' ||
        beverageCommoditySkus.includes(_.get(b, 'skuType', '')) ||
        bulkHelper.isDonating(b);
    if (!_.isEmpty(countsCombined)) {
        counts = countsCombined.map(
            count =>
                displayLine && (
                    <TableRow key={count.sku} style={{ height: 20 }}>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles1,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                    ? colors.red[500]
                                    : theme.palette.text.secondary
                            }}
                        >
                            <span style={{ marginRight: theme.spacing.unit }}>
                                <MDIcon
                                    path={_.get(allIcons, commodityIcon)}
                                    size={0.6}
                                    color={theme.palette.text.secondary}
                                />
                            </span>
                            {count.fullDescription}
                        </TableCell>
                        <TableCell
                            style={{
                                ...tableCellStyles,
                                ...tableCellStyles2,
                                paddingTop: 0,
                                paddingBottom: 0,
                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                    ? colors.red[500]
                                    : theme.palette.text.secondary,
                                textAlign: 'right'
                            }}
                        >
                            {_.round(count.quantity, 2)}{' '}
                            {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                        </TableCell>
                    </TableRow>
                )
        );
    } else {
        counts = <></>;
    }

    return { totalCounts, counts };
}

function getGroupedCountsByBagtag({ b, rates, commodityIcon, tableCellStyles, theme, lang, beverageCommoditySkus }) {
    let totalCounts = 0,
        counts = <></>;

    let displayLine =
        bulkHelper.getCustomerAmount(b) !== 0 ||
        // _.get(b, 'skuType', '') === 'beverage' ||
        // _.get(b, 'skuType', '') === 'collectionbins' ||
        beverageCommoditySkus.includes(_.get(b, 'skuType', '')) ||
        bulkHelper.isDonating(b);

    const countingSessions = _.get(b, 'countingSessions', []);
    if (!_.isEmpty(countingSessions)) {
        counts = countingSessions.map((countingSession, i) => {
            const countsCombined = getCombinedCountsCountingSession(countingSession, rates, lang);
            totalCounts += countsCombined.reduce((sum, count) => sum + count.quantity, 0);
            return (
                displayLine && (
                    <React.Fragment key={i}>
                        <TableRow style={{ height: 20 }}>
                            <TableCell
                                colSpan={2}
                                style={{
                                    ...tableCellStyles,
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <div
                                    style={{
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <MDIcon
                                        path={_.get(allIcons, 'mdiLabel')}
                                        size={0.6}
                                        color={theme.palette.text.secondary}
                                        style={{ marginRight: theme.spacing.unit }}
                                    />
                                    <span>{_.get(countingSession, 'bagtag', '')}</span>
                                </div>
                            </TableCell>
                        </TableRow>
                        {countsCombined.map(
                            count =>
                                displayLine && (
                                    <TableRow key={count.sku} style={{ height: 20 }}>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                ...tableCellStyles1,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                                    ? colors.red[500]
                                                    : theme.palette.text.secondary
                                            }}
                                        >
                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                <MDIcon
                                                    path={_.get(allIcons, commodityIcon)}
                                                    size={0.6}
                                                    color={theme.palette.text.secondary}
                                                />
                                            </span>
                                            {count.fullDescription}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                ...tableCellStyles2,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                color: ['NON', 'Non-conformant'].includes(count.materialType)
                                                    ? colors.red[500]
                                                    : theme.palette.text.secondary,
                                                textAlign: 'right'
                                            }}
                                        >
                                            {_.round(count.quantity, 2)}{' '}
                                            {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                                        </TableCell>
                                    </TableRow>
                                )
                        )}
                    </React.Fragment>
                )
            );
        });
    } else {
        counts = <></>;
    }

    return { totalCounts, counts };
}

const Complaints = ({ adminView, firstBulk, onViewComplaintDialog, lang, theme }) => {
    return (
        <Typography>
            <span style={{ color: colors.red[500] }}>{uppercaseFirstLetter(loc('complaints', lang))}:</span>
            <span
                onClick={adminView && onViewComplaintDialog(firstBulk, null)}
                style={{
                    color: bulkHelper.hasUnresolvedCustomerIssue(firstBulk) && colors.red[500],
                    cursor: adminView && 'pointer'
                }}
            >
                <span
                    style={{
                        background: bulkHelper.hasUnresolvedCustomerIssue(firstBulk)
                            ? colors.red[500]
                            : colors.blue[100],
                        color: theme.palette.getContrastText(
                            bulkHelper.hasUnresolvedCustomerIssue(firstBulk) ? colors.red[500] : colors.blue[100]
                        ),
                        fontSize: theme.typography.fontSize * 0.875,
                        marginRight: theme.spacing.unit,
                        marginLeft: theme.spacing.unit,
                        padding: '2px 4px',
                        borderRadius: 4
                    }}
                >
                    {bulkHelper.hasUnresolvedCustomerIssue(firstBulk) ? 'UNRESOLVED' : 'RESOLVED'}
                </span>
                <span
                    style={{
                        textDecoration: 'underline',
                        color: bulkHelper.hasUnresolvedCustomerIssue(firstBulk)
                            ? colors.red[500]
                            : theme.palette.text.secondary
                    }}
                >
                    {_.get(firstBulk, 'customerIssues.issueDescription', '')}
                </span>
            </span>
        </Typography>
    );
};

const Issues = ({ firstBulk, payloadIssuesTxt, lang, onViewCustomerReport }) => {
    return (
        <Typography style={{ marginTop: 5 }} color="textSecondary">
            <span style={{ color: colors.red[500] }}>
                {loc('dashboardHistory21', lang)}: {payloadIssuesTxt.map(issue => issue.name).join(', ')}
                {'. '}
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={onViewCustomerReport(firstBulk, null)}
                >
                    {loc('dashboardHistory22', lang)}
                </span>
            </span>
        </Typography>
    );
};

const ManuallyCountedBulks = ({
    manuallyCountedBulks,
    commoditiesAvailable,
    allRates,
    fallbackRates,
    lang,
    tableCellStyles,
    theme,
    allowedToViewBagTag,
    adminView
}) => {
    const beverageCommoditySkus = _.filter(commoditiesAvailable, c => c.includeContainersInStats).map(c => c.skuType);
    return manuallyCountedBulks.map(b => {
        if (_.isEmpty(_.get(b, 'counts', []))) return null;

        const isManual = bulkHelper.isCommodityManual(b, commoditiesAvailable);

        const collectorRatesId = _.get(b, 'collector.configuration.rates');

        const bulkRates = _.find(allRates, { _id: _.get(b, 'rates') });
        const filteredRates = _.get(bulkRates, 'rates', fallbackRates);

        let commodityType = b.skuType;
        let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
        if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
            commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, b.skuType);
        }
        const commodityIcon = _.get(commodityForBulk, 'iconName');

        let subPayloads = _(_.get(b, 'commoditiesProcessedBreakdown', []))
            .filter({ isSubCommodity: true })
            .map(entry => {
                return {
                    amount: _.get(entry, 'totalAmount', 0),
                    skuType: b.skuType,
                    subCommodity: _.get(entry, 'subCommodity')
                };
            })
            .value();

        const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
            [{ payload: {}, subPayloads }],
            commoditiesAvailable
        );
        const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

        let counts = <></>;
        let totalCounts = 0;

        let isRogueBulk = _.get(b, 'autoComplete', false);
        let noExpectedBagTags = _.isNil(b.expectedBagtags) || _.isEmpty(b.expectedBagtags);

        if (b.dateCounted) {
            if ((noExpectedBagTags && !isRogueBulk) || (!allowedToViewBagTag && !adminView)) {
                const { totalCounts: totalCountsRet, counts: countsRet } = getGroupedCounts({
                    b,
                    rates: filteredRates,
                    commodityIcon,
                    tableCellStyles,
                    theme,
                    lang,
                    beverageCommoditySkus
                });
                totalCounts = totalCountsRet;
                counts = countsRet;
            } else {
                const { totalCounts: totalCountsRet, counts: countsRet } = getGroupedCountsByBagtag({
                    b,
                    rates: filteredRates,
                    commodityIcon,
                    tableCellStyles,
                    theme,
                    lang,
                    beverageCommoditySkus
                });
                totalCounts = totalCountsRet;
                counts = countsRet;
            }
        }

        let driverTip = _.get(b, 'tip.adjustedAmount');
        if (!_.isNil(driverTip)) driverTip *= 100;

        return (
            <div style={{ marginTop: 5 }} key={b._id}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography color="textSecondary">{uppercaseFirstLetter(commodityType)}</Typography>
                    {!b.dateCounted && (
                        <Typography style={{ marginRight: theme.spacing.unit }} color="textSecondary">
                            {loc('taxReceipts8', lang)}
                        </Typography>
                    )}
                    {/*display total counts inline with commodity type for non manual */}
                    {b.dateCounted && !isManual && (
                        <Typography style={{ marginRight: theme.spacing.unit }} color="textSecondary">
                            {_.round(totalCounts, 2)}{' '}
                            {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                        </Typography>
                    )}
                </div>

                {isManual && (
                    <>
                        <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                            <TableBody>
                                {!_.isNil(b.dateCounted) && (
                                    <>
                                        {counts}
                                        {Object.keys(groupedPayloadTotals).map((unit, i) => (
                                            <TableRow style={{ height: 20 }} key={i}>
                                                <TableCell style={{ ...tableCellStyles, ...tableCellStyles1 }}>
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(allIcons, commodityIcon)}
                                                            size={0.6}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                    {uppercaseFirstLetter(
                                                        groupedPayloadTotals[unit] > 1 ? getPlural(unit) : unit
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        ...tableCellStyles,
                                                        ...tableCellStyles2,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {groupedPayloadTotals[unit]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {driverTip && bulkHelper.isCompleted(b) && (
                                            <DriverTip
                                                b={b}
                                                tableCellStyles={tableCellStyles}
                                                driverTip={driverTip}
                                                lang={lang}
                                            />
                                        )}
                                    </>
                                )}
                                {_.isEmpty(counts) && (
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                ...tableCellStyles,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            }}
                                        >
                                            {loc('dashboardHistory55', lang)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {!_.isEmpty(counts) && (
                            <>
                                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                    <TableBody>
                                        <TableRow style={{ height: 20 }}>
                                            <TableCell
                                                style={{
                                                    ...tableCellStyles,
                                                    ...tableCellStyles1,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    fontWeight: 'bold',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {loc('dashboardHistory74', lang, {
                                                    count:
                                                        _.get(b, 'skuType', '') === 'clothing'
                                                            ? 'lbs'
                                                            : loc('items', lang)
                                                })}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    ...tableCellStyles,
                                                    ...tableCellStyles2,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    textAlign: 'right'
                                                }}
                                            >
                                                <Typography style={{ fontWeight: 'bold' }} color="textSecondary">
                                                    {_.round(totalCounts, 2)}{' '}
                                                    {_.get(b, 'skuType', '') === 'clothing'
                                                        ? 'lbs'
                                                        : loc('items', lang)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    });
};

const DriverTip = ({ b, tableCellStyles, driverTip, lang }) => {
    return (
        <>
            <TableRow key={`${b._id}-value`} style={{ height: 20 }}>
                <TableCell
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles1,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                >
                    Driver Tip
                </TableCell>
                <TableCell
                    data-cy="customer-history-driver-tip"
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles2,
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontWeight: 'bold',
                        textAlign: 'right',
                        color: colors.red[500]
                    }}
                >
                    {formatAsCurrency(-driverTip, lang)}
                </TableCell>
            </TableRow>
            <TableRow key={`${b._id}-value`} style={{ height: 36 }}>
                <TableCell
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles1,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                >
                    Value (After Tip)
                </TableCell>
                <TableCell
                    data-cy="customer-history-value-after-tip"
                    style={{
                        ...tableCellStyles,
                        ...tableCellStyles2,
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontWeight: 'bold',
                        textAlign: 'right'
                    }}
                >
                    {formatAsCurrency(bulkHelper.getCustomerAmount(b) - driverTip, lang)}
                </TableCell>
            </TableRow>
        </>
    );
};

const NonManuallyCountedBulks = ({
    lang,
    nonManuallyCountedBulks,
    commoditiesAvailable,
    allRates,
    fallbackRates,
    tableCellStyles,
    theme
}) => {
    return (
        <>
            <Typography color="textSecondary">{loc('other', lang)}</Typography>
            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <TableBody>
                    {nonManuallyCountedBulks.map(b => {
                        const bulkRates = _.find(allRates, { _id: _.get(b, 'rates') });
                        const rates = _.get(bulkRates, 'rates', fallbackRates);
                        let commodityType = b.skuType;
                        let commodityForBulk = _.find(commoditiesAvailable, c => c.skuType === b.skuType);
                        if (!_.isNil(commodityForBulk) && !_.isNil(commodityForBulk.payloadInputName)) {
                            commodityType = _.get(commodityForBulk, `payloadInputName.${lang}`, b.skuType);
                        }
                        const commodityIcon = _.get(commodityForBulk, 'iconName');

                        let countsCombined = [];
                        let totalCounts = 0;
                        if (b.dateCounted) {
                            countsCombined = getCombinedCounts(b, rates, lang);
                            totalCounts = countsCombined.reduce((sum, count) => sum + count.quantity, 0);
                        }

                        let driverTip = _.get(b, 'tip.adjustedAmount');
                        if (!_.isNil(driverTip)) driverTip *= 100;

                        return (
                            <TableRow key={b._id} style={{ height: 20 }}>
                                <TableCell style={{ ...tableCellStyles, ...tableCellStyles1 }}>
                                    <Typography color="textSecondary">
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(allIcons, commodityIcon)}
                                                size={0.6}
                                                color={theme.palette.text.secondary}
                                            />
                                        </span>
                                        {uppercaseFirstLetter(commodityType)}
                                    </Typography>
                                </TableCell>
                                {!b.dateCounted && (
                                    <TableCell style={{ ...tableCellStyles, ...tableCellStyles2 }}>
                                        <Typography
                                            style={{ ...tableCellStyles, textAlign: 'right' }}
                                            color="textSecondary"
                                        >
                                            {loc('taxReceipts8', lang)}
                                        </Typography>
                                    </TableCell>
                                )}
                                {b.dateCounted && (
                                    <TableCell style={{ ...tableCellStyles, textAlign: 'right' }} color="textSecondary">
                                        {_.round(totalCounts, 2)}{' '}
                                        {_.get(b, 'skuType', '') === 'clothing' ? 'lbs' : loc('items', lang)}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};
