import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import { PICKUP_TIME_OF_DAY_PREFERENCES } from 'constants.js';

import {
    Button,
    TextField,
    DialogContent,
    DialogActions,
    Dialog,
    withMobileDialog,
    Fade,
    LinearProgress,
    MobileStepper
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import HttpContext from 'utils/contexts/HttpContext';
import InProgress from './InProgress';

import ImageCapture from 'components/ImageUploads/ImageCapture';
import useImageUpload from 'components/ImageUploads/useImageUpload';
import { getSelect } from 'components/CRUDTables/helperFunctions';
import { loc } from '../../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const SkipDialog = ({
    admin,
    open,
    handleClose,
    pickupSelected,
    getNextPickup,
    handleCenterMapOnPickups,
    onSnackbar,
    socket,
    fullScreen,
    theme,
    onManuallySetLocation,
    gpsNotWorking
}) => {
    const { lang } = useContext(LocalizationContext);

    const STEPS_TITLES = [loc('enterReason', lang), loc('captureBags', lang)];
    const http = useContext(HttpContext);
    const [activeStep, setActiveStep] = useState(0);
    const [inProgress, setInProgress] = useState(false);

    const { imagePreviews, uploadingImage, handleDeleteImage, handleUploadImage, resetImages } = useImageUpload({});

    const formik = useFormik({
        initialValues: {
            explanation: '',
            timePreference: _.get(pickupSelected, 'timePreference', 'None')
        },
        onSubmit: async ({ explanation, timePreference }, { resetForm }) => {
            setInProgress(true);
            const formData = new FormData();

            formData.append(
                'form',
                JSON.stringify({
                    pickup_id: pickupSelected._id,
                    trip_id: _.get(pickupSelected, 'trip._id', pickupSelected.trip),
                    explanation,
                    timePreference,
                    skipped: true,
                    images: imagePreviews
                })
            );

            const res = await http.post('/pickups/skipPickup', formData, false, true);

            if (res.ok) {
                onSnackbar('Pickup skipped');
                setInProgress(false);
                resetForm();
                resetImages();
                if (!admin) handleCenterMapOnPickups();
                if (!_.isNil(getNextPickup)) getNextPickup(pickupSelected._id);

                handleClose();

                socket.emit('refresh-route-low-priority', { pickup_id: pickupSelected._id }, success => {
                    if (!success) {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                });
                if (gpsNotWorking && !admin) {
                    onManuallySetLocation(pickupSelected.location);
                }
            } else {
                onSnackbar('Failed to skip this pickup. Please try again in a moment.', 'error');
            }
        }
    });

    const steps = [
        <ConfirmDialog
            lang={lang}
            theme={theme}
            formik={formik}
            onNext={() => {
                setActiveStep(1);
            }}
        />,
        <CameraDialog
            // imageURLs={imageURLs}
            imagePreviews={imagePreviews}
            uploadingImage={uploadingImage}
            admin={admin}
            onSnackbar={onSnackbar}
            onBack={() => {
                setActiveStep(0);
            }}
            inProgress={inProgress}
            onSkip={() => {
                formik.handleSubmit();
                setActiveStep(0);
            }}
            handleUploadImage={handleUploadImage}
            handleDeleteImage={handleDeleteImage}
            lang={lang}
        />
    ];

    return (
        <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={handleClose}>
            <DialogTitlePrimary closeButtonShown onClose={inProgress ? () => undefined : handleClose}>
                {inProgress ? 'In progress...' : STEPS_TITLES[activeStep]}
            </DialogTitlePrimary>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                backButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                style={{ backgroundColor: theme.palette.background.paper }}
            />
            {inProgress ? (
                <DialogContent>
                    <InProgress />
                </DialogContent>
            ) : (
                steps[activeStep]
            )}
        </Dialog>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(SkipDialog));

const ConfirmDialog = ({ theme, formik, onNext, lang }) => {
    return (
        <>
            <DialogContent>
                <>
                    <CustomFormTitle
                        titleText={loc('whyAreYouSkipping', lang)}
                        iconName="message"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                    <TextField
                        variant="outlined"
                        multiline
                        label={loc('explanation', lang)}
                        name="explanation"
                        value={formik.values.explanation}
                        onChange={formik.handleChange}
                        fullWidth
                        data-cy="skip-pickup-reason"
                    />
                    <CustomFormTitle
                        titleText={loc('updateTimeOfDayPreference', lang)}
                        iconName="access_time"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                    {getSelect(
                        theme,
                        'timePreference',
                        loc('timePreference', lang),
                        PICKUP_TIME_OF_DAY_PREFERENCES.map(timeOfDay => timeOfDay.value),
                        formik
                    )}
                </>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    disabled={_.isEmpty(formik.values.explanation)}
                    onClick={onNext}
                    data-cy="skip-pickup-confirm"
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
};

const CameraDialog = ({
    // imageURLs,
    imagePreviews,
    uploadingImage,
    admin,
    onSnackbar,
    onBack,
    inProgress,
    handleUploadImage,
    handleDeleteImage,
    onSkip,
    lang
}) => {
    return (
        <>
            <DialogContent>
                <ImageCapture
                    imageURLs={imagePreviews}
                    uploadingImage={uploadingImage}
                    handleAddImage={handleUploadImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={
                        <span>
                            {loc('cameraDialogText1', lang)}
                            {admin ? ' This step is optional for administrators.' : ''}
                            <br />
                            {loc('cameraDialogText2', lang)}
                        </span>
                    }
                    onSnackbar={onSnackbar}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="skip-dialog-skip-button"
                    disabled={
                        inProgress ||
                        uploadingImage ||
                        (!admin && _.isEmpty(imagePreviews) && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE))
                    }
                    color="secondary"
                    onClick={onSkip}
                >
                    {_.capitalize(loc('skip', lang))}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
};
