import React, { useContext } from 'react';
import _ from 'lodash';

import { _time, _user } from 'std';
// npm components
import {
    withTheme,
    withMobileDialog,
    Paper,
    Typography,
    LinearProgress,
    Button,
    IconButton,
    Icon,
    colors,
    Collapse,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Tooltip
} from '@material-ui/core';
import OperatorContext from 'utils/contexts/OperatorContext';
import { useState } from 'react';
import { maxFileSize } from 'constants.js';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ReactJson from 'react-json-view';
import { useEffect } from 'react';
import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';
import { getReceiverName, isAUSRegion } from 'utils/misc';
import moment from 'moment-timezone';
import { AUS_STATES } from 'constants.js';

import MDIcon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';
import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function BulkImport(props) {
    const operator = useContext(OperatorContext);
    const { theme, collectors, history, initialCollector, http } = props;
    const { lang } = useContext(LocalizationContext);

    const [todayTrips, setTodayTrips] = useState([]);
    const [file, setFile] = useState(undefined);
    const [tripAssigned, setTripAssigned] = useState('');
    const [collectorSelected, setCollectorSelected] = useState('');
    const [stateSelected, setStateSelected] = useState('');
    const [stateError, setStateError] = useState('');
    const [isFutureDate, setIsFutureDate] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importReports, setImportReports] = useState([]);
    const [expandedReports, setExpandedReports] = useState([]);

    const onSnackbar = useContext(SnackbarContext);

    const handleDrop = e => {
        const uploadFile = e.target.files[0];
        if (uploadFile.size <= maxFileSize) {
            setFile(e.target.files[0]);
        } else {
            onSnackbar('File exceeds max file size', 'error');
        }
    };

    const handleImport = async () => {
        if (isAUSRegion() && (_.isNil(stateSelected) || _.isEmpty(stateSelected))) {
            setStateError('Please select a state');
            onSnackbar('You must select a state for accurate address lookup.', 'error');
            return;
        }
        setImporting(true);
        const formData = new FormData();
        formData.append('importFile', file);
        if (!_.isNil(tripAssigned) && !_.isEmpty(tripAssigned)) {
            formData.append('trip_id', tripAssigned);
        }
        if (!_.isNil(collectorSelected) && !_.isEmpty(collectorSelected)) {
            formData.append('collector_id', collectorSelected);
        }
        if (!_.isNil(stateSelected) && !_.isEmpty(stateSelected)) {
            formData.append('state', stateSelected);
        }

        const res = await http.postJSON(`/pickups/importPickups`, formData, false, true);
        if (res.ok) {
            let importReportsCopy = _.cloneDeep(importReports);
            importReportsCopy.splice(0, 0, res.data);
            setImportReports(importReportsCopy);
            let expandedReportsCopy = _.cloneDeep(expandedReports);
            expandedReportsCopy.splice(0, 0, false);
            setExpandedReports(expandedReportsCopy);
            setFile(undefined);
            setImporting(false);
            onSnackbar(`${res.data.numOfPickupsImported} pickups successfully imported`);
        } else {
            setImporting(false);
            onSnackbar('Error importing pickups', 'error');
        }
    };

    const handleExpandReport = (expand, index) => {
        let expandedReportsCopy = _.cloneDeep(expandedReports);
        expandedReportsCopy[index] = expand;
        setExpandedReports(expandedReportsCopy);
    };
    const checkFutureDate = async () => {
        setProcessing(true);
        const formData = new FormData();
        formData.append('importFile', file);
        const res = await http.postJSON(`/pickups/checkImportDate`, formData, false, true);
        if (res.ok) {
            if (res.data.isInvalidDate) {
                setFile(undefined);
                setIsFutureDate(false);
                onSnackbar(
                    'Unable to parse collection date. Please ensure the date is correct and is presented using a valid date format.',
                    'error'
                );
            }
            if (res.data.isPastDate) {
                setFile(undefined);
                setIsFutureDate(false);
                onSnackbar('Cannot add pickups for past dates', 'error');
            }
            setIsFutureDate(res.data.isFutureDate);
        } else {
            onSnackbar('Error processing file', 'error');
        }
        setProcessing(false);
    };

    const getTodayTrips = async () => {
        const timezone = _.get(operator, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
        const startDate = moment()
            .tz(timezone)
            .startOf('day')
            .toDate();

        let res;
        if (operator.accountType === 'Collector Administrator') {
            res = await http.getJSON(`/trips/getCollectorTrips?date=${startDate.toISOString()}&timezone=${timezone}`);
        } else if (operator.accountType === 'System Administrator') {
            res = await http.getJSON(`/trips/getCollectorTrips?date=${startDate.toISOString()}&timezone=${timezone}`);
        } else if (operator.accountType === 'Collector Employee') {
            res = await http.getJSON(`/users/operators/${operator._id}/getTrips`);
        }
        if (res.ok) {
            setTodayTrips(_.get(res.data, 'trips', []));
            if (_.get(res.data, 'trips', []).length === 1) {
                const trip = _.first(res.data.trips);
                setTripAssigned(_.get(trip, '_id', ''));
            }
        }
    };

    useEffect(() => {
        if (!_.isEmpty(stateSelected)) {
            setStateError('');
        }
    }, [stateSelected]);

    useEffect(() => {
        if (!_.isNil(file)) {
            checkFutureDate();
        }
    }, [file]);

    useEffect(() => {
        getTodayTrips();
    }, []);

    return (
        <div>
            <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <Typography variant="h5">{loc('importPickups', lang)}</Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {isAUSRegion() && !_.isNil(file) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <FormControl
                                    fullWidth
                                    style={{
                                        minWidth: 200,
                                        color: _.isEmpty(stateError) ? '' : theme.palette.error.main
                                    }}
                                >
                                    <InputLabel variant="outlined">State</InputLabel>

                                    <Select
                                        fullWidth
                                        input={
                                            <OutlinedInput
                                                labelWidth={'state'.length * theme.spacing.unit}
                                                data-cy={`${_.kebabCase('state')}-input`}
                                            />
                                        }
                                        error={!_.isEmpty(stateError)}
                                        helperText={stateError}
                                        onChange={e => setStateSelected(e.target.value)}
                                        value={stateSelected}
                                    >
                                        {AUS_STATES.map((state, idx) => (
                                            <MenuItem value={state} key={state}>
                                                {state}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!_.isEmpty(stateError) && (
                                        <Typography variant="caption" style={{ color: theme.palette.error.main }}>
                                            {stateError}
                                        </Typography>
                                    )}
                                </FormControl>
                            </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {_.isNil(file) && (
                                <>
                                    <Button
                                        style={{ padding: 0, display: 'block', margin: theme.spacing.unit / 2 }}
                                        color="secondary"
                                        variant="outlined"
                                    >
                                        <label
                                            htmlFor="fileInput"
                                            style={{ padding: theme.spacing.unit, cursor: 'pointer', display: 'block' }}
                                        >
                                            {loc('uploadFile', lang)}
                                        </label>
                                    </Button>

                                    <input
                                        data-cy="file-drop-input"
                                        type="file"
                                        accept={'.csv'}
                                        id="fileInput"
                                        name="fileInput"
                                        onChange={handleDrop}
                                        style={{ display: 'none' }}
                                    />
                                </>
                            )}
                            {!_.isNil(file) &&
                                !isFutureDate &&
                                (operator.accountType !== 'Collector Employee' || todayTrips.length > 1) && (
                                    <FormControl fullWidth style={{ minWidth: 200 }}>
                                        <InputLabel variant="outlined">{_.capitalize(loc('trip', lang))}</InputLabel>

                                        <Select
                                            fullWidth
                                            input={
                                                <OutlinedInput
                                                    labelWidth={'trip'.length * theme.spacing.unit}
                                                    data-cy={`${_.kebabCase('trip')}-input`}
                                                />
                                            }
                                            onChange={e => setTripAssigned(e.target.value)}
                                            value={tripAssigned}
                                        >
                                            {todayTrips.map(trip => {
                                                const timezone = _.get(
                                                    trip,
                                                    'transporterCollector.timezone',
                                                    _.get(
                                                        trip,
                                                        'transporter.location.timezone',
                                                        process.env.REACT_APP_REGION_TIMEZONE
                                                    )
                                                );
                                                return (
                                                    <MenuItem
                                                        key={trip._id}
                                                        value={trip._id}
                                                        data-cy={`${getReceiverName(trip.transporter)
                                                            .replace(/\s/g, '-')
                                                            .toLowerCase()}-pickups-admin-assign-trip-select-li`}
                                                    >
                                                        <span>
                                                            <span>
                                                                {moment(trip.startTime)
                                                                    .tz(timezone)
                                                                    .format('h:mm A')}
                                                                <CustomTextBadge
                                                                    tooltip={
                                                                        moment(trip.startTime)
                                                                            .tz(timezone)
                                                                            .format('h:mm A Z') +
                                                                        ' ' +
                                                                        timezone
                                                                    }
                                                                >
                                                                    {moment(trip.startTime)
                                                                        .tz(timezone)
                                                                        .format('z')}
                                                                </CustomTextBadge>
                                                            </span>{' '}
                                                            <span style={{ color: theme.palette.primary.main }}>
                                                                [{_.get(trip, 'transporterCollector.code', 'N/A')}]
                                                            </span>{' '}
                                                            {getReceiverName(trip.transporter)} ➡{' '}
                                                            <span style={{ color: theme.palette.primary.main }}>
                                                                {trip.collector.code}
                                                            </span>
                                                        </span>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                            {!_.isNil(file) && isFutureDate && operator.accountType === 'System Administrator' && (
                                <FormControl fullWidth style={{ minWidth: 200 }}>
                                    <InputLabel variant="outlined">{loc('collector', lang)}</InputLabel>

                                    <Select
                                        fullWidth
                                        input={
                                            <OutlinedInput
                                                labelWidth={'collector'.length * theme.spacing.unit}
                                                data-cy={`${_.kebabCase('collector')}-input`}
                                            />
                                        }
                                        onChange={e => setCollectorSelected(e.target.value)}
                                        value={collectorSelected}
                                    >
                                        {collectors.map((collector, idx) => (
                                            <MenuItem value={collector._id} key={collector._id}>
                                                {_.get(collector, 'name')}
                                                {_.get(collector, 'disabled') && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit * 2,
                                                            color: theme.palette.text.secondary
                                                        }}
                                                    >
                                                        DISABLED
                                                    </span>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <Button
                            onClick={handleImport}
                            variant="contained"
                            color="primary"
                            disabled={
                                importing ||
                                processing ||
                                _.isNil(file) ||
                                (!_user.isSystemAdmin(operator) && !isFutureDate && _.isEmpty(tripAssigned))
                            }
                            style={{ margin: theme.spacing.unit }}
                        >
                            {_.capitalize(loc('import', lang))}
                        </Button>
                    </div>
                </div>
            </Paper>
            <div style={{ margin: theme.spacing.unit * 2, marginTop: 0 }}>{importing && <LinearProgress />}</div>
            {!_.isNil(file) && (
                <Paper
                    elevation={0}
                    style={{
                        paddingTop: theme.spacing.unit,
                        paddingBottom: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        margin: theme.spacing.unit * 2,
                        textAlign: 'center',
                        justifyContent: 'space-around',
                        backgroundColor: theme.palette.background.default,
                        border: `thin solid rgba(0, 0, 0, 0.23)`
                    }}
                >
                    <Icon style={{ fontSize: 40 }}>file_present</Icon>
                    <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit }}>
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            <span style={{ color: theme.palette.primary.main }}>
                                {file.name} ({(file.size / 1000).toFixed(1)} kB)
                            </span>
                        </span>
                        <IconButton
                            onClick={() => {
                                setFile(undefined);
                            }}
                            style={{ padding: 0, margin: 0 }}
                        >
                            <Icon>clear</Icon>
                        </IconButton>
                    </Typography>
                    {isFutureDate && (
                        <>
                            <Icon style={{ color: colors.orange[500], marginRight: theme.spacing.unit }}>
                                warning_amber
                            </Icon>
                            <Typography style={{ color: colors.orange[500] }}>
                                This file is for a future date.
                            </Typography>
                        </>
                    )}
                </Paper>
            )}

            {importReports.map((report, index) => {
                return (
                    <Paper
                        style={{
                            margin: theme.spacing.unit * 2,
                            marginTop: 0,
                            padding: theme.spacing.unit * 2,
                            overflowX: 'scroll'
                        }}
                    >
                        <Typography variant="h6">
                            <IconButton onClick={() => handleExpandReport(!expandedReports[index], index)}>
                                <Icon>{expandedReports[index] ? 'expand_less' : 'expand_more'}</Icon>
                            </IconButton>
                            Imported {_.filter(report.pickups, p => _.isNil(p.errorMessage)).length}/
                            {report.pickups.length} pickups from{' '}
                            <span style={{ fontWeight: 'bold' }}>{report.fileName}</span>
                            {!_.isNil(_.find(report.pickups, p => !_.isNil(p.errorMessage))) && (
                                <Tooltip title={'Some imports were unsuccessful'}>
                                    <span style={{ margin: theme.spacing.unit / 2 }}>
                                        <Icon style={{ color: theme.palette.error.main }}>warning_amber</Icon>
                                    </span>
                                </Tooltip>
                            )}
                        </Typography>
                        <Collapse in={expandedReports[index]}>
                            {report.pickups.map(p => {
                                const hasError = !_.isNil(p.errorMessage);
                                return (
                                    <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: hasError
                                                            ? theme.palette.error.main
                                                            : theme.palette.primary.main
                                                    }}
                                                >
                                                    {hasError ? (
                                                        <MDIcon path={mdiAlertCircle} size={1} color={'white'} />
                                                    ) : (
                                                        p.bagsLarge + p.bagsMedium + p.bagsSmall
                                                    )}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    hasError ? (
                                                        <span style={{ color: theme.palette.error.main }}>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                {loc('importUnsuccesful', lang)}:{' '}
                                                            </span>
                                                            {p.errorMessage}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span style={{ fontWeight: 'bold' }}>{loc('customerName', lang)}: </span>
                                                            {p.customerName} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>{loc('email', lang)}: </span>
                                                            {p.customerEmail} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>{loc("phone", lang)}: </span>
                                                            {p.customerPhone}
                                                        </span>
                                                    )
                                                }
                                                secondary={
                                                    <span
                                                        style={{
                                                            color: hasError
                                                                ? theme.palette.error.main
                                                                : theme.palette.text.secondary
                                                        }}
                                                    >
                                                        <span style={{ fontWeight: 'bold' }}>{_.capitalize(loc('date', lang))}: </span>
                                                        {_.isNil(p.dateInteger) || _.isNaN(p.dateInteger)
                                                            ? 'N/A'
                                                            : _time
                                                                  .getMomentObjectFromDateInteger(
                                                                      _.get(
                                                                          operator,
                                                                          'location.timezone',
                                                                          process.env.REACT_APP_REGION_TIMEZONE
                                                                      ),
                                                                      p.dateInteger
                                                                  )
                                                                  .format('YYYY-MM-DD')}{' '}
                                                        | <span style={{ fontWeight: 'bold' }}>{loc('address', lang)}: </span>
                                                        {p.address}
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </ListItem>
                                );
                            })}
                        </Collapse>
                    </Paper>
                );
            })}
        </div>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkImport));
