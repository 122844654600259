import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user, _pickup } from 'std';

import * as colors from '@material-ui/core/colors';
import {
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Avatar,
    IconButton,
    Icon,
    Divider,
    LinearProgress,
    Tooltip,
    Collapse
} from '@material-ui/core';

import PhoneNumberPopover from 'components/Popovers/PhoneNumber';
import PayloadIndicators from 'components/DriverWidgets/PayloadIndicators';
import PickupStatusIndicators from 'components/DriverWidgets/PickupStatusIndicators';

import { withTheme } from '@material-ui/core/styles';
import { formatAsAddress, convertSecondsToMinutes } from 'utils/misc';

import useDriverTripStatsList from './hooks/useDriverTripStatsList';
import usePopover from 'components/Popovers/usePopover';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { Icon as MDIcon } from '@mdi/react';
import { mdiMessageAlert } from '@mdi/js';
import useWindowSize from 'utils/hooks/useWindowSize';

import { deviceHelper } from 'utils/misc';
import PDFPreviewer from 'components/PDFComponents/PDFPreviewer';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { loc } from '../../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const tdStyle = { paddingRight: 8 };

function DriversTripStatsList(props) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 415;
    const {
        theme,
        debug,
        trip,
        pickups,
        driverConfig,
        numberOfScheduledPickups,
        isTodayOrFutureDate,
        onClickInfoAction,
        onViewOnMaps,
        commoditiesAvailable,
        rateValues,
        hideCustomerIdentification,
        rescheduledPickupsCount
    } = props;
    const {
        abortedPickups,
        skippedPickups,
        completedPickups,
        totalNumberOfBags,
        averageTimePerPickup,
        averageTimePerBag,
        finishedPickups,
        displayPickups,
        driver,
        timezone
    } = useDriverTripStatsList({
        pickups,
        commoditiesAvailable,
        trip
    });

    useEffect(() => {
        pickups.forEach(p => {
            p.numberOfBins = _pickup.getNumberOfBins(p, commoditiesAvailable);
        });
    }, [pickups, commoditiesAvailable]);

    const { anchorEl, handleClose: handleClosePopover, handleOpen: handleOpenPopover } = usePopover();
    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
    const [pdfUriStr, setPdfUriStr] = useState('');

    const collapseStats = useMediaQuery(theme.breakpoints.down('sm'));
    const [statsCollapsed, setStatsCollapsed] = useState(collapseStats);

    const { lang } = useContext(LocalizationContext);

    const handleGeneratePDF = () => {
        const enabledCmdties = commoditiesAvailable.filter(cmdty => !cmdty.disabled);

        let cmdtyCols = [];
        enabledCmdties.forEach(({ skuType }) => {
            let cmdtyTitle;
            switch (skuType) {
                case 'beverage':
                    cmdtyTitle = 'Bags';
                    break;
                case 'clothing':
                    cmdtyTitle = 'Cloth';
                    break;
                case 'electronics':
                    cmdtyTitle = 'Elec';
                    break;
                default:
                    cmdtyTitle = _.capitalize(skuType).substring(0, 5);
            }

            let cmdtyCol = {
                title: cmdtyTitle,
                dataKey: skuType
            };

            cmdtyCols.push(cmdtyCol);
        });

        let rateValCols = [];
        rateValues.forEach(rateVal => {
            let showCents = true,
                titleRateVal = rateVal;

            if (rateVal >= 100) {
                titleRateVal = rateVal / 100;
                showCents = false;
            }
            if (rateVal > 0) {
                let rateValCol = {
                    title: `${showCents ? '' : '$'}${titleRateVal}${showCents ? '\xA2' : ''}`,
                    dataKey: rateVal.toString()
                };
                rateValCols.push(rateValCol);
            }
        });

        let name = '',
            date = '',
            time = '',
            rows = [],
            columns = [
                { title: 'Tag', dataKey: 'tag' },
                { title: 'Code', dataKey: 'code' },
                ...cmdtyCols,
                ...rateValCols,
                { title: 'Non-ref', dataKey: 'non-ref' }
            ];

        if (!_.isEmpty(displayPickups)) {
            const firstPickupInTrip = _.last(displayPickups);

            name = `${_.get(firstPickupInTrip, 'receiver.name.first', '')} ${_.get(
                firstPickupInTrip,
                'receiver.name.last',
                ''
            )}`;

            const completionDate = _.get(firstPickupInTrip, 'completionDate');
            if (completionDate) {
                date = moment(completionDate)
                    .tz(timezone)
                    .format('MMM DD/YY');

                time = moment(completionDate)
                    .tz(timezone)
                    .format('h:mm A');
            }

            displayPickups.forEach(pickup => {
                let row = {};

                const bagtag = _.get(pickup, 'bagtag', '');
                if (bagtag !== _.get(pickup, 'customer.uniqueID', '')) {
                    row.tag = bagtag;
                } else {
                    row.code = bagtag;
                }

                cmdtyCols.forEach(({ dataKey }) => {
                    row[dataKey] = _.get(pickup, `payload.${dataKey}`, '').toString();
                });

                rows.push(row);
            });
        }

        let doc = new jsPDF({ orientation: 'l' });
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(16);

        doc.text(`${name} ${date} ${time}`, 15, 15);

        doc.autoTable(columns, rows, {
            styles: {
                lineWidth: 0.25,
                lineColor: 'black'
            },
            headerStyles: {
                fillColor: theme.palette.primary.main,
                halign: 'center'
            },
            startY: 20
        });

        if (deviceHelper.isNativeApp()) {
            doc.save(`${name} ${date}.pdf`);
        } else {
            setPdfUriStr(doc.output('datauristring', `${name} ${date}`));
            setPdfViewerOpen(true);
        }
    };

    return (
        <div
            style={{
                border: `1px solid ${theme.palette.text.disabled}`,
                borderRadius: 2,
                padding: theme.spacing.unit,
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PhoneNumberPopover
                debug={debug}
                anchorEl={anchorEl}
                phone={_user.getPhoneNumber(driver)}
                email={_user.getEmail(driver)}
                startTime={_.get(trip, 'startTime')}
                timezone={timezone}
                driverEfficiency={_.get(trip, 'driverEfficiency')}
                stopBaseDuration={_.get(trip, 'stopBaseDuration')}
                onClose={handleClosePopover}
            />
            <div>
                <List disablePadding>
                    <ListItem style={mobileMode ? {} : { width: 324 }}>
                        <ListItemAvatar>
                            <Avatar
                                style={{
                                    backgroundColor: theme.palette.primary.main
                                }}
                            >
                                <Icon>person</Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={_user.getNameFull(driver)}
                            secondary={
                                mobileMode ? (
                                    `[${finishedPickups.length} / ${pickups.length}] ${loc('finished', lang).toLowerCase()} \n${_.get(
                                        trip,
                                        'transporterCollector.code',
                                        _.get(trip, 'transporter.collector.code', 'N/A')
                                    )} ➡ ${_.get(trip, 'collector.code', 'N/A')}`
                                ) : (
                                    <>
                                        <Typography
                                            style={{
                                                opacity: 0.7
                                            }}
                                        >{`[${finishedPickups.length} / ${pickups.length}] ${loc('finished', lang).toLowerCase()}`}</Typography>
                                        <Typography
                                            style={{
                                                opacity: 0.7,
                                                noWrap: true,
                                                width: 250
                                            }}
                                        >{`${_.get(
                                            trip,
                                            'transporterCollector.code',
                                            _.get(trip, 'transporter.collector.code', 'N/A')
                                        )} ➡ ${_.get(trip, 'collector.code', 'N/A')}`}</Typography>
                                    </>
                                )
                            }
                            primaryTypographyProps={{ style: mobileMode ? {} : { noWrap: true, width: 250 } }}
                            style={{ paddingRight: 88 }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={handleOpenPopover} disabled={false}>
                                <MDIcon path={mdiMessageAlert} size={1} color="rgba(0, 0, 0, 0.54)" />
                            </IconButton>
                            <IconButton
                                onClick={() => onViewOnMaps(_.get(pickups, '[0].trip', null))}
                                disabled={!isTodayOrFutureDate}
                            >
                                <Icon>map</Icon>
                            </IconButton>
                            <IconButton onClick={handleGeneratePDF} disabled={displayPickups.length === 0}>
                                <Icon>picture_as_pdf</Icon>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <LinearProgress
                    variant="determinate"
                    value={(finishedPickups.length / pickups.length) * 100}
                    style={{ marginBottom: theme.spacing.unit }}
                />
                <Collapse in={!statsCollapsed}>
                    <table
                        style={{
                            width: '100%'
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={tdStyle}>
                                    <Typography>{_.capitalize(loc('completed', lang))}</Typography>
                                </td>
                                <td>
                                    <Typography>{completedPickups.length || 0}</Typography>
                                </td>
                                <td style={tdStyle}>
                                    <Typography>{loc('pickups', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>{pickups.length}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={tdStyle}>
                                    <Typography>{loc('aborted', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>{abortedPickups.length || 0}</Typography>
                                </td>
                                <td>
                                    <Typography>{_.capitalize(loc('bags', lang))}</Typography>
                                </td>
                                <td>
                                    <Typography>{totalNumberOfBags || 0}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={tdStyle}>
                                    <Typography>{loc('skipped', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>{skippedPickups.length || 0}</Typography>
                                </td>
                                <td style={tdStyle}>
                                    <Typography>{loc('perPickup', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>{averageTimePerPickup || 'N/A'}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={tdStyle}>
                                    <Typography>{loc('rescheduled', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography> {rescheduledPickupsCount}</Typography>
                                </td>
                                <td style={tdStyle}>
                                    <Typography>{loc('perBag', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>{averageTimePerBag || 'N/A'}</Typography>
                                </td>
                            </tr>

                            <tr>
                                <td style={tdStyle}>
                                    <Typography>{loc('startTime', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {_.isNil(trip.actualStartTime)
                                            ? 'N/A'
                                            : moment(_.get(trip, 'actualStartTime', ''))
                                                  .tz(timezone)
                                                  .format('h:mm A')}
                                    </Typography>
                                </td>
                                <td style={tdStyle}>
                                    <Typography>{loc('endTime', lang)}</Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {_.isNil(trip.actualEndTime)
                                            ? 'N/A'
                                            : moment(_.get(trip, 'actualEndTime', ''))
                                                  .tz(timezone)
                                                  .format('h:mm A')}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Collapse>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={() => setStatsCollapsed(!statsCollapsed)} size="sm">
                        <Icon>{statsCollapsed ? 'expand_more' : 'expand_less'}</Icon>
                    </IconButton>
                </div>

                <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
            </div>
            <div
                style={{
                    width: '100%',
                    flex: '1',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    paddingBottom: theme.spacing.unit * 2
                }}
            >
                <List dense disablePadding>
                    {displayPickups.map(pickup => {
                        const timeCompleted = pickup.completionDate ? pickup.completionDate : pickup.updatedAt;
                        const eta = _.last(_.get(pickup, 'estimatedCompletionDates', []));
                        let etaDiff = null,
                            isGoodTime = true;
                        if (eta) {
                            etaDiff = moment(timeCompleted).diff(eta, 'minutes');
                            isGoodTime = etaDiff > -10 && etaDiff < 10;
                        }

                        const tripStats = pickup.tripStats;

                        let stopBaseDuration = _.get(trip, 'stopBaseDuration');
                        let stopPerBagDuration = _.get(trip, 'driverEfficiency');

                        if (_.isNil(stopBaseDuration)) {
                            stopBaseDuration = _.get(
                                pickup,
                                'receiver.stopBaseDuration',
                                _.get(driverConfig, 'stopBaseDuration', 240)
                            );
                        }

                        if (_.isNil(stopPerBagDuration)) {
                            stopPerBagDuration = _.get(
                                pickup,
                                'receiver.driverEfficiency',
                                _.get(driverConfig, 'stopPerBagDuration', 30)
                            );
                        }

                        stopBaseDuration = _.round(stopBaseDuration / 60, 2);
                        stopPerBagDuration = _.round(stopPerBagDuration / 60, 2);

                        let primaryText = formatAsAddress(pickup.location);
                        let secondaryText = (
                            <span>
                                {moment(timeCompleted)
                                    .tz(timezone)
                                    .format('h:mm A')}
                                {pickup.skipped && (
                                    <span
                                        style={{
                                            color: colors.orange[500]
                                        }}
                                    >
                                        {" "}({loc('skipped', lang)})
                                    </span>
                                )}
                                {!pickup.skipped && etaDiff && (
                                    <span
                                        style={{
                                            color: _.get(tripStats, 'isGoodTime', true)
                                                ? colors.green[500]
                                                : colors.red[500]
                                        }}
                                    >
                                        ({Math.abs(etaDiff)} mins
                                        {etaDiff > 0 ? ' late' : ' early'})
                                    </span>
                                )}{' '}
                                - {pickup.receiverDistance ? pickup.receiverDistance + 'm ' : 'N/A '}
                                <br />
                                {_.get(pickup, 'pickupType') === 'Commercial'
                                    ? _.get(pickup, 'location.businessName')
                                    : (
                                          _.get(pickup, 'customer.name.first', loc('guest', lang)) +
                                          ' ' +
                                          _.get(pickup, 'customer.name.last', '').charAt(0)
                                      ).trim()}{' '}
                                ({_.get(pickup, 'customer.uniqueID', 'N/A')}){' '}
                                {pickup.complete && (
                                    <span
                                        style={{
                                            padding: '0px 6px',
                                            backgroundColor:
                                                theme.palette.type === 'light'
                                                    ? theme.palette.grey[100]
                                                    : theme.palette.grey[900],
                                            color: theme.palette.text.primary,
                                            borderRadius: 4,
                                            border: `1px solid ${
                                                theme.palette.type === 'light'
                                                    ? theme.palette.grey[300]
                                                    : theme.palette.grey[700]
                                            }`
                                        }}
                                    >
                                        {pickup.bagtag || 'N/A'}
                                    </span>
                                )}
                            </span>
                        );
                        if (hideCustomerIdentification) {
                            primaryText = (
                                <span>
                                    {moment(timeCompleted)
                                        .tz(timezone)
                                        .format('h:mm A')}
                                    {pickup.skipped && (
                                        <span
                                            style={{
                                                color: colors.orange[500]
                                            }}
                                        >
                                            {" "}({loc('skipped', lang)})
                                        </span>
                                    )}
                                    {!pickup.skipped && etaDiff && (
                                        <span
                                            style={{
                                                color: _.get(tripStats, 'isGoodTime', true)
                                                    ? colors.green[500]
                                                    : colors.red[500]
                                            }}
                                        >
                                            ({Math.abs(etaDiff)} mins
                                            {etaDiff > 0 ? ' late' : ' early'})
                                        </span>
                                    )}{' '}
                                    - {pickup.receiverDistance ? pickup.receiverDistance + 'm ' : 'N/A '}
                                    <br /> {_.get(pickup, 'customer.uniqueID', 'N/A')}{' '}
                                    {pickup.complete && (
                                        <span
                                            style={{
                                                padding: '0px 6px',
                                                backgroundColor:
                                                    theme.palette.type === 'light'
                                                        ? theme.palette.grey[100]
                                                        : theme.palette.grey[900],
                                                color: theme.palette.text.primary,
                                                borderRadius: 4,
                                                border: `1px solid ${
                                                    theme.palette.type === 'light'
                                                        ? theme.palette.grey[300]
                                                        : theme.palette.grey[700]
                                                }`
                                            }}
                                        >
                                            {pickup.bagtag || 'N/A'}
                                        </span>
                                    )}
                                </span>
                            );
                            secondaryText = null;
                        }

                        return (
                            <React.Fragment key={pickup._id}>
                                <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                style={{
                                                    backgroundColor: !_.isNil(pickup.customer)
                                                        ? pickup.aborted
                                                            ? theme.palette.error.main
                                                            : pickup.complete
                                                            ? theme.palette.primary.main
                                                            : pickup.skipped
                                                            ? colors.yellow[600]
                                                            : colors.grey[500]
                                                        : colors.grey[500]
                                                }}
                                            >
                                                {_pickup.numberOfPayloadBags(pickup, commoditiesAvailable)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={primaryText} secondary={secondaryText} />
                                    </div>
                                    <ListItemText
                                        style={{ padding: 0 }}
                                        primary={
                                            <span>
                                                {pickup.complete && (
                                                    <>
                                                        <div
                                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                                        >
                                                            <PickupStatusIndicators
                                                                pickup={pickup}
                                                                style={{ marginTop: theme.spacing.unit }}
                                                                indicatorSize={0.75}
                                                                labelStyle={{
                                                                    fontSize: '0.75rem'
                                                                }}
                                                                numberOfBins={pickup.numberOfBins}
                                                            />
                                                            {!_pickup.isOutOfSystemStop(pickup) && (
                                                                <PayloadIndicators
                                                                    showUnits
                                                                    payload={pickup.payload}
                                                                    commodities={_.get(
                                                                        pickup,
                                                                        'zone.payloadAccepted',
                                                                        {}
                                                                    )}
                                                                    style={{ marginTop: theme.spacing.unit }}
                                                                    indicatorSize={0.75}
                                                                    labelStyle={{
                                                                        fontSize: '0.75rem'
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                <br />
                                                {debug && tripStats && (
                                                    <>
                                                        <span
                                                            style={{
                                                                color:
                                                                    tripStats.actualTimeFromLastLocation <=
                                                                    tripStats.estTimeFromLastLocation + 2
                                                                        ? colors.green[500]
                                                                        : colors.red[500]
                                                            }}
                                                        >
                                                            (DBG) ETA:{' '}
                                                            <Tooltip title="ETA">
                                                                <span>
                                                                    {moment(eta)
                                                                        .tz(timezone)
                                                                        .format('h:mm A')}
                                                                </span>
                                                            </Tooltip>{' '}
                                                            {` (${tripStats.etaDifferenceFromLastETA > 0 ? '+' : ''}`}
                                                            <Tooltip title="Difference from last ETA">
                                                                <span>{tripStats.etaDifferenceFromLastETA}</span>
                                                            </Tooltip>
                                                            {` from last ETA)`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                color:
                                                                    tripStats.actualTimeFromLastLocation <=
                                                                    tripStats.estTimeFromLastLocation + 2
                                                                        ? colors.green[500]
                                                                        : colors.red[500]
                                                            }}
                                                        >
                                                            Est: {tripStats.estTimeFromLastLocation} min, Act:{' '}
                                                            {tripStats.actualTimeFromLastLocation} min
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                color:
                                                                    tripStats.actualTimeFromLastLocation <=
                                                                    tripStats.estTimeFromLastLocation + 2
                                                                        ? colors.green[500]
                                                                        : colors.red[500]
                                                            }}
                                                        >
                                                            Breakdown:{' '}
                                                            <Tooltip title="Base Stop Duration (min)">
                                                                <span>{stopBaseDuration}</span>
                                                            </Tooltip>
                                                            {' + '}
                                                            <Tooltip title="Bags">
                                                                <span>
                                                                    {_pickup.numberOfPayloadBags(
                                                                        pickup,
                                                                        commoditiesAvailable
                                                                    )}
                                                                </span>
                                                            </Tooltip>
                                                            {' * '}
                                                            <Tooltip title="Time Per Bag (min)">
                                                                <span>{stopPerBagDuration}</span>
                                                            </Tooltip>
                                                            {' + '}
                                                            <Tooltip title="Driving Time (min)">
                                                                <span>{tripStats.estDrivingTimeFromLastLocation}</span>
                                                            </Tooltip>
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                color:
                                                                    tripStats.actualTimeFromLastLocation <=
                                                                    tripStats.estTimeFromLastLocation + 2
                                                                        ? colors.green[500]
                                                                        : colors.red[500]
                                                            }}
                                                        >
                                                            <Tooltip title="Start Address">
                                                                <span>
                                                                    {
                                                                        _.get(tripStats, 'legStartAddress', '').split(
                                                                            ','
                                                                        )[0]
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                            {' -> '}
                                                            <Tooltip title="End Address">
                                                                <span>
                                                                    {
                                                                        _.get(tripStats, 'legEndAddress', '').split(
                                                                            ','
                                                                        )[0]
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        </span>
                                                    </>
                                                )}
                                            </span>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            data-cy="driver-history-pickup-btn"
                                            onClick={() => onClickInfoAction(pickup._id)}
                                        >
                                            <Icon>info</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {_.get(tripStats, 'legLunchBreakDuration', 0) > 0 && (
                                    <div
                                        style={{
                                            margin: theme.spacing.unit * 2,
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Divider
                                            style={{
                                                background: colors.grey[500]
                                            }}
                                        />
                                        <Typography variant="caption" style={{ color: colors.grey[500] }}>
                                            {convertSecondsToMinutes(_.get(tripStats, 'legLunchBreakDuration', 0), 0)}{' '}
                                            lunch break
                                        </Typography>
                                        <Divider
                                            style={{
                                                background: colors.grey[500]
                                            }}
                                        />
                                    </div>
                                )}
                                {_.get(tripStats, 'etaRecalculations', []).map((e, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            style={{
                                                marginBottom: theme.spacing.unit * 2,
                                                textAlign: 'center'
                                            }}
                                        >
                                            {' '}
                                            <Typography variant="caption" style={{ color: colors.red[500] }}>
                                                {e.initiator === 'N/A'
                                                    ? ''
                                                    : e.manualRecalculation
                                                    ? 'Manual '
                                                    : 'Automatic '}
                                                ETA Recalc Triggered By {e.initiator}
                                            </Typography>
                                            <Divider
                                                style={{
                                                    background: colors.red[500]
                                                }}
                                            />
                                            <Typography variant="caption" style={{ color: colors.red[500] }}>
                                                (
                                                {e.recalculationTimestamp
                                                    ? moment(e.recalculationTimestamp)
                                                          .tz(timezone)
                                                          .format('h:mm:ss A')
                                                    : 'N/A'}
                                                )
                                                {!_.isNil(e.notificationSent) &&
                                                    e.notificationSent > 0 &&
                                                    `[Notification Sent]`}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </List>
            </div>
            <PDFPreviewer open={pdfViewerOpen} onClose={() => setPdfViewerOpen(false)} pdfUrl={pdfUriStr} />
        </div>
    );
}

export default withTheme()(DriversTripStatsList);
