import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';

import { getPhoto, takePhoto } from 'utils/camera';
import imageCompression from 'browser-image-compression';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import { withTheme } from '@material-ui/core/styles';
import { deviceHelper } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { colors } from '@material-ui/core';
import { maxFileSizeMB, maxImageWidthAndHeight } from '../../constants';

function ImageCapture(props) {
    const {
        theme,
        imageURLs = [],
        imageIcons = [],
        uploadingImage,
        placeholderText,
        handleAddImage,
        handleDeleteImage,
        outerStyles = {},
        innerStyles = {},
        altContentA = null,

        //you can leave these as null if parent component doesn't need to control/read the currently displayed image
        displayImageIdx = null,
        onDisplayImageIdxChange = null,

        showLastImageButton = false,
        lastImageBlob = null,
        lastImagePreview = null,
        condensed = false,
        autoDisplayLastImage = false,
        maxImages = null,
        maxFileSize = maxFileSizeMB
    } = props;
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);

    const [displayImages, setDisplayImages] = useState([]);

    useEffect(() => {
        setDisplayImages(imageURLs);
    }, [imageURLs]);

    const options = {
        maxSizeMB: maxFileSize,
        maxWidthOrHeight: maxImageWidthAndHeight,
        fileType: 'image/jpeg',
        useWebWorker: true
    };

    const handleImageUpload = async e => {
        const reader = new FileReader();
        const imageFile = await imageCompression(e.target.files[0], options);

        reader.onloadend = () => {
            e.target.value = ''; // IMPORTANT: Allows for same image uploads
            handleAddImage(imageFile, reader.result);
        };

        if (!_.isNil(imageFile)) {
            reader.readAsDataURL(imageFile);
            if (!_.isNil(onDisplayImageIdxChange)) {
                onDisplayImageIdxChange(displayImages.length);
            }
        }
    };

    const handleTakePhoto = async (uploadExistingPhoto = false) => {
        let imageObj = null;
        try {
            imageObj = await getPhoto(uploadExistingPhoto);
        } catch (err) {
            if (err.message == 'Unable to access camera, user denied permission request') {
                if (uploadExistingPhoto) {
                    handleError(new Error(loc('uploadPermissionError', lang)));
                } else {
                    handleError(new Error(loc('cameraPermissionError', lang)));
                }
                return;
            } else {
                return;
            }
        }
        let imageData = imageObj.data;

        try {
            const compressedFile = await imageCompression(imageData, options);
            imageData = compressedFile;
        } catch (error) {
            console.log(error);
        }

        if (_.isNil(imageObj)) return;

        try {
            handleAddImage(imageData, imageObj.image.webPath);
        } catch (err) {
            handleError(err);
        }
    };

    const handleUseLastPhoto = () => {
        if (_.isNil(lastImageBlob) || _.isNil(lastImagePreview)) return;
        handleAddImage(lastImageBlob, lastImagePreview);
    };

    const handleError = err => {
        onSnackbar(err.message + '.', 'error');
    };

    const condensedOuterStyles = {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    };
    const condensedInnerStyles = {
        maxWidth: '50%',
        minWidth: '50%',
        minHeight: '',
        maxHeight: 200,
        margin: 0,
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    };
    const condensedScrollerStyles = {
        // backgroundColor: colors.red[500], //for testing
        height: 185,
        display: 'flex',
        flexWrap: 'wrap',
        overflowX: '',
        overflowY: 'scroll'
    };

    return (
        <>
            {altContentA && (
                <Grid container style={{ margin: '0px auto 8px', maxWidth: 420 }}>
                    {altContentA}
                </Grid>
            )}
            <ImageViewer
                displayImageIdx={displayImageIdx}
                onDisplayImageIdxChange={onDisplayImageIdxChange}
                displayImages={displayImages}
                handleDeleteImage={handleDeleteImage}
                condensed={condensed}
                placeholderText={
                    <div>
                        {placeholderText}
                        {!deviceHelper.isNativeApp() && (
                            <Grid item xs={12}>
                                <FormControl fullWidth data-cy="upload-photo-input">
                                    <input
                                        accept="image/jpg, image/jpeg, .webp, .heif, .heic, .gif, .jpg"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple={false}
                                        type="file"
                                        onChange={handleImageUpload}
                                        disabled={
                                            uploadingImage || (!_.isNil(maxImages) && imageURLs.length >= maxImages)
                                        }
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            disabled={
                                                uploadingImage || (!_.isNil(maxImages) && imageURLs.length >= maxImages)
                                            }
                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                        >
                                            <Icon style={{ marginRight: theme.spacing.unit }}>cloud_upload</Icon>{' '}
                                            {loc('pickupDialogDrop23a', lang)}
                                        </Button>
                                    </label>
                                    {showLastImageButton && (
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            component="span"
                                            disabled={
                                                uploadingImage || (_.isNil(lastImageBlob) || _.isNil(lastImagePreview))
                                            }
                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                            onClick={handleUseLastPhoto}
                                        >
                                            <Icon style={{ marginRight: theme.spacing.unit }}>content_copy</Icon>
                                            Use Previous Image
                                        </Button>
                                    )}
                                </FormControl>
                            </Grid>
                        )}
                        {deviceHelper.isNativeApp() && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ marginTop: theme.spacing.unit }}
                                            // disabled={_.isNil(window.cordova)}
                                            disabled={
                                                uploadingImage || (!_.isNil(maxImages) && imageURLs.length >= maxImages)
                                            }
                                            color="primary"
                                            onClick={() => handleTakePhoto(true)}
                                        >
                                            <Icon style={{ marginRight: theme.spacing.unit }}>cloud_upload</Icon>{' '}
                                            {loc('pickupDialogDrop23a', lang)}
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ marginTop: theme.spacing.unit * 1.5 }}
                                            // disabled={_.isNil(window.cordova)}
                                            disabled={
                                                uploadingImage || (!_.isNil(maxImages) && imageURLs.length >= maxImages)
                                            }
                                            color="primary"
                                            onClick={() => handleTakePhoto(false)}
                                        >
                                            <Icon style={{ marginRight: theme.spacing.unit }}>camera_alt</Icon>{' '}
                                            {loc('pickupDialogDrop23b', lang)}
                                        </Button>
                                    </FormControl>
                                </Grid>
                                {showLastImageButton && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                component="span"
                                                disabled={
                                                    uploadingImage ||
                                                    (_.isNil(lastImageBlob) || _.isNil(lastImagePreview))
                                                }
                                                style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                onClick={handleUseLastPhoto}
                                            >
                                                <Icon style={{ marginRight: theme.spacing.unit }}>content_copy</Icon>
                                                Use Previouse Image
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                )}
                            </>
                        )}
                    </div>
                }
                outerStyles={condensed ? { ...condensedOuterStyles, ...outerStyles } : outerStyles}
                innerStyles={condensed ? { ...condensedInnerStyles, ...innerStyles } : { ...innerStyles, width: 420 }}
                scrollerStyles={condensed ? condensedScrollerStyles : { maxWidth: 420, overflowX: 'auto' }}
                autoDisplayFirstImage={false}
                autoDisplayLastImage={autoDisplayLastImage}
                imageIcons={imageIcons}
                handleImageUpload={handleImageUpload}
                maxImages={maxImages}
            />
            <Grid container style={{ margin: '8px auto 0px', maxWidth: 420 }} />
        </>
    );
}
export default withTheme()(ImageCapture);
