import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { validate } from 'utils/validate';
import {
    deviceHelper,
    profileNotComplete,
    emailIsVerified,
    getFilteredName,
    isEXPRegion,
    isAUSRegion,
    getUserAccountStatus
} from 'utils/misc.js';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { withTheme } from '@material-ui/core/styles';

import { NativeBiometric } from '@capgo/capacitor-native-biometric';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { Formik } from 'formik';

import DisableAccountDialog from 'components/Dialogs/Customer/DisableAccountDialog';
import DeleteAccountDialog from 'components/Dialogs/Customer/DeleteAccountDialog';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { _user } from 'std';

import HttpContext from 'utils/contexts/HttpContext';
import useGetJSON from 'utils/hooks/useGetJSON';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';

import DeviceInfoWidget from 'components/DeviceInfoWidget';
import {
    Avatar,
    Icon,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    InputAdornment,
    IconButton,
    FormGroup,
    Switch,
    InputLabel,
    Select,
    ListItemSecondaryAction
} from '@material-ui/core';
import TwoFactorAuthenticationDialog from 'components/Dialogs/Customer/TwoFactorAuthenticationDialog';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import GoogleContext from 'utils/contexts/GoogleContext';
import { createLocation, getEmptyLocation } from 'helpers/locationHelper';
import PasswordInput from 'components/InputComponents/PasswordInput';
import { useMemo } from 'react';
import HelpDialog from 'components/HelpDialog/HelpDialog';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { INVALID_REDEMPTION_EMAILS } from '../../../constants';
import ProfileNotCompleteDialog from './Dialogs/ProfileNotCompleteDailog';

const FirebaseAdmin = require('firebase/firebaseAdmin.js');

function Profile(props) {
    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const { google } = useContext(GoogleContext);
    const warnAction = useContext(ConfirmDialogContext);

    const history = useHistory();
    const onSnackbar = useContext(SnackbarContext);

    const {
        loading: collectorsLoading,
        error: collectorsError,
        data: { collectors = [] }
    } = useGetJSON(`/collectors/all`);
    const {
        data: { twoFactorAuthentication }
    } = useGetJSON(`/system/twoFactorAuthentication`);

    const {
        customer,
        pickups,
        reloadCustomer,
        onHowItWorks,
        logOut,
        theme,
        operator,
        balance,
        handleToggleReactivateDialog,
        reactivateDialogOpen,
        twoFactorAuthenticationWarning,
        onLogoutAll,
        onDeleteAccount,
        onDisableAccount,
        charityEnabled,
        pickupsEnabled,
        customerCanChangeAccountType,
        registrationConfig,
        isSMSEnabled
    } = props;

    const [savingUser, setSavingUser] = useState(false);
    const [savingPassword, setSavingPassword] = useState(false);
    const [savingSettings, setSavingSettings] = useState(false);
    const [savingSecurityQuestion, setSavingSecurityQuestion] = useState(false);

    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [guestDialogOpen, setGuestDialogOpen] = useState(false);
    const [redirectRequired, setRedirectRequired] = useState(profileNotComplete(customer));
    const [profileNotCompleteDialogOpen, setprofileNotCompleteDialogOpen] = useState(profileNotComplete(customer));
    const [profileNotCompleted, setProfileNotCompleted] = useState(profileNotComplete(customer));
    const [accountSubStatusDialogOpen, setAccountSubStatusDialogOpen] = useState(false);
    const [accountSubStatusDialogReason, setAccountSubStatusDialogReason] = useState('');
    const [accountSubStatusDialogType, setAccountSubStatusDialogType] = useState('');
    const [accountSubStatusDialogState, setAccountSubStatusDialogState] = useState(null);

    const [banReason, setBanReason] = useState('');
    const [banAccountDialogOpen, setBanAccountDialogOpen] = useState(false);
    const [banningAccount, setBanningAccount] = useState(false);

    const [disableReason, setDisableReason] = useState({ reason: '', comments: '' });
    const [disableAccountDialogOpen, setDisableAccountDialogOpen] = useState(false);
    const [disablingAccount, setDisablingAccount] = useState(false);

    const [deleteReason, setDeleteReason] = useState({ reason: '', comments: '' });
    const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
    const [deletingAccount, setDeletingAccount] = useState(false);

    const [twoFactorAuthenticationDialogOpen, setTwoFactorAuthenticationDialogOpen] = useState(false);
    const [twoFactorAuthenticationSubmit, setTwoFactorAuthenticationSubmit] = useState(() => {});
    const [twoFactorAuthenticationPhoneNumber, setTwoFactorAuthenticationPhoneNumber] = useState(
        _.get(customer, 'phone', '')
    );
    const [services, setServices] = useState([]);

    const [submittingStatus, setSubmittingStatus] = useState(false);
    const [emailChangeDialogOpen, setEmailChangeDialogOpen] = useState(false);
    const [emailChangePending, setEmailChangePending] = useState(
        !_.isNil(_.get(customer, 'verification.newEmail')) &&
            moment().isBefore(moment(_.get(customer, 'verification.newEmail.tokenExpiration')))
    );
    const [timeoutId, setTimeoutId] = useState(null);

    const [notificationSettings, setNotificationSettings] = useState({
        emailNotificationsEnabled: _.get(customer, 'settings.emailNotificationsEnabled', false),
        forcedEmailNotificationsEnabled: _.get(customer, 'settings.forcedEmailNotificationsEnabled', false),
        textNotificationsEnabled: _.get(customer, 'settings.textNotificationsEnabled', false),
        pushNotificationsEnabled: _.get(customer, 'settings.pushNotificationsEnabled', false),
        donationNotificationsEnabled: _user.donationNotificationsEnabled(customer),
        pushToken: customer.settings ? customer.settings.pushToken : null
    });

    const [informationDialogOpen, setInformationDialogOpen] = useState(false);
    const [informationDialogText, setInformationDialogText] = useState(false);

    const [biometricsAvailable, setBiometricsAvailable] = useState(false);
    const [biometricsOptIn, setBiometricsOptIn] = useState(false);
    const [biometricsHasCredentials, setBiometricsHasCredentials] = useState(false);

    const [emailToAuthorize, setEmailToAuthorize] = useState('');
    const [emailToAuthorizeError, setEmailToAuthorizeError] = useState(null);
    const [resendAuthorizationEmailDialogOpen, setResendAuthorizationEmailDialogOpen] = useState(false);
    const [authorizedEmail, setAuthorizedEmail] = useState({});

    const [initialSecurityQuestion, setInitialSecurityQuestion] = useState(_.get(customer, 'securityQuestion', ''));
    const [initialSecurityAnswer, setInitialSecurityAnswer] = useState(_.get(customer, 'securityAnswer', ''));

    const [securityQuestion, setSecurityQuestion] = useState(_.get(customer, 'securityQuestion', ''));
    const [securityAnswer, setSecurityAnswer] = useState(_.get(customer, 'securityAnswer', ''));
    const isUnchanged = securityQuestion === initialSecurityQuestion && securityAnswer === initialSecurityAnswer;

    const [showPOBox, setShowPOBox] = useState(isEXPRegion() ? true : _.get(customer, 'POBox', '') !== '');
    const [showUnitNum, setShowUnitNum] = useState(
        isEXPRegion() ? true : _.get(customer, 'location.unitNumber', '') !== ''
    );
    const [showTradingName, setShowTradingName] = useState(
        isEXPRegion()
            ? true
            : _.get(customer, 'location.locationType', 'Residential') === 'Commercial'
            ? _.get(customer, 'businessName', '') !== ''
            : false
    );

    useEffect(() => {
        const fetchData = async () => {
            const resServices = await http.getJSON('/services', false, true);

            if (resServices.ok) {
                const services = _.get(resServices, 'data.services', []);
                setServices(services);
            }

            try {
                const availableResult = await NativeBiometric.isAvailable();
                setBiometricsAvailable(availableResult.isAvailable);
                setBiometricsHasCredentials(JSON.parse(window.localStorage.getItem('biometricsHasCredentials')));
                setBiometricsOptIn(JSON.parse(window.localStorage.getItem('biometricsHasCredentials')));
            } catch (err) {}
        };

        fetchData();
    }, []);

    useEffect(() => {
        unsetEmailPending();
        const emailPending =
            !_.isNil(_.get(customer, 'verification.newEmail')) &&
            moment().isBefore(moment(_.get(customer, 'verification.newEmail.tokenExpiration')));
        setEmailChangePending(emailPending);
        if (emailPending) {
            clearTimeout(timeoutId);
            const id = setTimeout(() => {
                unsetEmailPending();
            }, moment(_.get(customer, 'verification.newEmail.tokenExpiration')).diff(moment(), 'milliseconds') + 10);
            setTimeoutId(id);
        }
    }, [customer]);

    const unsetEmailPending = async (ifExpired = true) => {
        if (ifExpired) {
            if (_.isNil(_.get(customer, 'verification.newEmail'))) {
                setEmailChangePending(false);
                clearTimeout(timeoutId);
                setEmailChangeDialogOpen(false);
            }
            if (
                !_.isNil(_.get(customer, 'verification.newEmail')) &&
                moment().isAfter(moment(_.get(customer, 'verification.newEmail.tokenExpiration')))
            ) {
                await http.post('/users/' + customer._id + '/removePendingEmailChanges', { ifExpired });
                setEmailChangePending(false);
                clearTimeout(timeoutId);
                setEmailChangeDialogOpen(false);
            }
        } else {
            await http.post('/users/' + customer._id + '/removePendingEmailChanges', { ifExpired });
            setEmailChangePending(false);
            clearTimeout(timeoutId);
            setEmailChangeDialogOpen(false);
        }
    };
    const handleRevertEmailChange = () => {
        unsetEmailPending(false);
        setEmailChangeDialogOpen(false);
    };

    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(services, service => service.isMobilePickupService && !service.disabled),
                'subServices',
                []
            ).filter(service => !service.disabled),
        [services]
    );
    const selectedSubService = useMemo(
        () => _.find(mobilePickupSubServices, { pickupType: _.get(customer, 'location.locationType', '') }),
        [mobilePickupSubServices]
    );

    // const { showAll: showAllCollectors, collector, handleChange: handleChangeCollector } = useCollectorDropDown({
    //     collectors,
    //     saveStateInURL: false
    // });

    // useEffect(() => {
    //     const { name, phone } = form;
    //     if (!customer.adminView && (_.isEmpty(name.first) || _.isEmpty(name.last) || _.isEmpty(phone))) {
    //         setGuestDialogOpen(true);
    //     }
    // }, [customer.adminView, form]);

    const handleSettingsChange = e => {
        let updatedSettings = _.cloneDeep(notificationSettings);

        if (e.target.value === 'forcedEmailNotificationsEnabled' && !e.target.checked) {
            _.set(updatedSettings, 'emailNotificationsEnabled', e.target.checked);
            _.set(updatedSettings, 'forcedEmailNotificationsEnabled', e.target.checked);

            setNotificationSettings(updatedSettings);
            return;
        }

        if (e.target.value === 'emailNotificationsEnabled' && e.target.checked) {
            _.set(updatedSettings, 'emailNotificationsEnabled', e.target.checked);
            _.set(updatedSettings, 'forcedEmailNotificationsEnabled', e.target.checked);
            setNotificationSettings(updatedSettings);
            return;
        }

        _.set(updatedSettings, e.target.value, e.target.checked);
        setNotificationSettings(updatedSettings);
    };

    const handleSettingsSubmit = async () => {
        let settings = _.clone(notificationSettings);

        setSavingSettings(true);

        // only attempt to grab new push token if on app
        if (deviceHelper.isNativeApp() && settings.pushNotificationsEnabled) {
            settings.pushToken = await FirebaseAdmin.enablePushNotifications(
                () => {},
                actionPerformed => {
                    const clickActionLink = _.get(actionPerformed, 'notification.data.clickActionLink');
                    if (!_.isEmpty(clickActionLink)) {
                        window.location.replace(clickActionLink);
                    }
                }
            );
        }

        let res = await http.post(`/notifications/${customer._id}/update`, { settings });
        if (res.ok) {
            await reloadCustomer();
            onSnackbar(loc('updatedNotificationSettings', lang));
        } else if (res.status === 400) {
            onSnackbar(res.errorMessage + '.', 'error');
        }

        setSavingSettings(false);
    };

    const handleCancel = () => {
        history.push(`/customers/${customer._id}`);
    };

    const handlePasswordsChange = e => {
        const passwordField = e.target.name;
        const passwordChange = e.target.value;

        switch (passwordField) {
            case 'passwordCurrent':
                setPasswordCurrent(passwordChange);
                break;
            case 'password':
                setPassword(passwordChange);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(passwordChange);
                break;
            default:
        }
    };

    const toggleHelpDialog = text => () => {
        if (text === informationDialogText && informationDialogOpen) {
            setInformationDialogOpen(false);
        } else {
            setInformationDialogOpen(true);
            setInformationDialogText(text);
        }
    };

    const handleClearTrustedDevices = async () => {
        const res = await http.post(`/users/${customer._id}/clearTrustedDevices`);
        if (res.ok) {
            onSnackbar(loc('clearTrustedDevices', lang), 'success');
        } else {
            onSnackbar(loc('somethingWentWrong', lang), 'error');
        }
    };

    const handleAccountSubStatusToggle = type => e => {
        if (!_user.isSystemAdmin(operator) && !_user.isInternalRole(operator)) {
            return;
        }
        setAccountSubStatusDialogReason('');
        setAccountSubStatusDialogType(type);
        setAccountSubStatusDialogState(e.target.checked);
        setAccountSubStatusDialogOpen(true);
    };

    const handleAccountSubStatusSubmit = async () => {
        setSubmittingStatus(true);
        if (!_user.isSystemAdmin(operator) && !_user.isInternalRole(operator)) {
            onSnackbar('You do not have permission to perform this action', 'error');
            return;
        }
        if (_.isNil(accountSubStatusDialogState)) {
            onSnackbar('Error updating account sub-status', 'error');
            return;
        }
        const res = await http.post(`/users/${customer._id}/updateAccountSubStatus`, {
            subStatusType: accountSubStatusDialogType,
            state: accountSubStatusDialogState,
            reason: accountSubStatusDialogReason
        });
        if (res.ok) {
            await reloadCustomer();
            setAccountSubStatusDialogReason('');
            setAccountSubStatusDialogType('');
            setAccountSubStatusDialogState(null);
            setAccountSubStatusDialogOpen(false);
            onSnackbar('Successfullly updated account sub-status');
            //show snackbar success
        } else {
            onSnackbar('Error updating account sub-status', 'error');
            //show snackbar error
        }
        setSubmittingStatus(false);
    };

    const handleAccountInformationSubmit = async form => {
        if (
            savingUser ||
            customer.deleted ||
            ((customer.disabled || customer.suspended) &&
                !_user.isSystemAdmin(operator) &&
                !_user.isInternalRole(operator))
        ) {
            onSnackbar('There is an error. Please fix it before saving.');
            return;
        }
        setSavingUser(true);
        await fetch(process.env.REACT_APP_API_URL + `/users/${customer._id}/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(form)
        })
            .then(async res => {
                if (res.status === 200) {
                    await reloadCustomer();
                    const json = await res.json();
                    onSnackbar(
                        json.requireEmailAuthentication
                            ? loc('updatedProfileWithEmailAuthentication', lang, { email: customer.email })
                            : loc('updatedProfile', lang),
                        json.requireEmailAuthentication ? 'info' : undefined
                    );
                    if (redirectRequired && emailIsVerified(customer)) {
                        customer.firstLogin = true; // HACK!
                        onHowItWorks(true)();
                        history.push(`/customers/${customer._id}`);
                    }
                    setTwoFactorAuthenticationDialogOpen(false);
                } else if (res.status === 400) {
                    let message, variant;
                    try {
                        const json = await res.json();
                        message = json.message; // error message supplied by the server
                        variant = json.variant;
                        if (json.twoFactorAuthenticationRequired) {
                            setTwoFactorAuthenticationDialogOpen(true);
                            setTwoFactorAuthenticationPhoneNumber(_.get(form, 'phone', customer.phone));
                            setTwoFactorAuthenticationSubmit(() => (code, phone) =>
                                handleAccountInformationSubmit({
                                    ...form,
                                    twoFactorAuthenticationCode: code,
                                    twoFactorAuthenticationPhoneNumber: phone
                                })
                            );
                        } else {
                            setTwoFactorAuthenticationDialogOpen(false);
                        }
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                        variant = 'error';
                    }
                    onSnackbar(message, variant);
                } else if (res.status === 401) {
                    onSnackbar(loc('authCodeFailed', lang), 'error');
                    if ((await res.json()).twoFactorAuthenticationRequired) {
                        setTwoFactorAuthenticationDialogOpen(true);
                        setTwoFactorAuthenticationPhoneNumber(_.get(form, 'phone', customer.phone));
                        setTwoFactorAuthenticationSubmit(() => (code, phone) =>
                            handleAccountInformationSubmit({
                                ...form,
                                twoFactorAuthenticationCode: code,
                                twoFactorAuthenticationPhoneNumber: phone
                            })
                        );
                    } else {
                        setTwoFactorAuthenticationDialogOpen(false);
                    }
                } else if (res.status === 429) {
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    logOut();
                } else if (res.status === 409) {
                    onSnackbar(loc('errorVerifyingCreds', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                } else if (res.status === 502) {
                    onSnackbar(loc('serverUnreachable', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                } else {
                    onSnackbar(loc('serverError', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                }
                setSavingUser(false);
            })
            .catch(err => {
                onSnackbar(loc('connectionErr', lang), 'error');
                setTwoFactorAuthenticationDialogOpen(true);
                setTwoFactorAuthenticationPhoneNumber(_.get(form, 'phone', customer.phone));
                setTwoFactorAuthenticationSubmit(() => (code, phone) =>
                    handleAccountInformationSubmit({
                        ...form,
                        twoFactorAuthenticationCode: code,
                        twoFactorAuthenticationPhoneNumber: phone
                    })
                );
                setSavingUser(false);
            });
    };

    const handleChangeSecurityQuestionSubmit = async () => {
        setSavingSecurityQuestion(true);
        const res = await http.post(`/users/${customer._id}/updateSecurityQuestion`, {
            ques: securityQuestion,
            ans: securityAnswer
        });
        if (res.ok) {
            await reloadCustomer();
            onSnackbar('Successfullly updated Security Question/Answer');
        } else {
            onSnackbar('Error updating Security Question/Answer', 'error');
        }
        setSavingSecurityQuestion(false);
    };

    const handleChangePasswordSubmit = async (
        twoFactorAuthenticationCode = null,
        twoFactorAuthenticationPhoneNumber = null
    ) => {
        setSavingPassword(true);
        let form = {
            passwordCurrent,
            password
        };
        if (!_.isNil(twoFactorAuthenticationCode)) {
            form.twoFactorAuthenticationCode = twoFactorAuthenticationCode;
        }
        if (!_.isNil(twoFactorAuthenticationPhoneNumber)) {
            form.twoFactorAuthenticationPhoneNumber = twoFactorAuthenticationPhoneNumber;
        }

        await fetch(process.env.REACT_APP_API_URL + `/users/${customer._id}/changePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(form)
        })
            .then(async res => {
                if (res.status === 200) {
                    onSnackbar(loc('changedPassword', lang));
                    setTwoFactorAuthenticationDialogOpen(false);

                    if (deviceHelper.isNativeApp() && biometricsAvailable && biometricsOptIn) {
                        try {
                            //Save user's credentials to the device - Keychain (iOS) and Keystore (Android).
                            await NativeBiometric.setCredentials({
                                username: customer.email,
                                password,
                                server: process.env.REACT_APP_ORIGIN_URL
                            });
                            window.localStorage.setItem('biometricsHasCredentials', 'true');
                        } catch (err) {}
                    }
                    const json = await res.json();

                    if (json.isPasswordForceChanged) {
                        await reloadCustomer();

                        history.push(`/customers/${customer._id}`);
                    }

                    setPasswordCurrent('');
                    setPassword('');
                    setPasswordConfirmation('');
                } else if (res.status === 400) {
                    let message, variant;
                    try {
                        const json = await res.json();
                        message = json.message; // error message supplied by the server
                        variant = json.variant;
                        if (json.twoFactorAuthenticationRequired) {
                            setTwoFactorAuthenticationDialogOpen(true);
                            setTwoFactorAuthenticationPhoneNumber(customer.phone);
                            setTwoFactorAuthenticationSubmit(() => (code, phone) =>
                                handleChangePasswordSubmit(code, phone)
                            );
                        } else {
                            setTwoFactorAuthenticationDialogOpen(false);
                        }
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                        variant = 'error';
                    }
                    onSnackbar(message, variant);
                } else if (res.status === 401) {
                    onSnackbar(loc('authCodeFailed', lang), 'error');
                    if ((await res.json()).twoFactorAuthenticationRequired) {
                        setTwoFactorAuthenticationDialogOpen(true);
                        setTwoFactorAuthenticationPhoneNumber(customer.phone);
                        setTwoFactorAuthenticationSubmit(() => (code, phone) =>
                            handleChangePasswordSubmit(code, phone)
                        );
                    } else {
                        setTwoFactorAuthenticationDialogOpen(false);
                    }
                } else if (res.status === 429) {
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    logOut();
                } else if (res.status === 409) {
                    onSnackbar(loc('errorVerifyingCreds', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                } else if (res.status === 502) {
                    onSnackbar(loc('serverUnreachable', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                } else {
                    onSnackbar(loc('serverError', lang), 'error');
                    setTwoFactorAuthenticationDialogOpen(false);
                }
            })
            .catch(err => {
                onSnackbar(loc('connectionErr', lang), 'error');
                setTwoFactorAuthenticationDialogOpen(true);
                setTwoFactorAuthenticationPhoneNumber(customer.phone);
                setTwoFactorAuthenticationSubmit(() => (code, phone) => handleChangePasswordSubmit(code, phone));
            });

        setSavingPassword(false);
    };

    // const handleAccountInformationSubmit = async form => {
    //     setSavingUser(true);

    //     const res = await http.post(`/users/${customer._id}/update`, form, true);
    //     if (res.ok) {
    //         await reloadCustomer();
    //         onSnackbar(loc('updatedProfile', lang));
    //         if (redirectRequired && emailIsVerified(customer)) {
    //             customer.firstLogin = true; // HACK!
    //             onHowItWorks(true)();
    //             history.push(`/customers/${customer._id}`);
    //         }
    //     } else if (res.status === 400) {
    //         onSnackbar(res.errorMessage + '.', 'error');
    //     }

    //     setSavingUser(false);
    // };

    // const handleChangePasswordSubmit = async () => {
    //     setSavingPassword(true);

    //     const res = await http.post(`/users/${customer._id}/changePassword`, {
    //         passwordCurrent,
    //         password
    //     });

    //     if (res.ok) {
    //         onSnackbar(loc('changedPassword', lang));
    //         setPasswordCurrent('');
    //         setPassword('');
    //         setPasswordConfirmation('');
    //     } else {
    //         onSnackbar(loc('changePasswordFailed', lang), 'error');
    //     }

    //     setSavingPassword(false);
    // };

    const handleEmailToAuthorize = e => {
        setEmailToAuthorize(e.target.value);
        setEmailToAuthorizeError('');
    };

    const handleAddAuthorizedEmail = async () => {
        if (
            _.get(customer, 'authorizedEmails', [])
                .map(authorization => authorization.email)
                .includes(emailToAuthorize)
        ) {
            setEmailToAuthorizeError(loc('duplicateAuthorizationEmail', lang, { email: emailToAuthorize }));
            return;
        }
        let error = validate(['email', 'e-transfer-email'], emailToAuthorize, lang);
        if (error.fail) {
            setEmailToAuthorizeError(error.reason);
            return;
        }
        const res = await http.post(`/users/${customer._id}/addAuthorizedEmail`, { email: emailToAuthorize });
        if (res.ok) {
            setEmailToAuthorize('');
            setEmailToAuthorizeError('');
            reloadCustomer();
            onSnackbar(loc('authorizationEmailSent', lang, { email: emailToAuthorize, accountEmail: customer.email }));
        }
    };

    const handleRemoveAuthorizedEmail = async email => {
        const res = await http.post(`/users/${customer._id}/removeAuthorizedEmail`, { email });
        if (res.ok) {
            reloadCustomer();
            onSnackbar(loc('emailAuthorizationRemoved', lang, { email }));
        }
    };

    const handleResendAuthorizationEmail = async email => {
        const res = await http.post(`/users/${customer._id}/resendAuthorizationEmail`, { email });
        if (res.ok) {
            setResendAuthorizationEmailDialogOpen(false);
            setAuthorizedEmail({});
            onSnackbar(loc('authorizationEmailSent', lang, { email, accountEmail: customer.email }));
        }
    };

    const handleResendVerificationEmail = async () => {
        const res = await http.post(`/users/${customer._id}/resendVerificationEmail`, {});
        if (res.ok) {
            onSnackbar(loc('verificationEmailSent', lang));
        }
    };

    const handleDisableReasonChange = e => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setDisableReason({ ...disableReason, [fieldName]: fieldValue });
    };

    const handleBanAccount = async () => {
        setBanningAccount(true);
        if (customer.banned) {
            const res = await http.post(`/users/${customer._id}/enableUser`, { reason: banReason });
            if (res.ok) {
                setBanAccountDialogOpen(false);
                setBanReason('');
                reloadCustomer();
                onSnackbar('Successfully unbanned user');
            }
        } else {
            const res = await http.post(`/users/${customer._id}/banUser`, {
                banReason
            });
            if (res.ok) {
                setBanAccountDialogOpen(false);
                setBanReason('');
                reloadCustomer();
                onSnackbar('Successfully banned user');
            }
        }
        setBanningAccount(false);
    };

    const handleDisableAccount = async () => {
        setDisableAccountDialogOpen(false);
        onDisableAccount(disableReason);
    };

    const handleDeleteReasonChange = e => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setDeleteReason({ ...deleteReason, [fieldName]: fieldValue });
    };

    const handleDeleteAccount = async () => {
        setDeleteAccountDialogOpen(false);
        onDeleteAccount(deleteReason);
    };

    const {
        pushNotificationsEnabled,
        textNotificationsEnabled,
        emailNotificationsEnabled,
        forcedEmailNotificationsEnabled,
        donationNotificationsEnabled
    } = notificationSettings;

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    const passwordsMatch = password === passwordConfirmation;
    const passwordErr = validate(['password'], password, lang);

    const pickupsPendingToday = pickups.filter(
        p => _.isNil(p.completionDate) && moment(p.date.substring(0, 10)).isSame(new Date(), 'day')
    );

    const isDisabledCustomer = _.get(customer, 'disabled', false);
    const customerHasApp = !_.isEmpty(_.get(customer, 'deviceInfo.appVersion', ''));

    return (
        <React.Fragment>
            {!emailIsVerified(customer) && (
                <Paper
                    style={{
                        width: 'calc(100% - 32px)',
                        margin: 16,
                        padding: '16px 24px',
                        background: colors.red[500]
                    }}
                >
                    <Typography style={{ color: theme.palette.common.white }}>
                        {loc('accountSettings23', lang, { email: _.get(customer, 'email', '') })}
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        onClick={handleResendVerificationEmail}
                    >
                        {loc('accountSettings24', lang)}
                    </Button>
                </Paper>
            )}

            {customer.accountsWithAccess && !_.isEmpty(customer.accountsWithAccess) && (
                <Paper
                    style={{
                        width: 'calc(100% - 32px)',
                        margin: 16,
                        padding: '16px 24px'
                    }}
                    data-cy="viewing-access-panel"
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: theme.spacing.unit }}>
                        <Icon
                            style={{
                                color: 'red',
                                marginRight: theme.spacing.unit,
                                marginTop: theme.spacing.unit / 2,
                                fontSize: 25
                            }}
                        >
                            error
                        </Icon>
                        <Typography variant="h6">{loc('accountSettings26', lang)}</Typography>
                    </div>
                    <Typography variant="body2">{loc('accountSettings27', lang)}</Typography>
                    <List
                        style={{
                            margin: 0
                        }}
                    >
                        {customer.accountsWithAccess.map((user, index) => {
                            return (
                                <ListItem
                                    style={{ paddingLeft: 0, paddingBottom: 0, opacity: user.disabled ? 0.7 : 1 }}
                                >
                                    <Avatar
                                        style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white'
                                        }}
                                    >
                                        {_.get(user, 'name.first[0]', '?')}
                                    </Avatar>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 'inherit'
                                                }}
                                            >
                                                {`${_.get(user, 'name.first', 'N/A')} ${_.get(user, 'name.last', '')}`}
                                            </span>
                                        }
                                        style={{
                                            whiteSpace: 'normal',
                                            paddingRight: theme.spacing.unit * 3,
                                            position: 'relative'
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Paper>
            )}

            <Paper
                style={{
                    width: 'calc(100% - 32px)',
                    margin: 16,
                    padding: '16px 24px',
                    border:
                        profileNotCompleted &&
                        (_.isEmpty(customer.phone) ||
                            _.isEmpty(_.get(customer, 'location.description', {})) ||
                            _.isEmpty(customer.email) ||
                            !getFilteredName(customer, 'name.first') ||
                            !getFilteredName(customer, 'name.last'))
                            ? `1px solid ${theme.palette.error.main}`
                            : ''
                }}
            >
                <Typography variant="h6">{loc('account', lang)}</Typography>
                <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: {
                            first: _.get(customer, 'name.first', ''),
                            last: _.get(customer, 'name.last', '')
                        },
                        email: customer.email,
                        phone: customer.phone ? customer.phone : '',
                        businessPhone: customer.businessPhone ? customer.businessPhone : '',
                        receiveTaxReceipts: _.isNull(_.get(customer, 'receiveTaxReceipts', true))
                            ? false
                            : _.get(customer, 'receiveTaxReceipts', true),
                        twoFactorAuthentication: {
                            isEnabled: _.get(customer, 'twoFactorAuthentication.isEnabled', false)
                        },
                        bypassTwoFactorAuthentication: _.get(customer, 'bypassTwoFactorAuthentication', false),
                        lockDonationPreference: _.get(customer, 'lockDonationPreference', false),
                        location:
                            profileNotCompleted &&
                            _.isEqual(_.get(customer, 'isExpressLegacyLocationUpdated', true), false)
                                ? getEmptyLocation()
                                : _.get(customer, 'location', getEmptyLocation()),
                        unitNumber: _.get(customer, 'location.unitNumber', ''),
                        businessName: _.get(customer, 'businessName', ''),
                        registeredBusinessName: _.get(customer, 'registeredBusinessName', ''),
                        businessNumber: _.get(customer, 'businessNumber', ''),
                        locationType: _.get(customer, 'location.locationType', 'Residential'),
                        POBox: _.get(customer, 'POBox', '')
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email()
                            .trim()
                            .required(),
                        phone: Yup.string().test('validPhoneNumber', loc('labels15', lang), value => {
                            if (!value) return _user.isSystemAdmin(operator) || _user.isInternalRole();
                            let errorsObj = validate(['phone'], value, lang);
                            return !errorsObj.fail;
                        }),
                        businessPhone: Yup.string().when('locationType', {
                            is: 'Commercial',
                            then: Yup.string()
                                .test('validPhoneNumber', loc('validate4', lang), value => {
                                    return !value && isEXPRegion() ? false : true;
                                })
                                .test('validPhoneNumber', loc('labels15', lang), value => {
                                    if (!_.get(registrationConfig, 'showBusinessPhone', true)) return true;
                                    if (!value) return _user.isSystemAdmin(operator) || _user.isInternalRole();
                                    let errorsObj = validate(['phone'], value, lang);
                                    return !errorsObj.fail;
                                })
                        }),
                        name: Yup.object({
                            first: Yup.string()
                                .max(40)
                                .trim()
                                .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s-/.@&',()]+$/, 'Please enter a valid name')
                                .required(loc('labels16', lang)),
                            last: Yup.string()
                                .max(40)
                                .trim()
                                .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s-/.@&',()]+$/, 'Please enter a valid name')
                                .required(loc('labels17', lang))
                        }),
                        location: Yup.object({
                            description: Yup.string().required(loc('validate4', lang))
                        }),
                        unitNumber: Yup.string(),
                        locationType: Yup.string().required(loc('validate4', lang)),
                        businessName: isEXPRegion() ? Yup.string().nullable() : Yup.string(),
                        registeredBusinessName: Yup.string().when('locationType', {
                            is: 'Commercial',
                            then: Yup.string().required(loc('validate4', lang)),
                            otherwise: Yup.string().notRequired()
                        }),
                        businessNumber: Yup.string(),
                        POBox: Yup.string()
                    })}
                    onSubmit={async (form, { resetForm }) => {
                        await handleAccountInformationSubmit({
                            ...form,
                            phone: form.phone.replace(/[^0-9.]/g, ''),
                            businessPhone: form.businessPhone.replace(/[^0-9.]/g, '')
                        });
                        resetForm();
                    }}
                >
                    {formik => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            setFieldValue,
                            getFieldProps
                        } = formik;

                        return (
                            <>
                                {!_.isNil(mobilePickupSubServices) &&
                                    !_.isEmpty(mobilePickupSubServices) &&
                                    mobilePickupSubServices.length > 1 && (
                                        <TextField
                                            {...getFieldProps('locationType')}
                                            fullWidth
                                            select
                                            disabled={
                                                !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) &&
                                                !customerCanChangeAccountType
                                            }
                                            data-cy="profile-location-type-select"
                                            label={loc('accountType', lang)}
                                            InputProps={{
                                                endAdornment:
                                                    !(
                                                        _user.isSystemAdmin(operator) || _user.isInternalRole(operator)
                                                    ) && !customerCanChangeAccountType ? (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={toggleHelpDialog(
                                                                    loc('accountSettings31', lang)
                                                                )}
                                                            >
                                                                <Icon
                                                                    data-cy={`profile-location-type-select-info-icon`}
                                                                >
                                                                    help
                                                                </Icon>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ) : null
                                            }}
                                        >
                                            {_(mobilePickupSubServices)
                                                .map((service, i) => {
                                                    const title = _.get(service, `text.${lang}.title`, '');
                                                    return (
                                                        <MenuItem
                                                            value={_.get(service, 'pickupType')}
                                                            key={_.get(service, 'pickupType')}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-start',
                                                                    verticalAlign: 'middle'
                                                                }}
                                                            >
                                                                <tr
                                                                    style={{
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    <td
                                                                        style={{
                                                                            lineHeight: 0,
                                                                            width: 32,
                                                                            color: 'white'
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={_.get(
                                                                                allIcons,
                                                                                _.get(service, 'mdiIcon')
                                                                            )}
                                                                            size={1}
                                                                        />
                                                                    </td>
                                                                    <td>{title}</td>
                                                                </tr>
                                                            </div>
                                                        </MenuItem>
                                                    );
                                                })
                                                .value()}
                                        </TextField>
                                    )}
                                {_.get(formik.values, 'locationType') === 'Commercial' && (
                                    <TextField
                                        {...getFieldProps('registeredBusinessName')}
                                        error={
                                            _.get(touched, 'registeredBusinessName', false) &&
                                            _.get(errors, 'registeredBusinessName', false)
                                                ? true
                                                : null
                                        }
                                        label={loc('accountSettings29', lang)}
                                        helperText={
                                            _.get(touched, 'registeredBusinessName', false) &&
                                            _.get(errors, 'registeredBusinessName', false)
                                                ? _.get(errors, 'registeredBusinessName', false)
                                                : null
                                        }
                                        fullWidth
                                        style={{ marginBottom: theme.spacing.unit }}
                                    />
                                )}
                                {_.get(formik.values, 'locationType') === 'Commercial' && (
                                    <>
                                        {showTradingName ? (
                                            <TextField
                                                {...getFieldProps('businessName')}
                                                error={
                                                    _.get(touched, 'businessName', false) &&
                                                    _.get(errors, 'businessName', false)
                                                        ? true
                                                        : null
                                                }
                                                label={
                                                    isAUSRegion()
                                                        ? loc('accountSettings28a', lang)
                                                        : loc('accountSettings28', lang)
                                                }
                                                helperText={
                                                    _.get(touched, 'businessName', false) &&
                                                    _.get(errors, 'businessName', false)
                                                        ? _.get(errors, 'businessName', false)
                                                        : null
                                                }
                                                fullWidth
                                                style={{ marginBottom: theme.spacing.unit }}
                                            />
                                        ) : (
                                            <div>
                                                <Typography
                                                    color="primary"
                                                    style={{
                                                        textDecoration: 'underline',
                                                        marginTop: 18,
                                                        fontSize: 15,
                                                        cursor: 'pointer',
                                                        display: 'inline-block'
                                                    }}
                                                    onClick={() => setShowTradingName(true)}
                                                >
                                                    {loc('labels23', lang)}
                                                </Typography>
                                            </div>
                                        )}
                                    </>
                                )}
                                {_.get(formik.values, 'locationType') === 'Commercial' &&
                                    _.get(registrationConfig, 'showBusinessNumber', true) && (
                                        <TextField
                                            {...getFieldProps('businessNumber')}
                                            error={
                                                _.get(touched, 'businessNumber', false) &&
                                                _.get(errors, 'businessNumber', false)
                                                    ? true
                                                    : null
                                            }
                                            label={
                                                isAUSRegion()
                                                    ? loc('charityNumName', lang)
                                                    : loc('accountSettings30', lang)
                                            }
                                            helperText={
                                                _.get(touched, 'businessNumber', false) &&
                                                _.get(errors, 'businessNumber', false)
                                                    ? _.get(errors, 'businessNumber', false)
                                                    : null
                                            }
                                            fullWidth
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}
                                <GMapsAutocomplete
                                    data-cy="registration-address-input"
                                    google={google}
                                    location={values.location}
                                    label={
                                        _.get(formik.values, 'locationType') === 'Commercial'
                                            ? loc('companyAddress', lang)
                                            : loc('address', lang)
                                    }
                                    placeholder={loc('placeholder1', lang)}
                                    selectedValue={values.location.description}
                                    types={['address']}
                                    error={{
                                        fail: _.get(errors, 'location', false)
                                            ? true
                                            : false ||
                                              (profileNotCompleted &&
                                                  _.isEmpty(_.get(values, 'location.description', false))),
                                        reason: loc('validate4', lang)
                                    }}
                                    onChange={() => {
                                        formik.setFieldValue('location', getEmptyLocation());
                                    }}
                                    onSuggestionSelected={({ suggestion, place }) => {
                                        let locationUpdated = createLocation(
                                            place,
                                            suggestion.description,
                                            place.geometry.location.lat(),
                                            place.geometry.location.lng(),
                                            _.get(
                                                _.find(place.address_components, ac => ac.types.includes('locality')),
                                                'long_name',
                                                undefined
                                            ),
                                            _.get(
                                                _.find(place.address_components, ac =>
                                                    ac.types.includes('administrative_area_level_1')
                                                ),
                                                'long_name',
                                                undefined
                                            ),
                                            _.get(
                                                _.find(place.address_components, ac =>
                                                    ac.types.includes('postal_code')
                                                ),
                                                'short_name',
                                                undefined
                                            )
                                        );

                                        formik.setFieldValue('location', locationUpdated);
                                    }}
                                    variant=""
                                    suggestionsOccupyVerticalSpace={true}
                                    style={{ marginTop: theme.spacing.unit, marginBottom: 0 }}
                                />
                                {showUnitNum ? (
                                    <TextField
                                        {...getFieldProps('unitNumber')}
                                        error={
                                            _.get(touched, 'unitNumber', false) && _.get(errors, 'unitNumber', false)
                                                ? true
                                                : null
                                        }
                                        label={loc('labels9', lang)}
                                        helperText={
                                            _.get(touched, 'unitNumber', false) && _.get(errors, 'unitNumber', false)
                                                ? _.get(errors, 'unitNumber', false)
                                                : null
                                        }
                                        fullWidth
                                        style={{ marginBottom: theme.spacing.unit }}
                                    />
                                ) : (
                                    <div>
                                        <Typography
                                            color="primary"
                                            style={{
                                                textDecoration: 'underline',
                                                marginTop: 18,
                                                marginBottom: 15,
                                                fontSize: 15,
                                                cursor: 'pointer',
                                                display: 'inline-block'
                                            }}
                                            onClick={() => setShowUnitNum(true)}
                                        >
                                            {loc('labels24', lang)}
                                        </Typography>
                                    </div>
                                )}
                                {showPOBox ? (
                                    <TextField
                                        {...getFieldProps('POBox')}
                                        error={
                                            _.get(touched, 'POBox', false) && _.get(errors, 'POBox', false)
                                                ? true
                                                : null
                                        }
                                        label={loc('labels21', lang)}
                                        helperText={
                                            _.get(touched, 'POBox', false) && _.get(errors, 'POBox', false)
                                                ? _.get(errors, 'POBox', false)
                                                : null
                                        }
                                        fullWidth
                                        style={{ marginBottom: theme.spacing.unit }}
                                    />
                                ) : (
                                    <div>
                                        <Typography
                                            color="primary"
                                            style={{
                                                textDecoration: 'underline',
                                                marginTop: 13,
                                                marginBottom: 20,
                                                fontSize: 15,
                                                cursor: 'pointer',
                                                display: 'inline-block'
                                            }}
                                            onClick={() => setShowPOBox(true)}
                                        >
                                            {loc('labels25', lang)}
                                        </Typography>
                                    </div>
                                )}

                                <TextField
                                    {...getFieldProps('name.first')}
                                    error={
                                        _.get(touched, 'name.first', false) && _.get(errors, 'name.first', false)
                                            ? true
                                            : null ||
                                              (profileNotCompleted && _.isEmpty(_.get(values, 'name.first', false)))
                                    }
                                    label={
                                        _.get(formik.values, 'locationType') === 'Commercial'
                                            ? loc('contactFirstName', lang)
                                            : loc('firstName', lang)
                                    }
                                    // variant="outlined"
                                    helperText={
                                        _.get(touched, 'name.first', false) && _.get(errors, 'name.first', false)
                                            ? _.get(errors, 'name.first', false)
                                            : null
                                    }
                                    fullWidth
                                    style={{ marginBottom: theme.spacing.unit }}
                                />

                                <TextField
                                    {...getFieldProps('name.last')}
                                    error={
                                        _.get(touched, 'name.last', false) && _.get(errors, 'name.last', false)
                                            ? true
                                            : null ||
                                              (profileNotCompleted && _.isEmpty(_.get(values, 'name.last', false)))
                                    }
                                    label={
                                        _.get(formik.values, 'locationType') === 'Commercial'
                                            ? loc('contactLastName', lang)
                                            : loc('lastName', lang)
                                    }
                                    // variant="outlined"
                                    helperText={
                                        _.get(touched, 'name.last', false) && _.get(errors, 'name.last', false)
                                            ? _.get(errors, 'name.last', false)
                                            : null
                                    }
                                    fullWidth
                                    style={{ marginBottom: theme.spacing.unit }}
                                />
                                {emailChangePending ? (
                                    <TextField
                                        disabled
                                        value={`${_.get(customer, 'verification.newEmail.email', '')} (${loc(
                                            'pendingApproval',
                                            lang
                                        )})`}
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? loc('contactEmail', lang)
                                                : loc('email', lang)
                                        }
                                        fullWidth
                                        style={{ marginBottom: theme.spacing.unit }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-cy="string-list-input-add-button"
                                                        color={colors.yellow[500]}
                                                        onClick={() => setEmailChangeDialogOpen(true)}
                                                    >
                                                        <Icon>help</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        {...getFieldProps('email')}
                                        error={
                                            _.get(touched, 'email', false) && _.get(errors, 'email', false)
                                                ? true
                                                : null ||
                                                  (profileNotCompleted && _.isEmpty(_.get(values, 'email', false)))
                                        }
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? loc('contactEmail', lang)
                                                : loc('email', lang)
                                        }
                                        // variant="outlined"
                                        helperText={
                                            _.get(touched, 'email', false) && _.get(errors, 'email', false)
                                                ? _.get(formik.errors, 'email', false)
                                                : null
                                        }
                                        fullWidth
                                        style={{ marginBottom: theme.spacing.unit }}
                                    />
                                )}
                                <TextField
                                    {...getFieldProps('phone')}
                                    type="tel"
                                    // variant="outlined"
                                    label={loc('phoneNumber', lang)}
                                    fullWidth
                                    helperText={
                                        _.get(touched, 'phone', false) && _.get(errors, 'phone', false)
                                            ? _.get(errors, 'phone', false)
                                            : null
                                    }
                                    error={
                                        _.get(touched, 'phone', false) && _.get(errors, 'phone', false)
                                            ? true
                                            : null || (profileNotCompleted && _.isEmpty(_.get(values, 'phone', false)))
                                    }
                                    InputProps={{
                                        inputComponent: TextMaskPhoneNumber
                                    }}
                                    style={{ marginBottom: theme.spacing.unit }}
                                />
                                {_.get(formik.values, 'locationType') === 'Commercial' &&
                                    _.get(registrationConfig, 'showBusinessPhone', true) && (
                                        <TextField
                                            {...getFieldProps('businessPhone')}
                                            error={
                                                _.get(touched, 'businessPhone', false) &&
                                                _.get(errors, 'businessPhone', false)
                                                    ? true
                                                    : null
                                            }
                                            label={loc('businessPhoneNumber', lang)}
                                            helperText={
                                                _.get(touched, 'businessPhone', false) &&
                                                _.get(errors, 'businessPhone', false)
                                                    ? _.get(errors, 'businessPhone', false)
                                                    : null
                                            }
                                            fullWidth
                                            InputProps={{
                                                inputComponent: TextMaskPhoneNumber
                                            }}
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}

                                {charityEnabled && (
                                    <FormControl
                                        fullWidth
                                        style={{ ...formControlStyle, marginBottom: 0 }}
                                        data-cy="profile-receiveTaxReceipts-checkbox"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...getFieldProps('receiveTaxReceipts')}
                                                    data-cy="receiveTaxReceipts"
                                                    checked={_.get(formik.values, 'receiveTaxReceipts', true)}
                                                    disabled={
                                                        (customer.disabled || customer.suspended) &&
                                                        !_user.isSystemAdmin(operator)
                                                    }
                                                />
                                            }
                                            label={loc('accountSettings1', lang)}
                                        />
                                    </FormControl>
                                )}
                                {_.get(twoFactorAuthentication, 'isEnabled', false) && (
                                    <FormControl
                                        fullWidth
                                        style={{ ...formControlStyle, marginBottom: theme.spacing.unit * 2 }}
                                        data-cy="profile-twoFactorAuthentication-checkbox"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...getFieldProps('twoFactorAuthentication.isEnabled')}
                                                    data-cy="two-factor-authentication"
                                                    checked={
                                                        _.get(formik.values, 'twoFactorAuthentication.isEnabled') ||
                                                        _.get(twoFactorAuthentication, 'isRequired', false)
                                                    }
                                                    disabled={
                                                        _.get(twoFactorAuthentication, 'isRequired', false) ||
                                                        ((customer.disabled || customer.suspended) &&
                                                            !_user.isSystemAdmin(operator))
                                                    }
                                                />
                                            }
                                            label={
                                                <>
                                                    {loc('accountSettings2', lang)}
                                                    <IconButton
                                                        onClick={toggleHelpDialog(loc('accountSettings2a', lang))}
                                                    >
                                                        <Icon style={{ fontSize: 24, color: 'grey' }}>help</Icon>
                                                    </IconButton>
                                                </>
                                            }
                                        />
                                    </FormControl>
                                )}

                                {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) &&
                                    _.get(twoFactorAuthentication, 'isEnabled', false) &&
                                    _.get(twoFactorAuthentication, 'isRequired', false) && (
                                        <FormControl
                                            fullWidth
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            data-cy="profile-bypassTwoFactorAuthentication-checkbox"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...getFieldProps('bypassTwoFactorAuthentication')}
                                                        data-cy="bypass-two-factor-authentication"
                                                        checked={formik.values.bypassTwoFactorAuthentication}
                                                    />
                                                }
                                                label={loc('labels18', lang)}
                                            />
                                        </FormControl>
                                    )}

                                {/* {!_.isEmpty(twoFactorAuthenticationWarning) && process.env.REACT_APP_ENV === 'TEST' && (
                                    <Typography variant="body2" style={{ color: colors.red[500] }}>
                                        {twoFactorAuthenticationWarning}
                                    </Typography>
                                )} */}

                                <Button color="primary" disabled={savingUser} onClick={handleCancel}>
                                    {loc('cancel', lang)}
                                </Button>

                                <Button
                                    data-cy="profile-save-button-info"
                                    variant="contained"
                                    color="primary"
                                    // disabled={
                                    //     !dirty ||
                                    //     !_.isEmpty(errors) ||
                                    //     savingUser ||
                                    //     customer.deleted ||
                                    //     ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator))
                                    // }
                                    disabled={!dirty || savingUser}
                                    style={{ marginLeft: theme.spacing.unit * 2 }}
                                    onClick={handleSubmit}
                                >
                                    {loc('save', lang)}{' '}
                                    <CircularProgress
                                        size={22}
                                        thickness={4.8}
                                        style={{
                                            display: savingUser ? '' : 'none',
                                            marginLeft: 4,
                                            color: 'white',
                                            verticalAlign: 'bottom'
                                        }}
                                    />
                                </Button>
                            </>
                        );
                    }}
                </Formik>

                <div style={{ clear: 'both' }} />
            </Paper>
            <Paper style={{ width: 'calc(100% - 32px)', margin: 16, padding: '16px 24px' }}>
                <Typography variant="h6">{loc('accountSettings32', lang)}</Typography>
                <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                <List
                    style={{
                        margin: 0
                    }}
                >
                    {!_.some(INVALID_REDEMPTION_EMAILS, string => customer.email.includes(string)) && (
                        <ListItem
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 0,
                                paddingRight: 0,
                                opacity: 1
                            }}
                        >
                            <ListItemText
                                primary={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            paddingRight: 'inherit',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                display: 'block'
                                            }}
                                        >
                                            {customer.email}
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            Primary Email
                                        </span>
                                    </div>
                                }
                                style={{
                                    whiteSpace: 'normal',
                                    position: 'relative'
                                }}
                            />
                        </ListItem>
                    )}
                    {_.get(customer, 'authorizedEmails', []).map((authorization, idx) => {
                        return (
                            <ListItem
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 0,
                                    opacity: !authorization.authorized ? 0.7 : 1
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                display: 'block',
                                                paddingRight: 'inherit'
                                            }}
                                        >
                                            {authorization.email}
                                        </span>
                                    }
                                    style={{
                                        whiteSpace: 'normal',
                                        paddingRight: theme.spacing.unit * 3,
                                        position: 'relative'
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => {
                                            setResendAuthorizationEmailDialogOpen(true);
                                            setAuthorizedEmail(authorization);
                                        }}
                                    >
                                        {authorization.authorized ? (
                                            <Icon style={{ color: colors.green[500] }}>check</Icon>
                                        ) : (
                                            <Icon style={{ color: colors.amber[500] }}>help</Icon>
                                        )}
                                    </IconButton>

                                    <IconButton
                                        onClick={() => {
                                            warnAction(() => {
                                                handleRemoveAuthorizedEmail(authorization.email);
                                            }, loc('confirmDeletion', lang));
                                        }}
                                    >
                                        <Icon>close</Icon>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                {_.get(customer, 'authorizedEmails', []).length > 0 && (
                    <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                )}
                <TextField
                    data-cy="string-list-input"
                    onChange={handleEmailToAuthorize}
                    label={loc('addAuthorizedEmail', lang)}
                    value={emailToAuthorize}
                    margin="normal"
                    fullWidth
                    error={!_.isNil(emailToAuthorizeError) && !_.isEmpty(emailToAuthorizeError)}
                    helperText={emailToAuthorizeError}
                    disabled={customer.suspended || customer.banned || customer.disabled || customer.deleted}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    data-cy="string-list-input-add-button"
                                    color="primary"
                                    onClick={handleAddAuthorizedEmail}
                                    disabled={
                                        _.isEmpty(emailToAuthorize) ||
                                        customer.suspended ||
                                        customer.banned ||
                                        customer.disabled ||
                                        customer.deleted
                                    }
                                >
                                    <Icon>add_circle</Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    InputLabelProps={{
                        style: { maxWidth: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                    }}
                />
            </Paper>
            <Dialog
                open={resendAuthorizationEmailDialogOpen}
                onClose={() => {
                    setResendAuthorizationEmailDialogOpen(false);
                    setAuthorizedEmail({});
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        {authorizedEmail.authorized
                            ? loc('emailAuthorization2', lang, { email: authorizedEmail.email })
                            : loc('emailAuthorization1', lang, { email: authorizedEmail.email })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setResendAuthorizationEmailDialogOpen(false);
                            setAuthorizedEmail({});
                        }}
                        color="primary"
                        data-cy="resend-email-authorization-cancel-button"
                    >
                        {authorizedEmail.authorized ? loc('close', lang) : loc('cancel', lang)}
                    </Button>

                    {!authorizedEmail.authorized && (
                        <Button
                            onClick={() => handleResendAuthorizationEmail(authorizedEmail.email)}
                            color="primary"
                            data-cy="resend-email-authorization-resend-button"
                        >
                            {loc('resendAuthorizationEmail', lang)}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Paper
                style={{
                    width: 'calc(100% - 32px)',
                    margin: 16,
                    padding: '16px 24px'
                }}
            >
                <Typography variant="h6">{loc('password', lang)}</Typography>
                <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                {!customer.forcePasswordChange && (
                    <FormControl fullWidth style={formControlStyle}>
                        <PasswordInput
                            data-cy="profile-current-password"
                            name="passwordCurrent"
                            label={loc('accountSettings3', lang)}
                            type="password"
                            value={passwordCurrent}
                            helperText={''}
                            disabled={
                                !_.isNil(customer.oAuth.provider) ||
                                ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator))
                            }
                            onChange={handlePasswordsChange}
                            error={false}
                        />
                    </FormControl>
                )}
                <FormControl fullWidth style={formControlStyle}>
                    <PasswordInput
                        showValidationRules
                        data-cy="profile-new-password"
                        name="password"
                        label={loc('accountSettings4', lang)}
                        type="password"
                        value={!profileNotCompleteDialogOpen ? password : ''}
                        helperText={passwordErr.description}
                        disabled={
                            !_.isNil(customer.oAuth.provider) ||
                            ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator))
                        }
                        onChange={handlePasswordsChange}
                        error={!profileNotCompleteDialogOpen && (passwordErr.fail && !_.isEmpty(password))}
                    />
                </FormControl>
                <FormControl fullWidth style={formControlStyle}>
                    <PasswordInput
                        data-cy="profile-new-password-confirmation"
                        name="passwordConfirmation"
                        label={loc('accountSettings5', lang)}
                        type="password"
                        value={!profileNotCompleteDialogOpen ? passwordConfirmation : ''}
                        helperText={''}
                        disabled={
                            !_.isNil(customer.oAuth.provider) ||
                            ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator))
                        }
                        onChange={handlePasswordsChange}
                        error={!profileNotCompleteDialogOpen && !passwordsMatch}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                onChange={() => setBiometricsOptIn(!biometricsOptIn)}
                                value={biometricsOptIn}
                                checked={biometricsOptIn}
                                disabled={biometricsHasCredentials || !biometricsAvailable}
                            />
                        }
                        label={
                            <>
                                {loc('biometrics1', lang)}
                                <IconButton
                                    onClick={toggleHelpDialog(
                                        loc(biometricsAvailable ? 'biometrics9' : 'biometrics8', lang)
                                    )}
                                >
                                    <Icon style={{ fontSize: 24, color: 'grey' }}>help</Icon>
                                </IconButton>
                            </>
                        }
                    />
                </FormControl>
                <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                    <Button color="primary" disabled={savingPassword} onClick={handleCancel}>
                        {loc('cancel', lang)}
                    </Button>
                    <Button
                        data-cy="profile-change-password"
                        variant="contained"
                        color="primary"
                        disabled={
                            !passwordsMatch ||
                            password.length === 0 ||
                            savingPassword ||
                            customer.deleted ||
                            passwordErr.fail ||
                            ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator)) ||
                            profileNotCompleteDialogOpen
                        }
                        style={{ marginLeft: theme.spacing.unit * 2 }}
                        onClick={() => handleChangePasswordSubmit()}
                    >
                        {loc('change', lang)}{' '}
                        <CircularProgress
                            size={22}
                            thickness={4.8}
                            style={{
                                display: !profileNotCompleteDialogOpen && savingPassword ? '' : 'none',
                                marginLeft: 4,
                                color: 'white',
                                verticalAlign: 'bottom'
                            }}
                        />
                    </Button>
                </div>
                <div style={{ clear: 'both' }} />
            </Paper>
            {isEXPRegion() && (
                <Paper style={{ width: 'calc(100% - 32px)', margin: 16, padding: '16px 24px' }}>
                    <Typography variant="h6">{loc('securityQuestion', lang)}</Typography>

                    <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                    {_.get(registrationConfig, 'showSecurityQuestions', true) &&
                        !_.isEmpty(_.get(registrationConfig, `securityQuestions.${lang}`, [])) && (
                            <FormControl
                                data-cy={`$-registration-security-question-input`}
                                fullWidth
                                // style=}
                                label={loc('currentSecurityQuestion', lang)}
                                error={false}
                            >
                                <InputLabel htmlFor="question">{loc('currentSecurityQuestion', lang)}</InputLabel>
                                <Select
                                    value={securityQuestion}
                                    onChange={event => setSecurityQuestion(event.target.value)}
                                    inputProps={{
                                        name: 'securityQuestion',
                                        id: 'security-question'
                                    }}
                                >
                                    {_.get(registrationConfig, `securityQuestions.${lang}`, []).map(
                                        (question, index) => {
                                            return (
                                                <MenuItem
                                                    data-cy={index + '-redemption-dialog-question'}
                                                    key={question}
                                                    value={question}
                                                    style={{ whiteSpace: 'normal' }}
                                                >
                                                    {question}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        )}

                    <FormControl fullWidth style={formControlStyle}>
                        <PasswordInput
                            data-cy="profile-current-securityQuestion"
                            name="currentSecurityQuestion"
                            label={'Answer'}
                            type="password"
                            value={securityAnswer}
                            helperText={''}
                            disabled={
                                !_.isNil(customer.oAuth.provider) ||
                                ((customer.disabled || customer.suspended) && !_user.isSystemAdmin(operator))
                            }
                            onChange={event => setSecurityAnswer(event.target.value)}
                            error={false}
                        />
                    </FormControl>
                    <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                        <Button color="primary" disabled={savingPassword} onClick={handleCancel}>
                            {loc('cancel', lang)}
                        </Button>
                        <Button
                            data-cy="profile-change-securityQuestion"
                            variant="contained"
                            color="primary"
                            disabled={isUnchanged || savingSecurityQuestion}
                            style={{ marginLeft: theme.spacing.unit * 2 }}
                            onClick={() => handleChangeSecurityQuestionSubmit()}
                        >
                            {loc('change', lang)}{' '}
                            <CircularProgress
                                size={22}
                                thickness={4.8}
                                style={{
                                    display: savingPassword ? '' : 'none',
                                    marginLeft: 4,
                                    color: 'white',
                                    verticalAlign: 'bottom'
                                }}
                            />
                        </Button>
                    </div>
                    <div style={{ clear: 'both' }} />
                </Paper>
            )}

            <Paper style={{ width: 'calc(100% - 32px)', margin: 16, padding: '16px 24px' }}>
                <DeviceInfoWidget
                    user={customer}
                    isAdminView={_user.isSystemAdmin(operator)}
                    biometricsAvailable={biometricsAvailable}
                    biometricsHasCredentials={biometricsHasCredentials}
                    onReloadUser={reloadCustomer}
                    onCancel={handleCancel}
                    onLogoutAll={onLogoutAll}
                    onBiometricsHasCredentialsChange={biometricsHasCredentials =>
                        setBiometricsHasCredentials(biometricsHasCredentials)
                    }
                />
            </Paper>

            <Paper style={{ width: 'calc(100% - 32px)', margin: theme.spacing.unit * 2, padding: '16px 24px' }}>
                <Typography variant="h6">
                    {loc('accountSettings7', lang)}
                    {getUserAccountStatus(customer) !== 'Active' &&
                        (_user.isSystemAdmin(operator) || getUserAccountStatus(customer) !== 'Under Review') &&
                        ` (${getUserAccountStatus(customer)})`}
                </Typography>
                <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                <FormControl fullWidth style={formControlStyle} data-cy="profile-emailNotificationsEnabled-checkbox">
                    <FormControlLabel
                        control={
                            <Checkbox
                                data-cy="user-email-notifications"
                                checked={emailNotificationsEnabled}
                                onChange={handleSettingsChange}
                                value="emailNotificationsEnabled"
                            />
                        }
                        label={loc('accountSettings8', lang)}
                    />
                </FormControl>
                {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                    <FormControl
                        fullWidth
                        style={formControlStyle}
                        data-cy="profile-forcedEmailNotificationsEnabled-checkbox"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-cy="user-forced-email-notifications"
                                    checked={forcedEmailNotificationsEnabled}
                                    onChange={handleSettingsChange}
                                    value="forcedEmailNotificationsEnabled"
                                />
                            }
                            label={
                                <>
                                    {loc('accountSettings8a', lang)}{' '}
                                    <Button onClick={toggleHelpDialog(loc('accountSettings8b', lang))}>
                                        <Icon style={{ fontSize: 24, color: 'grey' }}>info</Icon>
                                    </Button>
                                </>
                            }
                        />
                    </FormControl>
                )}
                {isSMSEnabled && (
                    <FormControl fullWidth style={formControlStyle} data-cy="profile-textNotificationsEnabled-checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={textNotificationsEnabled}
                                    onChange={handleSettingsChange}
                                    value="textNotificationsEnabled"
                                />
                            }
                            label={loc('accountSettings9', lang)}
                        />
                    </FormControl>
                )}
                <FormControl fullWidth style={formControlStyle} data-cy="profile-pushNotificationsEnabled-checkbox">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!customerHasApp}
                                checked={pushNotificationsEnabled}
                                onChange={handleSettingsChange}
                                value="pushNotificationsEnabled"
                            />
                        }
                        label={customerHasApp ? loc('accountSettings10', lang) : loc('accountSettings11', lang)}
                    />
                </FormControl>
                {_user.isCharityAdmin(customer) && (
                    <FormControl fullWidth style={formControlStyle} data-cy="profile-someonesDonating-checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={donationNotificationsEnabled}
                                    onChange={handleSettingsChange}
                                    value="donationNotificationsEnabled"
                                />
                            }
                            label={loc('accountSettings12', lang)}
                        />
                    </FormControl>
                )}
                <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                    <Button color="primary" disabled={savingSettings} onClick={handleCancel}>
                        {loc('cancel', lang)}
                    </Button>
                    <Button
                        data-cy="profile-save-button-settings"
                        disabled={savingSettings || customer.deleted}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: theme.spacing.unit * 2 }}
                        onClick={handleSettingsSubmit}
                    >
                        {loc('save', lang)}{' '}
                        <CircularProgress
                            size={22}
                            thickness={4.8}
                            style={{
                                display: savingSettings ? '' : 'none',
                                marginLeft: 4,
                                color: 'white',
                                verticalAlign: 'bottom'
                            }}
                        />
                    </Button>
                </div>
                <div style={{ clear: 'both' }} />
            </Paper>
            {!customer.deleted && (
                <Paper style={{ width: 'calc(100% - 32px)', margin: 16, padding: '16px 24px' }}>
                    <Typography variant="h6">
                        {_user.isSystemAdmin(operator) || _user.isInternalRole(operator)
                            ? 'Manage Account'
                            : isDisabledCustomer
                            ? loc('accountSettings13', lang)
                            : loc('accountSettings14', lang)}
                    </Typography>
                    <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                    {!customer.banned && (
                        <Typography color="textSecondary">
                            {isDisabledCustomer && loc('accountSettings15', lang)}
                            {!isDisabledCustomer && loc('accountSettings16a', lang)}
                            {!isDisabledCustomer && pickupsEnabled && loc('accountSettings16b', lang)}
                        </Typography>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: theme.spacing.unit * 2
                        }}
                    >
                        {!customer.banned && (
                            <Button
                                disabled={disablingAccount || reactivateDialogOpen}
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    isDisabledCustomer
                                        ? handleToggleReactivateDialog()
                                        : setDisableAccountDialogOpen(true)
                                }
                            >
                                {isDisabledCustomer ? loc('accountSettings13', lang) : loc('accountSettings14', lang)}
                                <CircularProgress
                                    size={22}
                                    thickness={4.8}
                                    style={{
                                        display: disablingAccount ? '' : 'none',
                                        marginLeft: 4,
                                        color: 'white',
                                        verticalAlign: 'bottom'
                                    }}
                                />
                            </Button>
                        )}
                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                            <Button
                                disabled={disablingAccount || reactivateDialogOpen}
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: theme.spacing.unit * 2 }}
                                onClick={() => setBanAccountDialogOpen(true)}
                            >
                                {customer.banned ? 'Unban' : 'Ban'} Account
                                <CircularProgress
                                    size={22}
                                    thickness={4.8}
                                    style={{
                                        display: banningAccount ? '' : 'none',
                                        marginLeft: 4,
                                        color: 'white',
                                        verticalAlign: 'bottom'
                                    }}
                                />
                            </Button>
                        )}
                        <Button
                            disabled={deletingAccount}
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: theme.spacing.unit * 2, backgroundColor: colors.red[500] }}
                            onClick={() => setDeleteAccountDialogOpen(true)}
                            data-cy="profile-delete-account-button"
                        >
                            {loc('accountSettings17', lang)}
                            <CircularProgress
                                size={22}
                                thickness={4.8}
                                style={{
                                    display: disablingAccount ? '' : 'none',
                                    marginLeft: 4,
                                    color: 'white',
                                    verticalAlign: 'bottom'
                                }}
                            />
                        </Button>
                    </div>
                    <div style={{ clear: 'both' }} />
                    {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) &&
                        !customer.deleted &&
                        !customer.banned &&
                        !customer.disabled && (
                            <>
                                <CustomFormTitle titleText="Account Sub-Status" iconName="manage_accounts" />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        margin: theme.spacing.unit / 2
                                    }}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    id="suspended"
                                                    name="suspended"
                                                    data-cy="profile-account-status-suspended-toggle"
                                                    color="primary"
                                                    onClick={handleAccountSubStatusToggle('suspended')}
                                                    onChange={() => {}}
                                                    value={_.get(customer, 'suspended', false)}
                                                    checked={_.get(customer, 'suspended', false)}
                                                />
                                            }
                                            margin="normal"
                                            label="Suspended"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    id="underReview"
                                                    name="underReview"
                                                    data-cy="profile-account-status-under-review-toggle"
                                                    color="primary"
                                                    onClick={handleAccountSubStatusToggle('underReview')}
                                                    onChange={() => {}}
                                                    value={_.get(customer, 'underReview', false)}
                                                    checked={_.get(customer, 'underReview', false)}
                                                />
                                            }
                                            margin="normal"
                                            label="Under Review"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    id="stale"
                                                    name="stale"
                                                    data-cy="profile-account-status-stale-toggle"
                                                    color="primary"
                                                    onClick={handleAccountSubStatusToggle('stale')}
                                                    onChange={() => {}}
                                                    value={_.get(customer, 'stale', false)}
                                                    checked={_.get(customer, 'stale', false)}
                                                />
                                            }
                                            margin="normal"
                                            label="Stale"
                                        />
                                    </FormGroup>
                                </div>
                            </>
                        )}
                </Paper>
            )}

            <Dialog open={guestDialogOpen} maxWidth="sm" fullWidth>
                <DialogTitle>{loc('accountSettings18', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('accountSettings19', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setGuestDialogOpen(false)} color="primary">
                        {loc('okay', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <ProfileNotCompleteDialog
                profileNotCompleteDialogOpen={profileNotCompleteDialogOpen}
                customer={customer}
                lang={lang}
                formControlStyle={formControlStyle}
                password={password}
                passwordErr={passwordErr}
                operator={operator}
                savingPassword={savingPassword}
                handlePasswordsChange={handlePasswordsChange}
                passwordConfirmation={passwordConfirmation}
                passwordsMatch={passwordsMatch}
                handleChangePasswordSubmit={handleChangePasswordSubmit}
                setprofileNotCompleteDialogOpen={setprofileNotCompleteDialogOpen}
            />

            <Dialog
                open={accountSubStatusDialogOpen}
                onClose={() => setAccountSubStatusDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Manage Account Sub-Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {accountSubStatusDialogState ? 'set ' : 'unset '}
                        {`${_.get(customer, 'name.first', 'N/A')} ${_.get(customer, 'name.last', '')}`}'s account as{' '}
                        {accountSubStatusDialogType === 'underReview' ? 'under review' : accountSubStatusDialogType}?
                    </DialogContentText>

                    <TextField
                        label={`Reason`}
                        error={!accountSubStatusDialogReason}
                        value={accountSubStatusDialogReason}
                        onChange={e => setAccountSubStatusDialogReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        data-cy="account-sub-status-reason"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setAccountSubStatusDialogOpen(false)}
                        color="primary"
                        data-cy="account-sub-status-cancel-button"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAccountSubStatusSubmit}
                        disabled={_.isEmpty(accountSubStatusDialogReason.trim()) || submittingStatus}
                        color="primary"
                        data-cy="account-sub-status-accept-button"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={banAccountDialogOpen} maxWidth="sm" fullWidth>
                <DialogTitle>{customer.banned ? 'Ban' : 'Unban'} Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {customer.banned ? 'unban ' : 'ban '}
                        {`${_.get(customer, 'name.first', 'N/A')} ${_.get(customer, 'name.last', '')}`}'s account?
                    </DialogContentText>

                    <TextField
                        label={`Reason`}
                        error={!banReason || _.isEmpty(banReason)}
                        value={banReason}
                        onChange={e => setBanReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        data-cy="ban-account-reason"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setBanAccountDialogOpen(false)}
                        color="primary"
                        data-cy="ban-account-cancel-button"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleBanAccount}
                        disabled={_.isEmpty(banReason.trim()) || banningAccount}
                        data-cy="ban-account-accept-button"
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={emailChangeDialogOpen}
                maxWidth="sm"
                fullWidth
                onClose={() => setEmailChangeDialogOpen(false)}
            >
                <DialogTitle>{loc('accountSettings18', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {loc('accountSettings34', lang, {
                            oldEmail: customer.email,
                            newEmail: _.get(customer, 'verification.newEmail.email', 'N/A')
                        })}{' '}
                        {getExpirationTimeText(moment(_.get(customer, 'verification.newEmail.tokenExpiration')), lang)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEmailChangeDialogOpen(false)} data-cy="email-change-close-button">
                        {loc('close', lang)}
                    </Button>
                    <Button onClick={handleRevertEmailChange} data-cy="email-change-revert-button" color="secondary">
                        {loc('accountSettings36', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <DisableAccountDialog
                open={disableAccountDialogOpen}
                disableReason={disableReason}
                pickupsLeft={!_.isEmpty(pickupsPendingToday)}
                handleClose={() => setDisableAccountDialogOpen(false)}
                handleDisableAccount={handleDisableAccount}
                handleDisableReasonChange={handleDisableReasonChange}
            />

            <DeleteAccountDialog
                open={deleteAccountDialogOpen}
                balance={balance}
                deleteReason={deleteReason}
                pickupsLeft={!_.isEmpty(pickupsPendingToday)}
                handleClose={() => setDeleteAccountDialogOpen(false)}
                handleDeleteAccount={handleDeleteAccount}
                handleDeleteReasonChange={handleDeleteReasonChange}
            />
            <TwoFactorAuthenticationDialog
                open={twoFactorAuthenticationDialogOpen}
                onSnackbar={onSnackbar}
                onSubmit={twoFactorAuthenticationSubmit}
                onLogOut={logOut}
                onCancel={() => setTwoFactorAuthenticationDialogOpen(false)}
                onClose={() => setTwoFactorAuthenticationDialogOpen(false)}
                twoFactorAuthenticationPhoneNumber={twoFactorAuthenticationPhoneNumber || ''}
                http={http}
                user={customer}
            />
            <HelpDialog
                open={informationDialogOpen}
                body={<Typography variant="body1">{informationDialogText}</Typography>}
                onClose={toggleHelpDialog(informationDialogText)}
            />
        </React.Fragment>
    );
}

export default withTheme()(Profile);

function getExpirationTimeText(expirationTime, lang) {
    const timeFromNowHours = moment(expirationTime).diff(moment(), 'hours', true);
    if (timeFromNowHours > 1) {
        return loc('accountSettings35c', lang, { hours: _.round(timeFromNowHours) });
    }
    const timeFromNowMinutes = moment(expirationTime).diff(moment(), 'minutes');
    if (timeFromNowMinutes < 1) {
        return loc('accountSettings35b', lang);
    } else {
        return loc('accountSettings35a', lang, {
            minutes: timeFromNowMinutes
        });
    }
}
