import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import * as colors from '@material-ui/core/colors';
import _ from 'lodash';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    ListItemAvatar,
    Typography
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';

import { mdiEye, mdiAlert, mdiClockCheck } from '@mdi/js';

import CRONLogDialog from 'components/Dialogs/CRONLog';
import useCRONLogDialog from 'components/Dialogs/CRONLog/useCRONLogDialog';

function CRONLogList({ theme, logs = [], selectedTimeZone, selectedTasks }) {
    const { open, log, handleViewLog, handleClose } = useCRONLogDialog();
    const [logsToDisplay, setLogsToDisplay] = useState([]);

    useEffect(() => {
        setLogsToDisplay(
            logs.filter(log =>
                selectedTimeZone.includes(_.get(log, 'timezones[0]', process.env.REACT_APP_REGION_TIMEZONE)) &&
                selectedTasks.includes(_.get(log, 'description', log.name))
            )
        );
    }, [logs, selectedTimeZone, selectedTasks]);

    return (
        <>
            {logsToDisplay.length > 0 ? (
                <List style={{ maxHeight: '100%', overflowY: 'scroll', paddingBottom: theme.spacing.unit * 2 }}>
                    {logsToDisplay.map((log, index) => {
                        const didSucceed = !_.isNil(log.completionTime);
                        const timeZone = _.get(log, 'timezones[0]', process.env.REACT_APP_REGION_TIMEZONE);
                        return (
                            <ListItem
                                key={`${log.name}-${index}`}
                                disableGutters
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <ListItemAvatar>
                                    <MDIcon
                                        path={didSucceed ? mdiClockCheck : mdiAlert}
                                        color={didSucceed ? colors.green[500] : colors.red[500]}
                                        size={1}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    secondary={
                                        timeZone +
                                        ' ' +
                                        moment(log.startTime)
                                            .tz(timeZone)
                                            .format('L - LTS')
                                    }
                                    primary={_.get(log, 'description', log.name)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => handleViewLog(log)}>
                                        <MDIcon path={mdiEye} size={1} color={theme.palette.text.primary} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                <Typography>No Logs Available</Typography>
            )}
            <CRONLogDialog open={open} log={log} onClose={handleClose} />
        </>
    );
}

export default withTheme()(CRONLogList);
