import React, { useState, useMemo, useContext, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import {
    DialogTitle,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    Typography,
    Button,
    TextField,
    LinearProgress,
    Tooltip,
    Icon,
    IconButton
} from '@material-ui/core';

import pickupHelper from 'helpers/pickupHelper';
import { withTheme } from '@material-ui/core/styles';

import { formatAsAddress, deviceHelper } from 'utils/misc';

import usePickupInfoDialog from './hooks/usePickupInfoDialog';
import { _commodity, _user, _pickup } from 'std';

import OperatorContext from 'utils/contexts/OperatorContext';
import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';
import PickupStatusIndicators from 'components/DriverWidgets/PickupStatusIndicators';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function PickupInfoDialog({
    theme,
    debug,
    open,
    driverConfig,
    pickup,
    trip,
    commoditiesAvailable,
    onClose,
    allowUndoPickup = false,
    reloadOperator,
    onSnackbar,
    hideCustomerIdentification,
    http
}) {
    const operator = useContext(OperatorContext);
    const { lang } = useContext(LocalizationContext);

    const history = useHistory();

    const [debugDialogOpen, setDebugDialogOpen] = useState(false);
    const [timeSheet, setTimeSheet] = useState(null);

    const {
        inProgress,
        bagtag,
        bagtagDialogOpen,
        undoDialogOpen,
        handleSubmitBagtag,
        handleSubmitUndo,
        handleUndoDialogOpen,
        handleBagtagDialogOpen,
        handleBagtagChange
    } = usePickupInfoDialog({
        pickup,
        reloadOperator,
        onClose,
        onSnackbar
    });

    const labelStyle = useMemo(
        () => ({
            marginLeft: 4,
            padding: '0px 6px',
            backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            color: theme.palette.text.primary,
            borderRadius: 4,
            border: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700]}`
        }),
        [theme]
    );

    const handleViewCustomer = () => {
        let URL = `/customers/${_.get(pickup, 'customer._id', '')}`;

        if (deviceHelper.isNativeApp()) {
            history.push(URL);
        } else {
            window.open(URL, '_blank');
        }
    };
    const getTodayTimeSheet = async function() {
        const res = await http.getJSON(`/timeSheets/${operator._id}/getTodayTimeSheet`);
        if (res.ok) {
            setTimeSheet(_.get(res, 'data.timeSheet', null));
        }
    };
    useEffect(() => {
        getTodayTimeSheet();
    }, []);

    if (_.isNil(pickup)) {
        return null;
    }

    const handleDebugDialogOpen = state => {
        setDebugDialogOpen(state);
    };

    let stopBaseDuration = _.get(trip, 'stopBaseDuration');
    let stopPerBagDuration = _.get(trip, 'driverEfficiency');

    if (_.isNil(stopBaseDuration)) {
        stopBaseDuration = _.get(pickup, 'receiver.stopBaseDuration', _.get(driverConfig, 'stopBaseDuration', 240));
    }

    if (_.isNil(stopPerBagDuration)) {
        stopPerBagDuration = _.get(pickup, 'receiver.driverEfficiency', _.get(driverConfig, 'stopPerBagDuration', 30));
    }

    stopBaseDuration = _.round(stopBaseDuration / 60, 2);
    stopPerBagDuration = _.round(stopPerBagDuration / 60, 2);

    return (
        <>
            <Dialog open={open && !bagtagDialogOpen && !undoDialogOpen} fullWidth onClose={onClose}>
                <DialogTitle>{loc('pickupInfo', lang)}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item sm={6} xs={12} style={{ marginBottom: theme.spacing.unit }}>
                            {pickupHelper.isOutOfSystemStop(pickup) && (
                                <>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>{loc('type', lang)}:</span>{' '}
                                        <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                                            out-of-system stop
                                        </span>
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>{loc('directions', lang)}:</span>{' '}
                                        {pickup.comment || 'N/A'}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>{loc('driverNote', lang)}:</span>{' '}
                                        {pickup.driverNote || 'N/A'}
                                    </Typography>
                                </>
                            )}
                            {!pickupHelper.isOutOfSystemStop(pickup) && pickup.complete && (
                                <Typography color="textSecondary">
                                    <span style={{ color: theme.palette.text.primary }}>{loc('bagtag', lang)}:</span>{' '}
                                    <span style={labelStyle}>{pickup.bagtag || 'N/A'}</span>
                                </Typography>
                            )}
                            {(pickup.aborted || pickup.skipped) && (
                                <>
                                    <Typography>
                                        <span>{loc('status', lang)}:</span>{' '}
                                        <span style={{ color: theme.palette.error.main, fontWeight: 500 }}>
                                            {pickup.aborted ? loc('aborted', lang) : loc('skipped', lang)}
                                        </span>
                                    </Typography>
                                    {pickup.aborted && (
                                        <Typography color="textSecondary">
                                            <span style={{ color: theme.palette.text.primary }}>
                                                {loc('reason', lang)}:
                                            </span>{' '}
                                            {pickup.reason}
                                        </Typography>
                                    )}

                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>
                                            {loc('comment', lang)}:
                                        </span>{' '}
                                        {pickup.explanation}
                                    </Typography>
                                </>
                            )}
                            <Typography color="textSecondary">
                                <span style={{ color: theme.palette.text.primary }}>{loc('distance', lang)}:</span>{' '}
                                {pickupHelper.getReceiverDistance(pickup)} m
                            </Typography>
                            {!hideCustomerIdentification && (
                                <Typography color="textSecondary">
                                    <span style={{ color: theme.palette.text.primary }}>{loc('address', lang)}:</span>{' '}
                                    {formatAsAddress(pickup.location)}
                                </Typography>
                            )}
                            {!hideCustomerIdentification && !_.isNil(pickup.customer) && (
                                <Typography color="textSecondary">
                                    <span style={{ color: theme.palette.text.primary }}>{loc('phone', lang)}:</span>{' '}
                                    {_.get(pickup, 'customer.phone', 'N/A')}
                                </Typography>
                            )}
                            {!_pickup.isOutOfSystemStop(pickup) && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <DetailedPayloadIndicators
                                        indicatorSize={0.75}
                                        labelStyle={{
                                            fontSize: '0.75rem'
                                        }}
                                        pickups={[pickup]}
                                        style={{ marginTop: theme.spacing.unit }}
                                        commodities={commoditiesAvailable}
                                        showUnits
                                    />
                                </div>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <PickupStatusIndicators
                                    pickup={pickup}
                                    style={{ marginTop: theme.spacing.unit }}
                                    indicatorSize={0.75}
                                    labelStyle={{
                                        fontSize: '0.75rem'
                                    }}
                                    numberOfBins={pickup.numberOfBins}
                                />
                            </div>
                            {pickup.tripStats && debug && (
                                <div style={{ marginTop: theme.spacing.unit * 4 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography>Debug (System Admin)</Typography>
                                        <IconButton onClick={() => handleDebugDialogOpen(true)}>
                                            <Icon fontSize="small">help</Icon>
                                        </IconButton>
                                    </div>
                                    <br />
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>Est. Distance:</span>{' '}
                                        {pickup.tripStats.estDistanceFromLastLocation} (km)
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>Est. Duration:</span>{' '}
                                        {pickup.tripStats.estTimeFromLastLocation} (min)
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>Breakdown:</span>{' '}
                                        <Tooltip title="Base Stop Duration (min)">
                                            <span>{stopBaseDuration}</span>
                                        </Tooltip>
                                        {' + '}
                                        <Tooltip title="Bags">
                                            <span>{_pickup.numberOfPayloadBags(pickup, commoditiesAvailable)}</span>
                                        </Tooltip>
                                        {' * '}
                                        <Tooltip title="Time Per Bag (min)">
                                            <span>{stopPerBagDuration}</span>
                                        </Tooltip>
                                        {' + '}
                                        <Tooltip title="Driving Time (min)">
                                            <span>{pickup.tripStats.estDrivingTimeFromLastLocation}</span>
                                        </Tooltip>
                                    </Typography>
                                    <Typography color="textSecondary">
                                        <span style={{ color: theme.palette.text.primary }}>Actual Duration:</span>{' '}
                                        {pickup.tripStats.actualTimeFromLastLocation} (min)
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <ImageViewer
                                displayImages={pickupHelper.getPickupImageURL(pickup)}
                                disableDelete={true}
                                outerStyles={{ paddingTop: theme.spacing.unit * 2 }}
                                scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {allowUndoPickup && (
                        <Button
                            disabled={
                                _.isNil(pickup.customer) ||
                                (_.get(operator, 'collector.configuration.enableClockin', true) &&
                                    _user.isDriver(operator) &&
                                    (timeSheet === null || _.get(timeSheet, 'totalWorkingHours', null) !== null))
                            }
                            data-cy="driver-history-undo-btn"
                            color="secondary"
                            onClick={() => handleUndoDialogOpen(true)}
                        >
                            {_.capitalize(loc('undo', lang))}
                        </Button>
                    )}
                    {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                        <Button color="default" onClick={handleViewCustomer}>
                            View Customer
                        </Button>
                    )}

                    <Button color="default" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={bagtagDialogOpen}
                fullWidth
                onClose={inProgress ? () => null : () => handleBagtagDialogOpen(false)}
            >
                <DialogTitle>Edit bagtag</DialogTitle>
                <DialogContent>
                    <TextField
                        data-cy="driver-history-enter-bagtag"
                        fullWidth
                        variant="outlined"
                        label="Bagtag"
                        value={bagtag}
                        style={{ marginTop: 4 }}
                        onChange={e => handleBagtagChange(e.target.value.toUpperCase())}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" disabled={inProgress} onClick={() => handleBagtagDialogOpen(false)}>
                        {_.capitalize(loc('cancel', lang))}
                    </Button>
                    <Button
                        data-cy="driver-history-update-btn"
                        color="primary"
                        disabled={inProgress || _.isEmpty(bagtag)}
                        onClick={handleSubmitBagtag}
                    >
                        {_.capitalize('update', lang)}
                    </Button>
                </DialogActions>
                {inProgress && <LinearProgress />}
            </Dialog>

            <Dialog open={debugDialogOpen} fullWidth onClose={() => handleDebugDialogOpen(false)}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Breakdown may not reflect the estimated duration accurately if a driver's efficiency data
                        has been changed, and the trip does not have it's own efficiency data.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={() => handleDebugDialogOpen(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={undoDialogOpen}
                fullWidth
                onClose={inProgress ? () => null : () => handleUndoDialogOpen(false)}
            >
                <DialogTitle>{loc('undoPickup', lang)}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('reAddPickupRouteMessage', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="default" disabled={inProgress} onClick={() => handleUndoDialogOpen(false)}>
                        {loc('cancel', lang)}
                    </Button>
                    <Button
                        data-cy="driver-history-undo-confirm"
                        color="secondary"
                        disabled={inProgress}
                        onClick={handleSubmitUndo}
                    >
                        {loc('yes', lang)}
                    </Button>
                </DialogActions>
                {inProgress && <LinearProgress />}
            </Dialog>
        </>
    );
}

export default withTheme()(PickupInfoDialog);
