import { useMemo } from 'react';

import _ from 'lodash';
import { _user } from 'std';

import { addColorsToTrips } from '../functions';

function useDataToRender({
    tripsOnDate = [],
    tripIdsUnchecked,
    pickups,
    selectedCollector,
    showAllPickups,
    collectors,
    commoditiesAvailable,
    filters
}) {
    addColorsToTrips(tripsOnDate); // mutates the array

    // const transporterIds = useMemo(() => {
    //     const ids = [];

    //     tripsOnDate.forEach(({ _id, transporter }) => {
    //         if (tripIdsUnchecked.includes(_id)) {
    //             ids.push(transporter._id);
    //         }
    //     });
    //     return ids;
    // }, [tripIdsUnchecked, tripsOnDate]);

    const tripIds = useMemo(() => {
        const ids = [];

        tripsOnDate.forEach(({ _id }) => {
            if (tripIdsUnchecked.includes(_id)) {
                ids.push(_id);
            }
        });
        return ids;
    }, [tripIdsUnchecked, tripsOnDate]);

    const tripsRendered = useMemo(() => _.filter(tripsOnDate, trip => !tripIdsUnchecked.includes(trip._id)), [
        tripIdsUnchecked,
        tripsOnDate
    ]);

    const routesRendered = useMemo(
        () =>
            tripsRendered.map(trip => {
                if (
                    !showAllPickups &&
                    _.get(trip, 'transporterCollector._id', _.get(trip, 'transporter.collector._id', '').toString()) !==
                        selectedCollector
                ) {
                    return null;
                }

                return trip.route;
            }),
        [tripsRendered, selectedCollector, showAllPickups]
    );

    const pickupsRendered = useMemo(() => {
        const uniqueMasterClusterIds = _.uniq(
            _.filter(pickups, p => !_.isNil(p.master_zone_vid)).map(pickup => pickup.master_zone_vid)
        ).sort();
        return _(pickups)
            .filter(p => {
                if (
                    tripIds.includes(_.get(p, 'trip._id', _.isNil(p.trip) ? '' : p.trip).toString()) ||
                    !filters.pickupTypes.includes(p.pickupType || 'Stop') || //out of system stops have no pickupType
                    !filters.modifierTypes.every(modifier => {
                        let pass = false;

                        if (modifier === 'confirmed') {
                            pass = p[modifier];
                        } else if (modifier === 'unconfirmed') {
                            pass = !p.confirmed;
                        } else if (modifier === 'highPriority' && p.postponedBySystem > 0) {
                            pass = true;
                        } else {
                            pass = p[modifier];
                        }

                        return pass;
                    }) ||
                    !Object.keys(p.payload).some(key => {
                        let pass = p.payload[key] > 0;
                        if (!filters.commodityTypes.includes(key) && p.payload[key] > 0) pass = false;
                        if (_.isNil(p.customer)) {
                            pass = true; //out of system stops always have empty payload
                        }
                        return pass;
                    })
                )
                    return false;

                // Get code for collector customer
                if (_user.isCollectorCustomer(p.customer)) {
                    const foundCollector = _.find(collectors, c => c._id === p.customer.collector);
                    if (foundCollector) {
                        p.customer.collector = foundCollector.code;
                    }
                }

                let trip = _.find(tripsOnDate, t => t._id === _.get(p, 'trip._id', p.trip));
                if (!_.isNil(trip) && !_.isEmpty(trip)) {
                    const colorKeys = ['pink', 'deepOrange', 'amber', 'lightGreen', 'teal', 'blue', 'indigo', 'purple'];
                    let uniqueClusterIds = _.uniq(trip.pickups.map(pickup => pickup.zone_vid));
                    let tripPickup = _.find(trip.pickups, p2 => p2._id.toString() === p._id.toString());
                    if (!_.isNil(tripPickup)) {
                        p.zone_vid = _.get(tripPickup, 'zone_vid', null);
                        p.clusterColor =
                            p.zone_vid === null ? 'grey' : colorKeys[_.indexOf(uniqueClusterIds, p.zone_vid) % 8];
                        p.masterClusterColor =
                            p.master_zone_vid === null
                                ? 'grey'
                                : colorKeys[_.indexOf(uniqueMasterClusterIds, p.master_zone_vid) % 8];
                    }
                }
                if (!showAllPickups) {
                    return (
                        !p.complete &&
                        (_.isNil(trip)
                            ? _.get(p, 'collector._id')
                            : _.get(
                                  trip,
                                  'transporterCollector._id',
                                  _.get(trip, 'transporter.collector._id', '')
                              ).toString()) === selectedCollector
                    );
                }
                return !p.complete;
            })
            .value();
    }, [pickups, tripIds, tripsRendered, showAllPickups, selectedCollector, filters]);

    const startLocationsRendered = useMemo(() => {
        // TODO: Change this from a nested loop
        const locations = [];
        tripsRendered.forEach(trip => {
            if (
                showAllPickups ||
                (!showAllPickups &&
                    _.get(trip, 'transporterCollector._id', _.get(trip, 'transporter.collector._id', '')).toString() ===
                        selectedCollector)
            ) {
                trip.startLocation.transporter = trip.transporter._id;
                locations.push(trip.startLocation);
                // trip.currentStartLocation.forEach(currentStartLocation => {
                //     currentStartLocation.transporter = trip.transporter._id;
                //     locations.push(currentStartLocation);
                // });
            }
        });

        return locations;
    }, [selectedCollector, showAllPickups, tripsRendered]);
    const destinationsRendered = collectors.map(c => ({
        code: c.code,
        isProcessor: c.configuration.enableCounting,
        isTransporter: c.configuration.enablePickups,
        ...c.location
    }));

    return { tripsRendered, routesRendered, pickupsRendered, startLocationsRendered, destinationsRendered };
}

export default useDataToRender;
