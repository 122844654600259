import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { DAYS_OF_THE_WEEK, DRIVER_PAYMENT_MODELS } from 'constants.js';
import useQueryString from 'utils/hooks/useQueryString';
import useGetJSON from 'utils/hooks/useGetJSON';
import HttpContext from 'utils/contexts/HttpContext';
import { formatAsCurrency, convertSecondsToHHmm, convertSecondsToHours, downloadObjectAsCSV } from 'utils/misc';

import {
    Paper,
    Icon,
    IconButton,
    TableCell,
    TableRow,
    Button,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Grid,
    colors,
    Typography,
    Tooltip
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import MDIcon from '@mdi/react';
import { mdiAccountOff } from '@mdi/js';
import * as allIcons from '@mdi/js';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import IconCard from 'components/IconCard/IconCard';
import CostCalculation from './CostCalculation';
import BulkUpdate from './BulkUpdate';

import { _commodity, _time, _user } from 'std';

import { DEFAULT_DRIVER_WAGE } from 'constants.js';

const headers = [
    { name: 'Trip Details' },
    { name: 'Incomplete^' },
    { name: 'Date^' },
    { name: 'Day^' },
    { name: 'Start Time Est^' },
    { name: 'Start Time Act^' },
    { name: 'Start Time Diff(hr)^' },
    { name: 'Transporter^' },
    { name: 'Processor^' },
    { name: 'Driver^' },
    { name: 'Truck #^' },
    { name: 'License Plate^' },
    { name: 'Distance Est(km)^' },
    { name: 'Distance Act(km)^' },
    { name: 'Distance Diff^' },
    { name: 'Distance Depot(km)^' },
    { name: 'Vehicle Eff(km/L)^' },
    { name: 'Gas Price($)^' },
    { name: 'Gas Cost($)^' },
    { name: 'Time Driving Est(hr)^' },
    { name: 'Time Driving Act(hr)^' },
    { name: 'Time Driving Diff(hr)^' },
    { name: 'Time Driving Depot(hr)^' },
    { name: 'Time Start Trip(hr)^' },
    { name: 'Time Offload(hr)^' },
    { name: 'Time Total(hr)^' },
    { name: 'Pickups^' },
    { name: 'Processed^' },
    { name: 'Aborted^' },
    { name: 'Rescheduled^' },
    { name: 'OOS Stops^' },
    { name: 'OOS Pay^' },
    { name: 'Bags Split^' },
    { name: 'Units Split^' },
    { name: 'Containers / Hour^' },
    { name: 'Synthetic Containers^' },
    { name: 'Synthetic Containers / Hour^' },
    { name: 'Cost / Container($)^' },
    { name: 'Cost / Synthetic Containers($)^' },
    { name: 'Time / Pickup(min)^' },
    { name: 'Time / Bag(min)^' },
    { name: 'Pay Contractor^' },
    { name: 'Payment Type^' },
    { name: 'Pay Rate($)^' },
    { name: 'Pay Breakdown($)^' },
    { name: 'Pay Total($)^' },
    { name: 'CSF Breakdown($)^' },
    { name: 'CSF Total($)^' },
    { name: 'HFs($)^' },
    { name: 'Volume Fee($)^' },
    { name: 'Depot Cost Breakdown($)^' },
    { name: 'Depot Cost($)^' },
    { name: 'Depot CSF-Cost($)^' },
    { name: 'Depot Profit($)^' }
];

const INIT_TOTALS = {
    startTimeDifference: 0,
    measuredDuration: 0,
    durationFromDepot: 0,
    durationToDepot: 0,
    differenceInTime: 0,
    startTripTime: 0,
    offloadTime: 0,
    distance: 0,
    distanceFromDepot: 0,
    distanceToDepot: 0,
    countedPickups: 0,
    completePickups: 0,
    incompletePickups: 0,
    rescheduledPickups: 0,
    outOfSystemStops: 0,
    outOfSystemPay: 0,
    containers: 0,
    containersProjected: 0,
    bags: 0,
    handlingFees: 0,
    csf: 0,
    cost: 0,
    totalPay: 0,
    duration: 0,
    gasCost: 0,
    csfCostDifference: 0,
    depotProfit: 0,
    bagsGroupedBySku: { valueToSum: 'total', accum: [] },
    costBreakdown: { valueToSum: 'total', accum: [] },
    countsBySkuType: { valueToSum: 'total', accum: [] },
    csfBySkuType: { valueToSum: 'csf', accum: [] },
    payBreakdown: { valueToSum: 'pay', accum: [] },
    chargeVolumeFee: true,
    volumeFee: 0
};

const headerPresets = {
    driverPayment: [
        'Incomplete^',
        'Date^',
        'Transporter^',
        'Processor^',
        'Driver^',
        'Truck #^',
        'License Plate',
        'Distance Est(km)^',
        'Vehicle Eff(km/L)^',
        'Gas Price($)^',
        'Gas Cost($)^',
        'Time Total(hr)^',
        'OOS Pay^',
        'Units Split^',
        'Pay Contractor^',
        'Payment Type^',
        'Pay Rate($)^',
        'Pay Breakdown($)^',
        'Pay Total($)^'
    ],
    driverEfficiency: [
        'Incomplete^',
        'Date^',
        'Start Time Est^',
        'Start Time Act^',
        'Start Time Diff(hr)^',
        'Transporter^',
        'Processor^',
        'Driver^',
        'Truck #^',
        'License Plate',
        'Time Total(hr)^',
        'Pickups^',
        'Processed^',
        'Aborted^',
        'Rescheduled^',
        'OOS Stops^',
        'Synthetic Containers^',
        'Synthetic Containers / Hour^',
        'Time / Pickup(min)^',
        'Time / Bag(min)^'
    ],
    depotCost: [
        'Incomplete^',
        'Date^',
        'Transporter^',
        'Processor^',
        'Driver^',
        'Truck #^',
        'License Plate',
        'Gas Cost($)^',
        'Units Split^',
        'Synthetic Containers ^',
        'Cost / Synthetic Containers($)^',
        'Pay Total($)^',
        'CSF Breakdown($)^',
        'CSF Total($)^',
        'HFs($)^',
        'Depot Cost Breakdown($)^',
        'Depot Cost($)^',
        'Depot CSF-Cost($)^',
        'Depot Profit($)^'
    ]
};

const DriverHistory = ({ theme, operator, collectors, collector }) => {
    const http = useContext(HttpContext);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/trips/getAdminTrips',
        key: 'trips',
        headers: headers.map(h => h.name),
        initialRowsPerPage: 'All'
    });

    const { loading: commoditiesLoading, error: commoditiesLoadingErr, data: commodities } = useGetJSON(
        `/commodities/getAllCommodities`,
        'collectionData'
    );

    const [allDrivers, setAllDrivers] = useState([]);
    const [fixedCosts, setAllFixedCosts] = useState([]);
    const [costCalcDialog, setCostCalcDialog] = useState({});
    const [bulkUpdateDialog, setBulkUpdateDialog] = useState(false);
    const [selectedTrips, setSelectedTrips] = useState([]);
    const [totals, setTotals] = useState(INIT_TOTALS);
    const [days, setDays] = useQueryString('days', [...Array(7).keys()], true);
    const [selectedCollectors, setSelectedCollectors] = useQueryString(
        'collectors',
        _user.isCollectorAdmin(operator)
            ? [collector._id]
            : collectors.filter(c => _.get(c, 'configuration.enablePickups', true)).map(({ _id }) => _id),
        true
    );
    const [selectedProcessors, setSelectedProcessors] = useQueryString(
        'processors',
        _user.isCollectorAdmin(operator)
            ? [collector._id]
            : collectors.filter(c => _.get(c, 'configuration.enableCounting', true)).map(({ _id }) => _id),
        true
    );
    const [selectedDrivers, setSelectedDrivers] = useQueryString('drivers', [], true);

    const handleDownload = () => {
        downloadCSV(filteredData, totals);
    };

    const handleCostCalcDialog = info => () => {
        setCostCalcDialog(info);
    };

    const handleBulkUpdateDialog = open => () => {
        setBulkUpdateDialog(open);
    };

    const handleCollectorChange = collectorsToUpdate => {
        setSelectedCollectors(collectorsToUpdate);
        let newVals = [];

        allDrivers.forEach(({ _id, collector }) => {
            if (collectorsToUpdate.includes(collector._id)) newVals.push(_id);
        });

        setSelectedDrivers(newVals);
    };

    const handleProcessorChange = processorsToUpdate => {
        setSelectedProcessors(processorsToUpdate);
    };

    useEffect(() => {
        if (!_.isNil(collector) && _user.isCollectorAdmin(operator)) {
            handleCollectorChange([collector._id]);
        }
    }, [collector]);

    const handleDropdownButtons = (name, all) => e => {
        e.stopPropagation();
        let newVals = [];

        if (name === 'collectors') {
            if (all)
                newVals = collectors.filter(c => _.get(c, 'configuration.enablePickups', true)).map(({ _id }) => _id);
            handleCollectorChange(newVals);
        } else if (name === 'processors') {
            if (all)
                newVals = collectors.filter(c => _.get(c, 'configuration.enableCounting', true)).map(({ _id }) => _id);
            handleProcessorChange(newVals);
        } else if (name === 'drivers') {
            if (all) {
                allDrivers.forEach(({ _id, collector }) => {
                    if (selectedCollectors.includes(collector._id)) newVals.push(_id);
                });
            }

            setSelectedDrivers(newVals);
        } else if (name === 'days') {
            if (all) newVals = [...Array(7).keys()];
            setDays(newVals);
        }
    };

    const handleSelectTrip = _id => () => {
        let newSelected = [];
        if (_id === 'all' && selectedTrips.length !== filteredData.length) {
            newSelected = filteredData.map(({ _id }) => _id);
        } else if (_id !== 'all') {
            const selectedIndex = selectedTrips.indexOf(_id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedTrips, _id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedTrips.slice(1));
            } else if (selectedIndex === selectedTrips.length - 1) {
                newSelected = newSelected.concat(selectedTrips.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedTrips.slice(0, selectedIndex),
                    selectedTrips.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedTrips(newSelected);
    };
    const {
        totalWageDuration,
        totalWageDepotDuration,
        totalWageCost,
        totalCommissionCostsBySkuType,
        distanceGasPaidOut,
        distanceGasPaidOutCollector,
        totalGasPaidOut,
        totalCost
    } = useMemo(() => {
        const depotDuration = totals.durationFromDepot + totals.durationToDepot;
        const depotDistance = totals.distanceFromDepot + totals.distanceToDepot;
        //let depotCost = 0;
        let totalWageDuration = 0;
        let totalWageDepotDuration = 0;
        let totalWageCost = 0;
        let totalCommissionCostsBySkuType = [];
        let distanceGasPaidOut = 0;
        let distanceGasPaidOutCollector = 0;
        let totalGasPaidOut = 0;
        let containers = totals.containers.toLocaleString();
        filteredData.forEach(
            ({
                _id,
                paymentModel,
                gasPrice = 0,
                gasCost,
                driverWage,
                driverCommissions = [],
                kmPerLiter,
                durationFromDepot = 0,
                durationToDepot = 0,
                distanceFromDepot = 0,
                distanceToDepot = 0,
                measuredDuration,
                distance = 0,
                duration = 0,
                countsBySkuType = [],
                containersPerHour,
                startTripTime,
                offloadTime,
                payOutGas,
                payBreakdown = {}
            }) => {
                const wage = !_.isNil(driverWage) ? driverWage : DEFAULT_DRIVER_WAGE;

                if (paymentModel === 'Wage') {
                    const startTripTimeSec = !_.isNil(startTripTime) ? startTripTime * 60 : 0;
                    const offloadTimeSec = !_.isNil(offloadTime) ? offloadTime * 60 : 0;
                    totalWageDepotDuration += durationFromDepot + durationToDepot + offloadTimeSec;
                    let tripDuration =
                        startTripTimeSec + (!_.isNil(measuredDuration) ? measuredDuration : duration) + offloadTimeSec;
                    if (_.isNaN(tripDuration)) {
                        tripDuration = 0;
                    }
                    totalWageDuration += tripDuration;

                    const hours = tripDuration / 3600;
                    totalWageCost += wage * hours;
                } else if (paymentModel === 'Commission') {
                    payBreakdown.forEach(payBreakdownItem => {
                        let commissionCostForSkuType = _.find(totalCommissionCostsBySkuType, {
                            skuType: payBreakdownItem.skuType
                        });
                        let countsForSkuType = _.find(countsBySkuType, { skuType: payBreakdownItem.skuType });
                        if (_.isNil(commissionCostForSkuType)) {
                            commissionCostForSkuType = {
                                commissionCost: 0,
                                count: 0,
                                skuType: payBreakdownItem.skuType
                            };
                            totalCommissionCostsBySkuType.push(commissionCostForSkuType);
                        }
                        commissionCostForSkuType.count += _.get(countsForSkuType, 'total', 0);
                        commissionCostForSkuType.commissionCost += _.get(
                            _.find(payBreakdown, {
                                skuType: payBreakdownItem.skuType
                            }),
                            'pay',
                            0
                        );
                    });
                }

                if (payOutGas) {
                    distanceGasPaidOut += distance;
                    distanceGasPaidOutCollector += distanceFromDepot + distanceToDepot;
                    totalGasPaidOut += gasCost;
                }
            }
        );

        return {
            totalWageDuration,
            totalWageDepotDuration,
            totalWageCost,
            totalCommissionCostsBySkuType,
            distanceGasPaidOut,
            distanceGasPaidOutCollector,
            totalGasPaidOut,
            totalCost: totalWageCost + _.sumBy(totalCommissionCostsBySkuType, 'commissionCost') + totalGasPaidOut
        };
    }, [totals]);
    const costStats = useMemo(() => {
        return [
            {
                name: '',
                value: ['Total', 'Depot Part', 'Cost'],
                title: '*Depot Values from depot to first pickup, and last pickup to depot'
            },
            {
                name: 'Wage hr',
                value: [
                    convertSecondsToHours(totalWageDuration, false),
                    convertSecondsToHours(totalWageDepotDuration, false),
                    formatAsCurrency(totalWageCost)
                ],
                title: '*Depot Values from depot to first pickup, and last pickup to depot'
            },
            ..._(totalCommissionCostsBySkuType)
                .filter(commissionCostForSkuType => commissionCostForSkuType.commissionCost !== 0)
                .sortBy('skuType')
                .map(commissionCostForSkuType => ({
                    name: `${_.startCase(commissionCostForSkuType.skuType)} ${getUnitForSkuType(
                        commissionCostForSkuType.skuType
                    )}`,
                    value: [
                        `${commissionCostForSkuType.count.toLocaleString()}`,
                        '',
                        formatAsCurrency(commissionCostForSkuType.commissionCost)
                    ],
                    title: '*Depot Values from depot to first pickup, and last pickup to depot'
                }))
                .value(),
            {
                name: 'Gas km',
                value: [
                    `${(distanceGasPaidOut / 1000).toLocaleString()}`,
                    `${(distanceGasPaidOutCollector / 1000).toLocaleString()}`,
                    formatAsCurrency(totalGasPaidOut)
                ],
                title: '*Depot Values from depot to first pickup, and last pickup to depot'
            },
            {
                name: 'Total',
                value: ['', '', formatAsCurrency(totalCost)],
                title: ''
            }
        ];
    }, [totals]);

    const containerStats = useMemo(() => {
        return [
            {
                name: 'Containers per hour',
                value: `${(totals.containers / (totals.measuredDuration / 3600)).toLocaleString()}`,
                title: 'Number of containers picked up per hour of driving'
            },
            {
                name: 'Trips',
                value: filteredData.length
            },
            {
                name: 'Containers Collected',
                value: totals.containers.toLocaleString()
            },
            {
                name: 'Handling Fees',
                value: formatAsCurrency(totals.handlingFees)
            },
            {
                name: 'CSF',
                value: formatAsCurrency(totals.csf)
            }
        ];
    }, [totals]);

    const revenueStats = useMemo(() => {
        const totalFixedCosts = fixedCosts.reduce((sum, val) => sum + val.amount, 0);

        return [
            /*{
                name: 'Cost Per Container ($)',
                value: (totals.cost / totals.containers / 100).toFixed(4)
            },*/
            {
                name: 'Fixed Cost / Container',
                value: (totalFixedCosts / totals.containersProjected / 100).toLocaleString('en-CA', {
                    style: 'currency',
                    currency: 'CAD',
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                })
            },
            {
                name: 'Cost / Container',
                value: ((totalCost + totalFixedCosts) / totals.containersProjected / 100).toLocaleString('en-CA', {
                    style: 'currency',
                    currency: 'CAD',
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                })
            },
            {
                name: '(Cost - CSF) / Containers',
                value: ((totalCost + totalFixedCosts - totals.csf) / totals.containersProjected / 100).toLocaleString(
                    'en-CA',
                    {
                        style: 'currency',
                        currency: 'CAD',
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                    }
                )
            },
            {
                name: 'CSF - Cost',
                value: formatAsCurrency(totals.csf - totalCost)
            },
            {
                name: 'CSF + HF - Cost',
                value: formatAsCurrency(totals.csf + totals.handlingFees - totalCost)
            }
        ];
    }, [totals, fixedCosts]);

    const tripsWithIncompleteBulks = useMemo(() => {
        return _.some(filteredData, trip => trip.tripHasIncompleteBulks);
    }, [filteredData]);

    const firstSelectedTrip = useMemo(() => {
        return _.find(filteredData, trip => trip._id === _.first(selectedTrips));
    }, [selectedTrips, filteredData]);

    useEffect(() => {
        (async function() {
            const driverRes = await http.getJSON(
                `/users/getAllDrivers${
                    _user.isSystemAdmin(operator) ||
                    _user.isInternalRole(operator) ||
                    (_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1)
                        ? ''
                        : `/${collector._id}`
                }`
            );
            if (driverRes.ok) {
                setAllDrivers(driverRes.data.drivers);
                const newSelectedDrivers = [];

                driverRes.data.drivers.forEach(({ _id, collector }) => {
                    if (selectedCollectors.includes(collector._id)) newSelectedDrivers.push(_id);
                });

                setSelectedDrivers(newSelectedDrivers);
            }
        })();

        //handleChangeRowsPerPage({ target: { value: 'All' } });
    }, []);

    useEffect(() => {
        const newSelectedDrivers = selectedDrivers.filter(_id => {
            const driver = _.find(allDrivers, d => d._id === _id);
            if (driver) {
                return selectedCollectors.includes(driver.collector._id);
            }
            return false;
        });
        setSelectedDrivers(newSelectedDrivers);
    }, [selectedCollectors]);

    useEffect(() => {
        const newTotals = _.cloneDeep(INIT_TOTALS);

        filteredData.forEach(trip => {
            _.forEach(trip, (value, key) => {
                const newTotal = newTotals[key];
                if (!_.isNil(newTotal)) {
                    if (isNaN(newTotal)) {
                        // handles the columns with the multiple values
                        newTotals[key]['accum'] = newTotals[key]['accum'].concat(value); // accumulates all object values into a single array
                    } else {
                        // handles the columns with single numeric values
                        newTotals[key] += value; // calculates a running total
                    }
                }
            });
        });

        const multiValColTotals = _.pickBy(newTotals, isNaN); // grab the totals object subset for the multiple-value columns

        _.forEach(multiValColTotals, ({ valueToSum, accum }, key) => {
            let totalsArr = _(accum)
                .groupBy('commodity')
                .map((groupArr, cmdty) => ({
                    ...groupArr[0],
                    [valueToSum]: _.sumBy(groupArr, valueToSum)
                }))
                .value();

            newTotals[key] = totalsArr; // replace value in totals object with the array of totals for the current multiple-value column
        });

        setTotals(newTotals);

        (async function() {
            const fixedCostsRes = await http.getJSON(
                `/collector/getFixedCostsInDateRange?collectors=${selectedCollectors.join(
                    ','
                )}&startDate=${startDate.format('YYYYMM')}&endDate=${endDate.format('YYYYMM')}`
            );
            if (fixedCostsRes.ok) {
                setAllFixedCosts(fixedCostsRes.data.fixedCosts);
            }
        })();
    }, [filteredData]);

    const handleFilterHeaderPreset = presetName => {
        const preset = _.get(headerPresets, presetName, []);
        handleChangeFilterHeaders({ target: { value: preset } });
    };

    const customAction = (
        <>
            <Button
                disabled={loading}
                style={{ marginRight: theme.spacing.unit }}
                onClick={() => handleFilterHeaderPreset('driverPayment')}
            >
                Payment
            </Button>
            <Button
                disabled={loading}
                style={{ marginRight: theme.spacing.unit }}
                onClick={() => handleFilterHeaderPreset('driverEfficiency')}
            >
                Efficiency
            </Button>
            <Button
                disabled={loading}
                style={{ marginRight: theme.spacing.unit }}
                onClick={() => handleFilterHeaderPreset('depotCost')}
            >
                Cost
            </Button>
            {!_.isEmpty(selectedTrips) && (
                <Button
                    disabled={loading}
                    color="secondary"
                    style={{ marginRight: theme.spacing.unit }}
                    onClick={handleBulkUpdateDialog(true)}
                >
                    <Icon>update</Icon>
                </Button>
            )}
            <Button
                disabled={loading}
                color="primary"
                style={{ marginRight: theme.spacing.unit }}
                onClick={handleDownload}
            >
                <Icon>get_app</Icon>
            </Button>
        </>
    );

    let commoditiesWithExtras = [
        {
            _id: 'gasCost',
            iconName: 'mdiFuel',
            color: '#E72929'
        },
        {
            _id: 'oosPay',
            iconName: 'mdiMapMarkerMultiple',
            color: '#FFA402'
        },
        {
            _id: 'bags',
            iconName: 'mdiBottleTonic',
            color: '#5794CF'
        },
        {
            _id: 'volumeFee',
            iconName: 'mdiCurrencyUsd',
            color: '#000000'
        }
    ];

    if (!commoditiesLoading) {
        commoditiesWithExtras = [...commodities, ...commoditiesWithExtras];
    }

    const cellStyle = { whiteSpace: 'nowrap' };

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                <Grid container spacing={theme.spacing.unit * 2}>
                    <Grid item xs={12}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        disabled={loading}
                                        value={dateWindow}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        disabled={
                                            loading ||
                                            !(
                                                _user.isSystemAdmin(operator) ||
                                                _user.isInternalRole(operator) ||
                                                collectors.length > 1
                                            )
                                        }
                                        multiple
                                        renderValue={val => `${val.length} Transporter${val.length !== 1 ? 's' : ''}`}
                                        value={selectedCollectors}
                                        onChange={e => handleCollectorChange(e.target.value)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button onClick={handleDropdownButtons('collectors', true)}>All</Button>
                                            <Button onClick={handleDropdownButtons('collectors', false)}>None</Button>
                                        </div>
                                        {collectors &&
                                            collectors
                                                .filter(c => _.get(c, 'configuration.enablePickups', true))
                                                .map(({ _id, name, code }) => {
                                                    return (
                                                        <MenuItem
                                                            key={_id}
                                                            value={_id}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {name}({code})
                                                        </MenuItem>
                                                    );
                                                })}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        disabled={
                                            loading ||
                                            !(
                                                _user.isSystemAdmin(operator) ||
                                                _user.isInternalRole(operator) ||
                                                collectors.length > 1
                                            )
                                        }
                                        multiple
                                        renderValue={val => `${val.length} Processor${val.length !== 1 ? 's' : ''}`}
                                        value={selectedProcessors}
                                        onChange={e => handleProcessorChange(e.target.value)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button onClick={handleDropdownButtons('processors', true)}>All</Button>
                                            <Button onClick={handleDropdownButtons('processors', false)}>None</Button>
                                        </div>
                                        {collectors &&
                                            collectors
                                                .filter(c => _.get(c, 'configuration.enableCounting', true))
                                                .map(({ _id, name, code }) => {
                                                    return (
                                                        <MenuItem
                                                            key={_id}
                                                            value={_id}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {name}({code})
                                                        </MenuItem>
                                                    );
                                                })}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        disabled={loading}
                                        multiple
                                        renderValue={val => `${val.length} Driver${val.length !== 1 ? 's' : ''}`}
                                        value={selectedDrivers}
                                        onChange={e => setSelectedDrivers(e.target.value)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button onClick={handleDropdownButtons('drivers', true)}>All</Button>
                                            <Button onClick={handleDropdownButtons('drivers', false)}>None</Button>
                                        </div>
                                        {_.orderBy(
                                            allDrivers,
                                            [d => !d.banned, d => d.name.first.toLowerCase()],
                                            ['desc', 'asc']
                                        ).map(driver => {
                                            const { _id, collector, banned } = driver;
                                            if (!selectedCollectors.includes(collector._id)) return null;
                                            return (
                                                <MenuItem
                                                    key={_id}
                                                    value={_id}
                                                    style={{
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    {_user.getNameFull(driver).toLowerCase()}
                                                    {banned && (
                                                        <span
                                                            style={{
                                                                marginLeft: theme.spacing.unit,
                                                                display: 'grid',
                                                                placeItems: 'center'
                                                            }}
                                                        >
                                                            <MDIcon
                                                                path={mdiAccountOff}
                                                                size={0.85}
                                                                color={theme.palette.text.secondary}
                                                            />
                                                        </span>
                                                    )}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        disabled={loading}
                                        multiple
                                        renderValue={val => `${val.length} Day${val.length !== 1 ? 's' : ''}`}
                                        value={days}
                                        onChange={e => setDays(e.target.value)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button onClick={handleDropdownButtons('days', true)}>All</Button>
                                            <Button onClick={handleDropdownButtons('days', false)}>None</Button>
                                        </div>
                                        {DAYS_OF_THE_WEEK.map((day, i) => {
                                            return (
                                                <MenuItem value={i} style={{ textTransform: 'capitalize' }} key={i}>
                                                    {day}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <Button
                                    disabled={loading}
                                    color="primary"
                                    variant="contained"
                                    data-cy="dashboard-search-button"
                                    type="submit"
                                    onClick={handleSearch}
                                >
                                    <Icon>search</Icon>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>

                    <IconCard
                        title="Cost"
                        icon="local_shipping"
                        color="pink"
                        data={costStats}
                        headers={['Total', 'Depot Part', 'Cost']}
                    />
                    <IconCard title="Containers" icon="delete" color="orange" data={containerStats} />
                    <IconCard
                        title={
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span>Revenue (Projected)</span>
                                {tripsWithIncompleteBulks && (
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon style={{ color: colors.red[500], marginRight: theme.spacing.unit }}>
                                            warning
                                        </Icon>
                                        <Typography style={{ color: colors.red[500] }} variant="caption">
                                            Incomplete orders
                                        </Typography>
                                    </span>
                                )}
                            </span>
                        }
                        icon="attach_money"
                        color="green"
                        data={revenueStats}
                    />

                    <CostCalculation
                        open={!_.isEmpty(costCalcDialog)}
                        handleClose={handleCostCalcDialog({})}
                        info={costCalcDialog}
                        commodities={commodities}
                        theme={theme}
                        http={http}
                        getData={getData}
                    />

                    <BulkUpdate
                        open={bulkUpdateDialog}
                        ids={selectedTrips}
                        firstSelectedTrip={firstSelectedTrip}
                        commodities={commodities}
                        handleClose={handleBulkUpdateDialog(false)}
                        getData={getData}
                    />
                </Grid>
            </div>

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        title="Trip Statistics"
                        loading={loading}
                        headers={headers}
                        filteredHeaders={filteredHeaders}
                        length={totalDataEntries}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        customAction={customAction}
                        handleChange={handleChange}
                        handleChangeFilterHeaders={handleChangeFilterHeaders}
                        handleAllFilterHeaders={handleAllFilterHeaders}
                        handleSearch={handleSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleCheckbox={handleSelectTrip('all')}
                        checkedItems={selectedTrips.length}
                        refresh={getData}
                        noSearch={true}
                        checkbox
                    >
                        {!_.isNil(filteredData) &&
                            !_.isEmpty(filteredData) &&
                            _.concat(filteredData, totals).map((trip, idx) => {
                                const {
                                    _id,
                                    timezone,
                                    startTime: time,
                                    actualStartTime,
                                    startTimeDifference,
                                    code,
                                    transporterCode,
                                    driver,
                                    truckNum,
                                    licensePlate,
                                    paymentModel,
                                    contractor,
                                    driverWage,
                                    driverCommissions,
                                    kmPerLiter,
                                    gasPrice,
                                    gasCost,
                                    duration,
                                    measuredDuration,
                                    startTripTime = 0,
                                    differenceInTime,
                                    durationFromDepot,
                                    durationToDepot,
                                    offloadTime,
                                    distance,
                                    distanceFromDepot,
                                    distanceToDepot,
                                    completePickups,
                                    incompletePickups,
                                    rescheduledPickups,
                                    outOfSystemStops,
                                    outOfSystemPay,
                                    countedPickups,
                                    containers,
                                    containersPerHour,
                                    containersProjected,
                                    containersProjectedPerHour,
                                    averagePickupTime,
                                    averageBagTime,
                                    handlingFees,
                                    csf,
                                    csfBySkuType,
                                    costBreakdown,
                                    cost = 0,
                                    payBreakdown,
                                    totalPay,
                                    countsBySkuType,
                                    bagsGroupedBySku,
                                    tripHasIncompleteBulks,
                                    depotProfit,
                                    volumeFee,
                                    chargeVolumeFee
                                } = trip;

                                const costPerContainer = containers === 0 ? 0 : cost / containers / 100;
                                const costPerContainersProjected =
                                    containersProjected === 0 ? 0 : cost / containersProjected / 100;
                                const csfCostDifference = csf - cost;

                                return (
                                    <TableRow key={idx} data-cy={`trip-statistics-row-${idx}`}>
                                        <TableCell padding="checkbox">
                                            {!_.isNil(_id) && (
                                                <Checkbox
                                                    onClick={handleSelectTrip(_id)}
                                                    checked={selectedTrips.includes(_id)}
                                                />
                                            )}
                                        </TableCell>
                                        {filteredHeaders.includes('Trip Details') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(_id) && (
                                                    <IconButton
                                                        data-cy={`trip-statistics-info-${idx}`}
                                                        onClick={handleCostCalcDialog(trip)}
                                                    >
                                                        <Icon>info</Icon>
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Incomplete^') && (
                                            <TableCell style={cellStyle}>
                                                {tripHasIncompleteBulks && (
                                                    <Icon style={{ color: colors.red[500] }}>warning</Icon>
                                                )}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Date^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(time) &&
                                                    moment(time)
                                                        .tz(timezone)
                                                        .format('MMM DD ')}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Day^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(time) &&
                                                    moment(time)
                                                        .tz(timezone)
                                                        .format('ddd')}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Start Time Est^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(time) &&
                                                    moment(time)
                                                        .tz(timezone)
                                                        .format('h:mm A')}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Start Time Act^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(actualStartTime) &&
                                                    moment(actualStartTime)
                                                        .tz(timezone)
                                                        .format('h:mm A')}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Start Time Diff(hr)^') && (
                                            <TableCell style={cellStyle}>{_.round(startTimeDifference, 2)}</TableCell>
                                        )}

                                        {filteredHeaders.includes('Transporter^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-trip-stats-collector`}>
                                                {transporterCode}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Processor^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-trip-stats-processor`}>
                                                {code}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Driver^') && (
                                            <TableCell style={cellStyle}>{driver}</TableCell>
                                        )}

                                        {filteredHeaders.includes('Truck #^') && (
                                            <TableCell style={cellStyle}>{truckNum}</TableCell>
                                        )}

                                        {filteredHeaders.includes('License Plate^') && (
                                            <TableCell style={cellStyle}>{licensePlate}</TableCell>
                                        )}

                                        {filteredHeaders.includes('Distance Est(km)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-est-distance`}>
                                                {_.isNil(distance)
                                                    ? 'N/A'
                                                    : _.round(distance / 1000, 2).toLocaleString()}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Distance Act(km)^') && (
                                            <TableCell style={cellStyle}>N/A</TableCell>
                                        )}

                                        {filteredHeaders.includes('Distance Diff^') && (
                                            <TableCell style={cellStyle}>N/A</TableCell>
                                        )}

                                        {filteredHeaders.includes('Distance Depot(km)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-distance-depot`}>
                                                {_.isNil(distanceFromDepot) || _.isNil(distanceToDepot)
                                                    ? 'N/A'
                                                    : _.round(
                                                          (distanceToDepot + distanceFromDepot) / 1000,
                                                          2
                                                      ).toLocaleString()}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Vehicle Eff(km/L)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-vehicle-efficiency`}>
                                                {!_.isNil(kmPerLiter) && kmPerLiter.toFixed(2)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Gas Price($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-gas-price`}>
                                                {!_.isNil(gasPrice) && formatCents(gasPrice)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Gas Cost($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-gas-cost`}>
                                                {!_.isNil(gasCost) && formatCents(gasCost)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Driving Est(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-est-driving-time`}>
                                                {convertToHours(duration)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Driving Act(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-act-driving-time`}>
                                                {convertToHours(measuredDuration)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Driving Diff(hr)^') && (
                                            <TableCell
                                                style={{
                                                    ...cellStyle,
                                                    color: differenceInTime >= 0 ? green[500] : red[500]
                                                }}
                                            >
                                                {convertSecondsToHours(differenceInTime, false)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Driving Depot(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-depot-driving-time`}>
                                                {_.isNil(durationFromDepot) || _.isNil(durationToDepot)
                                                    ? 'N/A'
                                                    : convertToHours(durationFromDepot + durationToDepot)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Start Trip(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-start-trip-time`}>
                                                {(startTripTime / 60).toFixed(2)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Offload(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-offload-time`}>
                                                {(offloadTime / 60).toFixed(2)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time Total(hr)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-total-time`}>
                                                {convertToHours(
                                                    startTripTime * 60 + measuredDuration + offloadTime * 60
                                                )}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Pickups^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-pickups-count`}>
                                                {completePickups}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Processed^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-processed-pickups-count`}>
                                                {countedPickups}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Aborted^') && (
                                            <TableCell style={cellStyle}>{incompletePickups}</TableCell>
                                        )}
                                        {filteredHeaders.includes('Rescheduled^') && (
                                            <TableCell style={cellStyle}>{rescheduledPickups}</TableCell>
                                        )}
                                        {filteredHeaders.includes('OOS Stops^') && (
                                            <TableCell style={cellStyle}>{outOfSystemStops}</TableCell>
                                        )}
                                        {filteredHeaders.includes('OOS Pay^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-oos-pay`}>
                                                {!_.isNil(outOfSystemPay) &&
                                                    paymentModel === DRIVER_PAYMENT_MODELS[1] &&
                                                    formatCents(outOfSystemPay)}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Bags Split^') && (
                                            <TableCell style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
                                                <ValuesByCommodityDisplay
                                                    row={idx}
                                                    name="bags-split"
                                                    values={bagsGroupedBySku}
                                                    valueToDisplay="total"
                                                    commodities={commoditiesWithExtras}
                                                    theme={theme}
                                                />
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Units Split^') && (
                                            <TableCell style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
                                                <ValuesByCommodityDisplay
                                                    row={idx}
                                                    name="units-split"
                                                    values={countsBySkuType}
                                                    valueToDisplay="total"
                                                    commodities={commodities}
                                                    theme={theme}
                                                />
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Containers / Hour^') && (
                                            <TableCell style={cellStyle}>{containersPerHour}</TableCell>
                                        )}

                                        {filteredHeaders.includes('Synthetic Containers^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-containers-synthetic`}>
                                                {(containersProjected || 0).toLocaleString('en-CA', {
                                                    maximumFractionDigits: 2
                                                })}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Synthetic Containers / Hour^') && (
                                            <TableCell style={cellStyle}>{containersProjectedPerHour}</TableCell>
                                        )}

                                        {filteredHeaders.includes('Cost / Container($)^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(_id) && costPerContainer.toFixed(3)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Cost / Synthetic Containers($)^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(_id) && costPerContainersProjected.toFixed(3)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time / Pickup(min)^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(averagePickupTime) && averagePickupTime.toFixed(2)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Time / Bag(min)^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(averageBagTime) && averageBagTime.toFixed(2)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Pay Contractor^') && (
                                            <TableCell style={cellStyle}>
                                                {!_.isNil(_id) &&
                                                    (contractor ? (
                                                        <Icon style={{ color: colors.green[500] }}>done</Icon>
                                                    ) : (
                                                        <Icon style={{ color: colors.red[500] }}>clear</Icon>
                                                    ))}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Payment Type^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-payment-type`}>
                                                {paymentModel}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Pay Rate($)^') && (
                                            <TableCell style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
                                                {paymentModel === 'Commission' ? (
                                                    <ValuesByCommodityDisplay
                                                        row={idx}
                                                        values={driverCommissions}
                                                        name="rate"
                                                        valueToDisplay="rate"
                                                        formatValue={value =>
                                                            (value / 100).toLocaleString('en-CA', {
                                                                maximumFractionDigits: 4,
                                                                minimumFractionDigits: 2
                                                            })
                                                        }
                                                        commodities={commodities}
                                                        theme={theme}
                                                    />
                                                ) : (
                                                    !_.isNil(driverWage) && formatCents(driverWage)
                                                )}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Pay Breakdown($)^') && (
                                            <TableCell style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
                                                <ValuesByCommodityDisplay
                                                    row={idx}
                                                    name="pay"
                                                    values={payBreakdown}
                                                    valueToDisplay="pay"
                                                    formatValue={value => formatCents(value)}
                                                    commodities={commoditiesWithExtras}
                                                    theme={theme}
                                                />
                                            </TableCell>
                                        )}

                                        {/*filteredHeaders.includes('Wage^') && (
                                            <TableCell style={cellStyle}>
                                                {paymentModel === 'Wage'
                                                    ? !_.isNil(driverWage) && formatAsCurrency(driverWage)
                                                    : 'N/A'}
                                            </TableCell>
                                        )*/}

                                        {filteredHeaders.includes('Pay Total($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-total-pay`}>
                                                {formatCents(totalPay)}
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('CSF Breakdown($)^') && (
                                            <TableCell style={cellStyle}>
                                                {
                                                    <ValuesByCommodityDisplay
                                                        row={idx}
                                                        name="csf"
                                                        values={csfBySkuType}
                                                        valueToDisplay="csf"
                                                        commodities={commodities}
                                                        theme={theme}
                                                        formatValue={value => formatCents(value)}
                                                    />
                                                }
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('CSF Total($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-csf-total`}>
                                                {formatCents(csf)}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('HFs($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-handling-fees`}>
                                                {formatCents(handlingFees)}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Volume Fee($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-volume-fee`}>
                                                {chargeVolumeFee ? (
                                                    formatCents(volumeFee)
                                                ) : (
                                                    <Icon style={{ color: colors.red[500] }}>close</Icon>
                                                )}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Depot Cost Breakdown($)^') && (
                                            <TableCell style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
                                                <ValuesByCommodityDisplay
                                                    row={idx}
                                                    name="depot-cost"
                                                    values={costBreakdown}
                                                    valueToDisplay="total"
                                                    formatValue={value => formatCents(value)}
                                                    commodities={commoditiesWithExtras}
                                                    theme={theme}
                                                />
                                            </TableCell>
                                        )}

                                        {filteredHeaders.includes('Depot Cost($)^') && (
                                            <TableCell style={cellStyle} data-cy={`${idx}-depot-cost`}>
                                                {formatCents(cost)}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Depot CSF-Cost($)^') && (
                                            <TableCell
                                                style={{
                                                    ...cellStyle,
                                                    color: csfCostDifference >= 0 ? green[500] : red[500]
                                                }}
                                            >
                                                {formatCents(csfCostDifference)}
                                            </TableCell>
                                        )}
                                        {filteredHeaders.includes('Depot Profit($)^') && (
                                            <TableCell
                                                style={{
                                                    ...cellStyle,
                                                    color: depotProfit >= 0 ? green[500] : red[500]
                                                }}
                                            >
                                                {formatCents(depotProfit)}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                    </Table>
                </Grid>
            </Grid>
        </>
    );
};

export default withTheme()(DriverHistory);

const downloadCSV = async (trips, totals) => {
    const tripsToDownload = [];
    trips
        .concat(totals)
        .forEach(
            ({
                _id,
                driverWage,
                timezone,
                startTime,
                actualStartTime,
                startTimeDifference,
                code,
                driver,
                paymentModel,
                contractor,
                driverCommissions,
                kmPerLiter,
                gasPrice,
                gasCost,
                duration,
                measuredDuration,
                differenceInTime,
                durationFromDepot,
                durationToDepot,
                startTripTime = 0,
                offloadTime,
                distance,
                distanceFromDepot,
                distanceToDepot,
                completePickups,
                incompletePickups,
                rescheduledPickups,
                outOfSystemStops,
                outOfSystemPay,
                countedPickups,
                containers,
                containersPerHour,
                containersProjected,
                containersProjectedPerHour,
                averagePickupTime,
                averageBagTime,
                handlingFees,
                csf,
                csfBySkuType,
                costBreakdown,
                cost,
                payBreakdown,
                totalPay,
                countsBySkuType,
                bagsGroupedBySku,
                tripHasIncompleteBulks,
                depotProfit
            }) => {
                const payRateBeverages = _.get(_.find(driverCommissions, { skuType: 'beverage' }), 'rate'),
                    payRateClothing = _.get(_.find(driverCommissions, { skuType: 'clothing' }), 'rate'),
                    payRateElectronics = _.get(_.find(driverCommissions, { skuType: 'electronics' }), 'rate'),
                    payBreakdownBeverages = _.get(_.find(payBreakdown, { skuType: 'beverage' }), 'pay'),
                    payBreakdownClothing = _.get(_.find(payBreakdown, { skuType: 'clothing' }), 'pay'),
                    payBreakdownElectronics = _.get(_.find(payBreakdown, { skuType: 'electronics' }), 'pay'),
                    payBreakdownWage = _.get(_.find(payBreakdown, { commodity: 'wage' }), 'pay');

                tripsToDownload.push({
                    incomplete: _id ? tripHasIncompleteBulks : 'TOTAL',
                    date:
                        startTime &&
                        moment(startTime)
                            .tz(timezone)
                            .format('MMM DD '),
                    day:
                        startTime &&
                        moment(startTime)
                            .tz(timezone)
                            .format('ddd'),
                    estimatedStartTime:
                        startTime &&
                        moment(startTime)
                            .tz(timezone)
                            .format('h:mm A'),
                    actualStartTime:
                        actualStartTime &&
                        moment(actualStartTime)
                            .tz(timezone)
                            .format('h:mm A'),
                    startTimeDifference: startTimeDifference && _.round(startTimeDifference, 2),
                    collector: code,
                    driver: driver,
                    estimatedDistance: _.isNil(distance) ? 'N/A' : _.round(distance / 1000, 2).toLocaleString(),
                    actualDistance: 'N/A',
                    distanceDifference: 'N/A',
                    distanceDepot:
                        _.isNil(distanceFromDepot) || _.isNil(distanceToDepot)
                            ? 'N/A'
                            : _.round((distanceToDepot + distanceFromDepot) / 1000, 2).toLocaleString(),
                    vehicleEfficiency: kmPerLiter && kmPerLiter.toFixed(2),
                    gasPrice: gasPrice && formatCents(gasPrice),
                    gasCost: formatCents(gasCost),
                    estimatedDrivingTime: duration && convertToHours(duration),
                    actualDrivingTime: measuredDuration && convertToHours(measuredDuration),
                    timeDrivingDifference: differenceInTime && convertSecondsToHours(differenceInTime, false),
                    timeDrivingDepot:
                        _.isNil(durationFromDepot) || _.isNil(durationToDepot)
                            ? 'N/A'
                            : convertToHours(durationFromDepot + durationToDepot),
                    offloadTime: offloadTime && (offloadTime / 60).toFixed(2),
                    totalTime:
                        measuredDuration &&
                        offloadTime &&
                        convertToHours(startTripTime * 60 + measuredDuration + offloadTime * 60),
                    pickups: completePickups,
                    processed: countedPickups,
                    aborted: incompletePickups,
                    rescheduled: rescheduledPickups,
                    oosStops: outOfSystemStops,
                    oosPay: outOfSystemPay,
                    bagsSplitBeverages: _.get(_.find(bagsGroupedBySku, { skuType: 'beverage' }), 'total', 0),
                    bagsSplitClothing: _.get(_.find(bagsGroupedBySku, { skuType: 'clothing' }), 'total', 0),
                    bagsSplitElectronics: _.get(_.find(bagsGroupedBySku, { skuType: 'electronics' }), 'total', 0),
                    bagsSplitBagCount: _.get(_.find(bagsGroupedBySku, { commodity: 'bags' }), 'total', 0),
                    unitsSplitBeverages: _.get(_.find(countsBySkuType, { skuType: 'beverage' }), 'total', 0),
                    unitsSplitClothing: _.get(_.find(countsBySkuType, { skuType: 'clothing' }), 'total', 0).toFixed(2),
                    unitsSplitElectronics: _.get(_.find(countsBySkuType, { skuType: 'electronics' }), 'total', 0),
                    containersPerHour: _id && (containersPerHour ? containersPerHour : 'N/A'),
                    containersProjected: (containersProjected || 0).toLocaleString('en-CA', {
                        maximumFractionDigits: 2
                    }),
                    containersProjectedPerHour:
                        _id && (containersProjectedPerHour ? containersProjectedPerHour : 'N/A'),
                    costPerContainer: _id && (containers === 0 ? 0 : cost / containers / 100).toFixed(3),
                    costPerContainerProjected:
                        _id && (containersProjected === 0 ? 0 : cost / containersProjected / 100).toFixed(3),
                    timePerPickup: averagePickupTime && averagePickupTime.toFixed(2),
                    timePerBag: averageBagTime && averageBagTime.toFixed(2),
                    payContractor: contractor,
                    paymentType: paymentModel,
                    payRateBeverages: _id && (payRateBeverages ? formatCents(payRateBeverages) : 'N/A'),
                    payRateClothing: _id && (payRateClothing ? formatCents(payRateClothing) : 'N/A'),
                    payRateElectronics: _id && (payRateElectronics ? formatCents(payRateElectronics) : 'N/A'),
                    payRateWage: _id && (driverWage && paymentModel !== 'Commission' ? formatCents(driverWage) : 'N/A'),
                    payBreakdownBeverages: payBreakdownBeverages ? formatCents(payBreakdownBeverages) : 'N/A',
                    payBreakdownClothing: payBreakdownClothing ? formatCents(payBreakdownClothing) : 'N/A',
                    payBreakdownElectronics: payBreakdownElectronics ? formatCents(payBreakdownElectronics) : 'N/A',
                    payBreakdownWage: payBreakdownWage ? formatCents(payBreakdownWage) : 'N/A',
                    payBreakdownGas: formatCents(_.get(_.find(payBreakdown, { commodity: 'gasCost' }), 'pay')),
                    payBreakdownOos: formatCents(_.get(_.find(payBreakdown, { commodity: 'oosPay' }), 'pay')),
                    totalPay: formatCents(totalPay),
                    csfBreakdownBeverages: formatCents(_.get(_.find(csfBySkuType, { skuType: 'beverage' }), 'csf')),
                    csfBreakdownClothing: formatCents(_.get(_.find(csfBySkuType, { skuType: 'clothing' }), 'csf')),
                    csfBreakdownElectronics: formatCents(
                        _.get(_.find(csfBySkuType, { skuType: 'electronics' }), 'csf')
                    ),
                    csfTotal: formatCents(csf),
                    handlingFees: formatCents(handlingFees),
                    depotCostBreakdownWages: formatCents(_.get(_.find(costBreakdown, { commodity: 'wage' }), 'total')),
                    depotCostBreakdownGas: formatCents(_.get(_.find(costBreakdown, { commodity: 'gasCost' }), 'total')),
                    depotCostBreakdownOos: formatCents(_.get(_.find(costBreakdown, { commodity: 'oosPay' }), 'total')),
                    depotCost: formatCents(cost),
                    depotCostCsdDifference: formatCents(csf - cost),
                    depotProfit: formatCents(depotProfit)
                });
            }
        );

    const date = moment().format('YYYY-MM-DD HH:mm');
    const fileName = `trip_statistics_${date}`;
    try {
        await downloadObjectAsCSV(tripsToDownload, fileName);
    } catch (err) {
        console.log(err);
    }
};

function convertToHours(seconds) {
    return `${(seconds / 3600).toFixed(2)}`;
}

function ValuesByCommodityDisplay({
    row = 0,
    name = '',
    values = [],
    valueToDisplay,
    commodities,
    formatValue = null,
    theme
}) {
    return (
        <span style={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
            {_(values)
                .sortBy('skuType')
                .map((obj, idx) => {
                    const count = _.get(obj, valueToDisplay, 0);
                    if (count > 0) {
                        const commodityForSku = _.find(commodities, { _id: obj.commodity });
                        const commodityIcon = _.get(commodityForSku, 'iconName');
                        const commodityName = _commodity.getSkuType(commodityForSku);
                        return (
                            <Tooltip title={_.startCase(commodityName) || _.get(obj, 'label', '')}>
                                <span
                                    key={idx}
                                    style={{
                                        display: 'flex',
                                        borderRadius: 4,
                                        borderColor: colors.blue[300],
                                        alignItems: 'center',
                                        marginRight: theme.spacing.unit,
                                        flexWrap: 'nowrap'
                                    }}
                                >
                                    {commodityIcon && (
                                        <>
                                            <MDIcon
                                                path={_.get(allIcons, commodityIcon)}
                                                size={0.8}
                                                color={_.get(commodityForSku, 'color')}
                                            />{' '}
                                        </>
                                    )}
                                    <span style={{ marginRight: 4 }} data-cy={`${row}-${commodityName}-${name}`}>
                                        {_.isNil(formatValue)
                                            ? count.toLocaleString('en-CA', { maximumFractionDigits: 2 })
                                            : formatValue(count)}
                                    </span>
                                </span>
                            </Tooltip>
                        );
                    } else {
                        return <></>;
                    }
                })
                .value()}
        </span>
    );
}

function getUnitForSkuType(skuType) {
    switch (skuType) {
        case 'beverage':
            return 'containers';
        case 'clothing':
            return 'lbs';
        case 'electronics':
            return 'items';
        default:
            return 'items';
    }
}

function formatCents(cents) {
    return ((cents || 0) / 100).toLocaleString('en-CA', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}
