import React, { useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Paper, Typography, Button, colors, withTheme } from '@material-ui/core';
import { _commodity } from 'std';

import CommodityPickupRequestDialog from 'components/CommodityPickupRequestDialog';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import OperatorContext from 'utils/contexts/OperatorContext';

import useImageUpload from 'components/ImageUploads/useImageUpload';
import CommodityInventoryDialog from 'components/Dialogs/CommodityInventoryDialog';

function CommodityPickupWidget(props) {
    const {
        collector,
        commodities,
        latestCommodityPickups,
        history,
        openBulkCounts,
        openBulkCountsLoading,
        reloadOperator,
        reloadOpenBulkCounts,
        reloadLatestCommodityPickups,
        collectorIdsSelected,
        endDate,
        setCollectorIdsSelected,
        setEndDate,
        allCollectorsBySkuType,
        theme,
        trackedCommodities
    } = props;

    const operator = useContext(OperatorContext);

    const commoditiesEnabled = _(commodities)
        .filter(commodity => _.get(commodity, 'commodityPickupsEnabled', false))
        .sortBy('skuType')
        .value();

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [commoditySelected, setCommoditySelected] = useState(null);
    const [requestPickupDialogOpen, setRequestPickupDialogOpen] = useState(false);
    const [comments, setComments] = useState('');

    const [commodityInventorySelected, setCommodityInventorySelected] = useState(null);
    const [commodityInvetoryDialogOpen, setCommodityInventoryDialogOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const {
        imageURLs,
        imagePreviews,
        uploadingImage,
        handleDeleteImage,
        handleAddImage,
        resetImages,
        setImagePreviews
    } = useImageUpload({});

    const commodityPickupConfig = _.get(collector, 'commodityPickupConfig', []);

    const handleGoToCommodityPickups = () => {
        props.history.push(`/operators/${operator._id}/commodity-pickups`);
    };

    const handleSubmit = async (comments, imageURLs) => {
        setLoading(true);
        const formData = new FormData();
        let images = [];
        if (!_.isEmpty(imageURLs)) {
            imageURLs.forEach(imageURL => {
                formData.append('images', imageURL);
            });

            const res = await http.postJSON(`/system/uploadMultipleImages`, formData, false, true);
            images = _.get(res, 'data.imageUrls', []);
        }

        const lastCommodityPickup = _.get(
            _.find(latestCommodityPickups, { _id: commoditySelected._id }),
            'commodityPickup'
        );

        let res;
        if (_.isNil(lastCommodityPickup) || _.get(lastCommodityPickup, 'status') !== 'Requested') {
            //collector is requesting a commodity pickup
            res = await http.post(
                `/collector/requestCommodityPickup`,
                {
                    collectorIds: collectorIdsSelected,
                    commodityId: commoditySelected._id,
                    comments,
                    images,
                    endDate
                },
                true
            );
        } else {
            //collector is setting the commodity pickup to collected
            res = await http.post(
                `/collector/collectCommodityPickup`,
                {
                    collectorIds: collectorIdsSelected,
                    commodityId: commoditySelected._id,
                    comments,
                    images,
                    endDate
                },
                true
            );
        }
        if (res.ok) {
            await Promise.all([reloadOperator(), reloadOpenBulkCounts(), reloadLatestCommodityPickups()]);

            setRequestPickupDialogOpen(false);
            setComments('');
            resetImages();

            //onSnackbar('Pickup requested successfully.');
        } else {
            onSnackbar(res.errorMessage, 'error');

            if (res.status === 429) {
                await Promise.all([reloadOperator(), reloadOpenBulkCounts(), reloadLatestCommodityPickups()]);
            }
        }
        setLoading(false);
    };

    const handleCancelRequest = async () => {
        setLoading(true);

        const res = await http.post(
            `/collector/cancelCommodityPickup`,
            {
                collectorIds: collectorIdsSelected,
                commodityId: commoditySelected._id
            },
            true
        );

        if (res.ok) {
            await Promise.all([reloadOperator(), reloadOpenBulkCounts(), reloadLatestCommodityPickups()]);

            setRequestPickupDialogOpen(false);
            setComments('');
            resetImages();

            //onSnackbar('Pickup requested successfully.');
        } else {
            onSnackbar(res.errorMessage, 'error');

            if (res.status === 429) {
                await Promise.all([reloadOperator(), reloadOpenBulkCounts(), reloadLatestCommodityPickups()]);
            }
        }
        setLoading(false);
    };

    const handleSubmitCommodityAmount = async (collector, commodity, commodityAmount) => {
        const res = await http.post('/collector/' + collector._id + '/updateInventory', {
            commodityId: commodity._id,
            amount: commodityAmount
        });
        if (res.ok) {
            reloadOperator();
            onSnackbar('Successfully updated inventory');
            setCommodityInventoryDialogOpen(false);
        } else {
            onSnackbar('Error updating inventory', 'error');
        }
    };

    const handleCommoditySelected = (commodity, collectorSelected, lastCommodityPickup) => {
        setCommoditySelected(commodity);
        setRequestPickupDialogOpen(true);
        setCollectorIdsSelected([collectorSelected._id]);
        if (_.get(lastCommodityPickup, 'status') === 'Requested') {
            setComments(_.get(lastCommodityPickup, 'commentsOnRequest', ''));
            setImagePreviews(_.get(lastCommodityPickup, 'imagesOnRequest', []));
        } else {
            setComments('');
            setImagePreviews([]);
        }
    };

    return (
        <>
            <Paper
                style={{
                    margin: theme.spacing.unit,
                    padding: theme.spacing.unit * 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1 }}>
                    <Typography
                        variant="h6"
                        style={{
                            marginRight: theme.spacing.unit * 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {_.get(collector, 'name', '')}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {commoditiesEnabled.map(commodity => {
                            const lastCommodityPickup = _.get(
                                _.find(latestCommodityPickups, { _id: commodity._id }),
                                'commodityPickup'
                            );
                            const configForCommodity = _.find(commodityPickupConfig, { commodity: commodity._id });
                            const lastRequested = _.get(configForCommodity, 'lastRequested');
                            const lastRequestedFormated = !_.isNil(lastRequested)
                                ? moment(lastRequested)
                                      .tz(collector.timezone)
                                      .format('MMM D, YYYY')
                                : 'N/A';

                            return commodityNotConfigured(configForCommodity) ? null : (
                                <Paper
                                    key={commodity._id}
                                    style={{
                                        padding: theme.spacing.unit,
                                        margin: theme.spacing.unit,
                                        backgroundColor:
                                            _.isNil(lastCommodityPickup) ||
                                            _.get(lastCommodityPickup, 'status') !== 'Requested'
                                                ? _.get(commodity, 'color')
                                                : colors.yellow[500],
                                        color:
                                            _.isNil(lastCommodityPickup) ||
                                            _.get(lastCommodityPickup, 'status') !== 'Requested'
                                                ? 'white'
                                                : 'black',
                                        cursor: 'pointer',
                                        flexGrow: 1
                                    }}
                                    onClick={() => handleCommoditySelected(commodity, collector, lastCommodityPickup)}
                                    data-cy={`commodity-pickup-widget-${_.kebabCase(_commodity.getSkuType(commodity))}`}
                                >
                                    <Typography color="inherit" variant="button">
                                        {_.isNil(lastCommodityPickup) ||
                                        _.get(lastCommodityPickup, 'status') !== 'Requested'
                                            ? `Request ${_commodity.getSkuType(commodity)} Pickup`
                                            : `${_commodity.getSkuType(commodity)} Pickup Scheduled`}
                                    </Typography>
                                    <Typography color="inherit" variant="caption">
                                        {commodityNotConfigured(configForCommodity)
                                            ? 'Not configured'
                                            : _.get(lastCommodityPickup, 'status') !== 'Requested'
                                            ? `Schedule A Pickup Here`
                                            : `Cancel Or Complete Pickup Here`}
                                    </Typography>
                                </Paper>
                            );
                        })}
                    </div>
                    {trackedCommodities.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {trackedCommodities.map(commodity => {
                                const commodityName = _.get(
                                    commodity,
                                    commodity.isSubCommodity ? 'name.en' : 'skutype',
                                    'Item'
                                );
                                const commodityInventory = _.get(collector, `inventory.${commodity._id}`, 0);

                                return (
                                    <Paper
                                        key={`inventory-${commodity._id}`}
                                        style={{
                                            padding: theme.spacing.unit,
                                            margin: theme.spacing.unit,
                                            backgroundColor: _.get(commodity, 'color'),
                                            color: 'white',
                                            cursor: 'pointer',
                                            flexGrow: 1
                                        }}
                                        onClick={() => {
                                            setCommodityInventorySelected(commodity);
                                            setCommodityInventoryDialogOpen(true);
                                        }}
                                        data-cy={`commodity-inventory-widget-${commodity._id}`}
                                    >
                                        <Typography color="inherit" variant="button">
                                            {commodityName} ({commodityInventory})
                                        </Typography>
                                        <Typography color="inherit" variant="caption">
                                            Manage Inventory
                                        </Typography>
                                    </Paper>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <Button color="primary" onClick={handleGoToCommodityPickups}>
                        View All Pickups
                    </Button>
                </div>
            </Paper>
            <CommodityInventoryDialog
                open={commodityInvetoryDialogOpen}
                collector={collector}
                commodity={commodityInventorySelected}
                onClose={() => setCommodityInventoryDialogOpen(false)}
                onSubmit={handleSubmitCommodityAmount}
            />
            <CommodityPickupRequestDialog
                open={requestPickupDialogOpen}
                commodity={commoditySelected}
                latestCommodityPickups={latestCommodityPickups}
                openBulkCounts={openBulkCounts}
                collector={collector}
                loading={loading || openBulkCountsLoading}
                onClose={() => setRequestPickupDialogOpen(false)}
                onSubmit={handleSubmit}
                onCancelRequest={handleCancelRequest}
                isAdmin={operator.adminView}
                allCollectorsBySkuType={allCollectorsBySkuType}
                collectorIdsSelected={collectorIdsSelected}
                endDate={endDate}
                setCollectorIdsSelected={setCollectorIdsSelected}
                setEndDate={setEndDate}
                imageURLs={imageURLs}
                imagePreviews={imagePreviews}
                handleAddImage={handleAddImage}
                handleDeleteImage={handleDeleteImage}
                uploadingImage={uploadingImage}
                resetImages={resetImages}
                comments={comments}
                onComments={comments => setComments(comments)}
            />
        </>
    );
}

export default withTheme()(CommodityPickupWidget);

function commodityNotConfigured(configForCommodity) {
    return (
        _.isNil(configForCommodity) ||
        _.isEmpty(_.get(configForCommodity, 'emails')) ||
        _.get(configForCommodity, 'collectorSubmittable', false) === false
    );
}
