import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { _commodity } from 'std';

import { withMobileDialog, withTheme } from '@material-ui/core';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    InputAdornment,
    Icon,
    colors,
    DialogTitle
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';
import OperatorContext from 'utils/contexts/OperatorContext';
import useWindowSize from 'utils/hooks/useWindowSize';

function EditNumberOfBagsDialogV2({ theme, open, bulk, onClose, commodities, http, refreshBulk }) {
    const subPayload = _.get(bulk, 'pickup.subPayloads', _.get(bulk, 'pickupLessSubPayloads', []));
    const skuType = _.get(bulk, 'skuType', '');
    const payloadAmount = _.get(bulk, `pickup.payload.${skuType}`, _.get(bulk, 'commodityAmount', 0));

    const [width, height] = useWindowSize();
    let mobileMode = width <= 620;
    const operator = useContext(OperatorContext);
    const { lang } = useContext(LocalizationContext);
    const [payloadList, setPayloadList] = useState([]);
    const handleSubmit = async () => {
        // if has pickup, update pickup subpayload, else update subpayload on bulk
        const subPayLoadOnly = payloadList.filter(p => !_.isNil(p.subCommodity));
        const numOfBags = _.get(
            payloadList.find(p => _.isNil(p.subCommodity)),
            'amount',
            0
        );
        let subComBagEquiv = getComEquivalentPayload(commodities, subPayLoadOnly);
        let newCommodityAmount = subComBagEquiv + numOfBags;
        const res2 = await http.post(`/bulks/updateCommodityAmount`, {
            bulkId: _.get(bulk, '_id', ''),
            commodityAmount: newCommodityAmount
        });
        if (_.isNil(_.get(bulk, 'pickup'))) {
            const res = await http.post(`/bulks/updatePickupLessSubPayloads`, {
                bulkId: _.get(bulk, '_id', ''),
                subPayloads: subPayLoadOnly
            });
            if (res.ok) {
                refreshBulk();
            }
        } else {
            let newPayload = _.cloneDeep(_.get(bulk, `pickup.payload`));
            _.set(newPayload, `${skuType}`, newCommodityAmount);
            const res = await http.post(`/pickups/updateSubPayloads`, {
                pickupId: _.get(bulk, 'pickup._id', ''),
                subPayloads: subPayLoadOnly,
                payload: newPayload
            });
            if (res.ok) {
                refreshBulk();
            }
        }
        onClose();
    };

    useEffect(() => {
        initPayloadList();
    }, []);

    function initPayloadList() {
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);

        const subPayloads = _.isNil(_.get(bulk, 'pickup'))
            ? _.get(bulk, 'pickupLessSubPayloads', []).filter(
                  subPayload => subPayload.skuType === _.get(bulk, 'skuType', '')
              )
            : _.get(bulk, 'pickup.subPayloads', []).filter(
                  subPayload => subPayload.skuType === _.get(bulk, 'skuType', '')
              );
        let subComBagEquiv = getComEquivalentPayload(commodities, subPayloads);
        let numOfBags = payloadAmount - subComBagEquiv;
        let newPayloadList = [];
        newPayloadList.push({
            color: _.get(currentSkuCom, 'color', colors.blue[300]),
            icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
            amount: numOfBags,
            name: _.get(bulk, 'commodityUOM', 'bag') + 's',
            skuType: skuType
        });
        subCommodityLookup.forEach(subCom => {
            let subComId = _.get(subCom, '_id', null);
            if (subComId !== null) {
                newPayloadList.push({
                    color: _.get(subCom, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCom, 'iconName', ''))],
                    amount: _.get(_.find(subPayload, { subCommodity: subComId }), 'amount', 0),
                    name: shortenSubComName(_.get(subCom, `name.${lang}`, '')),
                    subCommodity: subComId,
                    skuType: skuType
                });
            }
        });
        setPayloadList(newPayloadList);
        return newPayloadList;
    }
    function updatePayloadList(newAmount, subCommodity) {
        if (isValidCommodityAmount(newAmount, 0, 99)) {
            let newPayloadList = _.cloneDeep(payloadList);
            if (subCommodity) {
                for (let i = 0; i < payloadList.length; i++) {
                    if (newPayloadList[i].subCommodity === subCommodity) {
                        newPayloadList[i].amount = newAmount;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < payloadList.length; i++) {
                    if (_.isNil(newPayloadList[i].subCommodity)) {
                        newPayloadList[i].amount = newAmount;
                        break;
                    }
                }
            }
            setPayloadList(newPayloadList);
        }
    }

    return (
        <Dialog open={open} maxWidth="sm" fullWidth fullScreen={mobileMode}>
            <DialogTitle>Update Payload</DialogTitle>

            <DialogContent>
                {payloadList.map(payload => {
                    return (
                        <TextField
                            fullWidth
                            data-cy="number-of-items-input"
                            name="number-of-items-input"
                            label={`Number of ${payload.name} to process`}
                            value={payload.amount}
                            type="number"
                            variant="outlined"
                            style={{
                                marginTop: theme.spacing.unit * 2
                            }}
                            InputProps={{
                                inputProps: { min: 0, max: 99 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            disabled={payload.amount <= 0}
                                            onClick={() => {
                                                updatePayloadList(payload.amount - 1, payload.subCommodity);
                                            }}
                                            style={{
                                                height: theme.spacing.unit * 4,
                                                minWidth: theme.spacing.unit * 6,
                                                marginRight: theme.spacing.unit,
                                                padding: 0
                                            }}
                                        >
                                            <Icon>remove</Icon>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                updatePayloadList(payload.amount + 1, payload.subCommodity);
                                            }}
                                            style={{
                                                height: theme.spacing.unit * 4,
                                                minWidth: theme.spacing.unit * 6,
                                                marginRight: theme.spacing.unit,
                                                padding: 0
                                            }}
                                        >
                                            <Icon>add</Icon>
                                        </Button>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MDIcon path={payload.icon} size={1} color={payload.color} />
                                    </InputAdornment>
                                )
                            }}
                            onChange={e => {
                                updatePayloadList(e.target.value, payload.subCommodity);
                            }}
                        />
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => {
                        onClose();
                    }}
                >
                    {loc('close', lang)}
                </Button>
                <Button data-cy="bulk-counter-bags-submit" color="primary" onClick={handleSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EditNumberOfBagsDialogV2));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

export const getComEquivalentPayload = (commodities, subPayloads) => {
    if (_.isEmpty(subPayloads)) return 0;

    const tmpSubPayloads = _.cloneDeep(subPayloads);
    _commodity.populateSubPayloadsSubCommodities(tmpSubPayloads, commodities);

    let commodityUnitEquivalent = 0;
    for (let subPayload of tmpSubPayloads) {
        const amount = subPayload.amount;
        const multiplier = _.get(subPayload, 'subCommodity.multiplier', 0);

        commodityUnitEquivalent += Math.ceil(amount * multiplier);
    }
    return commodityUnitEquivalent;
};

const isValidCommodityAmount = (rawNum, min, max) => {
    if (rawNum === '') {
        return true;
    }

    const number = Number(rawNum);
    if (!_.isNaN(number) && Number.isInteger(number) && number >= min && number <= max) {
        return true;
    }

    return false;
};
